/// <reference types="node"/> // somehow not works on cli from typings.d.ts file
import { Injector, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  imports: [ ],
  declarations: [ ],
  exports: [ ],
  providers: [ CookieService ]
})
export class MiscModule {
  static injector: Injector;

  constructor(injector: Injector) {
    MiscModule.injector = injector;
  }
}
