import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { OverlayService } from '@upero/services';


@Component({
  selector: 'upero-max-qty-modal',
  templateUrl: './max-qty-modal.component.html',
  styleUrls: ['./max-qty-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxQtyModalComponent implements OnInit
{

  constructor(private overlayService: OverlayService)
  {
  }

  ngOnInit(): void
  {
  }

  public close(): void
  {
    this.overlayService.closeAll();
  }

}
