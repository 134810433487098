import { Inject, Injectable } from '@angular/core';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService
{
  constructor(
    private http: ApiAuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  sendFeedback(rating)
  {
    return this.http.post(this.env.apiPath + 'users/howlikely ', rating);
  }
}
