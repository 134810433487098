import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@upero/store';
import { BaseBlockComponent, PromoCaseStudyFields } from '@upero/misc';
import {CollectionsService} from "@upero/collections";

@Component({
  selector: 'upero-promo-case-study',
  templateUrl: './promo-case-study.component.html',
  styleUrls: ['./promo-case-study.component.scss']
})
export class PromoCaseStudyComponent extends BaseBlockComponent<PromoCaseStudyFields>  implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  deviceType$ = this.store.select<string>('deviceType');
  caseStudy;

  constructor(
    private collectionsService: CollectionsService,
    private store: Store
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.collectionsService
      .getCollectionEntriesById(this.block.fields.caseStudy, 1).subscribe(data => {
        this.caseStudy = data;
      })
  }
}
