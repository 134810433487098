import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitService } from '@upero/routing';

@Directive({
  selector: '[uperoRouterLinkSwipeProtected]'
})
export class RouteLinkSwipeProtectedDirective
{
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('uperoRouterLinkSwipeProtected') link: any;

  clientX = 0;

  @HostBinding('class')
  elementClass = 'hover';

  @HostListener('mousedown', ['$event'])
  mouseDownListener(event: MouseEvent)
  {
    this.checkForSwipe(event);
  }

  @HostListener('mouseup', ['$event'])
  mouseUpListener(event: MouseEvent)
  {
    this.checkForSwipe(event);
  }

  constructor(private router: Router)
  {
  }

  checkForSwipe(event: MouseEvent)
  {
    if (event.type === 'mousedown') {
      this.clientX = event.clientX;
    } else {
      if (this.clientX !== event.clientX) {
      } else {
          this.router.navigate(AppInitService.doBrandModUrl(this.link, true));
      }
    }
  }
}
