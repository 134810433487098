import {Directive, HostBinding, Input} from '@angular/core';
import {Store} from "@upero/store";

@Directive({
  selector: '[uperoBgSetter]'
})
export class BgSetterDirective {
  @Input() setBg? = true;
  @Input() setClass? = '';
  currentBrand = this.store.selectForLocal('currentBrand');

  @HostBinding('class')
  get elementClass(): string {
    if (this.setBg && !this.setClass) {
      return this.currentBrand ? 'bg-' + this.currentBrand.settings['primary'] : 'bg-primary';
    }

    if (this.setBg && this.setClass) {
      return this.currentBrand ? 'bg-' + this.currentBrand.settings[this.setClass] : 'bg-primary';
    }

    return '';
  }

  constructor(
    private store: Store,
  ) { }

}
