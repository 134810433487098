import { inject, InjectionToken } from '@angular/core';
import { DATA_LAYER } from './data-layer-token';
import { WINDOW } from './window-token';

export function getGtagFn(window: Window, dataLayer: any)
{
  // @ts-ignore
  return window ? window['gtag'] = window['gtag'] || function ()
    {
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments);
    }
    : null;
}

export const GTAG_FN = new InjectionToken('gtag-fn', {
  providedIn: 'root',
  factory: () => getGtagFn(inject(WINDOW), inject(DATA_LAYER))
});
