import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GOOGLE_ANALYTICS_SETTINGS_TOKEN } from './token/google-analytics-settings-token';
import { FACEBOOK_PIXEL_SETTINGS_TOKEN } from './token/facebook-pixel-settings-token';
import { ToastrModule } from 'ngx-toastr';
import { EnvironmentType } from '@upero/misc';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
  ]
})
export class ServicesModule
{
  constructor(@Optional() @SkipSelf() parentModule?: ServicesModule) {
    if (parentModule) {
      throw new Error(
        'ServicesModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(environmentConfig: EnvironmentType): ModuleWithProviders<ServicesModule>
  {
    return {
      ngModule: ServicesModule,
      providers: [
        {
          provide: GOOGLE_ANALYTICS_SETTINGS_TOKEN,
          useValue: environmentConfig.ga
        },
        {
          provide: FACEBOOK_PIXEL_SETTINGS_TOKEN,
          useValue: environmentConfig.fbPixelId
        }
      ]
    };
  }
}
