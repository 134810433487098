import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@upero/store';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { ApiAuthHttpClient } from '@upero/misc';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService
{
  private productGroups$ = {};

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  priceProductSku(itemToPrice)
  {
    const dataToSend = {
      itemToPrice
    }
    return this.http.post(this.env.apiPath + 'pricing/sku', dataToSend)
      .pipe(map(({ data }: any) => data));
  }

  findAll()
  {
    return this.http
      .get(this.env.apiPath + 'products')
      .pipe(map((data: any) => data));
  }

  getLinkedProducts(productId)
  {
    return this.http
      .get(this.env.apiPath + 'products/linked/' + productId)
      .pipe(map((data: any) => data));
  }

  getApiUrl(src)
  {
    //https://api.ysdev.co.uk/products/09dbfe0233634f8eca813e9da6ca5397.jpg?width=1353 =>
    //https://api.ysdev.co.uk/products/images/09dbfe0233634f8eca813e9da6ca5397.jpg?width=1353
    if (src.findIndex('.co.uk/products') !== -1) {
      return src.replace(/\/products\//gi, '/products/images/');
    }
    return src
  }

  getLinkedCaseStudies(productId)
  {
    return this.http
      .get(this.env.apiPath + 'products/case-studies/' + productId)
      .pipe(map((data: any) => data));
  }

  getProductDetails(slug)
  {
    return this.http
      .get(this.env.apiPath + 'products/public/' + slug)
      .pipe(map((data: any) => data));
  }

  getProductGroups(groupSlug?: string)
  {
    if (!this.productGroups$[groupSlug]) {
      this.productGroups$[groupSlug] = new ReplaySubject<any>();
      this.http
        .get(this.env.apiPath + 'product-groups' + (groupSlug ? '/' + groupSlug : ''))
        .pipe(map((data: any) => data))
        .subscribe(data => this.productGroups$[groupSlug].next(data));
    }

    return this.productGroups$[groupSlug];
  }

  productsByGroup(productGroup)
  {
    return this.http
      .get(this.env.apiPath + 'products/group/' + productGroup.id)
      .pipe(map((data: any) => data));
  }

  productsByGroupIds(productGroupIds: number[])
  {
    return this.http
      .post(this.env.apiPath + 'products/groups', productGroupIds)
      .pipe(map((data: any) => data));
  }

  productsByGroups(productGroups: Array<number>) {
    return this.http
      .get(this.env.apiPath + 'products/groups?groupId[]=' + productGroups.join('&groupId[]='))
      .pipe(map((data: any) => data));
  }

  productsDetailsByGroups(productGroups: Array<number>) {
    return this.http
      .get(this.env.apiPath + 'products/groups/details?groupId[]=' + productGroups.join('&groupId[]='))
      .pipe(map((data: any) => data));
  }

  getProductRange(slug)
  {
    return this.http
      .get(this.env.apiPath + 'product-range/public/' + slug)
      .pipe(map((data: any) => data));
  }

  productsByRange(productRange)
  {
    return this.http
      .get(this.env.apiPath + 'products/range/' + productRange.id)
      .pipe(map((data: any) => data));
  }

  getProductBySpace(slug)
  {
    return this.http
      .get(this.env.apiPath + 'product-groups/space/' + slug)
      .pipe(map((data: any) => data));
  }

  specialOffers()
  {
    return this.http
      .get(this.env.apiPath + 'products/offers/')
      .pipe(map((data: any) => data));
  }

  search(params)
  {
    const dataToSend = {
      params,
    };

    return this.http
      .post(this.env.apiPath + 'products/search', dataToSend)
      .pipe(map((data: any) => data));
  }

  getProductRanges()
  {
    return this.http
      .get(this.env.apiPath + 'product-range/public/all')
      .pipe(map((data: any) => data));
  }

  getProductFinishes()
  {
    return this.http
      .get(this.env.apiPath + 'product-finish')
      .pipe(map((data: any) => data));
  }

  getProductTypes()
  {
    return this.http
      .get(this.env.apiPath + 'product-types/')
      .pipe(map((data: any) => data));
  }
}
