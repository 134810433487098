<div class="container-fluid p-4">
    <button
        [uperoBrandRouterLink]="['/blog-and-news']"
        class="btn btn-primary me-2">
        All Categories
    </button>

    <button class="btn me-2"
        *ngFor="let c of categories"
        [uperoBrandRouterLink]="['/blog-and-news', c.slug]"
        [ngClass]="{'btn-warning' : category?.slug === c.slug, 'btn-primary' : category?.slug !== c.slug}"
    >{{c.name}}</button>

    <hr />


    <div *ngIf="articles.length">
        <!--        <input type="text" class="form-control" [(ngModel)]="params.freetext" />-->
        <div class="row">
            <div
                class="col-12 col-lg-3 mb-3"
                *ngFor="let s of articles">

                <div class="data-tile no-border hover" [uperoBrandRouterLink]="['/blog-and-news', s.category.slug, s.slug]">
                    <div class="gallery-image-tile" [ngStyle]="{'background-image': 'url(' + s.tile + ')'}"></div>
                    <div class="mt-2 text-start">
                        <h2 class="mb-1">{{s.title}}</h2>
                        <p class="mb-2">{{s.category.name}}</p>
                        <p>{{s.createdAt | date: 'dd MMM yyyy'}}</p>
                        <button class="btn btn-primary btn-slim" [uperoBrandRouterLink]="['/blog-and-news', s.category.slug, s.slug]">
                            View article
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>

</div>
