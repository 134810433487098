<section [ngClass]="{ 'overflow-hidden': showOverflow }">
  <div [ngClass]="block.fields.backgroundColour">
    <div class="container-fluid container--1920 g-0 px-4 py-5 px-md-5">
      <h2 class="fs-5 text-dark text-center text-xl-start px-1 px-lg-2 mb-3">
        {{ block.fields.title ? block.fields.title : 'Our Products' }}
      </h2>
      <div class="overflow-wrapper">
        <upero-tile-slider-keen
          *ngIf="productGroups.length > 0"
          [tiles]="productGroups"
          [showOverflow]="showOverflow"
          [block]="this.block"
          [arrowColour]="'rgb(38, 56, 71)'"></upero-tile-slider-keen>
      </div>
    </div>
  </div>
</section>
