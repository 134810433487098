import { Inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@upero/store';
import { EnvironmentType, ENVIRONMENT_CONFIG, ApiAuthHttpClient } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class ProductsBespokeService
{
  pricingLog = [];

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  sendBespokeForm(product, contact)
  {
    const dataToSend = {
      product,
      contact
    }
    return this.http
      .post(this.env.apiPath + 'bespoke-form', dataToSend)
      .pipe(map((data: any) => data));
  }

  getBespokeProductTypes()
  {
    return this.http
      .get(this.env.apiPath + 'product-types/bespoke')
      .pipe(map((data: any) => data));
  }

  getRangesByProductType(productType)
  {
    return this.http
      .get(this.env.apiPath + 'product-range/product-type/' + productType)
      .pipe(map((data: any) => data));
  }

  getRangesByProductTypeOption(productType, optionId)
  {
    return this.http
      .get(this.env.apiPath + 'product-range/product-type/' + productType + '/option/' + optionId)
      .pipe(map((data: any) => data));
  }

  getProductVariableOptionsByGroup(id)
  {
    return this.http
      .get(this.env.apiPath + 'product-variable-options/variable-group/' + id)
      .pipe(map((data: any) => data));
  }

  getProductVariableOptionsByGroupBespoke(id)
  {
    return this.http
      .get(this.env.apiPath + 'product-variable-options/variable-group/' + id + '/bespoke')
      .pipe(map((data: any) => data));
  }

  getProductFinishesByProductType(productType)
  {
    return this.http
      .get(this.env.apiPath + 'product-variable-options/variable-group/product-type/' + productType)
      .pipe(map((data: any) => data));
  }

  async productFinishesByProductType(productType)
  {
    const result: any = await this.http
      .get(this.env.apiPath + 'product-variable-options/variable-group/product-type/' + productType)
      .toPromise();
    return result.data;
  }

  async bespokeProductTypes()
  {
    const result: any = await this.http
      .get(this.env.apiPath + 'product-types/bespoke')
      .toPromise();
    return result.data;
  }

  async rangesByProductTypeOption(productType, optionId)
  {
    const result: any = await this.http
      .get(this.env.apiPath + 'product-range/product-type/' + productType + '/option/' + optionId)
      .toPromise();
    return result.data;
  }

  async productVariableOptionsByGroupBespoke(id)
  {
    const result: any = await this.http
      .get(this.env.apiPath + 'product-variable-options/variable-group/' + id + '/bespoke')
      .toPromise();
    return result.data;
  }

  async bespokeConfiguration(productRange, productType, supplierId)
  {
    const dataToSend = {
      productRange,
      productType,
      supplierId,
    };

    const result: any = await this.http
      .post(this.env.apiPath + 'product-range/product-type/configuration/public', dataToSend)
      .toPromise();
    return result.data;
  }

  async loadTestParams()
  {
    const result: any = await this.http
      .get(this.env.apiPath + 'products/test/bespoke')
      .toPromise();
    return result.data;
  }

  async writeTestResults(testResult)
  {
    const result: any = await this.http
      .post(this.env.apiPath + 'products/test/bespoke', testResult)
      .toPromise();
    return result.data;
  }

  loadBespokeConfiguration(productType, supplierId, rangeId)
  {
    const dataToSend: any = {
      productType,
      supplierId,
    };
    if (rangeId) {
      dataToSend.rangeId = rangeId;
    }
    return this.http
      .post(this.env.apiPath + 'product-range/product-type/configuration/public/V2', dataToSend)
      .pipe(map((data: any) => data));
  }

  calcBespokeCost(product, costsSQM)
  {
    this.pricingLog = [];
    // this.pricingLog.push('- - - START OF PRICING')
    product.unitCostBreakdown = this.resetUnitCostBreakdown()

    const heightToUseForCalculations = product.height;

    if (product.range) {
      product.quantityMetres = +product.quantity;

      product.unitCostBreakdown.squareMetres = (((+product.width / 1000) * (+product.length / 1000)) * product.quantity).toFixed(3);
      product.unitCostBreakdown.linearMetres = ((((+product.width * 2) / 1000) * ((+product.length * 2) / 1000)) * product.quantity).toFixed(3);

      if (product.sellUnit.id === 'PSM') {
        product.unitCostBreakdown.singlePieceSize = +((+product.width / 1000) * (+product.length / 1000)).toFixed(2);
        product.unitCostBreakdown.piecesRequired = Math.ceil(+product.quantity / +product.unitCostBreakdown.singlePieceSize);
        product.unitCostBreakdown.squareMetres = +product.unitCostBreakdown.singlePieceSize * +product.unitCostBreakdown.piecesRequired;
      }

      // if length is random then we do an alternative PSM which does not calculate the pieces
      if (product.sellUnit.id === 'PSM' && product.lengthRandom) {
        product.unitCostBreakdown.singlePieceSize = 0;
        product.unitCostBreakdown.piecesRequired = 0;
        product.unitCostBreakdown.squareMetres = product.quantity;
      }

      if (product.sellUnit.id === 'P') {
        product.quantityMetres = +(((product.width / 1000) * (product.length / 1000)) * product.quantity).toFixed(3);
      } else {
        product.quantityMetres = +product.quantity;
      }

      if (product.type.id === 'coping') {
        product.quantityMetres = product.quantity;

        if (product.lengthRandom) {
          product.length = 1000;
        }
      }
      if (product.type.id === 'coping' && product.finish.id === 71) {
        product.quantityMetres = (((product.width / 1000) * (product.length / 1000)) * product.quantity).toFixed(3);
      }

      if (product.type.id === 'coping') {
        if (product.sellUnit.id === 'PLM' && product.lengthRandom) {
          product.unitCostBreakdown.allowPieces = 0;
          product.unitCostBreakdown.singlePieceSize = 0;
          product.unitCostBreakdown.piecesRequired = 0;
        } else {
          product.unitCostBreakdown.allowPieces = 1;

          product.unitCostBreakdown.singlePieceSize = (+product.length / 1000).toFixed(2);
          product.unitCostBreakdown.piecesRequired = Math.ceil(+product.quantity / +product.unitCostBreakdown.singlePieceSize);
          if (product.finish.id === 72) {
            product.quantityMetres = (+product.unitCostBreakdown.singlePieceSize * +product.unitCostBreakdown.piecesRequired).toFixed(2);
          }
          product.unitCostBreakdown.squareMetres = (+product.length / 1000) * (+product.width / 1000) * +product.unitCostBreakdown.piecesRequired;
        }
      }

      if (product.purchaseUnit === 'PSM') {
        product.weightPerSellUnit = +product.range.baseWeight * (+heightToUseForCalculations / 1000);
        product.pricePerSellUnit = this.calculateSQMPricing(product, costsSQM);
        product.unitCostBreakdown.unitCost = +product.pricePerSellUnit;
      }
      if (product.purchaseUnit === 'PLM') {
        const l = (+product.length / 1000);
        const w = (+product.width / 1000);
        const t = (+heightToUseForCalculations / 1000);

        product.weightPerSellUnit = +((l * w * t) * +product.range.baseWeight).toFixed(2);
        product.pricePerSellUnit = +((l * w * t) * +product.range.baseCost).toFixed(2);

      }
      if (product.purchaseUnit === 'CM') {
        // let l = 1;
        // if (!product.lengthRandom) {
        //     l = (+product.length / 1000);
        // }
        const l = (+product.length / 1000);
        const w = (+product.width / 1000);
        const t = (+heightToUseForCalculations / 1000);
        product.weightPerSellUnit = +((l * w * t) * +product.range.baseWeight).toFixed(2);
        product.pricePerSellUnit = +((l * w * t) * +product.range.baseCost).toFixed(2);
        product.unitCostBreakdown.unitCost = +product.pricePerSellUnit;
      }

      if (product.purchaseUnit === 'PT') {
        product.weightPerSellUnit = 1000;
        product.pricePerSellUnit = 0;
        this.pricingLog.push(`Pricing method used was PT`);
        this.pricingLog.push(`The calculated price was £${product.pricePerSellUnit}`)
      }

      if (product.purchaseUnit === 'P') {
        // this.pricingLog.push(`Pricing method used was P`);
        product.weightPerSellUnit = +product.range.baseWeight * (+heightToUseForCalculations / 1000);

        if (!costsSQM) {
          // this.pricingLog.push(`No sqm pricing so using PLM`);
          const l = (+product.length / 1000);
          const w = (+product.width / 1000);
          const t = (+heightToUseForCalculations / 1000);

          product.weightPerSellUnit = +((l * w * t) * +product.range.baseWeight).toFixed(2);
          product.pricePerSellUnit = +((l * w * t) * +product.range.baseCost).toFixed(2);
        } else {
          // this.pricingLog.push(`SQM pricing exists so using SQM`);
          product.pricePerSellUnit = this.calculateSQMPricing(product, costsSQM);
          product.unitCostBreakdown.unitCost = +product.pricePerSellUnit;
        }

        // this.pricingLog.push(`The calculated price was £${product.pricePerSellUnit}`)
      }
      product.unitCostBreakdown.baseUnitCost = +product.pricePerSellUnit;


      if (product.additionalRequirements) {
        // this.pricingLog.push(`There are additional requirements to consider so were working out additional costs`);
        let additionalCost = 0;
        // this.pricingLog.push(`Additional cost type is ${product.additionalRequirements.costType}`);

        if (product.additionalRequirements.costType === 'PLM') {
          additionalCost = +(product.additionalRequirements.cost *
            (+product.length / 1000) * (+product.width / 1000) * (+heightToUseForCalculations / 1000)).toFixed(2);
        } else {
          additionalCost = +(product.additionalRequirements.cost * (+heightToUseForCalculations / 1000)).toFixed(2);
        }
        // this.pricingLog.push(`Additional costs of ${additionalCost} were added`);
        product.pricePerSellUnit = (+product.pricePerSellUnit + +additionalCost).toFixed(2);
      }

      const sidesArray = ['front', 'back', 'left', 'right'];

      // risers
      if (product.riser) {
        product = this.calcRiserCost(product);
      }

      // weathering
      if (product.weathering) {
        product = this.calcWeatheringCost(product);
      }

      // finish top
      if (product.finishTop) {
        product = this.calcFinishTopCost(product);
      }

      // finish bottom without side options
      if (product.finishBottom && product.finishBottom.id) {
        product = this.calcFinishBottomNoSides(product);
      }

      // finish bottom with side options
      if (product.finishBottom && !product.finishBottom.id) {
        product = this.calcFinishBottomWithSides(product, sidesArray);
      }

      // edge finish with no side options
      if (product.finishEdge && product.finishEdge.id) {
        product = this.calcFinishEdgeNoSides(product);
      }
      // finish edge with side options
      if (product.finishEdge && !product.finishEdge.id) {
        product = this.calcFinishEdgeWithSides(product, sidesArray)
      }

      product.unitCost = product.pricePerSellUnit;

      if (product.type.id !== 'steps') {
        product.totalCost = product.unitCost * product.quantityMetres;
      }
      if (product.type.id === 'steps') {
        product.totalCost = product.unitCost * product.quantity;
      }

    }

    this.store.set('bespokePricingLog', this.pricingLog);
    product = this.convertSellPrice(product);

    this.pricingLog.push('- - - END OF PRICING')
    return product;
  }

  resetUnitCostBreakdown()
  {
    return {
      squareMetres: 0,
      linearMetres: 0,
      baseUnitCost: 0,
      unitCost: 0,
      singlePieceSize: 0,
      piecesRequired: 0,
      riserUnitCost: 0,
      weathering: 0,
      finishTop: 0,
      finishEdge: 0,
      finishBottom: 0,
      corner: 0,
      cornersLR: 0,
      cornersRR: 0,
      end: 0,
      totalUnitCost: 0,
      totalCharge: 0,
      sellConversion: '',
    }
  }

  convertSellPrice(product)
  {
    product.unitCostBreakdown.unitCost = +product.unitCostBreakdown.baseUnitCost;
    if (product.sellUnit.id === 'P' && product.purchaseUnit === 'PSM') {
      product.unitCostBreakdown.sellConversion = 'P over PSM'
      product.unitCostBreakdown.totalUnitCost = +product.unitCostBreakdown.unitCost * +product.quantityMetres;
      product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalUnitCost;
      product.pricePerSellUnit = +product.unitCostBreakdown.baseUnitCost * (+product.quantityMetres / +product.quantity);
      product.weightPerSellUnit = +product.weightPerSellUnit * (+product.quantityMetres / +product.quantity);
    }
    if (product.sellUnit.id === 'P' && product.purchaseUnit === 'CM') {
      product.unitCostBreakdown.sellConversion = 'P over CM'
      product.unitCostBreakdown.totalUnitCost = +product.unitCostBreakdown.unitCost * +product.quantity;
      product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalUnitCost;
      product.pricePerSellUnit = +product.unitCostBreakdown.unitCost;
    }
    if (product.sellUnit.id === 'PSM') {
      if (product.unitCostBreakdown.piecesRequired > 0) {
        product.unitCostBreakdown.sellConversion = 'PSM over pieces'
        product.unitCostBreakdown.totalUnitCost = (+product.unitCostBreakdown.unitCost * +product.unitCostBreakdown.singlePieceSize) * +product.unitCostBreakdown.piecesRequired;
        product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalUnitCost;
        product.pricePerSellUnit = +product.unitCostBreakdown.baseUnitCost;

      } else {
        product.unitCostBreakdown.sellConversion = 'PSM standard'
        product.unitCostBreakdown.totalUnitCost = +product.unitCostBreakdown.unitCost * +product.quantityMetres;
        product.unitCostBreakdown.totalCost = (+product.unitCostBreakdown.unitCost * +product.quantity);
        product.pricePerSellUnit = +product.unitCostBreakdown.unitCost * +product.quantityMetres;
      }
    }
    if (product.sellUnit.id === 'PLM' && product.purchaseUnit === 'CM') {
      product.unitCostBreakdown.sellConversion = 'PLM over CM'

      if (product.lengthRandom) {
        product.unitCostBreakdown.totalCost = (+product.unitCostBreakdown.unitCost * +product.quantity);
      } else {
        product.unitCostBreakdown.totalCost = (+product.unitCostBreakdown.unitCost * +product.unitCostBreakdown.piecesRequired)
      }

      product.unitCostBreakdown.totalUnitCost = +product.unitCostBreakdown.unitCost * +product.quantityMetres;
      product.pricePerSellUnit = +product.unitCostBreakdown.baseUnitCost * (+product.quantityMetres / +product.quantity);
    }

    if (product.sellUnit.id === 'PLM' && product.purchaseUnit === 'PSM') {
      product.unitCostBreakdown.sellConversion = 'PLM over PSM'
      if (product.unitCostBreakdown.piecesRequired > 0) {
        product.pricePerSellUnit = +product.unitCostBreakdown.baseUnitCost * ((+product.length / 1000) * (+product.width / 1000));
        product.unitCostBreakdown.totalUnitCost = +product.pricePerSellUnit * +product.unitCostBreakdown.piecesRequired;
        product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalUnitCost;

      } else {
        product.unitCostBreakdown.totalUnitCost = +product.unitCostBreakdown.unitCost * +product.quantityMetres;
        product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalUnitCost;
        product.pricePerSellUnit = +product.unitCostBreakdown.unitCost * +product.quantityMetres;
      }
    }

    if (product.type.id === 'coping') {
      if (product.lengthRandom) {

        product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalCost +
          (+product.unitCostBreakdown.finishBottom * +product.quantity) +
          (+product.unitCostBreakdown.cornersRR) +
          (+product.unitCostBreakdown.cornersLR) +
          (+product.unitCostBreakdown.end);

        product.unitCostBreakdown.totalCost = product.unitCostBreakdown.totalCost +
          (+product.unitCostBreakdown.finishTop * product.unitCostBreakdown.squareMetres) +
          (+product.unitCostBreakdown.finishEdge * product.quantity) +
          (+product.unitCostBreakdown.weathering * product.unitCostBreakdown.squareMetres);

        product.weightTotal = +product.weightPerSellUnit * +product.quantity;

      } else {

        product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalCost +
          (+product.unitCostBreakdown.finishBottom * +product.unitCostBreakdown.piecesRequired) +
          (+product.unitCostBreakdown.cornersRR) +
          (+product.unitCostBreakdown.cornersLR) +
          (+product.unitCostBreakdown.end);

        product.unitCostBreakdown.totalCost = product.unitCostBreakdown.totalCost +
          (+product.unitCostBreakdown.finishTop * +product.unitCostBreakdown.squareMetres) +
          (+product.unitCostBreakdown.finishEdge * +product.unitCostBreakdown.piecesRequired) +
          (+product.unitCostBreakdown.weathering * +product.unitCostBreakdown.squareMetres);

        product.weightTotal = +product.weightPerSellUnit * +product.unitCostBreakdown.piecesRequired;
      }
    } else {
      product.unitCostBreakdown.totalCost = +product.unitCostBreakdown.totalCost +
        (+product.unitCostBreakdown.finishBottom * +product.quantity) +
        (+product.unitCostBreakdown.cornersRR) +
        (+product.unitCostBreakdown.cornersLR) +
        (+product.unitCostBreakdown.end);

      product.unitCostBreakdown.totalCost = product.unitCostBreakdown.totalCost +
        (+product.unitCostBreakdown.finishTop * product.unitCostBreakdown.squareMetres) +
        (+product.unitCostBreakdown.finishEdge * product.quantity) +
        (+product.unitCostBreakdown.weathering * product.unitCostBreakdown.squareMetres);

      product.weightTotal = +product.weightPerSellUnit * +product.quantity;

    }

    return product;
  }

  calcRiserCost(product)
  {
    // this.pricingLog.push('- - - START OF RISER CALCULATION')
    let l = (+product.length / 1000);
    const w = (+product.riser.thickness / 1000);
    const t = (+product.riser.height / 1000);

    if (product.finishEdge && product.finishEdge.left.id) {
      l = +l + (+product.width / 1000);
    }
    if (product.finishEdge && product.finishEdge.right.id) {
      l = +l + (+product.width / 1000);
    }

    product.riser.weightPerSellUnit = +((l * w * t) * +product.range.baseWeight).toFixed(2);
    product.riser.pricePerSellUnit = +((l * w * t) * +product.range.baseCost).toFixed(2);
    product.riser.weight = product.riser.weightPerSellUnit * product.quantity;
    product.riser.price = product.riser.pricePerSellUnit * product.quantity;

    product.unitCostBreakdown.riserUnitCost = product.riser.pricePerSellUnit;
    // this.pricingLog.push('- - - END OF RISER CALCULATION')

    return product;
  }


  calcWeatheringCost(product)
  {
    // this.pricingLog.push(`- - - START OF WEATHERING`);
    // isolate the right cost range
    const cost = this.getFinishBaseCost(product.weathering.costs, product.height);
    const additionalCost = +cost;

    // const additionalCost = +(+cost * ((+product.width / 1000) * (+product.length / 1000))).toFixed(2);
    product.pricePerSellUnit = (+product.pricePerSellUnit + +additionalCost).toFixed(2);
    // this.pricingLog.push(`Additional weathering cost calculation ${cost} * ${product.width} / 1000) * ${product.length / 1000}`);
    // this.pricingLog.push(`Additional weathering cost was ${additionalCost} - pricePerSellUnit now ${product.pricePerSellUnit}`);

    product.unitCostBreakdown.weathering = +additionalCost;

    // this.pricingLog.push(`- - - END OF WEATHERING`);

    return product;
  }


  calcFinishTopCost(product)
  {

    if (product.finishTop.costType) {
      // this.pricingLog.push('- - - START OF TOP FINISH')
      // isolate the right cost range
      const cost = this.getFinishBaseCost(product.finishTop.costs, product.height);
      const additionalCost = cost;
      // let additionalCost = this.calcTotalFinishCostByType(product.finishTop.costType, cost, product.length, product.width, 1);
      product.unitCostBreakdown.finishTop = +additionalCost;

      product.pricePerSellUnit = (+product.pricePerSellUnit + +additionalCost).toFixed(2);

      product.unitCostBreakdown.finishTop = +additionalCost;
    }

    // this.pricingLog.push('- - - END OF TOP FINISH')
    return product;
  }


  calcFinishBottomNoSides(product)
  {

    this.pricingLog.push('- - - START OF FINISH BOTTOM NO SIDES')
    const cost = this.getFinishBaseCost(product.finishBottom.costs, product.height);
    let additionalCost = 0;

    if (product.type.id !== 'piercaps') {

      additionalCost = +additionalCost + +this.calcTotalFinishCostByType(
        product.finishBottom.costType,
        cost,
        product.length,
        product.width,
        product.type.masonryLengthsForCalc
      );
    }
    if (product.type.id === 'piercaps') {
      additionalCost = +additionalCost + +this.calcTotalFinishCostByType(
        product.finishBottom.costType,
        cost,
        product.length,
        product.width,
        2
      );

      additionalCost = +additionalCost + +this.calcTotalFinishCostByType(
        product.finishBottom.costType,
        cost,
        product.width,
        product.length,
        2
      );

    }


    product.pricePerSellUnit = (+product.pricePerSellUnit + +additionalCost).toFixed(2);

    product.unitCostBreakdown.finishBottom = +additionalCost;

    // if there is an end piece
    if (product.ends > 0) {
      product.unitCostBreakdown.end = product.unitCostBreakdown.end + this.calcEndCost(+cost, +product.width, +product.ends);
    }
    if (product.cornersLR > 0) {
      product.unitCostBreakdown.cornersLR = product.unitCostBreakdown.cornersLR + this.calcCornerCost(+cost, +product.width, +product.cornersLR);

    }
    if (product.cornersRR > 0) {
      product.unitCostBreakdown.cornersRR = product.unitCostBreakdown.cornersRR + this.calcCornerCost(+cost, +product.width, +product.cornersRR);

    }
    this.pricingLog.push(`- - - END OF FINISH BOTTOM NO SIDES`);

    return product;
  }

  calcFinishBottomWithSides(product, sidesArray)
  {
    let additionalCost = 0;
    if (product.finishBottom.front !== '') {
      const cost = this.getFinishBaseCost(product.finishBottom.front.costs, product.height);
      additionalCost = +additionalCost + this.calcTotalFinishCostByType(
        product.finishBottom.front.costType,
        cost,
        product.length,
        product.width,
        1
      );
    }
    if (product.finishBottom.back !== '') {
      const cost = this.getFinishBaseCost(product.finishBottom.back.costs, product.height);
      additionalCost = +additionalCost + this.calcTotalFinishCostByType(
        product.finishBottom.back.costType,
        cost,
        product.length,
        product.width,
        1
      );
    }

    if (product.finishBottom.left !== '') {
      const cost = this.getFinishBaseCost(product.finishBottom.left.costs, product.height);
      additionalCost = +additionalCost + this.calcTotalFinishCostByType(
        product.finishBottom.left.costType,
        cost,
        product.width,
        product.length,
        1
      );

    }
    if (product.finishBottom.right !== '') {
      const cost = this.getFinishBaseCost(product.finishBottom.right.costs, product.height);
      additionalCost = +additionalCost + this.calcTotalFinishCostByType(
        product.finishBottom.left.costType,
        cost,
        product.width,
        product.length,
        1
      );
    }
    product.pricePerSellUnit = (+product.pricePerSellUnit + +additionalCost).toFixed(2);
    product.unitCostBreakdown.finishBottom = +additionalCost;


    return product;
  }

  calcTotalFinishCostByType(costType, finishCost, length, width, sidesToCalcForFinishes)
  {
    let additionalCost = 0;
    // this.pricingLog.push('- - - START OF CALC TOTAL FINISH COST BY TYPE')
    // this.pricingLog.push(`Using a costtype of ${costType}`)
    if (costType === 'PLM') {
      additionalCost = +(+finishCost * (+length / 1000)).toFixed(2);
      // this.pricingLog.push(`Used a PLM calculation of ${finishCost} * ${length} / 1000`)
    } else {
      additionalCost = +(+finishCost * ((+length / 1000) * (+width / 1000))).toFixed(2);
      // this.pricingLog.push(`Used a NON PLM calculation of ${finishCost} * (${+length} / 1000) * (${width} / 1000)`)
    }
    if (sidesToCalcForFinishes > 0) {
      // this.pricingLog.push(`Multiplied additional cost by ${sidesToCalcForFinishes}. Value added was ${additionalCost}`);
      additionalCost = +additionalCost * +sidesToCalcForFinishes;
    }
    // this.pricingLog.push(`Additional cost added was ${additionalCost}`);
    // this.pricingLog.push('- - - END OF CALC TOTAL FINISH COST BY TYPE')
    return additionalCost;
  }

  calcTotalEdgeCostByType(costType, finishCost, length, width, widthsToCalcForFinishes, lengthsToCalcForFinishes)
  {
    let additionalCost = 0;
    // this.pricingLog.push('- - - START OF CALC TOTAL EDGE COST BY TYPE')
    if (costType === 'PLM') {
      additionalCost = +(+finishCost * (+length / 1000)).toFixed(2);
      // this.pricingLog.push(`Used a calculation of ${finishCost} * ${length} / 1000`)
    } else {
      additionalCost = +(+finishCost * ((+length / 1000) * (+width / 1000))).toFixed(2);
      // this.pricingLog.push(`Used a calculation of ${finishCost} * (${+length} / 1000) * (${width} / 1000)`)
    }

    // this.pricingLog.push(`Additional cost added was ${additionalCost}`);
    // this.pricingLog.push('- - - END OF CALC TOTAL EDGE COST BY TYPE')
    return additionalCost;
  }

  calcFinishEdgeNoSides(product)
  {
    // this.pricingLog.push(`- - - START OF FINISH EDGE NO SIDES`);
    // this.pricingLog.push(product.finishEdge.costs);

    let cost = this.getFinishBaseCost(product.finishEdge.costs, product.height);

    let additionalCost = this.calcTotalEdgeCostByType(
      product.finishEdge.costType,
      cost,
      product.length,
      product.width,
      +product.type.masonryWidthsForCalc,
      +product.type.masonryLengthsForCalc
    );

    // this.pricingLog.push(`Adding finishEdge to side with PLM pricing`);
    additionalCost = +(+cost * ((+product.length * +product.type.masonryLengthsForCalc) / 1000)).toFixed(2);
    additionalCost = +additionalCost + +(+cost * ((+product.width * +product.type.masonryWidthsForCalc) / 1000)).toFixed(2);

    product.pricePerSellUnit = (+product.pricePerSellUnit + +additionalCost).toFixed(2);

    product.unitCostBreakdown.finishEdge = +additionalCost;

    this.pricingLog.push(`Additional cost was ${additionalCost} - pricePerSellUnit now ${product.pricePerSellUnit}`);

    // if there is an end piece
    if (product.ends > 0) {
      // this.pricingLog.push(`Detected ${product.ends} required`)
      product.unitCostBreakdown.end = product.unitCostBreakdown.end + this.calcEndCost(+cost, +product.width, +product.ends);
    }
    if (product.cornersLR > 0) {
      product.unitCostBreakdown.cornersLR = product.unitCostBreakdown.cornersLR + this.calcCornerCost(+cost, +product.width, +product.cornersLR);

    }
    if (product.cornersRR > 0) {
      product.unitCostBreakdown.cornersRR = product.unitCostBreakdown.cornersRR + this.calcCornerCost(+cost, +product.width, +product.cornersRR);

    }

    // this.pricingLog.push(`- - - END OF FINISH EDGE NO SIDES`);
    return product;
  }

  calcFinishEdgeWithSides(product, sidesArray)
  {
    // this.pricingLog.push(`- - - START OF FINISH EDGE WITH SIDES`);
    let additionalCost = 0;
    for (let i = 0; i < sidesArray.length; i++) {
      // finish edge
      if (product.finishEdge[sidesArray[i]] !== '') {
        // isolate the right cost range
        const cost = this.getFinishBaseCost(product.finishEdge[sidesArray[i]].costs, product.height);
        let measurementToUse = product.length;
        if (sidesArray[i] === 'left' || sidesArray[i] === 'right') {
          measurementToUse = product.width;
        }

        additionalCost = additionalCost + this.calcTotalFinishCostByType(
          product.finishEdge[sidesArray[i]].costType,
          cost,
          measurementToUse,
          product.width,
          1
        );
        product.pricePerSellUnit = (+product.pricePerSellUnit + +additionalCost).toFixed(2);
        product.unitCostBreakdown.finishEdge = +additionalCost;
        // this.pricingLog.push(`Additional cost was ${additionalCost} - pricePerSellUnit now ${product.pricePerSellUnit}`);
      }
      // if there is an end piece and it's the first iteration of the loop
      // if there is an end piece
      if (product.ends > 0 && i === 0) {
        product.unitCostBreakdown.end = product.unitCostBreakdown.end + this.calcEndCost(+product.unitCostBreakdown.finishEdge, +product.width, +product.ends);

      }
      if (product.cornersLR > 0) {
        product.unitCostBreakdown.cornersLR = product.unitCostBreakdown.cornersLR + this.calcCornerCost(+product.unitCostBreakdown.finishEdge, +product.width, +product.cornersLR);

      }
      if (product.cornersRR > 0) {
        product.unitCostBreakdown.cornersRR = product.unitCostBreakdown.cornersRR + this.calcCornerCost(+product.unitCostBreakdown.finishEdge, +product.width, +product.cornersRR);

      }
    }
    // this.pricingLog.push(`- - - END OF FINISH EDGE WITH SIDES`);

    return product;
  }

  calcEndCost(masonryUnitCost, width, noOfEnds)
  {
    // this.pricingLog.push(`- - - START OF ADD END COSTS`);
    const endCost = +(+masonryUnitCost * ((+width / 1000) * noOfEnds)).toFixed(2);
    // this.pricingLog.push(`EndCost calculation is ${masonryUnitCost} * ${width} / ${1000} * ${noOfEnds} = ${endCost}`)
    // this.pricingLog.push(`- - - END OF ADD END COSTS`);
    return +endCost.toFixed(2);
  }

  calcCornerCost(masonryUnitCost, width, noOfCorners)
  {
    // this.pricingLog.push(`- - - START OF ADD CORNER COSTS`);
    const cornerCost = +(+masonryUnitCost * ((+width * noOfCorners) / 1000)).toFixed(2);
    // this.pricingLog.push(`CornerCost calculation is ${masonryUnitCost} * ${width} * ${noOfCorners} / 1000 = ${cornerCost}`)
    // this.pricingLog.push(`- - - END OF ADD CORNER COSTS`);
    return +cornerCost.toFixed(2);
  }

  getFinishBaseCost(costsArray, height)
  {
    // this.pricingLog.push(`START ISOLATING BASE COST`);

    let cost = 0;
    for (let ca = 0; ca < costsArray.length; ca++) {
      if (height >= +costsArray[ca].rangeMin && height <= +costsArray[ca].rangeMax) {
        cost = +costsArray[ca].cost;
      }
    }
    // this.pricingLog.push(`isolated a base cost of ${cost}`)
    // this.pricingLog.push(`- - - END OF ISOLATING BASE COST`);
    return cost;
  }

  calculateSQMPricing(sku, costsSQM)
  {
    // this.pricingLog.push('- - - START OF ISOLATING SQM PRICE')
    let pricingSet;
    let SQMPrice = 0;

    if ((sku.lengthRandom && !sku.widthRandom) || (sku.widthRandom && !sku.lengthRandom)) {
      pricingSet = costsSQM.setWidthRandom;
      // this.pricingLog.push(`pricingSet used was setWidthRandom`)
    }
    if (sku.lengthRandom && sku.widthRandom) {
      pricingSet = costsSQM.random;
      // this.pricingLog.push(`pricingSet used was random`)
    }
    if (!sku.lengthRandom && !sku.widthRandom) {
      pricingSet = costsSQM.setWidthSetLength;
      // this.pricingLog.push(`pricingSet used was setWidthSetLength`)
    }

    for (let i2 = 0; i2 < pricingSet.length; i2++) {
      if (+sku.height >= +pricingSet[i2].minThickness && +sku.height <= +pricingSet[i2].maxThickness) {
        SQMPrice = pricingSet[i2].cost;
        // this.pricingLog.push(`SQMPrice determined as ${SQMPrice}`)
      }
    }

    if (SQMPrice === 0) {
      this.pricingLog.push(`!!!! IT BORKED!!! --- !!!! wanted SQMPrice but none found`)
      SQMPrice = +(2200 * (+sku.height / 1000)).toFixed(2);
    }
    // this.pricingLog.push('- - - END OF ISOLATING SQM PRICE')

    return SQMPrice;
  }
}
