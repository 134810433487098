import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@upero/store';
import { CookieService } from 'ngx-cookie-service';
import * as FormData from 'form-data';
import {environment} from '../../../../../../apps/boilerplate/src/environments/environment';

@Injectable()
export class AuthRequestInterceptor implements HttpInterceptor
{

  constructor(private store: Store, private cookieService: CookieService)
  {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
  {
    // const token: any = this.cookieService.get('ysAuth');
    const apiKey = '6bf9284a-ebf9-4afa-a185-4d2b9bb29e85';
    const brand = this.store.selectForLocal('currentBrand');

    let headers = request.headers;

    headers = headers.set('domain', 'up');
    if (brand && brand.id) {
      headers = headers.set('brandId', String(brand.id));
    }
    headers = headers.set('api-key', apiKey);

    // for all non form FormData post data  we add content type json
    if (!headers.get('Content-Type') && !(request.body instanceof FormData)) {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (environment.emulateSslForLocalDev) {
      headers = headers.set('x-forwarded-proto', 'https');
    }
    const authReq = request.clone({ headers, withCredentials: true });

    return next.handle(authReq);
  }
}
