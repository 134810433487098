import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'upero-cta-banner-image',
  templateUrl: './cta-banner-image.component.html',
  styleUrls: ['./cta-banner-image.component.scss']
})
export class CtaBannerImageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
