import { Component, HostBinding, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { BaseBlockComponent, HeaderFields } from '@upero/misc';
import { Store } from '@upero/store';
import {
  AlertService,
  AuthService,
  FacebookPixelService,
  GoogleAnalyticsService, NotificationService,
  OverlayService, ProductService, ToolsService
} from '@upero/services';
import { CollectionsService } from '@upero/collections';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseBlockComponent<HeaderFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  user$ = this.store.select<any>('user');
  mobileMenu$ = this.store.select<boolean>('mobileMenu');
  mobileMenuUser$ = this.store.select<boolean>('mobileMenuUser');
  basket$ = this.store.select<any>('basket');
  notifications$ = this.store.select<any>('notifications');
  chatStore$ = this.store.select<any>('chatStore');
  siteSettings = this.store.selectForLocal('siteSettings');
  currentBrand = this.store.selectForLocal('currentBrand');
  menu$ = this.store.select<any>('menu');
  deviceType$ = this.store.select<string>('deviceType');
  favourites$ = this.store.select<string>('favourites');

  activeMenuImage = '';
  showPromoBar = false;

  rangesColors = {
    'Crazy Paving': 'bg-purple',
    Aged: 'bg-primary',
    Elland: 'bg-warning',
    Bancroft: 'bg-danger',
  };

  confirmationData = {
    open: false,
    title: '',
    detail: '',
    data: undefined,
  };

  closeTimer;

  items: any;
  productGroups: any;
  productRanges: any;

  colors = {
    primary: 'info',
  }

  isLoading = true;

  constructor(
    private store: Store,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private overlayService: OverlayService,
    private notificationService: NotificationService,
    private gaService: GoogleAnalyticsService,
    private fbService: FacebookPixelService,
    private toolsService: ToolsService,
    private collectionsService: CollectionsService,
    private route: ActivatedRoute,
    private productsService: ProductService,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    const currentUrl = this.router.url;
    this.showPromoBar = currentUrl === '/';

    this.collectionsService.getCollectionEntriesByType(this.block.fields.collectionType, 1)
      .subscribe((response) => {
      this.items = response;
    })

    this.getProductGroups();
  }

  getProductGroups()
  {
    this.productsService.getProductGroups().subscribe(data =>
    {
      this.productGroups = data.data;
      this.isLoading = false;
    });
  }

  toggleUserMenu()
  {
    this.store.set('mobileMenuUser', true);
  }

  toggleNotifications()
  {
    this.overlayService.toggle('notifications');
  }

  register()
  {
    this.store.set('RegisteredInBasketFlow', false);
    this.overlayService.toggle('registerModal');
  }

  logout()
  {
    const notifications = { unread: 0, data: [] };
    const chatStore = {
      unread: 0,
      groups: [],
      group: undefined,
      replyingTo: undefined,
      groupToOpen: undefined,
      messages: [],
    };

    this.store.set('chatStore', chatStore);
    this.store.set('notifications', notifications);

    this.notificationService.stopListening();
    this.authService.logout();
    //mantasthis.authService.clearAuthTimeout();
    this.router.navigate(AppInitService.doBrandModUrl(['login']));
  }

  logoutConfirmation()
  {
    this.confirmationData.title = 'Are you sure you want to logout?';
    this.confirmationData.data = {};
    this.confirmationData.detail = '';
    this.confirmationData.open = true;
  }

  confirmationComplete(e)
  {
    this.confirmationData.open = false;
    if (e.action) {
      this.logout();
    } else {
    }
  }

  closeSubMenu(menuItem)
  {
    this.closeTimer = setTimeout(() =>
    {
      menuItem.open = false;
    }, 100);
  }

  setActiveMenu(menuItem, trigger)
  {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
    }

    if (trigger === 'menuTrigger') {

      this.items.forEach((item) =>
      {
        item.open = false;
      });
      menuItem.open = true;
    }
  }

  setActiveMenuImage(src) {
    this.activeMenuImage = src;
  }

  unsetActiveMenuImage() {
    this.activeMenuImage = '';
  }

}
