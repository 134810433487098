import { Component, HostBinding, OnInit } from '@angular/core';
import { BaseBlockComponent, ProductRangesFields } from '@upero/misc';
import { ProductService } from '@upero/services';

@Component({
  selector: 'upero-product-ranges',
  templateUrl: './product-ranges.component.html',
  styleUrls: ['./product-ranges.component.scss']
})
export class ProductRangesComponent extends BaseBlockComponent<ProductRangesFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  productRanges = [];

  constructor(private productService: ProductService)
  {
    super();
    this.defaultFields = ProductRangesFields.default();
  }

  ngOnInit(): void
  {
    this.getProductGroups();
  }

  getProductGroups()
  {
    this.productService.getProductRanges().subscribe(data =>
    {
      this.productRanges = data.data;
    });
  }
}
