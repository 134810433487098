import { Component, HostBinding } from '@angular/core';
import { ProductTypesSliderComponent as ProductTypesSliderComponentBase } from '@upero/shared';

@Component({
  selector: 'upero-proxy-product-types-slider',
  templateUrl: './product-types-slider.component.html',
  styleUrls: ['./product-types-slider.component.scss']
})
export class ProductTypesSliderComponent extends ProductTypesSliderComponentBase
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };
}
