import { Component, Input, OnInit } from '@angular/core';
import {Store} from "@upero/store";

@Component({
  selector: 'upero-upero-footer',
  templateUrl: './upero-footer.component.html',
  styleUrls: ['./upero-footer.component.scss']
})
export class UperoFooterComponent implements OnInit {
  @Input() block: any;

  currentBrand = this.store.selectForLocal('currentBrand');

  constructor(private store: Store,) { }

  ngOnInit(): void {

  }


}
