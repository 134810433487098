import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesService } from './pages.service';
import {SettingsService} from "./settings.service";

@NgModule({
  imports: [CommonModule],
})
export class PagesModule {
  static forRoot(): ModuleWithProviders<PagesModule> {
    return {
      ngModule: PagesModule,
      providers: [
        PagesService,
        SettingsService
      ]
    };
  }
}

