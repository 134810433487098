import { Inject, Injectable } from '@angular/core';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { map } from 'rxjs/operators';
import { Store } from '@upero/store';


@Injectable({
    providedIn: 'root'
})
export class CustomerQuoteService
{

    constructor(
      private http: ApiAuthHttpClient,
      @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
    )
    { }

    getQuotePdf(quoteId: string)
    {
        return this.http.get(
            this.env.apiPath + `xero/quote/${quoteId}/pdf`,
            { responseType: 'arraybuffer' }
        );
    }

    getQuote(quoteId: string)
    {
        return this.http
            .get(this.env.apiPath + `xero/quote/${quoteId}`)
            .pipe(map((data: any) => data.data));
    }

    acceptQuote(quoteData, quoteId: string)
    {
        return this.http
            .patch(this.env.apiPath + `xero/quote/${quoteId}/accept`, quoteData)
            .pipe(map((data: any) => data.data));
    }

    declineQuote(quoteData, quoteId: string)
    {
        return this.http
            .patch(this.env.apiPath + `xero/quote/${quoteId}/decline`, quoteData)
            .pipe(map((data: any) => data.data));
    }

    quote2Invoice(quoteId: string)
    {
        return this.http
            .patch(this.env.apiPath + `xero/quote/${quoteId}/2invoice`, {})
            .pipe(map((data: any) => data.data));
    }

    sendInvoice2Customer(invoiceId: string)
    {
        return this.http
            .post(this.env.apiPath + `xero/invoice/${invoiceId}/email`, {})
            .pipe(map((data: any) => data.data));
    }

    openInvoiceOnline(invoiceId: string)
    {
        return this.http
            .get(this.env.apiPath + `xero/invoice/${invoiceId}/url`)
            .pipe(map((data: any) => data.data));
    }

    getInvoicePdf(quoteId: string)
    {
        return this.http.get(
            this.env.apiPath + `xero/invoice/${quoteId}/pdf`,
            { responseType: 'arraybuffer' }
        );
    }
}
