import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AUTH_HTTP_INTERCEPTORS, ERROR_HTTP_INTERCEPTORS, SINGLE_HTTP_INTERCEPTORS } from './Injection.tokens';
import { SingleRequestInterceptor } from './interceptors/single-request.interceptor';
import { AuthRequestInterceptor } from './interceptors/auth-request.interceptor';
import { ErrorRequestInterceptor } from './interceptors/error-request.interceptor';
import { ApiHttpClient } from './clients/ApiHttpClient';
import { AuthHttpClient } from './clients/AuthHttpClient';
import { ApiAuthHttpClient } from './clients/ApiAuthHttpClient';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalRequestInterceptor } from './interceptors/global-request.interceptor';

@NgModule({
  imports: [CommonModule],
})
export class HttpModule
{
  static forRoot(): ModuleWithProviders<HttpModule> {
    return {
      ngModule: HttpModule,
      providers: [
        SingleRequestInterceptor,
        AuthRequestInterceptor,
        ErrorRequestInterceptor,
        {
          provide: SINGLE_HTTP_INTERCEPTORS,
          useClass: SingleRequestInterceptor,
          multi: true,
        },
        {
          provide: AUTH_HTTP_INTERCEPTORS,
          useClass: AuthRequestInterceptor,
          multi: true,
        },
        {
          provide: ERROR_HTTP_INTERCEPTORS,
          useClass: ErrorRequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: GlobalRequestInterceptor,
          multi: true,
        },
        ApiHttpClient,
        AuthHttpClient,
        ApiAuthHttpClient
      ]
    };
  }
}
