import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Store } from '@upero/store';
import { ApiAuthHttpClient } from '@upero/misc';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  addToFavourites(productId)
  {
    let favourites: any[] = localStorage.getItem('favourites') ? JSON.parse(localStorage.getItem('favourites')) : [];
    if (!favourites) {
      favourites = [];
    }
    if (favourites.indexOf(productId) < 0) {
      favourites.push(productId);
    }
    localStorage.setItem('favourites', JSON.stringify(favourites));
    this.store.set('favourites', favourites);

    this.saveRemote();
  }

  deleteFavourite(productId)
  {
    const favourites: any[] = localStorage.getItem('favourites') ? JSON.parse(localStorage.getItem('favourites')) : [];
    const idx = favourites.indexOf(productId);
    if (idx >= 0) {
      favourites.splice(idx, 1);
    }
    localStorage.setItem('favourites', JSON.stringify(favourites));
    this.store.set('favourites', favourites);

    this.saveRemote();
  }

  saveRemote()
  {
    const favourites: any[] = localStorage.getItem('favourites') ? JSON.parse(localStorage.getItem('favourites')) : '';
    const dataToSend = {
      favourites,
      sessionId: localStorage.getItem('sessionId'),
      tmpSessionId: localStorage.getItem('tmpSessionId')
    };
    return this.http
      .post(this.env.apiPath + 'products/favourites/', dataToSend)
      .subscribe(data =>
      {
        return data;
      });
  }

  get()
  {
    const user = localStorage['user'];
    let userId = '';

    if (user) {
      userId = user;
    }
    if (!user) {
      userId = localStorage.getItem('sessionId') || '';
    }
    return this.http
      .get(this.env.apiPath + 'products/favourites/' + userId)
      .pipe(
        tap((data: any) =>
          {
            const favourites = new Array<number>();
            data.data.forEach(f =>
            {
              favourites.push(f.id);
            });
            localStorage.setItem('favourites', JSON.stringify(favourites));
            this.store.set('favourites', favourites);
            return data;
          }
        ));
  }

}
