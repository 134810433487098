import { Component, HostBinding, OnInit } from '@angular/core';
import { BaseBlockComponent, BreadcrumbFields } from '@upero/misc';
import { BreadcrumbsService } from '@upero/services';
import { delay, filter } from 'rxjs';

@Component({
  selector: 'upero-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BaseBlockComponent<BreadcrumbFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @HostBinding('style') get styles()
  {
    if (this.block.fields.absolute === true) {
      return { position: 'relative' };
    }
    return {  };
  };

  routes: Array<{ text: string, route?: string[] }> = [];
  floating: boolean | null = null;

  constructor(
    private breadcrumbsService: BreadcrumbsService
  )
  {
    super();
    this.defaultFields = BreadcrumbFields.default();
    this.breadcrumbsService.breadcrumbChanged
      .pipe(delay(10))
      .subscribe(value => {
        this.routes = [];
        if (value) {
          this.routes = value
        }
      });
    this.breadcrumbsService.isFloatingChanged
      .pipe(filter(value => !!value))
      .subscribe((value) => this.floating = value);
  }

  ngOnInit(): void
  {
    this.breadcrumbsService.changeFloatingState(this.block.fields.absolute);
  }
}
