import { Inject, Injectable } from "@angular/core";
import { Store } from "@upero/store";
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType, NotesData } from "@upero/misc";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotesService
{
    constructor(
        private http: ApiAuthHttpClient,
        private store: Store,
        @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
    )
    {

    }

    public getNotesFor(type: string, recordId: string): Observable<any>
    {  // https://api.ysdev.co.uk/notes/:type/:recordId
        return this.http
            .get(this.env.apiPath + `notes/${type}/${recordId}`)
            .pipe(map((result: any) => result.data));
    }

    public addNoteFor(note: NotesData): Observable<any>
    {
        return this.http
            .post(this.env.apiPath + `notes`, {
                ...note,
                createdBy: this.store.selectForLocal("user").id
            } as NotesData)
            .pipe(map((result: any) => result.data));
    }
}
/*
    POST https://api.ysdev.co.uk/notes
    PUT https://api.ysdev.co.uk/notes
    GET https://api.ysdev.co.uk/notes/:id
    GET https://api.ysdev.co.uk/notes/:type/:recordId
    DELETE https://api.ysdev.co.uk/notes/:id
    6:59
    POST object {
        “createdBy” : “ABC001",
        “content”: “This is my note not edited”,
        “recordId”: “REC001",
        “type”: “customer”
    }
    6:59
    PUT object {
        “id”: “d92b94ff-2840-4938-ba5a-dbdad806d67b”,
        “content”: “This is my note not edited”,
    }
    7:00
    The /type/id endpoint will bring back all notes of type attached to record ID of id. So all customer notes for customer xyz
* */
