import { Component, Input } from "@angular/core";

export interface SpinnerConfig {
    showSpinner: boolean;
    isGlobalSpinner: boolean;
    ldsLoader: boolean;
}

@Component({
  selector: 'upero-inline-spinner',
  templateUrl: './inline-spinner.component.html',
  styleUrls: ['./inline-spinner.component.scss']
})
export class InlineSpinnerComponent {
    @Input() set spinnerConfig(spinnerConfig: SpinnerConfig) {
        this.config = spinnerConfig;
    }

    get spinnerConfig(): SpinnerConfig {
        return this.config;
    }

    config: SpinnerConfig = { isGlobalSpinner: false, showSpinner: false, ldsLoader: false } as SpinnerConfig;
}
