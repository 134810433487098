import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService, CmsService } from '@upero/services';
import { BaseBlockComponent, BlogCategoryFields } from '@upero/misc';

@Component({
  selector: 'upero-blog-category',
  templateUrl: './blog-category.component.html',
  styleUrls: ['./blog-category.component.scss']
})
export class BlogCategoryComponent extends BaseBlockComponent<BlogCategoryFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  category;
  categories = [];
  articles = [];

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
    private cmsService: CmsService,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.getCategories();
    this.getCategory();
  }

  getCategory()
  {
    this.blogService.getCategory(this.route.snapshot.params.slug).subscribe(data =>
    {
      this.category = data;
      this.articles = data.articles;
      if (this.category.introBackground) {
        this.cmsService.broadCastBlockData.next({
          target: 'page-intro',
          data: {
            backgroundImgSrc: this.category.introBackground,
            title: this.category.name,
            text: this.category.introText
          }
        })
      }
    });
  }

  getCategories()
  {
    this.blogService.getCategories().subscribe(data => this.categories = data);
  }
}
