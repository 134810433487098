import { Component, Input, OnInit } from '@angular/core';
import {Store} from "@upero/store";

@Component({
  selector: 'upero-cta-banner',
  templateUrl: './cta-banner.component.html',
  styleUrls: ['./cta-banner.component.scss']
})
export class CtaBannerComponent implements OnInit {
  @Input() block: any;

  currentBrand = this.store.selectForLocal('currentBrand');

  constructor(private store: Store,) { }

  ngOnInit(): void {
  }

}
