import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ToolsService } from "./tools.service";
import { map, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { isPlatformBrowser } from '@angular/common';
import { Socket } from 'ngx-socket-io';
import { Store } from '@upero/store';
import { ApiAuthHttpClient } from '@upero/misc';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class NotificationService
{
  query: any;
  private sound;
  private socket: Socket;

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    private toolsService: ToolsService,
    private toastrService: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any,
    private injector: Injector,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
    if (isPlatformBrowser(this.platformId)) {
      this.initSound();
      this.socket = this.injector.get<Socket>(Socket);
    }
  }

  init()
  {
    const user = this.store.selectForLocal('user');
    return this.http
      .get(this.env.apiPath + 'notifications/' + user.id)
      .pipe(
        tap((data: any) => this.store.set('notifications', data.data)),
        map((data: any) => data)
      );
  }

  listenToNotifications()
  {
    this.socket
      .fromEvent("notifications")
      .subscribe((data: any) =>
      {
        const notifications = this.store.selectForLocal("notifications");
        notifications.unread++;
        notifications.data.unshift(data);
        this.sound.play();
        this.store.set("notifications", notifications);
        this.toastrService.success(data.content);
      });
  }

  stopListening()
  {
    this.store.set('notifications', { unread: 0, data: [] });
  }

  send(notificationData)
  {
    notificationData.id = this.toolsService.newUUID();
    notificationData.read = false;
    notificationData.deleted = false;
    notificationData.createdAt = new Date();
  }

  private initSound()
  {
    this.sound = document.createElement("audio");
    this.sound.src = "/assets/ding.mp3";
    this.sound.setAttribute("preload", "auto");
    this.sound.setAttribute("controls", "none");
    this.sound.style.display = "none";
    this.sound.volume = 0.2;
    document.body.appendChild(this.sound);
  }

  delete(id)
  {
    return this.http
      .delete(this.env.apiPath + "notifications/" + id);
  }

  markAllAsRead(id)
  {
    return this.http
      .put(this.env.apiPath + 'notifications/user/' + id, {})
      .pipe(map((data: any) => data));
  }
}
