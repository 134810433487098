import { Component, HostBinding } from '@angular/core';
import { BaseBlockComponent, WysiwygFields } from '@upero/misc';

@Component({
  selector: 'upero-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss']
})
export class WysiwygComponent extends BaseBlockComponent<WysiwygFields>
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  constructor()
  {
    super();
    this.defaultFields = WysiwygFields.default();
  }
}
