import { Component, HostBinding, OnInit } from '@angular/core';
import { BaseBlockComponent, ProductTypesFields } from '@upero/misc';
import { ProductService } from '@upero/services';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from "@angular/router";
import {Store} from "@upero/store";
import {filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'upero-product-types',
  templateUrl: './product-types.component.html',
  styleUrls: ['./product-types.component.scss']
})
export class ProductTypesComponent extends BaseBlockComponent<ProductTypesFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  currentBrand = this.store.selectForLocal('currentBrand');
  productGroups = [];
  public destroyed = new Subject<any>();

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    )
  {
    super();
  }

  ngOnInit(): void
  {
    this.getProductGroups();

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() =>
    {
      this.getProductGroups();
    });
  }

  getProductGroups()
  {
    this.productService
      .getProductGroups()
      .subscribe(data => this.productGroups = data.data);
  }
}
