import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseBlockComponent, PasswordResetConfirmationFields } from '@upero/misc';
import { Store } from '@upero/store';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-password-reset-confirmation',
  templateUrl: './password-reset-confirmation.component.html',
  styleUrls: ['./password-reset-confirmation.component.scss']
})
export class PasswordResetConfirmationComponent extends BaseBlockComponent<PasswordResetConfirmationFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  confirmationEmail = '';

  constructor(
    private store: Store,
    private router: Router,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    // this.confirmationEmail = this.store.selectForLocal('confirmationEmail');
    this.confirmationEmail = localStorage.getItem('confirmationEmail');
  }

  signIn()
  {
    this.router.navigate(AppInitService.doBrandModUrl(['/login']));
  }

  home()
  {
    this.router.navigate(AppInitService.doBrandModUrl(['/']));
  }
}
