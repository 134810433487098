<a [uperoBrandRouterLink]="[urlBase, slug]">
  <img class="image-as-bg hover"
       [src]="imageUrl ? imageUrl : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
       [appScrset]="imageUrl ? imageUrl : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
       [width]="310">
</a>
<div class="mt-4 mb-3">
  <a class="fs-6 text-dark"
     [uperoBrandRouterLink]="[urlBase, slug]">
    {{ name }}
  </a>
</div>
<p class="op-8 type-description mb-4" [innerHTML]="tileText"></p>
<p class="d-flex justify-content-center align-items-center" *ngIf="isPriceFromVisible">
  <span class="small me-2">Prices from</span>
  <span class="fs-5">
      <ng-container *ngIf="hasProducts">
          {{ pricesFrom | currency: 'GBP' }}
      </ng-container>
      <ng-container *ngIf="!hasProducts">
          (No products)
      </ng-container>
  </span>
</p>
<a class="btn"
   [ngClass]="[ctaBtnClass, ctaBtnSize]"
   [uperoBrandRouterLink]="[urlBase, slug]">
  {{ ctaTxt }}
</a>
