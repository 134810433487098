import {
    AfterViewInit,
    Component, ComponentFactoryResolver,
    HostBinding, ViewChild, ViewContainerRef
} from '@angular/core';
import { BaseBlockComponent, RegistrationFormFields } from '@upero/misc';

@Component({
    selector: 'upero-proxy-registration-form',
    templateUrl: './registration-form.component.html',
    styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent extends BaseBlockComponent<RegistrationFormFields> implements AfterViewInit
{
    @HostBinding('attr.class') get classes()
    {
        return this.cssClass;
    };

    @ViewChild('uperoProxyContainer', {
        read: ViewContainerRef,
        static: false
    }) viewContainerRef: ViewContainerRef;

    constructor(private componentFactoryResolver: ComponentFactoryResolver)
    {
        super();
    }

    async ngAfterViewInit()
    {
        if (this.viewContainerRef) {
            const { RegistrationFormComponent } = await import('@upero/partials');
            this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(RegistrationFormComponent));
        }
    }
}
