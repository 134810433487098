import { Inject, Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Store } from '@upero/store';
import { ApiAuthHttpClient } from '@upero/misc';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { ReplaySubject } from 'rxjs';
import { NavigationStart, Router, RouterEvent } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CmsService
{
  // using this to broadcast data from one componetn to another
  private blockDataBroadcast$: ReplaySubject<{target: string, data: any} | null> = new ReplaySubject<{target: string, data: any} | null>();

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    private router: Router,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
      .subscribe((event) => this.blockDataBroadcast$.next(null)); // clearing blocks data
  }

  search(searchText)
  {
    const dataToSend = {
      searchText
    };
    return this.http
      .post(this.env.apiPath + 'cms/search', dataToSend)
      .pipe(map((data: any) =>
      {
        this.store.set('pages', data.data);
        return data.data;
      }));
  }

  pages()
  {
    return this.http
      .get(this.env.apiPath + 'cms/pages')
      .pipe(map((data: any) =>
      {
        this.store.set('pages', data.data);
        return data.data;
      }));
  }

  pageSnippets(route)
  {
    const routeToFind = route.split('?')[0];
    return this.http
      .get(this.env.apiPath + 'cms/page/snippets/' + routeToFind)
      .pipe(map((data: any) =>
      {
        this.store.set('pageSnippets', data.data);
        return data.data;
      }));
  }

  resetPageSnippets()
  {
    this.store.set('pageSnippets', []);
  }

  quickLinks(type)
  {
    return this.http
      .get(this.env.apiPath + 'pages/quicklinks/' + type)
      .pipe(map((data: any) => data.data));
  }

  sendContactForm(contact)
  {
    const dataToSend = {
      contact
    };
    return this.http
      .post(this.env.apiPath + 'contactus', dataToSend)
      .pipe(map((data: any) => data.data));
  }

  sendCompetitionForm(contact)
  {
    const dataToSend = {
      contact
    };
    return this.http
      .post(this.env.apiPath + 'contactus/competition', dataToSend)
      .pipe(map((data: any) => data.data));
  }

  sendSubscriberForm(subscriber)
  {
    const dataToSend = {
      subscriber
    };
    return this.http
      .post(this.env.apiPath + 'subscriber', dataToSend)
      .pipe(map((data: any) => data));
  }

  getSettings()
  {
    return this.http
      .get(this.env.apiPath + 'settings')
      .pipe(map(({ data }: any) =>
      {
        const config = data;
        config['routes'] = {
          basket: {
            basket: 'basket',
            payment: 'basket/payment',
            billing: 'basket/billing',
            /*complete: 'complete/:id',*/
            /*reload: 'reload/:id',*/
          }
        };
        this.store.set('settings', config);
        return config;
      }));

  }

  gallery(id)
  {
    return this.http
      .get(this.env.apiPath + 'cms/gallery/' + id)
      .pipe(map((data: any) => data.data));
  }

  getStaff(slug)
  {
    return this.http
      .get(this.env.apiPath + 'staff/slug/' + slug)
      .pipe(map((data: any) => data.data));
  }

  getTermsContent()
  {
    return this.http
      .get(this.env.apiPath + 'terms')
      .pipe(map((data: any) => data.data));
  }

  get broadCastBlockData(): ReplaySubject<{target: string, data: any} | null>
  {
    return this.blockDataBroadcast$;
  }

  getUrlSettings(token: string, sessionId?: string)
  {
    return this.http
      .get(this.env.apiPath + 'settings/url/' + token + (sessionId ? '/' + sessionId : ''))
      .pipe(map((data: any) => data.data));
  }
}
