import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';
import { ApiAuthHttpClient } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class CaseStudyService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  get(id)
  {
    return this.http
      .get(this.env.apiPath + 'case-study/' + id)
      .pipe(map((data: any) => data));
  }

  homepagePromo()
  {
    return this.http
      .get(this.env.apiPath + 'case-study/promo')
      .pipe(map((data: any) => data));
  }

  search(params)
  {
    const dataToSend = {
      params,
    };
    return this.http
      .post(this.env.apiPath + 'case-study/search', dataToSend)
      .pipe(map((data: any) => data));
  }

  create(caseStudy)
  {
    const user = this.store.selectForLocal('user');
    const dataToSend = {
      caseStudy,
      user,
    };
    return this.http
      .post(this.env.apiPath + 'case-study', dataToSend)
      .pipe(map((data: any) => data));
  }

  update(caseStudy)
  {
    const user = this.store.selectForLocal('user');
    const dataToSend = {
      caseStudy,
      user,
    };
    return this.http
      .put(this.env.apiPath + 'case-study', dataToSend)
      .pipe(map((data: any) => data));
  }
}
