<div *ngIf="user" class="menu bg-dark h-100 position-fixed">
    <div class="d-flex flex-column h-100">
        <div class="storefront__header d-flex align-items-center py-4">
            <button
                [uperoBrandRouterLink]="['']"
                type="button"
                class="btn btn-success fs-6 fw-bold px-4 py-3 ms-4">
                Shop now
            </button>
        </div>
        <button
            [routerLink]="['/account']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 fw-bold text-start px-4 py-2 mt-4">
            My Account
        </button>
        <button
            [routerLink]="['/account', 'orders']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 fw-bold text-start px-4 py-2 mt-4">
            Orders
        </button>
        <button
            [routerLink]="['/account', 'quotes']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 fw-bold text-start px-4 py-2 mt-4">
            Quotes
        </button>
        <button
            [routerLink]="['/account', 'samples']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 fw-bold text-start px-4 py-2 mt-4">
            Samples
        </button>
        <button
            [routerLink]="['/account', 'payments']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 fw-bold text-start px-4 py-2 mt-4">
            Payment History
        </button>
        <button
            [routerLink]="['/account', 'rewards']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 fw-bold text-start px-4 py-2 mt-4">
            Rewards
        </button>
        <button
            [routerLink]="['/account', 'settings']"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 fw-bold text-start px-4 py-2 mt-4">
            Profile
        </button>

        <div  class="d-flex align-items-center op-4 px-4 pt-2 mt-auto">
            <div class="fs-6 fw-bold text-white">Account Type</div>
        </div>
        <div  class="fs-6 fw-bold text-white px-4 py-2 mb-4">
            Private
        </div>
    </div>
</div>
