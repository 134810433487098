import { Component, HostBinding, OnInit } from '@angular/core';
import { FavouritesFields, BaseBlockComponent } from '@upero/misc';
import { FavouritesService, OverlayService } from '@upero/services';
import { Store } from '@upero/store';

@Component({
  selector: 'upero-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent extends BaseBlockComponent<FavouritesFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  currentBrand = this.store.selectForLocal('currentBrand');
  favourites$ = this.store.select<any[]>('favourites');
  favourites = [];
  user$ = this.store.select<any>('user');


  constructor(
    private favouritesService: FavouritesService,
    private overlayService: OverlayService,
    private store: Store,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.getFavourites();
  }

  getFavourites()
  {
    this.favouritesService.get().subscribe(data =>
    {
      this.favourites = data.data;
    });
  }

  register()
  {
    this.store.set('RegisteredInBasketFlow', false);
    this.overlayService.toggle('registerModal');
  }

  login()
  {
    this.overlayService.toggle('loginModal');
  }


  toggleFavourite(productId)
  {
    const favourites = this.store.selectForLocal('favourites');
    if (favourites.indexOf(productId) < 0) {
      this.favouritesService.addToFavourites(productId);
    } else {
      this.favouritesService.deleteFavourite(productId);
    }
  }
}
