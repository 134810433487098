import { Component, HostBinding, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseBlockComponent, GoogleMapsFields } from '@upero/misc';

@Component({
  selector: 'upero-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent extends BaseBlockComponent<GoogleMapsFields> implements OnInit
{
  static _apiLoaded: ReplaySubject<boolean> = null;

  @HostBinding('attr.class') get classes() { return this.cssClass; };
  @HostBinding('attr.style') get style() {
    if (!this.block) {
      return '';
    }
    return `min-height: ${this.block.fields.height}px;`
  };

  @Input() mapOptions: google.maps.MapOptions =
    {
      center: { lat: 51.140629, lng: -0.5029757 },
      zoom: 15
    };
  @Input() markerOptions: google.maps.MarkerOptions =
    {
      draggable: false,
      icon: '/assets/images/icon-pin.svg'
    };
  @Input() markerPositions: google.maps.LatLngLiteral[] =
    [
      { lat: 51.140629, lng: -0.5029757 }
    ];


  isBrowser = false;

  constructor(
    private httpClient: HttpClient,
    @Inject(PLATFORM_ID) platformId: any
  )
  {
    super();
    this.defaultFields = GoogleMapsFields.default();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get apiLoaded(): Observable<boolean>
  {
    return GoogleMapsComponent._apiLoaded;
  }

  ngOnInit(): void
  {
    if (this.isBrowser && GoogleMapsComponent._apiLoaded === null) {
      GoogleMapsComponent._apiLoaded = new ReplaySubject();
      this.httpClient
        .jsonp(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyB2qvJu8WAyTiea7hJt7lQqB6ZPcyooEII&libraries=places',
          'callback'
        )
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        )
        .subscribe((data: any) => GoogleMapsComponent._apiLoaded.next(data));
    }
  }
}

