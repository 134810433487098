import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function checkPasswords(nestedFormProperties?: string[]): ValidatorFn
{
  return (form: FormGroup): ValidationErrors | null =>
  {
    let password: string;
    let passwordConfirm: string;

    if (nestedFormProperties) {
      let nestedFormGroup: FormGroup;

      nestedFormProperties.forEach((nestedProperty) =>
      {
        const isFormGroup = nestedFormGroup
          ? nestedFormGroup.get(nestedProperty) instanceof FormGroup
          : form.get(nestedProperty) instanceof FormGroup;

        if (nestedFormGroup) {
          if (isFormGroup) {
            nestedFormGroup = form.get(nestedProperty) as FormGroup;
          }
        } else {
          if (isFormGroup) {
            nestedFormGroup = form.get(nestedProperty) as FormGroup;
          }
        }
      });

      password = nestedFormGroup?.get('password').value;

      passwordConfirm = nestedFormGroup?.get('passwordConfirm')?.value
        ? nestedFormGroup?.get('passwordConfirm').value
        : nestedFormGroup?.get('confirmPassword').value;
    } else {
      password = form?.get('password').value;

      passwordConfirm = form?.get('passwordConfirm').value;
    }

    return password === passwordConfirm ? null : { passwordNotMatch: true };
  };
}
