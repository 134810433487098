<section>
  <div class="container g-0 p-5">
    <div class="row g-0">
      <ng-container *ngFor="let pg of productGroups; let last = last">
        <upero-thumbnail-tile class="col-12 col-sm-6 col-md-6 col-xl-3 text-center px-4"
          [ngClass]="{ 'mb-5': !last}"
          [imageUrl]="pg.imageUrl"
          [slug]="pg.slug"
          [name]="pg.name"
          [tileText]="pg.tileText"
          [hasProducts]="pg.hasProducts"
          [pricesFrom]="pg.pricesFrom"
          [ctaTxt]="block.fields['productButton'].text"
          [urlBase]="'/product-groups'"
          [ctaBtnClass]="buttonClass"
          [ctaBtnSize]="this.block.fields.productButton['size']"
        ></upero-thumbnail-tile>
      </ng-container>
    </div>
  </div>
</section>
