import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FaqService } from '@upero/services';
import { BaseBlockComponent, FaqFields } from '@upero/misc';
import {CollectionsService} from "@upero/collections";

@Component({
  selector: 'upero-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent extends BaseBlockComponent<FaqFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @Input() backgroundColour = 'bg-grey';

  topics = [];
  popularQuestions = [];

  params = {
    freetext: '',
    id: 0,
    limit: 8,
    limits: [8, 16, 24, 32, 64],
    page: 0,
    pages: 0,
    pageArray: [],
    totalRecords: 0,
    sort: 'faq_topic.sortOrder',
    sorts: [],
  };

  constructor(
    private faqService: FaqService,
    private collectionsService: CollectionsService,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.search();
  }

  search()
  {
    this.collectionsService.getCollectionEntriesByType(this.block.fields.topics, 1)
      .subscribe((response: []) => {
        this.topics = response;
      })
  }

  toggleExpanded(q)
  {
    q.expanded = !q.expanded;
  }
}
