import {Directive, HostBinding} from '@angular/core';
import {Store} from "@upero/store";

@Directive({
  selector: '[uperoLinkColorSetter]'
})
export class LinkColorSetterDirective {
  currentBrand = this.store.selectForLocal('currentBrand');

  @HostBinding('class')
  get elementClass(): string {
    return this.currentBrand ? 'link-' + this.currentBrand.settings['linkClass'] : 'link-primary';
  }

  constructor(
    private store: Store,
  ) {
  }
}
