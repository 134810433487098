import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver, forwardRef,
  HostBinding, Injector,
  OnInit, TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseBlockComponent, ContactFields } from '@upero/misc';

@Component({
  selector: 'upero-proxy-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent extends BaseBlockComponent<ContactFields> implements OnInit, AfterViewInit
{
  @HostBinding('attr.class') get classes() { return this.cssClass; };

  @ViewChild('uperoProxyContainer', {
    read: ViewContainerRef,
    static: false
  }) viewContainerRef: ViewContainerRef;

  @ViewChild('googleMaps', { read: TemplateRef }) googleMapsTemplate: TemplateRef<any>;

  mapOptions = {
    center: { lat: 51.140629, lng: -0.5029757 },
    zoom: 15
  };

  markerOptions = {
    draggable: false,
    icon: '/assets/images/icon-pin.svg'
  };

  markerPositions = [
    { lat: 51.140629, lng: -0.5029757 }
  ];

  constructor(private componentFactoryResolver: ComponentFactoryResolver)
  {
    super();
    this.defaultFields = ContactFields.default();
  }

  async ngOnInit()
  {

  }

  async ngAfterViewInit()
  {
    if (this.viewContainerRef) {
      const { ContactFormComponent } = await import('@upero/partials');
      const comp = this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(ContactFormComponent));
      comp.instance.title = this.block.fields.title;
      comp.instance.inquiryOptions = this.block.fields.inquiryOptions;
      comp.instance.submitButton = this.block.fields.submitButton;
      comp.instance.googleMaps = this.googleMapsTemplate;
    }
  }
}
