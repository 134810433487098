import { RESPONSE } from '@nguniversal/express-engine/tokens'
import { Component, OnInit, Inject, Optional, HostBinding } from '@angular/core';
import { Response } from 'express'
import { PageNotFoundFieldsFields, BaseBlockComponent } from '@upero/misc';

@Component({
  selector: 'upero-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends BaseBlockComponent<PageNotFoundFieldsFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  private response: Response;

  constructor(@Optional() @Inject(RESPONSE) response: any)
  {
    super();
    this.response = response;
  }

  ngOnInit(): void
  {
    if (this.response) {
      this.response.status(404);
    }
  }
}
