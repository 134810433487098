import { Component, HostBinding } from '@angular/core';
import { BaseBlockComponent, CtaContactFields } from '@upero/misc';
import {Store} from "@upero/store";

@Component({
  selector: 'upero-cta-contact',
  templateUrl: './cta-contact.component.html',
  styleUrls: ['./cta-contact.component.scss']
})
export class CtaContactComponent extends BaseBlockComponent<CtaContactFields>
{
  siteSettings: any;

  constructor(
    private store: Store,
  )
  {
    super();
  }

  ngOnInit(): void {
    this.siteSettings = this.store.selectForLocal('siteSettings');
  }
}
