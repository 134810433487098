<div
  [@slideBottomTopWelcome]
  class="chat shadow-lg">
  <!-- Start Screen -->
  <div
    *ngIf="!(chatStore$ | async).group && !showAllConversations && !newGuest"
    [@.disabled]="disableFirstSlideAnimation"
    [@slideLeftRight]
    class="d-flex flex-column justify-content-between h-100">
    <div class="d-flex justify-content-end align-items-center bg-dark p-3 p-lg-4">
      <div
        class="chat__close chat__close--white"
        (click)="close()">
        <svg-icon src="assets/images/icon-menu-close.svg"></svg-icon>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center bg-dark h-55 p-3 p-lg-4">
      <svg-icon
        src="assets/images/upero.svg"
        class="mb-3"
        [@listItem]
        [svgClass]="'logo--icon'">
      </svg-icon>
      <div
        [@listItem]
        class="fs-4 fw-400 text-white mb-2">
        Welcome!
      </div>
      <div
        class="me-4 mb-2"
        *ngIf="openChats?.length > 0">
        <button
          (click)="checkForUserInfo()"
          class="btn btn-primary btn-sm">
          Start New Chat
        </button>
      </div>
      <div
        [@listItem]
        class="fs-6 text-white">
        Need assistance? We are here to help!
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center h-45 p-3 p-lg-4">
      <div
        *ngIf="(chatStore$ | async).groups?.length > 0"
        class="d-flex flex-column my-auto">
        <div class="d-flex align-items-center mb-1">
          <small
            (click)="toggleAllConversations()"
            class="trigger-convos fw-bold">
            Closed conversations ({{ closedChats.length }})
            <i class="fas fa-chevron-right ms-1"></i>
          </small>
        </div>
        <p
          class="mt-3 mb-1 small"
          *ngIf="openChats.length">
          You have {{ openChats.length }} open conversation{{ openChats.length !== 1 ? 's' : '' }}
        </p>

        <div class="d-flex flex-column h-100 conversations-holder">
          <ng-container *ngFor="let group of openChats; let first = first">
            <div
              (click)="selectGroup(group)"
              class="card card--conv"
              *ngIf="!group.isClosed">
              <div class="card-body d-flex">
                <div class="d-flex flex-column w-100">
                  <div class="d-flex justify-content-between w-100">
                    <small class="quickChat__title fw-bold text-nowrap text-truncate text-muted">
                      <span *ngIf="!group.subjectName">{{ group.name }}</span>
                      <span *ngIf="group.subjectName">Subject: {{ group.subjectName }}</span>
                    </small>
                    <small class="text-muted">{{ group.lastAction | date: 'dd.MM.y' }}</small>
                  </div>
                  <div class="message-preview">{{ group.lastMessage | striphtml }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="mt-auto d-flex justify-content-center w-100">
        <upero-button
          *ngIf="!openChats.length"
          gaEvent="click_new_chat"
          gaCategory="Chat"
          gaBind="click"
          [type]="'function'"
          [text]="'Start new conversation'"
          [buttonClass]="'primary'"
          (clickCallback)="checkForUserInfo()"></upero-button>
      </div>
    </div>
  </div>

  <!-- New Conversation -->
  <div
    *ngIf="newGuest"
    [@slideLeftRight]
    class="d-flex flex-column justify-content-between h-100">
    <div class="d-flex justify-content-between align-items-center bg-dark p-3 p-lg-4">
      <div
        class="chat__back"
        (click)="back()">
        <svg-icon src="assets/images/icon-arrow-left.svg"></svg-icon>
      </div>
      <div
        class="chat__close"
        (click)="close()">
        <svg-icon src="assets/images/icon-menu-close.svg"></svg-icon>
      </div>
    </div>
    <div class="d-flex flex-column h-100 p-3 p-lg-4">
      <div class="fs-4 fw-400 mb-3">Tell us a bit about yourself.</div>
      <div class="mb-4">Please add your contact details so we can better support you.</div>
      <div class="d-flex flex-column">
        <form
          class="needs-validation"
          [formGroup]="guestForm"
          (ngSubmit)="onGuestFormSubmit()"
          novalidate>
          <label
            class="fw-bold small"
            for="name">
            Name*
          </label>

          <input
            id="name"
            type="text"
            class="form-control mt-2"
            formControlName="name"
            placeholder="Enter your name"
            [ngClass]="{ 'is-invalid': field.name.touched && field.name.errors }" />
          <div
            *ngIf="field.name.hasError && field.name.touched"
            class="invalid-feedback mt-1">
            <ng-container *ngIf="field.name.hasError('required')"> Please provide your name. </ng-container>
            <ng-container *ngIf="field.name.hasError('minlength')">
              Your name needs to be at least {{ field.name.errors.minlength.requiredLength }}
              characters long.
            </ng-container>
          </div>

          <label
            class="fw-bold small mt-3"
            for="email">
            Email*
          </label>

          <input
            id="email"
            type="email"
            class="form-control mt-2"
            name="email"
            formControlName="email"
            placeholder="Email address"
            [ngClass]="{ 'is-invalid': field.email.touched && field.email.errors }" />
          <div
            *ngIf="field.email.hasError && field.email.touched"
            class="invalid-feedback mt-1">
            <ng-container *ngIf="field.email.hasError('required')"> Please provide your email address. </ng-container>
            <ng-container *ngIf="field.email.hasError('minlength')">
              Email address needs to be at least {{ field.email.errors.minlength.requiredLength }}
              characters long.
            </ng-container>
            <ng-container *ngIf="field.email.hasError('email') && !field.email.hasError('minlength')">
              Please provide a valid email address.
            </ng-container>
          </div>

          <button
            (click)="onGuestFormSubmit()"
            class="btn btn-primary mt-3">
            Send
          </button>
        </form>
      </div>
    </div>
  </div>

  <!-- All Chat Conversations -->
  <div
    *ngIf="!(chatStore$ | async).group && !newGuest"
    [@slideLeftRightWithList]
    (@slideLeftRightWithList.done)="scrollBottom()"
    class="d-flex flex-column justify-content-between h-100">
    <div class="d-flex justify-content-between align-items-center p-3 p-lg-4">
      <div
        class="chat__back"
        (click)="back()">
        <svg-icon src="assets/images/icon-arrow-left.svg"></svg-icon>
      </div>
      <div class="fs-6 fw-400 text-center text-white">Your conversations</div>
      <div
        class="chat__close"
        (click)="close()">
        <svg-icon src="assets/images/icon-menu-close.svg"></svg-icon>
      </div>
    </div>
    <div class="chat__convo-list d-flex flex-column h-100 p-3 p-lg-4">
      <ng-container *ngFor="let group of (chatStore$ | async).groups">
        <div
          [@listItem]
          (click)="selectGroup(group)"
          class="card card--conv mb-2"
          *ngIf="group.isClosed">
          <div class="card-body d-flex">
            <div class="d-flex flex-column w-100">
              <div class="d-flex justify-content-between w-100">
                <small class="quickChat__title fw-bold text-nowrap text-truncate text-muted">
                  <span *ngIf="!group.subjectName">{{ group.name }}</span>
                  <span *ngIf="group.subjectName">Subject: {{ group.subjectName }}</span>
                </small>
                <small>{{ group.lastAction | date: 'dd.MM.y' }}</small>
              </div>
              <div class="message-preview">{{ group.lastMessage | striphtml }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="d-flex flex-column p-3 p-lg-4 mt-auto">
      <upero-button
        gaEvent="click_new_chat"
        gaCategory="Chat"
        gaBind="click"
        [type]="'function'"
        [text]="'Start new conversation'"
        [buttonClass]="'primary'"
        (clickCallback)="startSupportChat()"></upero-button>
    </div>
  </div>

  <!-- Chat Conversation -->
  <div
    *ngIf="(chatStore$ | async).group && !newGuest"
    [@slideLeftRightWithList]
    (@slideLeftRightWithList.start)="scrollBottom()"
    class="d-flex flex-column justify-content-between h-100">
    <div class="d-flex justify-content-between align-items-center bg-dark p-3 p-lg-4">
      <div
        class="chat__back"
        (click)="back()">
        <svg-icon
          *ngIf="(chatStore$ | async).group"
          src="assets/images/icon-arrow-left.svg"></svg-icon>
      </div>
      <div class="fs-6 fw-400 text-center text-white">
        {{ (chatStore$ | async).group.name }}
      </div>
      <div
        class="chat__close"
        (click)="close()">
        <svg-icon src="assets/images/icon-menu-close.svg"></svg-icon>
      </div>
    </div>

    <div
      *ngIf="showUploader"
      class="upload__window h-100 px-3 pb-3 px-lg-4 pb-lg-4">
      <upero-file-uploader
        [uploadConfig]="uploadConfig"
        [ownerType]="'chat'"
        (success)="onUploadSuccess($event)"></upero-file-uploader>
    </div>

    <div
      *ngIf="!showUploader"
      id="chat-content-holder"
      class="chat__window h-100 px-3 pb-3 px-lg-4 pb-lg-4">
      <div
        class="message__container"
        *ngFor="let message of (chatStore$ | async).messages as messages; let last = last; let i = index"
        [@listItem]>
        <div
          *ngIf="
            i === 0 ||
            (messages.length > 0 && (message.createdAt | date: 'yMMdd') > (messages[i - 1].createdAt | date: 'yMMdd'))
          "
          class="badge text-muted align-self-center p-2 my-3">
          {{ message.createdAt | date: 'fullDate' }}
        </div>
        <div
          *ngIf="
            i === 0 ||
            (i > 0 && (message.createdAt | date: 'yMMdd') !== (messages[i - 1].createdAt | date: 'yMMdd')) ||
            message.user.id !== messages[i - 1].user.id
          "
          class="d-flex align-items-center"
          [ngClass]="{ 'justify-content-end': message.user.id === user.id }">
          <div class="text-muted ff-spectral me-1 mb-2 mt-4">
            <ng-container *ngIf="message.user.id !== user.id">
              {{ message.user.name }}
            </ng-container>
            <ng-container *ngIf="message.user.id === user.id"> You </ng-container>
            {{ message.createdAt | date: 'HH:mm' }}
          </div>
        </div>
        <div
          *ngIf="message.type !== 'file'"
          class="message mt-1"
          [ngClass]="{ outcoming: message.user.id === user.id, 'message-edit-mode': message.isEditMode }"
          [ngStyle]="{
            'width.100%': message.isEditMode == true,
            'max-width.100%': message.isEditMode == true,
            opacity: message.sending ? '0.5' : '1'
          }">
          <div
            *ngIf="
              message.userId === user.id &&
              message.isEditMode === false &&
              message.type !== 'subject' &&
              !message.edited
            "
            class="more-action-btn"
            (click)="this.editMessage(message)">
            <img src="/assets/images/icon-edit-message.svg" />
          </div>
          <div
            *ngIf="message.type === 'chat' || message.type === 'welcome'"
            class="message-inline"
            [innerHTML]="message.content | autolinker: { newWindow: true }"
            id="{{ message.id }}"></div>
          <div
            *ngIf="message.type === 'subject' && message.isEditMode == false"
            class="message-inline"
            [innerHTML]="message.content"
            id="{{ message.id }}"></div>
          <div
            *ngIf="message.edited && !message.isEditMode"
            class="edited-flag"
            (click)="this.editMessage(message)">
            <i
              class="fa fa-pen"
              title="This message has been edited"></i>
          </div>
        </div>

        <div
          class="chat-assets"
          *ngIf="message.type === 'file'">
          <img
            *ngIf="message.type === 'file'"
            [src]="env.imageUploadPath + message.content"
            class="chat__image mt-3" />
        </div>

        <ng-container *ngIf="!(chatStore$ | async).group.subjectName && i === 0">
          <div
            class="d-flex justify-content-end mt-1"
            *ngFor="let subject of (chatStore$ | async).subjects">
            <a
              class="btn btn-outline-secondary btn-sm w-50"
              (click)="setSubject(subject)"
              >{{ subject.name }}</a
            >
          </div>
        </ng-container>

        <div style="height: 25px">
          <ng-container *ngIf="last">
            <ng-container
              *ngIf="
                (chatStore$ | async).group &&
                (chatStore$ | async).group.typing &&
                (chatStore$ | async).group.typing.length
              ">
              <div class="pt-2 pb-2">
                <img
                  class="me-1"
                  src="assets/images/typing-texting.gif"
                  style="height: 22px" />
                <span
                  class="small"
                  *ngIf="(chatStore$ | async).group.typing.length === 1"
                  >{{ (chatStore$ | async).group.typing[0].name }} is typing</span
                >
                <span
                  class="small"
                  *ngIf="(chatStore$ | async).group.typing.length !== 1">
                  {{ (chatStore$ | async).group.typing[0].name }} and
                  {{ (chatStore$ | async).group.typing.length - 1 }} others are typing
                </span>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(chatStore$ | async).group.isClosed">
      <div class="bg-info rounded text-center p-2 m-3 d-flex justify-content-start align-items-center">
        <i class="fa fa-info-circle"></i>
        <div>
          <p class="text-start ps-3 small">
            The conversation has ended. If you need any further support you can open this chat again
          </p>
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            (click)="openChat()">
            Open chat
          </button>
        </div>
      </div>
    </ng-container>

    <div class="d-flex justify-content-between align-items-top border-top p-3 p-lg-4">
      <ng-container *ngIf="(chatStore$ | async).group.isClosed">
        <div
          class="text-center"
          *ngIf="!(chatStore$ | async).group.transcriptSent">
          <p class="fs-6 ff-spectral">If you would like to save this conversation you can send it to your email</p>
          <button
            type="button"
            class="btn btn-primary"
            (click)="sendTranscript()">
            Send conversation
          </button>
        </div>
        <div
          class="text-center"
          *ngIf="(chatStore$ | async).group.transcriptSent">
          <p class="fs-6 ff-spectral">A copy of this conversation has been sent to your email address</p>
        </div>
      </ng-container>

      <ng-container *ngIf="!(chatStore$ | async).group.isClosed">
        <ng-container *ngIf="!showUploader">
          <textarea
            #textArea
            spellcheck="true"
            rows="1"
            autoComplete="true"
            class="chat__textarea px-2 py-0 me-3"
            [(ngModel)]="message.content"
            placeholder="Your message"
            (keydown)="this.textareaKeyDownHandler($event)"></textarea>
          <button
            class="chat__btn__send me-2"
            [disabled]="!message.content"
            (click)="this.addChat()">
            <i class="fas fa-paper-plane"></i>
          </button>
          <button
            class="chat__btn__upload"
            (click)="triggerUpload()">
            <i class="fas fa-paperclip"></i>
          </button>
        </ng-container>
        <div
          class="text-center"
          *ngIf="showUploader">
          <button
            class="btn btn-outline-primary"
            (click)="closeUploader()">
            Cancel upload
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
