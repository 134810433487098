import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BreadcrumbsService, CmsService, FaqService, SeoService } from '@upero/services';
import { FaqDetailFields, BaseBlockComponent } from '@upero/misc';
import { takeUntil } from 'rxjs/operators';
import {defer, first, iif, map, mergeMap, of, Subject} from 'rxjs';
import {CollectionsService} from "@upero/collections";
import {PagesService} from "@upero/pages";

@Component({
  selector: 'upero-faq-detail',
  templateUrl: './faq-detail.component.html',
  styleUrls: ['./faq-detail.component.scss']
})
export class FaqDetailComponent extends BaseBlockComponent<FaqDetailFields> implements OnInit, OnDestroy
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  private destroyed: Subject<any> = new Subject();

  topic;
  topics = [];
  entries = [];
  params = {
    freetext: '',
    id: 0,
    limit: 8,
    limits: [8, 16, 24, 32, 64],
    page: 0,
    pages: 0,
    pageArray: [],
    totalRecords: 0,
    sort: 'faq_topic.sortOrder',
    sorts: [],
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private faqService: FaqService,
    private breadcrumbsService: BreadcrumbsService,
    private titleService: Title,
    private seoService: SeoService,
    private cmsService: CmsService,
    private collectionsService: CollectionsService,
    private pagesService: PagesService,
  )
  {
    super();
  }

  ngOnDestroy(): void
  {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  ngOnInit(): void
  {
    this.collectionsService.getCollectionEntriesByType(this.block.fields.topics, 1)
      .subscribe((response: []) => {
        this.topics = response;
        this.setActiveTopic();
      })
    this.collectionsService.getCollectionEntriesByType(this.block.fields.collectionType, 1)
      .subscribe((response: []) => {
        this.entries = response;
      })
  }

  setActiveTopic() {
    console.log('here');
    this.topics.forEach((topic) => {
        if (this.activatedRoute.snapshot.paramMap.get('slug') === topic.slug) {
          this.topic = topic;
        }
    });
  }

  getTopic(slug)
  {
    this.collectionsService
      .getPageBySlugType(this.activatedRoute.snapshot.params.slug, this.block.fields.collectionType)
      .pipe(
        mergeMap((data: any) =>
          iif(
            () => data.length > 0,
            of(data),
            defer(() => this.pagesService.getPageBySlug('404')),
          )
        ),
        map(data => data[0]),
        first()
      )
      .subscribe(entry => {
        this.topic = entry;
        this.titleService.setTitle(entry.contents.title);
        this.breadcrumbsService.changeBreadcrumb([
          {text: entry.title, route: ['/advice']},
        ]);
      });
  }

  toggleExpanded(q)
  {
    q.expanded = !q.expanded;
  }

  setMetaTags(topic)
  {
    this.seoService.updateTitle(topic.contents.name);
    this.seoService.addMetaTag('description', topic.contents.content);
    this.seoService.createLinkForCanonicalURL();
  }
}
