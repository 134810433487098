import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Store } from '@upero/store';
import { OverlayService } from '@upero/services';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-menu-mobile-user',
  templateUrl: './menu-mobile-user.component.html',
  styleUrls: ['./menu-mobile-user.component.scss'],
  animations: [
    trigger('slideLeftRight', [
      state('void', style({ transform: 'translateX(100%)', opacity: '0.9' })),
      transition('void <=> *', [
        animate('200ms ease-in')
      ]),
    ]),
  ]
})
export class MenuMobileUserComponent implements OnInit, OnDestroy
{
  user = this.store.selectForLocal('user');
  isOpen = true;
  selectedRoute = '';
  user$ = this.store.select<any>('user');
  currentBrand = this.store.selectForLocal('currentBrand');

  onSlideLeftRightEnd()
  {
    if (!this.isOpen) {
      this.close();
    }
  }

  constructor(
    private store: Store,
    private overlayService: OverlayService,
    public router: Router,
    private renderer: Renderer2
  )
  {
  }


  ngOnInit(): void
  {
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  ngOnDestroy()
  {
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }

  startClosing()
  {
    this.isOpen = false;
  }

  close()
  {
    this.store.set('mobileMenuUser', false);
    this.overlayService.closeAll();
    if (this.selectedRoute) {
      this.router.navigateByUrl(AppInitService.doBrandModByUrl(this.selectedRoute));
    }
  }

  goToRouteWithDelay(route)
  {
    this.selectedRoute = route;
    this.delayRoute().pipe(delay(300)).subscribe(() =>
    {
      this.startClosing();
    });
  }

  delayRoute(): Observable<any>
  {
    return new Observable(observer =>
    {
      observer.next(true);
      observer.complete();
    });
  }
}
