import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';
import { ApiAuthHttpClient } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class FaqService
{

  constructor(
    private store: Store,
    private http: ApiAuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  findAllTopics(params)
  {
    const dataToSend = {
      params,
    };
    return this.http
      .post(this.env.apiPath + 'faq/search', dataToSend)
      .pipe(map((data: any) => data));
  }

  findOneBySlug(slug)
  {
    return this.http
      .get(this.env.apiPath + 'faq/slug/' + slug)
      .pipe(map((data: any) => data));
  }

  popularQuestions()
  {
    return this.http
      .get(this.env.apiPath + 'faq/questions/popular')
      .pipe(map((data: any) => data));
  }


}
