import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@upero/store';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class CustomerPhotosService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  getByUser(userId)
  {
    return this.http
      .get(this.env.apiPath + 'customer-photos/user/' + userId)
      .pipe(map((data: any) => data));
  }

  delete(id)
  {
    return this.http
      .delete(this.env.apiPath + 'customer-photos/' + id)
      .pipe(map((data: any) => data));
  }

  update(photo)
  {
    const user = this.store.selectForLocal('user');

    const dataToSend = {
      userId: user.id,
      photo
    };

    return this.http
      .put(this.env.apiPath + 'customer-photos/', dataToSend)
      .pipe(map((data: any) => data));
  }

  queueComplete(batchId)
  {
    const user = this.store.selectForLocal('user');

    const dataToSend = {
      batchId,
      userId: user.id,
      username: user.contact.firstname + ' ' + user.contact.surname,
    };

    return this.http
      .post(this.env.apiPath + 'customer-photos/queuecomplete', dataToSend)
      .pipe(map((data: any) => data));
  }
}
