import {Component, ElementRef, HostBinding, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {BaseBlockComponent, PartnersCarouselFields} from "@upero/misc";
import KeenSlider from "keen-slider";
import {isPlatformBrowser} from "@angular/common";
import {Store} from "@upero/store";

@Component({
  selector: 'upero-partners-carousel',
  templateUrl: './partners-carousel.component.html',
  styleUrls: ['./partners-carousel.component.scss'],
})
export class PartnersCarouselComponent extends BaseBlockComponent<PartnersCarouselFields> implements OnInit {
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('sliderRef', { static: false }) set setSliderRef(sliderRef: ElementRef<HTMLElement>) {
    if (sliderRef) {
      this.sliderRef = sliderRef;
      this.initSlider();
    }
  }

  currentSlide = 0;
  spacing = 0;
  dotHelper: Array<number> = [];
  slider: any = null;
  fullScreen = false;
  showDots = false;
  slidesPerView;

  private readonly isBrowser: boolean;
  private sliderRef: ElementRef<HTMLElement>;

  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) platformId: {}
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    const deviceType = this.store.selectForLocal('deviceType');

    if (deviceType === 'mobile') {
      this.slidesPerView = 2;
      this.showDots = true;
    }
    if (deviceType === 'tabletV') {
      this.slidesPerView = 4;
      this.showDots = true;
    }
    if (deviceType === 'tabletH') {
      this.slidesPerView = 6;
      this.showDots = true;
    }
    if (deviceType === 'desktop') {
      this.slidesPerView = 6;
      this.showDots = false;
    }
  }

  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  initSlider() {
    if (this.isBrowser) {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        slidesPerView: this.slidesPerView,
        spacing: 30,
        slideChanged: s => {
          const newSlide = s.details().relativeSlide;
          if (newSlide < this.dotHelper.length) {
            this.currentSlide = newSlide;
          }
        }
      });

      if (this.slider.details().size) {
        const dots = (+this.slider.details().size - +this.slidesPerView) + 1;
        this.dotHelper = dots < 0 ? [] : [...Array(dots)];
      } else {
        this.dotHelper = [];
      }
    }
  }
}
