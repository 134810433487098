import {
    AfterViewInit,
    Component,
    HostBinding,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BaseBlockComponent, RegisterFields, User } from '@upero/misc';
import { Store } from '@upero/store';
import { AlertService, AppService, AuthService, BasketService, ChatService, UserService } from '@upero/services';
import { AppInitService } from '@upero/routing';
import { debounce } from '@upero/misc';

@Component({
    selector: 'upero-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseBlockComponent<RegisterFields> implements OnInit, AfterViewInit
{
    @HostBinding('attr.class') get classes()
    {
        return this.cssClass;
    };

    @ViewChild('uperoProxyContainer', {
        read: ViewContainerRef,
        static: false
    }) viewContainerRef: ViewContainerRef;

    @ViewChild('uperoProxyContainer2', {
        read: ViewContainerRef,
        static: false
    }) viewContainerRef2: ViewContainerRef;

    navigationHistory = [];
    referralCode = '';
    accountType = 'customer';
    routing;

    public user: User;

    private _subscriptions: Subscription = new Subscription();

    constructor(
        private store: Store,
        private userService: UserService,
        private alertService: AlertService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private basketService: BasketService,
        private chatService: ChatService,
        private toastrService: ToastrService,
        private appService: AppService
    )
    {
        super();
        this.initRegistrationForm = debounce(this.initRegistrationForm, 100);
    }

    ngOnInit(): void
    {
        this.user = this.store.selectForLocal('user');
        this.navigationHistory = this.store.selectForLocal('navigationHistory');
        if (this.route.snapshot.params.referralCode) {
            this.referralCode = this.route.snapshot.params.referralCode;
        }

        if (this.route.snapshot.data.accountType) {
            this.accountType = this.route.snapshot.data.accountType;
        }
    }

    ngOnDestroy(): void
    {
        this._subscriptions.unsubscribe();
    }

    registered(user)
    {
        this.alertService.clearMessage();
        this._subscriptions.add(this.authService.login(user.user).subscribe(
            () =>
            {
                this.userService.getByToken().subscribe(() =>
                {
                    const basket = this.store.selectForLocal('basket');

                    basket.customer = user.user;
                    basket.deliveryAddress = undefined;
                    basket.billingAddress = undefined;
                    this.basketService.saveBasketChanges(basket, 'modalAuth');
                    this.chatService.receiveChat();

                    if (this.navigationHistory.length) {
                        if (this.navigationHistory[0] !== '/' && !this.router.isActive(this.navigationHistory[0], {
                            paths: 'exact',
                            queryParams: 'exact',
                            fragment: 'ignored',
                            matrixParams: 'ignored'
                        })) {
                            this.router.navigate(AppInitService.doBrandModUrl([this.navigationHistory[0]]));
                        } else {
                            this.router.navigate(AppInitService.doBrandModUrl(['/account', 'confirmation']));
                        }
                    } else {
                        this.router.navigate(AppInitService.doBrandModUrl(['/account', 'confirmation']));
                    }
                });
            },
            () =>
            {
                this.toastrService.success(
                    'Account was not created - please try at a different time.'
                );
            }
        ));
    }

    setAccountType(type: "trade" | "customer")
    {
        this.accountType = type;
        this.initRegistrationForm();
    }

    async ngAfterViewInit()
    {
        this.initRegistrationForm();
    }

    private async initRegistrationForm()
    {
        if (this.viewContainerRef && this.viewContainerRef.length === 0 && this.accountType === 'customer') {
            const { RegistrationFormComponent } = await import('@upero/partials');
            const componentRef = this.appService.createDynamicComponent(RegistrationFormComponent, this.viewContainerRef);
            componentRef.instance.button = this.block.fields.submitButtonCustomer;
            componentRef.instance.userFromStorage = this.user;
            componentRef.instance.referralCode = this.referralCode;
            componentRef.instance.registrationComplete.subscribe(($event) => this.registered($event));
        }

        if (this.viewContainerRef2 && this.viewContainerRef2.length === 0 && this.accountType === 'trade') {
            const { RegistrationFormTradeAccountComponent } = await import('@upero/partials');
            const componentRef2 = this.appService.createDynamicComponent(RegistrationFormTradeAccountComponent, this.viewContainerRef2);
            componentRef2.instance.button = this.block.fields.submitButtonTrade;
        }
    }
}
