<section [ngClass]="{ 'overflow-hidden': showOverflow }">
  <div [ngClass]="block.fields.backgroundColour">
    <div class="container-fluid container--1920 g-0 px-4 py-5 px-md-5">
      <h2 class="fs-5 text-dark text-center text-xl-start px-1 px-lg-2 mb-3">
        {{block.fields.title ? block.fields.title : 'Our Products'}}
      </h2>
      <div class="overflow-wrapper">
        <div class="position-relative">
          <div *ngIf="tiles" class="keen-slider" #sliderRef [ngClass]="{'overflow-visible': showOverflow}">
            <div
              class="keen-slider__slide text-center  d-flex flex-column align-items-center px-1 px-lg-2"
              [ngClass]="{'active' : gi === currentSlide}"
              *ngFor="let pg of tiles; let gi = index"
              (click)="currentSlide = gi">
              <a [uperoRouterLinkSwipeProtected]="['/products', pg.slug]" class="w-100 h-100">
                <div class="square-image">
                  <img class="image-as-bg hover"
                       [src]="pg.productImages[0] ? (pg.productImages[0].url | replace: '/products/': '/products/images/') : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                       [appScrset]="pg.productImages[0] ? (pg.productImages[0].url | replace: '/products/': '/products/images/')  : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                       [doCndReplace]="true"
                       [width]="1000"
                       [crop]="'2000,1000,500,550'"
                       [alt]="pg.name">
                </div>
              </a>
              <div class="mt-4 mb-3">
                <a [uperoRouterLinkSwipeProtected]="['/products', pg.slug]"
                   class="fs-5 title text-dark">
                  {{pg.name}}
                </a>
              </div>
              <p *ngIf="pg.tileText" class="op-8 description text-dark px-4 mb-4" [innerHTML]="pg.tileText"></p>
              <p class="d-flex justify-content-center align-items-center text-dark mb-4">
                <span *ngIf="pg.hasProducts" class="small me-2">Prices from</span>
                <span class="fs-5 fw-bold">
                        <ng-container *ngIf="pg.hasProducts">
                            {{pg.pricesFrom |currency: 'GBP'}}
                        </ng-container>
                    </span>
              </p>
              <div *ngIf="block" class="mt-auto mb-3">
                <upero-button [button]="block.fields.buttonCta" [uperoBrandRouterLink]="['/products', pg.slug]" class="me-3"></upero-button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="showNav">
            <div (click)="prevSlide($event)"
                 (mouseenter)="arrowPreviousState(true)"
                 (mouseleave)="arrowPreviousState(false)"
                 [ngClass]="'arrow arrow--left arrow-title-slider ' + ( currentSlide === 0 ? 'arrow--disabled' : '')">
              <svg-icon
                [src]="arrowLeftSrc"
                [style.stroke]="arrowColour"
                [style.fill]="arrowColour">
              </svg-icon>
            </div>

            <div *ngIf="tiles"
                 (click)="nextSlide($event)"
                 (mouseenter)="arrowNextState(true)"
                 (mouseleave)="arrowNextState(false)"
                 [ngClass]="'arrow arrow--right arrow-title-slider ' + ((currentSlide + slidesPerView) === tiles.length ? 'arrow--disabled' : '')">
              <div class="arrow-animation__container arrow-animation--pulse"
                   [ngClass]="{'animation--start': highlightAnimation}"
                   snInViewport
                   (inViewportChange)="onInViewportChange($event)">
                <svg-icon
                  *ngIf="slider"
                  [src]="arrowRightSrc"
                  [style.stroke]="arrowColour"
                  [style.fill]="arrowColour">
                </svg-icon>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="showDots" class="d-flex justify-content-center d-xl-none pt-4 pt-xl-5">
          <button
            (click)="slider.moveToSlideRelative(i)"
            *ngFor="let slide of dotHelper; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"
          ></button>
        </div>
      </div>
    </div>
  </div>
</section>
