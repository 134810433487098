<section class="d-flex flex-column justify-content-center align-items-center bg-primary p-5">
    <div class="ff-spectral fs-3 fw-500 text-white text-center mb-4">
        For special offers and all our latest news,<br class="d-none d-lg-block"> simply enter your email address below
    </div>
    <form class="newsletter__form needs-validation text-white" [formGroup]="newsletterForm" (ngSubmit)="onSubmit()"
          novalidate>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <input [ngClass]="{ 'is-invalid': submitted && field.email.errors }"
                   type="email"
                   formControlName="email"
                   class="form-control bg-sky me-3"
                   placeholder="Email address"/>
            <button type="submit" class="btn btn-thin btn-outline-white fw-bold">Sign-up</button>
        </div>
        <div class="d-flex align-items-center">
            <input id="confirm" type="checkbox" formControlName="confirm" class="custom-checkbox"/>
            <label for="confirm"
                   [ngClass]="{ 'is-invalid': submitted && field.confirm.errors }"
                   class="form-check-label">
                <span>I have read the GDPR policy and agree to the</span>
                <a [uperoBrandRouterLink]="'/terms-and-conditions'" [target]="'_blank'" class="ms-1">Terms &amp; Conditions</a>
            </label>
        </div>
        <p *ngIf="duplicateEmail"
           class="mt-3 duplicateError">
            The email address you have entered is already signed up - please use another address if needed.
        </p>
    </form>
</section>
