import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit, PLATFORM_ID,
  ViewChild
} from '@angular/core';
import KeenSlider from 'keen-slider';
import { empty, iif, Observable } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { Store } from '@upero/store';
import { FavouritesService } from '@upero/services';
import { debounce } from '@upero/misc';

@Component({
  selector: 'upero-special-offers-slider',
  templateUrl: './special-offers-slider.component.html',
  styleUrls: ['./special-offers-slider.component.scss'],
})
export class SpecialOffersSliderComponent implements OnInit, AfterViewInit, OnDestroy
{
  @Input() tiles;
  @Input() spacing = 0;
  @Input() slidesPerView = 4;
  @Input() showDots = true;
  @Input() showNav = true;
  @Input() showExpandArrows = false;
  @Input() showOverflow = false;
  @Input() arrowColour = '#263847';

  @ViewChild('sliderRef', { read: ElementRef }) sliderRef: ElementRef<HTMLElement>;

  currentSlide = 1;
  dotHelper: Array<number> = [];
  slider: any = null;
  fullScreen = false;
  highlightAnimation = false;
  innerWidth$ = this.store.select<number>('innerWidth');
  favourites$ = this.store.select<any[]>('favourites');

  arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
  arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
  private isBrowser: boolean;

  constructor(
    private store: Store,
    private favouritesService: FavouritesService,
    @Inject(PLATFORM_ID) platformId: any
  )
  {
    this.isBrowser = isPlatformBrowser(platformId);
    this.initSlider = debounce(this.initSlider, 50);
  }

  ngOnInit(): void
  {
    this.setSliderOptions();
  }

  ngAfterViewInit(): void
  {
    this.initSlider();
  }

  private initSlider()
  {
    iif(
      () => this.isBrowser,
      new Observable((observer) =>
      {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, {
          slidesPerView: this.slidesPerView,
          spacing: this.spacing,
          slideChanged: s =>
          {
            this.currentSlide = s.details().relativeSlide;
          }
        });

        if (this.slider.details().size) {
          const dots = (+this.slider.details().size - this.slidesPerView) + 1;
          this.dotHelper = dots < 0 ? [] : [...Array(dots)];
        } else {
          this.dotHelper = [];
        }
        observer.next();
        observer.complete();
      }),
      empty()
    ).subscribe();
  }

  ngOnDestroy(): void
  {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  prevSlide(event: MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();
    this.clearSelection();
    this.slider.prev();
    if (this.currentSlide === 1) {
      this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
    }
    return false;
  }

  nextSlide(event: MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();
    this.clearSelection();
    this.slider.next();
    if ((this.currentSlide + this.slidesPerView) + 1 === this.tiles.length) {
      this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
    }
    return false;
  }

  clearSelection()
  {
    const sel = window.getSelection();
    if (sel && sel.empty) {
      sel.removeAllRanges();
    }
  }

  setSliderOptions()
  {
    const deviceType = this.store.selectForLocal('deviceType');

    if (deviceType === 'mobile') {
      this.slidesPerView = 1;
      this.showNav = false;
    }
    if (deviceType === 'tabletV' || deviceType === 'tabletH') {
      this.slidesPerView = 2;
      this.showNav = false;
    }
    if (deviceType === 'desktop') {
      this.slidesPerView = 4;
      this.showNav = true;
    }
  }

  onInViewportChange(inViewport: boolean)
  {
    if (!this.highlightAnimation) {
      this.highlightAnimation = inViewport;
    }
  }

  toggleFavourite(productId)
  {
    const favourites = this.store.selectForLocal('favourites');
    if (favourites.indexOf(productId) < 0) {
      this.favouritesService.addToFavourites(productId);
    } else {
      this.favouritesService.deleteFavourite(productId);
    }
  }

  arrowPreviousState(reset)
  {
    if (this.currentSlide !== 0 && reset) {
      this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left-bold.svg';
    } else {
      this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
    }
  }

  arrowNextState(reset)
  {
    if ((this.currentSlide + this.slidesPerView) !== this.tiles.length && reset) {
      this.arrowRightSrc = 'assets/images/icon-arrow-slider-right-bold.svg';
    } else {
      this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
    }
  }
}
