import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment as env } from '../environments/environment';
import { InitialState } from './store.state';
import {
  AlertService,
  AuthExpiredService,
  AuthService, CspService,
  NotificationService,
  ServicesModule
} from '@upero/services';
import { Store, StoreModule } from '@upero/store';
import { AppInitService, RoutingModule } from '@upero/routing';
import { BlocksModule } from '@upero/blocks';
import { PagesModule } from '@upero/pages';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ENVIRONMENT_CONFIG, HttpModule, MiscModule } from '@upero/misc';
import { EnvironmentType } from '@upero/misc';
import {Router, RouterModule} from '@angular/router';
import {AppNameInterceptor} from './app-name.interceptor';
import { SharedModule } from '@upero/shared';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    RoutingModule,
    BlocksModule,
    BrowserTransferStateModule,

    MiscModule,
    SharedModule,
    HttpClientModule,
    PagesModule.forRoot(),
    HttpModule.forRoot(),
    RouterModule.forRoot(
      []
    ),
    StoreModule.forRoot(InitialState),
    ServicesModule.forRoot(env as EnvironmentType),
    SocketIoModule.forRoot(<SocketIoConfig>{
      url: env.socketPath,
      options: {},
    }),
  ],
  exports: [BlocksModule],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AppNameInterceptor,
        multi: true
    },
    {
        provide: ENVIRONMENT_CONFIG,
        useValue: env,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private store: Store,
    private authService: AuthService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private router: Router,
    private authExpiredService: AuthExpiredService,
    private cspService: CspService,
  ) {
    this.cspService.initialize();
    this.authExpiredService.authExpired$
      .subscribe(() => this.logout());
  }
  private logout() {
    const notifications = { unread: 0, data: [] };
    const chatStore = {
      unread: 0,
      groups: [],
      group: undefined,
      replyingTo: undefined,
      groupToOpen: undefined,
      messages: [],
    };

    this.store.set('chatStore', chatStore);
    this.store.set('notifications', notifications);

    this.notificationService.stopListening();
    this.authService.logout();
    this.router.navigate(['login']);
  }
}
