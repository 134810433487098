import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BaseBlockComponent, RegistrationFormTradeAccountFields } from '@upero/misc';

@Component({
  selector: 'upero-proxy-registration-form-trade-account',
  templateUrl: './registration-form-trade-account.component.html',
  styleUrls: ['./registration-form-trade-account.component.scss'],
})
export class RegistrationFormTradeAccountComponent extends BaseBlockComponent<RegistrationFormTradeAccountFields> implements AfterViewInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('uperoProxyContainer', {
    read: ViewContainerRef,
    static: false
  }) viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver)
  {
    super();
  }

  async ngAfterViewInit()
  {
    if (this.viewContainerRef) {
      const { RegistrationFormTradeAccountComponent } = await import('@upero/partials');
      this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(RegistrationFormTradeAccountComponent));
    }
  }
}
