import { Component, HostBinding, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AdviceFields, BaseBlockComponent } from '@upero/misc';
import {CollectionsService} from "@upero/collections";
import { AppInitService } from '@upero/routing';
import {Store} from "@upero/store";

@Component({
  selector: 'upero-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: '0' })),
      transition('void <=> *', [
        animate('300ms ease-in')
      ]),
    ]),
  ]
})
export class AdviceComponent extends BaseBlockComponent<AdviceFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };
  currentBrand = this.store.selectForLocal('currentBrand');
  items = [];
  gallery;

  params = {
    limit: 9,
    limits: [8, 16, 24, 32, 64],
    page: 0,
    pages: 0,
    pageArray: [],
    totalRecords: 0,
    sort: 'faq_advice.sortOrder',
    sorts: [],
  };
  totalRecords: number;
  nextPage: number;
  moreEntries = true;

  constructor(
    private collectionsService: CollectionsService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private store: Store,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.activatedRoute.params.subscribe(params =>
    {
      const page = params.page;
      if (page) {
        this.params.page = page;
        this.nextPage = +page + 1;
        if (page === '0') {
          this.router.navigate(AppInitService.doBrandModUrl(['/advice']));
        }
      } else {
        this.nextPage = 1;
      }
    });
    this.search();
  }

  search()
  {
    this.collectionsService.getCollectionEntriesByTypePaged(this.block.fields.collectionType, 1, this.params.page, this.params.limit + 1)
      .subscribe((response: []) => {
        this.items = response.slice(0, 9);

        if (response.length <= this.params.limit) {
          this.moreEntries = false;
        }
      })
  }

  showMore(event)
  {
    event.preventDefault();
    if (this.moreEntries) {
      ++this.params.page;
      this.collectionsService.getCollectionEntriesByTypePaged(this.block.fields.collectionType, 1, this.params.page, this.params.limit).subscribe((response: [])  =>
      {
        const items = response;
        if (items.length > 0) {
          items.forEach(item =>
          {
            this.items.push(item);
          });

          this.location.go('/advice/page/' + this.nextPage);
          this.nextPage++;

          if (response.length < this.params.limit) {
            this.moreEntries = false;
          }
        } else {
          this.moreEntries = false;
        }
      });
    }
  }
}
