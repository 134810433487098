<div class="container container--article py-5 blog-detail" *ngIf="article">
    <h1 class="title display-5 fw-500 text-center mx-auto mb-2 custom-mw">{{article.contents.title}}</h1>
    <div class="ff-spectral fw-500 text-center mb-lg-0">{{article.contents.introText}}</div>
</div>

<!--<div
    *ngIf="article && article.fields.introBackground"
    class="page-intro d-flex flex-column justify-content-center align-items-center"
    [ngStyle]="{'background-image': 'url(' + article.fields.introBackground + ')'}">
    <div class="banner-img-container" *ngIf="article && article.introImage">
        <div class="banner-img-box">
            <img src="{{article.introImage}}" alt=""/>
        </div>
    </div>
</div>-->


<div class="container container--article" *ngIf="article">

    <div [innerHTML]="article.contents.content" class="mt-30"></div>

    <br style="clear: both" />
<!--    <ng-container *ngIf="otherArticles.length">
        <hr />
        <h1>Related posts</h1>
        <div class="row">
            <div
                class="col-12 col-lg-4 mb-3"
                *ngFor="let s of otherArticles">

                <div class="data-tile no-border hover" [uperoBrandRouterLink]="['/blog-and-news', s.category.slug, s.slug]">
                    <div class="gallery-image-tile" [ngStyle]="{'background-image': 'url(' + s.tile + ')'}"></div>
                    <div class="mt-2 text-start">
                        <h2 class="mb-1">{{s.title}}</h2>
                        <p class="mb-2">{{s.category.name}}</p>
                        <p>{{s.createdAt | date: 'dd MMM yyyy'}}</p>
                        <button class="btn btn-primary btn-slim" [uperoBrandRouterLink]="['/blog-and-news', s.category.slug, s.slug]">
                            View article
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </ng-container>-->

</div>
