import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService
{
  breadcrumbChanged: BehaviorSubject<false | Array<{ text: string, route?: string[] }>> = new  BehaviorSubject(false);
  isFloatingChanged = new BehaviorSubject(false);
  private breadcrumbs: Array<{ text: string, route?: string[] }> = [];
  private isFloating = false;

  constructor()
  {
  }

  public changeBreadcrumb(value)
  {
    this.breadcrumbs = value;
    this.breadcrumbChanged.next(this.breadcrumbs);
  }

  public changeFloatingState(value)
  {
    this.isFloating = value;
    this.isFloatingChanged.next(this.isFloating);
  }
}
