<form [formGroup]="form" class="d-flex flex-column w-100 h-100" autocomplete="off" novalidate [autocomplete]="'off'"
    (ngSubmit)="onSubmit()">
    <div class="d-flex flex-column h-100 justify-content-between">
        <div>
            <div class="mb-3">
                Enter your account details to log-in
            </div>
            <div class="mb-3">
                <input type="email" autocomplete="new-password" class="form-control border-0"
                    formControlName="username" placeholder="Email" >
                <div *ngIf="submitted && form.controls.username.errors?.required" class="error">Email is required</div>
                <div *ngIf="submitted && form.controls.username.errors?.email" class="error">Enter a valid email</div>
            </div>
            <div class="mb-3">
                <input type="password" class="form-control border-0" autocomplete="new-password"
                    formControlName="password" placeholder="Password" >
               <div  *ngIf="submitted && form.controls.password.errors?.required" class="error">Password is required</div>
                <div  *ngIf="submitted && form.controls.password.errors?.minlength" class="error">Password must be 8 characters or more</div>
                <div  *ngIf="submitted && !passwordValidated && this.showErrorMessage" class="error">Email / password combination incorrect
                </div>
            </div>
            <div class="mb-2 mb-md-5">
                <div class="forgot-btn-main">
                    <a  href="javascript:void(0)" (click)="triggerForgottenPassword()"
                        uperoTextColorSetter
                        class="hover">Forgotten password</a>
                </div>
            </div>
        </div>
        <div class="mt-2 d-flex justify-content-center login-btn">
            <div class="login-btn-main">
                <button  class="btn btn-primary" type="submit">Log-in</button>
            </div>
        </div>
    </div>
</form>
