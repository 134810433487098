import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { empty, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GlobalRequestInterceptor implements HttpInterceptor {
  private isServer = false;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error) => {
        if (this.isServer) {
          console.error(`Global interceptor!! Can't load url: ${req.method}|${req.url}`);
          return empty();
        }
        return throwError(() => error);
      })
    )
  }

}
