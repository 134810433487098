import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasketService, OverlayService, UserService } from '@upero/services';
import { Store } from '@upero/store';
import { AlertConfirmable } from '../../decorators/alert-confirmable.decorator';
import { UserData } from '@upero/misc';

@Component({
  selector: 'upero-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
  @Input() basket;
  @Output() addingAddress: any = new EventEmitter();
  @Output() addressSelected: any = new EventEmitter();
  @Output() billingAddressSelected: any = new EventEmitter();
  @Input() user;
  addresses = [];

  confirmationData = {
    open: false,
    title: '',
    detail: '',
    data: undefined,
  };
  selectedAddress = null;

  constructor(
    private overlayService: OverlayService,
    private store: Store,
    private userService: UserService,
    private basketService: BasketService
  ) {}

  ngOnInit(): void {
    this.getAddresses();
  }

  getAddresses() {
    const storedUser = this.store.selectForLocal<UserData>('user');
    const addressType = this.store.selectForLocal('addressType');

    if (storedUser) {
      this.userService.findAddressesByCustomer(this.user.id).subscribe(({ data }) => {
        this.addresses = [];
        data.forEach((address) => {
          if (addressType === 'delivery') {
            if (this.basket.deliveryAddress.id === address.id) {
              this.selectedAddress = address;
            }
          }
          if (addressType === 'billing') {
            if (this.basket.billingAddress.id === address.id) {
              this.selectedAddress = address;
            }
          }

          this.addresses.push(address);
        });
      });
    } else {
      this.addresses = [this.user.address];
      if (addressType === 'billing') {
        this.selectedAddress = this.user.address;
      } else {
        this.selectedAddress = this.user.addressDelivery;
      }
    }
  }

  addAddress() {
    this.overlayService.toggle('addressCreate');
  }

  @AlertConfirmable({ title: 'Are you sure you want delete this address?' })
  deleteConfirm(address) {
    this.userService.addressDelete(address.address.id).subscribe((data) => {
      this.getAddresses();
    });
  }

  setBillingAddress(selectedAddress) {
    this.addresses.forEach((address) => {
      address.isBilling = 0;
    });
    this.selectedAddress = selectedAddress;
    this.selectedAddress.isBilling = 1;
  }

  setDeliveryAddress(selectedAddress) {
    this.selectedAddress = selectedAddress;
    this.addressSelected.emit([{ address: selectedAddress }]);
  }

  editAddress(address) {
    this.store.set('address', address);
    this.overlayService.toggle('addressEdit');
  }

  close() {
    this.overlayService.closeAll();
  }

  save() {
    const addressType = this.store.selectForLocal('addressType');
    this.userService.updateAddress(this.selectedAddress).subscribe((data) => {
      if (addressType === 'billing') {
        this.billingAddressSelected.emit({ address: this.selectedAddress });
      } else {
        this.addressSelected.emit([{ address: this.selectedAddress }, true]);
      }
      this.close();
    });
  }
}
