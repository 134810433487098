import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'upero-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss']
})
export class ProcessingComponent implements OnInit
{

  constructor()
  {
  }

  ngOnInit(): void
  {
  }

}
