import { TemplateRef } from "@angular/core";

export type AlertData = {
  errors: Array<any>;
  notifications: Array<any>;
  statusUpdate: any; // can't find this one used
  confirmAction: AlertConfirmData | null;
  simple: SimpleAlertData | null;
  customAlert: CustomAlertData | null;
  title: string;
  closable: boolean;
  resolve: any | null;
  reject: any | null;
};

export const DefaultAlertData = {
  errors: [],
  notifications: [],
  confirmAction: null,
  simple: null,
  customAlert: null,
  closable: false
} as AlertData;

export type CommonAlertData = {
  closeCb?: (data: any) => void;
}

export type SimpleAlertData = CommonAlertData & {
  message: string;
  okText: string;
  confirmCb?: (data: any) => void;
  okClass: 'btn-outline-success' | 'btn-outline-danger' | 'btn-outline-warning' | 'btn-outline-primary';
  data: any;
  closable?: boolean;
};

export const DefaultSimpleAlertData = {
  message: '',
  okText: 'Ok',
  confirmCb: null,
  closeCb: null,
  okClass: 'btn-outline-primary',
  data: null
} as SimpleAlertData;

export type CustomAlertData = CommonAlertData & {
  title: string;
  template: TemplateRef<any>;
  confirmText: string;
  confirmCb?: (data: any) => void;
  confirmClass: 'btn-success' | 'btn-danger' | 'btn-warning' | 'btn-primary';
  cancelText: string;
  cancelCb?: (data: any) => void;
  canConfirmCb?: () => boolean;
  data: any;
  theme: '' | 'xxl';
  closable?: boolean;
};

export const DefaultCustomAlertData = {
  title: '',
  template: null,
  confirmText: 'Ok',
  confirmClass: 'btn-success',
  confirmCb: null,
  closeCb: null,
  cancelText: 'Cancel',
  cancelCb: null,
  canConfirmCb: null,
  data: null,
  theme: ''
} as CustomAlertData;

export type AlertConfirmData = CommonAlertData & {
  title: string;
  message: string;
  confirmText: string;
  confirmCb?: (data: any) => void;
  confirmClass: 'btn-success' | 'btn-danger' | 'btn-warning' | 'btn-primary';
  cancelText: string;
  cancelCb?: (data: any) => void;
  data: any;
  closable?: boolean;
};

export const defaultAlertConfirmData = {
  title: '',
  message: '',
  confirmText: 'Ok',
  confirmClass: 'btn-success',
  confirmCb: null,
  closeCb: null,
  cancelText: 'Cancel',
  cancelCb: null,
  data: null
} as AlertConfirmData;
