import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { ApiAuthHttpClient } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class CareersService
{

  constructor(
    private http: ApiAuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  search(params)
  {
    const dataToSend = {
      params,
    };

    return this.http
      .post(this.env.apiPath + 'job/search', dataToSend)
      .pipe(map((data: any) => data));
  }

  sendApplicationForm(application)
  {
    const dataToSend = {
      application
    };

    return this.http
      .post(this.env.apiPath + 'job/public/apply', dataToSend)
      .pipe(map((data: any) => data.data));
  }
}
