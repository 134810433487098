import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Store } from '@upero/store';
import { AlertService, ToolsService, UserService } from '@upero/services';
import { AddressData } from '@upero/misc';

export type RequiredAddress = {
  add1?: boolean;
  add2?: boolean;
  add3?: boolean;
  town?: boolean;
  county?: boolean;
  postcode?: boolean;
  postcodePicker?: boolean;
  country?: boolean;
};


const requiredAddress: RequiredAddress = {
  add1: false,
  add2: false,
  add3: false,
  town: false,
  county: false,
  postcode: false,
  postcodePicker: false,
  country: false,
} as RequiredAddress;

@Component({
  selector: 'upero-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input() requiredFields: RequiredAddress = { ...requiredAddress };
  @Input() isBillingAddress = false;
  @Input() layout: 'layout-1' | 'layout-2' = 'layout-1';
  @Input() formTitle: string;
  @Output() addressCreated = new EventEmitter<any>(); // this is fired only when correct address is entered
  @Output() isAddressValid = new EventEmitter<any>(); // this is fired on every status change, only true/false
  @Input() address: AddressData = null;
  @Input() makeDirty: Observable<boolean> = null;

  destroy$ = new Subject<void>();

  form: FormGroup;

  addressResults = [];
  selAddress: any = null;
  manualAddress = false;

  postcode: string | null = null;
  countries: Array<any> = [];

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private userService: UserService,
    private alertService: AlertService,
    private toolsService: ToolsService
  ) {
    this.userService.countryList().subscribe((result) => {
      result.sort((item1, item2) => {
        return item1.sortOrder - item2.sortOrder;
      });
      this.countries = result;
    });
  }

  ngOnInit(): void {
    if (this.address) {
      this.manualAddress = true;
      this.form = this.fb.group({
        add1: [
          this.address.add1,
          [Validators.minLength(2), Validators.maxLength(80)].concat(
            this.requiredFields.add1 ? [Validators.required] : []
          ),
        ],
        add2: [
          this.address.add2,
          [Validators.minLength(2), Validators.maxLength(80)].concat(
            this.requiredFields.add2 ? [Validators.required] : []
          ),
        ],
        add3: [
          this.address.add3,
          [Validators.minLength(2), Validators.maxLength(80)].concat(
            this.requiredFields.add3 ? [Validators.required] : []
          ),
        ],
        town: [
          this.address.town,
          [Validators.minLength(2)].concat(this.requiredFields.town ? [Validators.required] : []),
        ],
        county: [
          this.address.county,
          [Validators.minLength(2)].concat(this.requiredFields.county ? [Validators.required] : []),
        ],
        postcode: [
          this.address.postcode,
          [Validators.minLength(2), Validators.maxLength(10)].concat(
            this.requiredFields.postcode ? [Validators.required] : []
          ),
        ],
        postcodePicker: [
          '',
          [Validators.minLength(2), Validators.maxLength(10)].concat(
            this.requiredFields.postcodePicker ? [Validators.required] : []
          ),
        ],
      });
      this.postcode = this.address.postcode;

      // if it's GB and postcode is defined we pre-search for addesses
      if (this.address.country.toLowerCase() === 'gb' && this.address.postcode) {
        this.getAddress();
      }
    } else {
      this.address = {
        id: this.toolsService.newUUID(),
        add1: '',
        add2: '',
        add3: '',
        town: '',
        county: '',
        country: 'GB',
        postcode: '',
        isBilling: this.isBillingAddress,
        defaultDeliveryAddress: true, // weird field, always true here
      };
      this.form = this.fb.group({
        add1: [
          '',
          [Validators.minLength(2), Validators.maxLength(80)].concat(
            this.requiredFields.add1 ? [Validators.required] : []
          ),
        ],
        add2: [
          '',
          [Validators.minLength(2), Validators.maxLength(80)].concat(
            this.requiredFields.add2 ? [Validators.required] : []
          ),
        ],
        add3: [
          '',
          [Validators.minLength(2), Validators.maxLength(80)].concat(
            this.requiredFields.add3 ? [Validators.required] : []
          ),
        ],
        town: ['', [Validators.minLength(2)].concat(this.requiredFields.town ? [Validators.required] : [])],
        county: ['', [Validators.minLength(2)].concat(this.requiredFields.county ? [Validators.required] : [])],
        postcode: [
          '',
          [Validators.minLength(2), Validators.maxLength(10)].concat(
            this.requiredFields.postcode ? [Validators.required] : []
          ),
        ],
        postcodePicker: [
          '',
          [Validators.minLength(2), Validators.maxLength(10)].concat(
            this.requiredFields.postcodePicker ? [Validators.required] : []
          ),
        ],
      });
    }
    if (this.makeDirty) {
      this.makeDirty
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => value === true && this.validateAllFormFields(this.form));
    }
    this.onChanges();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onChanges() {
    this.form.valueChanges
      .pipe(
        tap((item) => this.isAddressValid.emit(false)),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe((val) => this.prepareAddressForEmit());
  }

  async getAddress() {
    this.addressResults = [];
    try {
      if (!this.form.value.postcodePicker) {
        return;
      }
      this.isAddressValid.emit(false);
      const addressData = await this.userService.postcodeLookup(this.form.value.postcodePicker);
      this.addressResults = addressData.addresses;
      localStorage.setItem('tempAddressResults', JSON.stringify(this.addressResults));
      this.postcode = addressData.postcode;
      this.form.patchValue({ postcodePicker: this.postcode });
      this.form.patchValue({ postcode: this.postcode });
    } catch (err) {
      if ((err.status / 100) % 10 !== 4) {
        // if an error is not from 4xx range
        throw new Error(JSON.stringify(err));
      }
      this.alertService.error(['Sorry - we could not find an addess for the postcode you entered']);
    }
  }

  public handleAddressChange(address) {
    if (address) {
      this.selAddress = address;
      this.form.patchValue({
        add1: address.line_1,
        add2: address.line_2,
        add3: address.line_3,
        town: address.locality,
        county: address.county,
      });

      if (!address.locality) {
        this.form.patchValue({
          town: address.town_or_city,
        });
      }
    } else {
      this.form.reset();
    }

    this.prepareAddressForEmit();
  }

  public handleCountryChange(country): void {
    this.address.country = country;
    this.prepareAddressForEmit();
  }

  prepareAddressForEmit() {
    this.address.add1 = this.form.value.add1;
    this.address.add2 = this.form.value.add2;
    this.address.add3 = this.form.value.add3;
    this.address.town = this.form.value.town;
    this.address.county = this.form.value.county;
    if (this.manualAddress) {
      this.address.postcode = this.form.value.postcode;
    } else {
      this.address.postcode = this.postcode;
    }

    if (this.form.valid === true) {
      this.isAddressValid.emit(true);
      this.addressCreated.emit(this.address);
    } else {
      this.isAddressValid.emit(false);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
