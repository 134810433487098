import { CompanyData } from './user.data';

export type OrderGuestUserData = {
    orderId: string;
    user: {
        userId: string;
        password: string;
        accountType?: 'trade';
    };
    company?: CompanyData;
};
