import { Component, HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@upero/store';
import { BaseBlockComponent, FooterFields } from '@upero/misc';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AlertService, CmsService } from '@upero/services';
import { CollectionsService } from '@upero/collections';


@Component({
  selector: 'upero-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseBlockComponent<FooterFields> implements OnInit
{
  @HostBinding('attr.class') get classes() { return this.cssClass; };

  activeRoute$ = this.store.select<any>('activeRoute');
  menu$ = this.store.select<any>('menu');
  siteSettings = this.store.selectForLocal('siteSettings');
  currentBrand = this.store.selectForLocal('currentBrand');

  currentDate = new Date();
  isBrowser = false;

  email = '';
  newsletterForm: FormGroup;
  newsletterFormData = {
    email: '',
  };
  submitted = false;
  duplicateEmail = false;

  items: any;
  subItems: any;

  constructor(
    private store: Store,
    public router: Router,
    private formBuilder: FormBuilder,
    private cmsService: CmsService,
    private alertService: AlertService,
    private collectionsService: CollectionsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: any,
  )
  {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.newsletterForm = this.formBuilder.group({
      confirm: [false, Validators.requiredTrue],
      email: ['', [Validators.required, Validators.email]],
    });

    this.collectionsService.getCollectionEntriesByType(this.block.fields.collectionType, 1)
      .subscribe((response) => {
        this.items = response;
      });

    this.collectionsService.getCollectionEntriesByType(this.block.fields.subLinksCollectionType, 1)
      .subscribe((response) => {
        this.subItems = response;
      });
  }

  get field() {
    return this.newsletterForm.controls;
  }

  hideErrors() {
    if (!this.submitted) {
      this.duplicateEmail = false;
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.newsletterForm.invalid) {
      return;
    }

    this.newsletterFormData.email = this.newsletterForm.controls.email.value;
    this.cmsService.sendSubscriberForm(this.newsletterFormData).subscribe(data => {
        if (data.success) {
          this.newsletterForm.reset();
          this.submitted = false;
          this.duplicateEmail = false;
          this.alertService.notification(
            ['Thank you for joining us! <br> We promise high quality '+ this.siteSettings.name +'-related news straight to your inbox!'],
            4000);
        }
      },
      error => {
        this.newsletterForm.reset();
        this.submitted = false;
        this.duplicateEmail = true;
      });
  }

}
