<div class="bespoke__desktop">
    <div class="container h-100">
        <div class="row g-0 h-100">
            <div class="col-12 col-lg-8 offset-lg-2 bg-white page-min-h">
                <div class="position-sticky product-preview d-flex flex-column justify-content-center h-100">
                    <ng-container #uperoProxyContainer>
                        <!--<upero-bespoke-builder-form [source]="'onPage'"></upero-bespoke-builder-form>-->
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
