<ng-container *ngIf="(deviceType$| async) === 'desktop'">
  <div class="spacer"></div>
  <section class="header bg-white" id="header">
    <div class="container-fluid px-xl-5">
      <div class="header__content d-flex align-items-center w-100">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex justify-content-center align-items-center">
            <a class="d-flex align-items-center" [uperoBrandRouterLink]="['/']">
              <svg-icon [svgClass]="'header__logo-img'"
                        src="assets/images/upero.svg"></svg-icon>
              <div *ngIf="currentBrand" class="ff-cou ms-3 fw-400 fs-4 text-dark">
                {{currentBrand.name}}
              </div>
            </a>
          </div>

          <div
            *ngIf="!(user$ | async)"
            class="d-flex flex-1 justify-content-end align-items-center text-end">

            <a href="tel:{{siteSettings.settings.contact.phone}}" target="_blank"
               gaEvent="click_phone_number" gaCategory="Contact" gaBind="click"
               fbqEvent="click_phone_number" fbqBind="click"
               class="d-flex align-items-center text-black fs-6 me-4 me-xl-5">
              Get in touch {{siteSettings.settings.contact.phone}}
            </a>
            <upero-button gaEvent="header_cta_quote" gaCategory="Contact" gaBind="click" class="me-4 me-xl-5" [button]="block.fields['quoteButton']"></upero-button>
            <a [uperoBrandRouterLink]="['/account']" class="header-sub-item-icon">
              <svg-icon src="assets/images/icon-user.svg"></svg-icon>
            </a>
            <a [uperoBrandRouterLink]="['/my-favourites']"
               *ngIf="!block.fields.hideStoreIcons"
               class="header-sub-item-icon">
              <svg-icon src="assets/images/icon-heart.svg"></svg-icon>
              <span *ngIf="(favourites$ | async).length"
                    class="badge-count">{{(favourites$ | async).length}}</span>
            </a>
            <a [uperoBrandRouterLink]="['/basket']"
               *ngIf="!block.fields.hideStoreIcons"
               class="header-sub-item-icon me-0">
              <svg-icon src="assets/images/icon-cart.svg"></svg-icon>
              <span *ngIf="(basket$ | async).items.length || (basket$ | async).samples?.length"
                    class="badge-count">{{(basket$ | async).items.length + (basket$ | async).samples?.length}}</span>
            </a>
          </div>

          <div *ngIf="(user$ | async)" class="d-flex flex-1 justify-content-end align-items-center text-end">

            <a href="tel:{{siteSettings.settings.contact.phone}}" target="_blank"
               gaEvent="click_phone_number" gaCategory="Contact" gaBind="click"
               fbqEvent="click_phone_number" fbqBind="click"
               class="d-flex align-items-center text-black fs-6 me-4 me-xl-5">
              Get in touch {{siteSettings.settings.contact.phone}}
            </a>
            <upero-button class="me-4 me-xl-5" [button]="block.fields['quoteButton']"></upero-button>
            <a href="javascript:void(0)" class="header-sub-item-icon dropdown me-4" uperoDropdown>
              <svg-icon src="assets/images/icon-user.svg"></svg-icon>
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-user shadow-sm px-4 py-3">
                <a class="dropdown-item fw-bold small px-0 mb-2" [uperoBrandRouterLink]="['/account']">
                  My Account
                </a>
                <a class="dropdown-item fw-bold small px-0 mb-2"
                  *ngIf="!block.fields.hideStoreIcons"
                   [uperoBrandRouterLink]="['/my-favourites']"
                >
                  Favourites
                </a>
                <div class="dropdown-divider mt-0 mb-2"></div>
                <a [uperoBrandRouterLink]="['/signout']"
                   class="dropdown-item fw-bold px-0 small">
                  Sign out
                </a>
              </div>
            </a>
            <a uperoDropdown class="header-sub-item-icon dropdown" href="javascript:void(0)">
              <svg-icon src="assets/images/icon-bell.svg"></svg-icon>
              <div *ngIf="(notifications$ | async).unread > 0"
                   class="badge-count">{{(notifications$ | async).unread}}</div>
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-notifications shadow-sm">
                <upero-notifications-list></upero-notifications-list>
              </div>
            </a>
            <a *ngIf="!block.fields.hideStoreIcons" [uperoBrandRouterLink]="['/basket']" class="header-sub-item-icon me-0">
              <svg-icon src="assets/images/icon-cart.svg"></svg-icon>
              <span *ngIf="(basket$ | async).items.length || (basket$ | async)?.samples?.length"
                    class="badge-count">{{(basket$ | async).items.length + (basket$ | async)?.samples?.length}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid p-0" [ngClass]="currentBrand ? 'bg-' + currentBrand.settings.navbarClass : 'bg-' + block.fields.navbar_class">
      <div class="container-menu">
        <div class="d-flex justify-content-between align-items-center">
          <div [ngClass]="isLoading ? 'invisible': 'visible'" class="w-100">
            <nav class="navbar navbar-expand-lg navbar-dark d-flex justify-content-center"
                 id="header-nav">
              <ul class="navbar-nav position-relative d-flex justify-content-center ign-items-center"
                style="height: 40px;">
                <ng-container *ngFor="let item of items;">
                  <ng-container *ngIf="item.contents.byProducts && productGroups">
                    <li
                      (mouseleave)="closeSubMenu(item)"
                      class="nav-item me-4 me-xl-5">
                      <a
                        (mouseenter)="setActiveMenu(item, 'menuTrigger')"
                        (mouseleave)="unsetActiveMenuImage()"
                        [ngClass]="{'dropdown-toggle-chevron' : productGroups.length > 0}"
                        [uperoBrandRouterLink]="['/', item.contents.link]"
                        class="nav-link">{{item.contents.title}}
                      </a>

                      <ng-container *ngIf="productGroups.length > 0">
                        <ul class="dropdown-menu border-0 rounded-0 p-4 shadow" role="menu"
                            [ngClass]="{'menu-visible' : item.open}"
                            (mouseleave)="closeSubMenu(item)">
                          <div class="row g-0">
                            <div class="col-9">
                              <div class="row g-4">
                                <li *ngFor="let productGroup of productGroups"
                                    class="col-4 d-flex flex-column align-items-start"
                                    role="menuitem">
                                    <a class="custom-dropdown-item mb-3"
                                       [ngClass]="this.currentBrand ? 'border-' + this.currentBrand.settings['linkClass'] : 'border-primary'"
                                       (mouseenter)="setActiveMenuImage(productGroup.imageUrl)"
                                       (mouseleave)="unsetActiveMenuImage()"
                                       [uperoBrandRouterLink]="['/', 'product-groups', productGroup.slug]">
                                      {{productGroup.name}}
                                    </a>
                                    <ng-container *ngIf="productGroup.subGroups">
                                      <ng-container *ngFor="let subgroup of productGroup.subGroups">
                                        <a class="custom-dropdown-item ms-3 mb-3"
                                           [ngClass]="this.currentBrand ? 'border-' + this.currentBrand.settings['linkClass'] : 'border-primary'"
                                           (mouseenter)="setActiveMenuImage(subgroup.imageUrl)"
                                           (mouseleave)="unsetActiveMenuImage()"
                                           [uperoBrandRouterLink]="['/', 'product-groups', subgroup.slug]">
                                          {{subgroup.name}}
                                        </a>
                                      </ng-container>

                                    </ng-container>
                                </li>
                              </div>
                            </div>
                            <div class="col-3">
                              <div *ngIf="activeMenuImage" class="activeImageWrapper">
                                <img class="img-as-bg" [src]="activeMenuImage + '?width=175'" loading="eager">
                              </div>
                            </div>
                          </div>
                        </ul>
                      </ng-container>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="item.contents.bySpaces">
                    <li
                      (mouseleave)="closeSubMenu(item)"
                      class="nav-item me-4 me-xl-5">
                      <a [ngClass]="{'dropdown-toggle-chevron' : currentBrand.spaces.length > 0}"
                         (mouseenter)="setActiveMenu(item, 'menuTrigger')"
                         (mouseleave)="unsetActiveMenuImage()"
                        [uperoBrandRouterLink]="[item.contents.link]"
                        class="nav-link">{{item.contents.title}}
                      </a>

                      <ng-container *ngIf="currentBrand.spaces.length > 0">
                        <ul class="dropdown-menu border-0 rounded-0 p-4 shadow" role="menu"
                            [ngClass]="{'menu-visible' : item.open}"
                            (mouseleave)="closeSubMenu(item)">
                          <div class="row g-0">
                            <div class="col-9">
                              <div class="row g-4">
                                <li *ngFor="let space of currentBrand.spaces"
                                    (mouseenter)="setActiveMenuImage(space.imageUrl)"
                                    (mouseleave)="unsetActiveMenuImage()"
                                    class="col-4"
                                    role="menuitem">
                                  <a class="custom-dropdown-item mb-3"
                                     [ngClass]="this.currentBrand ? 'border-' + this.currentBrand.settings['linkClass'] : 'border-primary'"
                                     [uperoBrandRouterLink]="['/spaces', space.slug ? space.slug : '']">
                                    {{space.name}}
                                  </a>
                                </li>
                              </div>
                            </div>
                            <div class="col-3">
                              <div *ngIf="activeMenuImage" class="activeImageWrapper">
                                <img class="img-as-bg" [src]="activeMenuImage + '?width=175'" loading="eager">
                              </div>
                            </div>
                          </div>
                        </ul>
                      </ng-container>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="item.isRoot === 1 && !item.contents.byProducts && !item.contents.bySpaces">
                    <li *ngIf="item.contents.external === 1" class="nav-item">
                      <a [href]="[item.contents.link]"
                         class="nav-link me-4 me-xl-5"
                         target="_blank" rel="noopener">{{item.contents.title}}</a>
                    </li>

                    <li
                      *ngIf="!item.contents.external"
                      (mouseenter)="setActiveMenu(item, 'menuTrigger')"
                      (mouseleave)="closeSubMenu(item)"
                      class="nav-item me-4 me-xl-5">

                      <a
                        [ngClass]="{'dropdown-toggle-chevron' : item.children.length > 0}"
                        [uperoBrandRouterLink]="[item.contents.link]"
                        class="nav-link">{{item.contents.title}}
                      </a>

                      <ng-container *ngIf="item.children.length > 0">
                        <ul class="dropdown-menu border-0 rounded-0 p-4 shadow" role="menu"
                            [ngClass]="{'menu-visible' : item.open}"
                            (mouseleave)="closeSubMenu(item)">
                          <div class="row g-0">
                            <div class="col-9">
                              <div class="row g-4">
                                <li *ngFor="let subItem of item.children"
                                    [ngClass]="this.currentBrand ? 'border-' + this.currentBrand.settings['linkClass'] : 'border-primary'"
                                    (mouseenter)="setActiveMenuImage(subItem.contents.previewImage)"
                                    (mouseleave)="unsetActiveMenuImage()"
                                    class="col-4"
                                    role="menuitem">
                                  <a class="custom-dropdown-item mb-3" [uperoBrandRouterLink]="[subItem.contents.link]">
                                    {{subItem.contents.title}}
                                  </a>
                                </li>
                              </div>
                            </div>
                            <div class="col-3">
                              <div *ngIf="activeMenuImage" class="activeImageWrapper">
                                <img class="img-as-bg" [src]="activeMenuImage + '?width=175'" loading="eager">
                              </div>
                            </div>
                          </div>
                        </ul>
                      </ng-container>
                    </li>
                  </ng-container>

                </ng-container>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<upero-menu-mobile
  *ngIf="(mobileMenu$ | async) && ((deviceType$ | async) === 'mobile' || (deviceType$ | async) === 'tabletV' || (deviceType$ | async) === 'tabletH')"
  [items]="items" [productGroups]="productGroups">
</upero-menu-mobile>
<upero-menu-mobile-user
  *ngIf="(mobileMenuUser$ |async) && ((deviceType$ | async) === 'mobile' || (deviceType$ | async) === 'tabletV' || (deviceType$ | async) === 'tabletH')"
></upero-menu-mobile-user>

<div class="mobile-header-wrapper position-relative w-100"
  *ngIf="(deviceType$| async) === 'mobile' || (deviceType$| async) === 'tabletV' || (deviceType$| async) === 'tabletH'"
>
  <upero-header-mobile></upero-header-mobile>
</div>
