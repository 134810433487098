import { Component, HostBinding, OnInit } from '@angular/core';
import { BaseBlockComponent, UspFields } from '@upero/misc';
import {Store} from "@upero/store";

@Component({
  selector: 'upero-usp',
  templateUrl: './usp.component.html',
  styleUrls: ['./usp.component.scss']
})
export class UspComponent extends BaseBlockComponent<UspFields> implements OnInit
{
  currentBrand = this.store.selectForLocal('currentBrand');

  constructor(private store: Store,)
  {
    super();
  }

  ngOnInit(): void
  {
  }
}
