import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountComponent } from '@upero/layouts';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([
      {
        path: 'account',
        component: AccountComponent,
        loadChildren: () => import('@upero/user').then(m => m.AccountModule)
      }
    ], {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
    })
  ],
  exports: [RouterModule],
  declarations: [
  ]
})

export class RoutingModule {}
