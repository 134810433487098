import {Component, ComponentFactoryResolver, Input, Type, ViewChild, ViewContainerRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {CollectionsEntrySingleComponent} from "../collections-entry-single/collections-entry-single.component";
import {
  BasketDeliveryInformationComponent
} from '../basket-delivery-information/basket-delivery-information.component';

@Component({
  selector: 'upero-block-loader',
  templateUrl: './block-loader.component.html',
  styleUrls: ['./block-loader.component.scss']
})
export class BlockLoaderComponent
{
  blocks: any;

  isLoading = true;
  @Input()
  set pageBlocks(blocks: any) {
    this.blocks = blocks.sort((a: any, b: any) => {
      return (a.y - b.y !== 0 ? a.y - b.y : (a.x - b.x));
    });

    this.loadComponents().then(r => this.isLoading = false);
  }

  @ViewChild('loaderContainer', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
  )
  {}

  createDynamicComponent<T>(component: Type<T>)
  {
    if (this.viewContainerRef) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(component);
      return this.viewContainerRef.createComponent(factory);
    }

    return;
  }

  async loadComponents()
  {
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }

    if (!this.blocks) {
      return;
    }

    let xOffset = null; // block X offset
    let currentY = null; // current row we work on
    for (const block of this.blocks) {
      if (currentY !== this.blocks.y) { // new row
        currentY = this.blocks.y;
        xOffset = 0; // resetting x offset
      }
      if (xOffset != block.x) {
        block.xOffset = block.x - xOffset;
      }

      if (block.type === 'image') {
        const { ImageComponent } = await import('../image/image.component');
        const componentRef = this.createDynamicComponent(ImageComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'navbar') {
        const { NavbarComponent } = await import('../navbar/navbar.component');
        const componentRef = this.createDynamicComponent(NavbarComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'slider') {
        const { ImageSliderComponent } = await import('../image-slider/image-slider.component');
        const componentRef = this.createDynamicComponent(ImageSliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'usp') {
        const { UspComponent } = await import('../usp/usp.component');
        const componentRef = this.createDynamicComponent(UspComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'ranges-slider') {
        const { RangeSliderComponent } = await import('../range-slider/range-slider.component');
        const componentRef = this.createDynamicComponent(RangeSliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'cta-text-button') {
        const { CtaButtonTextComponent } = await import('../cta-button-text/cta-button-text.component');
        const componentRef = this.createDynamicComponent(CtaButtonTextComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'image-text-block') {
        const { ImageTextBlockComponent } = await import('../image-text-block/image-text-block.component');
        const componentRef = this.createDynamicComponent(ImageTextBlockComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'partners-carousel') {
        const { PartnersCarouselComponent } = await import('../partners-carousel/partners-carousel.component');
        const componentRef = this.createDynamicComponent(PartnersCarouselComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'cta-banner') {
        const { CtaBannerComponent } = await import('../cta-banner/cta-banner.component');
        const componentRef = this.createDynamicComponent(CtaBannerComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'contact-cta') {
        const { CtaContactComponent } = await import('../cta-contact/cta-contact.component');
        const componentRef = this.createDynamicComponent(CtaContactComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'trustpilot-slider') {
        const { TrustpilotSliderComponent } = await import('../trustpilot-slider/trustpilot-slider.component');
        const componentRef = this.createDynamicComponent(TrustpilotSliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'products-grid') {
        const { ProductsGridComponent } = await import('../products-grid/products-grid.component');
        const componentRef = this.createDynamicComponent(ProductsGridComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'image-text-side-block') {
        const { ImageTextSideComponent } = await import('../image-text-side/image-text-side.component');
        const componentRef = this.createDynamicComponent(ImageTextSideComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'contact-form') {
        const { ContactFormComponent } = await import('../contact-form/contact-form.component');
        const componentRef = this.createDynamicComponent(ContactFormComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'registration-form') {
        const { RegistrationFormComponent } = await import('../registration-form/registration-form.component');
        const componentRef = this.createDynamicComponent(RegistrationFormComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }


      if (block.type === 'google-map') {
        const { GoogleMapsComponent } = await import('../google-maps/google-maps.component');
        const componentRef = this.createDynamicComponent(GoogleMapsComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'page-intro') {
        const { PageIntroComponent } = await import('../page-intro/page-intro.component');
        const componentRef = this.createDynamicComponent(PageIntroComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'footer') {
        const { FooterComponent } = await import('../footer/footer.component');
        const componentRef = this.createDynamicComponent(FooterComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'faq') {
        const { FaqComponent } = await import('../faq/faq.component');
        const componentRef = this.createDynamicComponent(FaqComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'advice') {
        const { AdviceComponent } = await import('../advice/advice.component');
        const componentRef = this.createDynamicComponent(AdviceComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'case-study') {
        const { CollectionEntriesGridComponent } = await import('../collections-entries-grid/collections-entries-grid.component');
        const componentRef = this.createDynamicComponent(CollectionEntriesGridComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'brand-video') {
        const { BrandVideoComponent } = await import('../brand-video/brand-video.component');
        const componentRef = this.createDynamicComponent(BrandVideoComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'wysiwyg') {
        const { WysiwygComponent } = await import('../wysiwyg/wysiwyg.component');
        const componentRef = this.createDynamicComponent(WysiwygComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'cms-grid') {
        const { CmsGridComponent } = await import('../cms-grid/cms-grid.component');
        const componentRef = this.createDynamicComponent(CmsGridComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'job-tiles') {
        const { JobTilesComponent } = await import('../job-tiles/job-tiles.component');
        const componentRef = this.createDynamicComponent(JobTilesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'breadcrumb') {
        const { BreadcrumbComponent } = await import('../breadcrumb/breadcrumb.component');
        const componentRef = this.createDynamicComponent(BreadcrumbComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'product-ranges') {
        const { ProductRangesComponent } = await import('../product-ranges/product-ranges.component');
        const componentRef = this.createDynamicComponent(ProductRangesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'product-types') {
        const { ProductTypesComponent } = await import('../product-types/product-types.component');
        const componentRef = this.createDynamicComponent(ProductTypesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'product-spaces') {
        const { ProductSpacesComponent } = await import('../product-spaces/product-spaces.component');
        const componentRef = this.createDynamicComponent(ProductSpacesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'loyality-page-intro') {
        const { LoyalityPageIntroComponent } = await import('../loyality-page-intro/loyality-page-intro.component');
        const componentRef = this.createDynamicComponent(LoyalityPageIntroComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'favourites') {
        const { FavouritesComponent } = await import('../favourites/favourites.component');
        const componentRef = this.createDynamicComponent(FavouritesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'newsletter-cta') {
        const { NewsletterCtaComponent } = await import('../newsletter-cta/newsletter-cta.component');
        const componentRef = this.createDynamicComponent(NewsletterCtaComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'special-offers') {
        const { SpecialOffersComponent } = await import('../special-offers/special-offers.component');
        const componentRef = this.createDynamicComponent(SpecialOffersComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'tabs-terms') {
        const { TabsTermsComponent } = await import('../tabs-terms/tabs-terms.component');
        const componentRef = this.createDynamicComponent(TabsTermsComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'login-form') {
        const { LoginFormComponent } = await import('../login-form/login-form.component');
        const componentRef = this.createDynamicComponent(LoginFormComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'sign-up-or-login') {
        const { SignUpOrLoginComponent } = await import('../sign-up-or-login/sign-up-or-login.component');
        const componentRef = this.createDynamicComponent(SignUpOrLoginComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'gallery-slider') {
        const { GallerySliderComponent } = await import('../gallery-slider/gallery-slider.component');
        const componentRef = this.createDynamicComponent(GallerySliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'product-types-slider') {
        const { ProductTypesSliderComponent } = await import('../product-types-slider/product-types-slider.component');
        const componentRef = this.createDynamicComponent(ProductTypesSliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'quality-etched') {
        const { QualityEtchedComponent } = await import('../quality-etched/quality-etched.component');
        const componentRef = this.createDynamicComponent(QualityEtchedComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'promo-case-study') {
        const { PromoCaseStudyComponent } = await import('../promo-case-study/promo-case-study.component');
        const componentRef = this.createDynamicComponent(PromoCaseStudyComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket') {
        const { BasketComponent } = await import('../basket/basket.component');
        const componentRef = this.createDynamicComponent(BasketComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket-billing') {
        const { BasketBillingComponent } = await import('../basket-billing/basket-billing.component');
        const componentRef = this.createDynamicComponent(BasketBillingComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket-payment') {
        const { BasketPaymentComponent } = await import('../basket-payment/basket-payment.component');
        const componentRef = this.createDynamicComponent(BasketPaymentComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket-delivery-information') {
        const { BasketDeliveryInformationComponent } = await import('../basket-delivery-information/basket-delivery-information.component');
        const componentRef = this.createDynamicComponent(BasketDeliveryInformationComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket-delivery') {
        const { BasketDeliveryComponent } = await import('../basket-delivery/basket-delivery.component');
        const componentRef = this.createDynamicComponent(BasketDeliveryComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket-order-confirmation') {
        const { BasketOrderConfirmationComponent } = await import('../basket-order-confirmation/basket-order-confirmation.component');
        const componentRef = this.createDynamicComponent(BasketOrderConfirmationComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket-order-approval') {
        const { BasketOrderApprovalComponent } = await import('../basket-order-approval/basket-order-approval.component');
        const componentRef = this.createDynamicComponent(BasketOrderApprovalComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'basket-reload') {
        const { BasketReloadComponent } = await import('../basket-reload/basket-reload.component');
        const componentRef = this.createDynamicComponent(BasketReloadComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'signout') {
        const { SignoutComponent } = await import('../signout/signout.component');
        const componentRef = this.createDynamicComponent(SignoutComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'register') {
        const { RegisterComponent } = await import('../register/register.component');
        const componentRef = this.createDynamicComponent(RegisterComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'registration-form') {
        const { RegistrationFormComponent } = await import('../registration-form/registration-form.component');
        const componentRef = this.createDynamicComponent(RegistrationFormComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'registration-form-trade-account') {
        const { RegistrationFormTradeAccountComponent } = await import('../registration-form-trade-account/registration-form-trade-account.component');
        const componentRef = this.createDynamicComponent(RegistrationFormTradeAccountComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'product-range-detail') {
        const { ProductRangeDetailComponent } = await import('../product-range-detail/product-range-detail.component');
        const componentRef = this.createDynamicComponent(ProductRangeDetailComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'page-not-found') {
        const { PageNotFoundComponent } = await import('../page-not-found/page-not-found.component');
        const componentRef = this.createDynamicComponent(PageNotFoundComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'product-group-detail') {
        const { ProductGroupDetailComponent } = await import('../product-group-detail/product-group-detail.component');
        const componentRef = this.createDynamicComponent(ProductGroupDetailComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'faq-detail') {
        const { FaqDetailComponent } = await import('../faq-detail/faq-detail.component');
        const componentRef = this.createDynamicComponent(FaqDetailComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'advice-detail') {
        const { AdviceDetailComponent } = await import('../advice-detail/advice-detail.component');
        const componentRef = this.createDynamicComponent(AdviceDetailComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'blog-categories') {
        const { BlogCategoriesComponent } = await import('../blog-categories/blog-categories.component');
        const componentRef = this.createDynamicComponent(BlogCategoriesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'article') {
        const { ArticleComponent } = await import('../article/article.component');
        const componentRef = this.createDynamicComponent(ArticleComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'blog-category') {
        const { BlogCategoryComponent } = await import('../blog-category/blog-category.component');
        const componentRef = this.createDynamicComponent(BlogCategoryComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'product-details') {
        const { ProductDetailsComponent } = await import('../product-details/product-details.component');
        const componentRef = this.createDynamicComponent(ProductDetailsComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'bespoke-form-page') {
        const { BespokeFormPageComponent } = await import('../bespoke-form-page/bespoke-form-page.component');
        const componentRef = this.createDynamicComponent(BespokeFormPageComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'account-confirmation') {
        const { AccountConfirmationComponent } = await import('../account-confirmation/account-confirmation.component');
        const componentRef = this.createDynamicComponent(AccountConfirmationComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'password-reset') {
        const { PasswordResetComponent } = await import('../password-reset/password-reset.component');
        const componentRef = this.createDynamicComponent(PasswordResetComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'password-reset-confirmation') {
        const { PasswordResetConfirmationComponent } = await import('../password-reset-confirmation/password-reset-confirmation.component');
        const componentRef = this.createDynamicComponent(PasswordResetConfirmationComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'password-set') {
        const { PasswordSetComponent } = await import('../password-set/password-set.component');
        const componentRef = this.createDynamicComponent(PasswordSetComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'password-set-confirmation') {
        const { PasswordSetConfirmationComponent } = await import('../password-set-confirmation/password-set-confirmation.component');
        const componentRef = this.createDynamicComponent(PasswordSetConfirmationComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'bespoke-builder') {
        const { BespokeBuilderComponent } = await import('../bespoke-builder/bespoke-builder.component');
        const componentRef = this.createDynamicComponent(BespokeBuilderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'case-study-single') {
        const { CollectionsEntrySingleComponent } = await import('../collections-entry-single/collections-entry-single.component');
        const componentRef = this.createDynamicComponent(CollectionsEntrySingleComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'brands-slider') {
        const { BrandsSliderComponent } = await import('../brands-slider/brands-slider.component');
        const componentRef = this.createDynamicComponent(BrandsSliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'spaces-slider') {
        const { SpacesSliderComponent } = await import('../spaces-slider/spaces-slider.component');
        const componentRef = this.createDynamicComponent(SpacesSliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'featured-products-slider') {
        const { FeaturedProductsSliderComponent } = await import('../featured-products-slider/featured-products-slider.component');
        const componentRef = this.createDynamicComponent(FeaturedProductsSliderComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'spaces') {
        const { SpacesComponent } = await import('../spaces/spaces.component');
        const componentRef = this.createDynamicComponent(SpacesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'groups') {
        const { ProductGroupsComponent } = await import('../product-groups/product-groups.component');
        const componentRef = this.createDynamicComponent(ProductGroupsComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'jobTiles') {
        const { JobTilesComponent } = await import('../job-tiles/job-tiles.component');
        const componentRef = this.createDynamicComponent(JobTilesComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'upero-video-banner') {
        const { UperoVideoComponent } = await import('../upero-video/upero-video.component');
        const componentRef = this.createDynamicComponent(UperoVideoComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'upero-brands-carousel') {
        const { UperoBrandsCarouselComponent } = await import('../upero-brands-carousel/upero-brands-carousel.component');
        const componentRef = this.createDynamicComponent(UperoBrandsCarouselComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'upero-footer') {
        const { UperoFooterComponent } = await import('../upero-footer/upero-footer.component');
        const componentRef = this.createDynamicComponent(UperoFooterComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }

      if (block.type === 'blog-post-single') {
        const {BlogPostSingleComponent} = await import('../blog-post-single/blog-post-single.component');
        const componentRef = this.createDynamicComponent(BlogPostSingleComponent);
        if (componentRef) {
          componentRef.instance.block = block;
        }
      }
    }
  }
}
