import { Inject, Injectable } from '@angular/core';
import { ApiAuthHttpClient } from '@upero/misc';
import { Store } from '@upero/store';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class MenuService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  all()
  {
    return this.http
      .get(this.env.apiPath + 'cms/menus')
      .subscribe((data: any) =>
      {
        this.store.set('menus', data.data);
      });
  }

  menuByRoute(route)
  {
    const routeToFind = route.split('?')[0];
    return this.http
      .get(this.env.apiPath + 'cms/menugroup/' + routeToFind)
      .subscribe((data: any) =>
      {
        this.store.set('activeMenu', data.data)
      })
  }

  menuGroups()
  {
    return this.http
      .get(this.env.apiPath + 'cms/public/menugroups')
      .subscribe((data: any) =>
      {
        this.store.set('menu', data.data);
      });
  }
}
