<div class="container-fluid p-4">

    <button
        [uperoBrandRouterLink]="['/blog-and-news']"
        class="btn btn-warning me-2">
        All Categories
    </button>

    <button
        [uperoBrandRouterLink]="['/blog-and-news', category.slug]"
        class="btn btn-primary me-2"
        *ngFor="let category of categories">{{category.name}}</button>
</div>

