<div class="d-flex flex-column h-100">
    <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center bg-cover-image">
        <div class="container my-4">
            <div class="row g-0">
                <div class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 shadow-lg">
                    <div class="d-flex flex-column bg-primary py-4">
                        <div class="px-4 mb-3">
                            <a routerLink=".." >
                                <svg-icon
                                    src="assets/images/icon-arrow-left.svg"
                                    [class]="'hover'"
                                    [svgClass]="'icon--button'"></svg-icon>
                            </a>
                        </div>
                        <div class="d-flex flex-column my-auto">
                            <div class="d-flex flex-column justify-content-center text-center">
                                <svg-icon src="assets/images/ys-logo-text-reversed.svg"
                                          [svgClass]="'logo--text'"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center bg-white">
                        <div class="w-100 p-4 p-lg-5">
                            <div class="ff-spectral fs-4 text-center">
                                Forgotten Password
                            </div>
                            <hr>
                            <div class="text-center mb-4">
                                Instructions on how to reset your password have been sent to your registered email
                                address: {{confirmationEmail}}
                            </div>
                            <div class="d-flex justify-content-center">
                                <button
                                    type="button"
                                    (click)="signIn()"
                                    class="btn btn-wide btn-primary">
                                    Sign in
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
