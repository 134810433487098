import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@upero/store';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType, OrderGuestUserData } from '@upero/misc';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  ) {}

  getOrder(id) {
    return this.http.get(this.env.apiPath + 'orders/' + id).pipe(map((data: any) => data));
  }

  getOrderLineStatuses() {
    return this.http.get(this.env.apiPath + 'orders/line/statuses').pipe(map((data: any) => data));
  }

  update(order) {
    return this.http.put(this.env.apiPath + 'orders/update', { order }).pipe(map((data: any) => data));
  }

  updateStatus(order) {
    const user = this.store.selectForLocal('user');
    return this.http.put(this.env.apiPath + 'orders/update/status', {
      order,
      user,
    }).pipe(map((data: any) => data));
  }

  search(params) {
    return this.http.post(this.env.apiPath + 'orders/search', { params }).pipe(map((data: any) => data));
  }

  searchOrderDetails(params) {
    return this.http.post(this.env.apiPath + 'orders/search/orderdetails', { params }).pipe(map((data: any) => data));
  }

  getCancelReasons(): Observable<any> {
    return this.http.get(this.env.apiPath + 'cancellation-reason/public/2').pipe(map((data: any) => data));
  }

  requestUpdate(orderId) {
    return this.http.get(this.env.apiPath + `?id=${orderId}`).pipe(map((data: any) => data));
  }

  cancelOrder(orderIds) {
    return this.http.get(this.env.apiPath + `?id=${orderIds.orderId}&itemId=${orderIds.orderLineId}`)
        .pipe(map((data: any) => data));
  }

  updateOrderGuestUser(params: OrderGuestUserData) {
    return this.http.put(this.env.apiPath + 'orders/order/guest/update', params);
  }
}
