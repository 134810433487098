import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  get(id)
  {
    return this.http
      .get(this.env.apiPath + 'shipments/' + id)
      .pipe(map((data: any) => data));
  }

  getByOrderId(orderId)
  {
    return this.http
      .get(this.env.apiPath + 'shipments/order/' + orderId)
      .pipe(map((data: any) => data));
  }

  getStatuses()
  {
    return this.http
      .get(this.env.apiPath + 'shipments/statuses')
      .pipe(map((data: any) => data));
  }

  search(params)
  {
    return this.http
      .post(this.env.apiPath + 'shipments/search', { params })
      .pipe(map((data: any) => data));
  }

  shipmentsBoard(params)
  {
    return this.http
      .post(this.env.apiPath + 'shipments/board', { params })
      .pipe(map((data: any) =>
        {
          this.store.set('shipmentsBoard', data.data);
          return data;
        }
      ));
  }

  assignVehicle(vehicleId, shipmentId)
  {
    const dataToSend = {
      vehicleId,
      shipmentId,
    };

    return this.http
      .post(this.env.apiPath + 'shipments/vehicle/assign', dataToSend)
      .pipe(map((data: any) => data));
  }


  setStatus(status, shipmentId)
  {
    const dataToSend = {
      status,
      shipmentId,
    };

    return this.http
      .put(this.env.apiPath + 'shipments/status', dataToSend)
      .pipe(map((data: any) => data));
  }

  setShippingDate(shipment)
  {
    return this.http
      .put(this.env.apiPath + 'shipments/deliveryDate', { shipment })
      .pipe(map((data: any) => data));
  }

  setCollectionDate(shipment)
  {
    return this.http
      .put(this.env.apiPath + 'shipments/collectionDate', { shipment })
      .pipe(map((data: any) => data));
  }
}
