import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@upero/store';

@Pipe({ name: 'modBrandUrl' }) // log variable into the console
export class BrandUrlPipe implements PipeTransform {
  constructor(public store: Store) {}

  transform(commands: any[], forceFromRoot: boolean = false): any[] {
    const currentBrand = this.store.selectForLocal('currentBrand');
    if (currentBrand) {
      const relative = !(commands.length > 0 && commands[0] && commands[0].charAt(0) === '/');
      // cleaning only slashes
      commands = commands
        .join('/')
        .split('/')
        .filter((item) => item && item != '/');
      // if only one item there is posibility for ['path//path/auth']
      if (commands.length === 1 && commands[0].split('/').length > 0) {
        // work around for ['path//path/ath']
        commands = commands.filter((item) => item);
      }
      // hardcoded that everything under accound won't get branding
      if (
        (commands.length > 0 && commands[0] !== currentBrand.slug && commands[0] !== 'account') ||
        commands.length === 0
      ) {
        return [(!relative || forceFromRoot ? '/' : '') + currentBrand.slug].concat(commands);
      }
      return [!relative || forceFromRoot ? '/' : ''].concat(commands);
    }

    return commands;
  }
}
