import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';


@Injectable({
  providedIn: 'root',
})
export class CollectionsService {

  constructor(
    private authHttpClient: AuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  ) { }

  getCollectionEntriesByType(type: string, siteId: number): Observable<any> {
    return this.authHttpClient.get(this.env.apiPath + `cms-sites/contents/?type_equals=${type}&siteId=${siteId}`)
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getCollectionEntriesByTypeSlug(type: string, slug: string, siteId: number): Observable<{}> {
    return this.authHttpClient.get(this.env.apiPath + `cms-sites/contents/?type_like=${type}&slug_like${slug}&siteId=${siteId}`)
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getCollectionEntriesByTitle(title: string, siteId: number): Observable<{}> {
    return this.authHttpClient.get(this.env.apiPath + `cms-sites/contents/?title_like=${title}&siteId=${siteId}`)
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getCollectionEntriesById(id: number, siteId: number): Observable<{}> {
    return this.authHttpClient.get(this.env.apiPath + `cms-sites/contents/?id_equals=${id}&siteId=${siteId}`)
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getCollectionEntriesByTypePaged(type: string, siteId: number, page: number, limit: number): Observable<{}> {
    return this.authHttpClient.get(this.env.apiPath + `cms-sites/contents/?type_like=${type}&siteId=${siteId}&page=${page}&limit=${limit}`)
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getPageBySlug(slug: string): Observable<{}> {
    return this.authHttpClient.get(this.env.apiPath + `cms-sites/contents/?slug_like=${slug}`)
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getPageBySlugType(slug: string, type: string): Observable<{}> {
    return this.authHttpClient.get(this.env.apiPath + `cms-sites/contents/?slug_like=${slug}&type_like=${type}`)
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }
}
