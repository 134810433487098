<div class="row g-0">
    <div class="col-12 col-sm-6 offset-sm-3 text-center">
        <p class="main-heading mt-5 mb-60">Confirm sign-out</p>

        <p class="sub-heading mb-50">Are you sure you want to sign-out?</p>

        <button type="button" (click)="signOut()" class="btn btn-primary mb-60">
            Yes, sign-out
        </button>

    </div>
</div>
