<section id="header" class="header sticky-top">
    <div class="container-mobile-menu shadow-sm">
        <div class="row">
            <div class="col-3 col-md-4">
                <div class="w-100 d-flex">
                    <a class="header-sub-item-icon" (click)="toggleMenu()">
                        <svg-icon src="assets/images/icon-menu.svg"></svg-icon>
                    </a>
                </div>
            </div>
            <div class="col-6 col-md-4 mx-auto d-flex flex-column align-items-center">
                <a class="d-flex align-items-center" [uperoBrandRouterLink]="['/']">
                  <svg-icon [svgClass]="'header__logo-img'"
                            src="assets/images/upero.svg"></svg-icon>
                </a>
            </div>
            <div class="col-3 col-md-4">
                <div class="w-100 d-flex justify-content-end">
                    <a uperoDropdown class="header-sub-item-icon dropdown" href="javascript:void(0)">
                        <svg-icon src="assets/images/icon-bell.svg"></svg-icon>
                        <div *ngIf="(notifications$ | async).unread > 0"
                             class="badge-count">{{(notifications$ | async).unread}}</div>
                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-notifications shadow-sm">
                            <upero-notifications-list></upero-notifications-list>
                        </div>
                    </a>
                    <!-- <a class="header-sub-item-icon" [uperoBrandRouterLink]="['/account', 'mail']">
                        <svg-icon src="assets/images/icon-chat.svg"></svg-icon>
                        <div class="badge-count" *ngIf="(chatStore$ | async).unread">{{(chatStore$ | async).unread}}</div>
                    </a> -->
                    <!-- <a class="header-sub-item-icon me-0">
                        <svg-icon src="assets/images/icon-user.svg"></svg-icon>
                    </a> -->

                    <a (click)="toggleUserMenu()" class="header-sub-item-icon me-0">
                        <svg-icon src="assets/images/icon-user.svg"></svg-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
