import { Injectable } from '@angular/core';
import { Store } from '@upero/store';

declare var ga;
declare var dataLayer;

declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GaService
{

  constructor(
    private store: Store,
  )
  {
  }

  addToBasket(item)
  {
    let productName = item.product.name;
    let unitPrice = item.unitPrice;
    if (item.sample) {
      unitPrice = 0;
      productName = `${item.product.name} - sample`;
    }
    let rangeName = '';
    if (item.product.range) {
      rangeName = item.product.range.name;
    }


    gtag('event', 'add_to_cart', {
      'items': [
        {
          'name': productName,
          'id': item.skuId,
          'price': unitPrice,
          'list_name': rangeName,
          'brand': 'Upero',
          'category': item.product.typeId,
          'variant': item.product.typeId,
          'quantity': item.qty,
          'coupon': ''
        }
      ]
    });
  }


  removeFromBasket(item)
  {

    let rangeName = '';
    if (item.product.range) {
      rangeName = item.product.range.name;
    }

    let productName = item.product.name;
    let unitPrice = item.unitPrice;
    if (item.sample) {
      unitPrice = 0;
      productName = `${item.product.name} - sample`;
    }
    gtag('event', 'remove_from_cart', {
      'items': [
        {
          'name': productName,
          'id': item.skuId,
          'price': unitPrice,
          'list_name': rangeName,
          'brand': 'Upero',
          'category': item.product.typeId,
          'variant': item.product.typeId,
          'quantity': item.qty,
          'coupon': ''
        }
      ]
    });
  }

  checkout()
  {
    gtag('event', 'begin_checkout', {
      "items": this.buildProductsArray(),
      "coupon": ""
    });
  }

  checkoutComplete()
  {
    const basket = this.store.selectForLocal('basket');
    const basketId = localStorage.getItem('ysBasketId');

    const totalRevenue = +basket.values.gross;

    gtag('event', 'purchase', {
      "transaction_id": basketId,
      "affiliation": "yorkstonesupplies.co.uk",
      "value": totalRevenue,
      "currency": "GBP",
      "tax": +basket.values.vat.toFixed(2) + +basket.values.deliveryVat.toFixed(2),
      "shipping": basket.values.delivery,
      "items": this.buildProductsArray()
    });
  }


  buildProductsArray()
  {
    const basket = this.store.selectForLocal('basket');
    const products = new Array<any>();

    for (let i = 0; i < basket.items.length; i++) {
      let rangeName = '';
      if (basket.items[i].product.range) {
        rangeName = basket.items[i].product.range.name;
      }

      const product = {
        'name': basket.items[i].product.name,
        'id': basket.items[i].skuId,
        'price': basket.items[i].unitPrice,
        'brand': 'Upero',
        'list_name': rangeName,
        'category': basket.items[i].product.typeId,
        'variant': basket.items[i].product.typeId,
        'quantity': basket.items[i].qty,
        'coupon': ''
      };
      products.push(product);

    }

    // and samples
    for (let i = 0; i < basket.samples.length; i++) {
      let rangeName = '';

      if (basket.samples[i].product.range) {
        rangeName = basket.samples[i].product.range.name;
      }

      const product = {
        'name': `${basket.samples[i].product.name} - sample`,
        'id': basket.samples[i].skuId,
        'price': 0,
        'brand': 'Upero',
        'list_name': rangeName,
        'category': basket.samples[i].product.typeId,
        'variant': basket.samples[i].product.typeId,
        'quantity': basket.samples[i].qty,
        'coupon': ''
      };
      products.push(product);

    }

    return products;
  }

}
