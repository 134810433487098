<ng-container #uperoProxyContainer>
    <!--<upero-product-details></upero-product-details>-->
</ng-container>

<ng-template #uperoAddedToBasket>
    <!--<upero-added-to-basket *ngIf="(overlays$ | async).addedToBasket"></upero-added-to-basket>-->
</ng-template>
<ng-template #uperoPriceBandModal>
    <!--<upero-price-band-modal *ngIf="(overlays$ | async).priceBandModal"></upero-price-band-modal>-->
</ng-template>
<ng-template #uperoBespokeFormModal>
    <!--<upero-bespoke-form-modal *ngIf="(overlays$ | async).bespokeForm"></upero-bespoke-form-modal>-->
</ng-template>
<ng-template #uperoColourVariationsModal>
    <!--<upero-colour-variations-modal *ngIf="(overlays$ | async).colourVariationsModal"></upero-colour-variations-modal>-->
</ng-template>
<ng-template #uperoCompetitionEntry>
    <!--<upero-competition-entry *ngIf="(overlays$ | async).enterCompetition"></upero-competition-entry>-->
</ng-template>

<upero-max-qty-modal *ngIf="(overlays$ | async).maxQtyWarning"></upero-max-qty-modal>
