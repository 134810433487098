import {Directive, HostBinding, Input} from '@angular/core';
import {Store} from "@upero/store";

@Directive({
  selector: '[uperoBtnColorSetter]'
})
export class BtnColorSetterDirective {
  @Input() setBtn? = true;
  currentBrand = this.store.selectForLocal('currentBrand');

  @HostBinding('class')
  get elementClass(): string {
    if (this.setBtn) {
      return this.currentBrand ? 'btn-' + this.currentBrand.settings['primary'] : 'bg-primary';
    } else {
      return '';
    }
  }

  constructor(
    private store: Store,
  ) { }
}
