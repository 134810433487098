import {Component, HostBinding, OnInit} from '@angular/core';
import {BaseBlockComponent, BlogEntrySingleFields, CollectionsEntrySingleFields} from "@upero/misc";
import {Store} from "@upero/store";
import {ActivatedRoute} from "@angular/router";
import {CollectionsService} from "@upero/collections";
import {PagesService} from "@upero/pages";
import {Title} from "@angular/platform-browser";
import {BreadcrumbsService} from "@upero/services";
import {defer, first, iif, map, mergeMap, of} from "rxjs";

@Component({
  selector: 'upero-blog-post-single',
  templateUrl: './blog-post-single.component.html',
  styleUrls: ['./blog-post-single.component.scss'],
})
export class BlogPostSingleComponent extends BaseBlockComponent<BlogEntrySingleFields> implements OnInit {
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  deviceType$ = this.store.select<string>('deviceType');
  entry: any;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private collectionsService: CollectionsService,
    private pagesService: PagesService,
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.collectionsService
      .getPageBySlugType(this.route.snapshot.params.slug, this.block.fields.collectionType)
      .pipe(
        mergeMap((data: any) =>
          iif(
            () => data.length > 0,
            of(data),
            defer(() => this.pagesService.getPageBySlug('404')),
          )
        ),
        map(data => data[0]),
        first()
      )
      .subscribe(entry => {
        console.log('test');
        this.entry = entry;
        this.titleService.setTitle(entry.title);
        this.breadcrumbsService.changeBreadcrumb([
          {text: entry.title, route: ['/case-studies']},
        ]);
      });
  }
}
