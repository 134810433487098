<div class="form--min-height bg-dark">
    <div class="container h-100 py-3 py-md-5">
        <div class="row g-0 shadow-lg mb-4 mb-lg-0">
            <div class="col-12 col-lg-6 bg-white">
                <div class="p-3 py-lg-4 pt-lg-4 py-xl-5 ps-xl-5 pe-0">
                    <div class="fs-1 fw-light mb-3">
                        Create your account
                    </div>
                    <div class="fw-light mb-3">
                        Select the type of account you want to create
                    </div>
                    <div class="d-flex flex-column" [ngClass]="{'disabled': user}">
                        <div (click)="setAccountType('customer')"
                             [ngClass]="accountType === 'customer' ? 'text-white' : 'bg-white'"
                             uperoBgSetter
                             [setBg]="accountType === 'customer'"
                             class="hover p-3">
                            <div class="ms-4 fs-6 text-uppercase">
                                Customer Account
                            </div>
                        </div>
                        <div (click)="setAccountType('trade')"
                             [ngClass]="accountType === 'trade' ? 'text-white ' : 'bg-white'"
                             uperoBgSetter
                             [setBg]="accountType === 'trade'"
                             class="hover p-3">
                            <div class="ms-4 fs-6 text-uppercase">
                                Trade Account
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6" uperoBgSetter>
                <div class="d-flex flex-column p-3 p-lg-4 p-xl-5">
                    <div class="ff-spectral text-white text-center fs-5 mb-4">
                        <ng-container *ngIf="accountType === 'customer'">
                            Open a free loyalty account today and start to enjoy the many perks!
                        </ng-container>
                        <ng-container *ngIf="accountType === 'trade'">
                            Open a free trade account today to access all kinds of exclusive benefits & discounts.
                        </ng-container>
                    </div>
                    <div *ngIf="accountType === 'customer'" class="d-flex justify-content-between">
                        <div class="d-flex flex-column w-33">
                            <div class="circle bg-light mx-auto mb-3">
                                <img class="img-bg p-4" src="assets/images/register/samples.png">
                            </div>
                            <div class="ff-spectral text-white text-center">Free samples</div>
                        </div>
                        <div class="d-flex flex-column w-33">
                            <div class="circle bg-light mx-auto mb-3">
                                <img class="img-bg p-3" src="assets/images/register/delivery.png">
                            </div>
                            <div class="ff-spectral text-white text-center">Free delivery*</div>
                        </div>
                        <div class="d-flex flex-column w-33">
                            <div class="circle bg-light mx-auto mb-3">
                                <img class="img-bg p-3" src="assets/images/register/coins.png">
                            </div>
                            <div class="ff-spectral text-white text-center">Earn rewards & cashback</div>
                        </div>
                    </div>
                    <div *ngIf="accountType === 'trade'" class="d-flex justify-content-between">
                        <div class="d-flex flex-column w-33">
                            <div class="circle bg-light mx-auto mb-3">
                                <img class="img-bg p-3" src="assets/images/register/discount.png">
                            </div>
                            <div class="ff-spectral text-white text-center">Discount</div>
                        </div>
                        <div class="d-flex flex-column w-33">
                            <div class="circle bg-light mx-auto mb-3">
                                <img class="img-bg p-3" src="assets/images/register/delivery.png">
                            </div>
                            <div class="ff-spectral text-white text-center">Free delivery</div>
                        </div>
                        <div class="d-flex flex-column w-33">
                            <div class="circle bg-light mx-auto mb-3">
                                <img class="img-bg p-4" src="assets/images/register/gift.png">
                            </div>
                            <div class="ff-spectral text-white text-center">Rewards</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 card--min-height">
                <div class="bg-light h-100">
                    <div class="d-flex flex-column justify-content-center align-items-center bg-light h-100 p-3 p-lg-4 p-xl-5">
                        <div class="card--max-width w-100">
                            <div class="fs-4 ff-spectral fw-bold text-uppercase mb-5">
                               <ng-container *ngIf="accountType === 'customer' && !user">
                                   Customer Account
                               </ng-container>
                               <ng-container *ngIf="accountType === 'trade'">
                                   Trade Account
                               </ng-container>
                            </div>
                            <ng-container *ngIf="accountType === 'customer'" #uperoProxyContainer>
                                <!--<upero-registration-form
                                        [button]="block.fields.submitButtonCustomer"
                                        [userFromStorage]="user"
                                        [referralCode]="referralCode"
                                        (registrationComplete)="registered($event)"></upero-registration-form>-->
                            </ng-container>
                            <ng-container *ngIf="accountType === 'trade'" #uperoProxyContainer2>
                                <!--<upero-registration-form-trade-account [button]="block.fields.submitButtonTrade"></upero-registration-form-trade-account>-->
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
