import { Component, HostBinding,  OnInit } from '@angular/core';
import { BaseBlockComponent, BlogCategoriesFields } from '@upero/misc';
import { BlogService } from '@upero/services';

@Component({
  selector: 'upero-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.scss']
})
export class BlogCategoriesComponent extends BaseBlockComponent<BlogCategoriesFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  categories = [];
  category;

  articles = [];

  articleParams = {
    category: '',
    searchText: '',
    limit: 12,
    limits: [5, 10, 25, 50, 75, 100],
    page: 0,
    pages: 0,
    pageArray: [],
    totalRecords: 0,
    sort: 'blog_article.createdAt DESC',
    sorts: [],
  };

  constructor(
    private blogService: BlogService,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.getCategories();

    this.getArticlesByCategory();
  }

  getCategories()
  {
    this.blogService.getCategories().subscribe(data =>
    {
      this.categories = data;
    });
  }

  getArticlesByCategory()
  {
    this.blogService.getArticlesByCategory(this.articleParams).subscribe(data => this.articles = data.articles);
  }
}
