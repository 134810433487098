import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@upero/store';
import { OverlayService } from '@upero/services';

@Component({
  selector: 'upero-company-menu',
  templateUrl: './company-menu.component.html',
  styleUrls: ['./company-menu.component.scss']
})
export class CompanyMenuComponent
{
  @Input() user;
  @Output() navigationChange = new EventEmitter();

  enableQuoteBackend = this.store.selectForLocal('enableQuoteBackend');
  step = 'dashboard';
  overlays$ = this.store.select<any>('overlays');
  overlayData;
  user$ = this.store.select<any>('user');

  constructor(
    private store: Store,
    private overlayService: OverlayService
  )
  {
  }

  setStep(v)
  {
    this.step = v;
    this.navigationChange.emit(v);
  }

  editUser()
  {
    this.store.set('userToEdit', this.store.selectForLocal('user'));
    this.setStep('user-edit');
  }

  addUser()
  {
    const overlayData = {
      user: undefined,
      accountType: 'trade',
      company: this.user.company,
    };

    this.store.set('addUser', true);
    this.store.set('overlayData', overlayData);
    this.overlayService.toggle('userEdit');
  }

  requestCreate()
  {
    this.overlayData = {
      id: '',
      companyId: '',
      createdBy: '',
      type: 0,
      message: '',
    };

    this.store.set('overlayData', this.overlayData);
    this.overlayService.toggle('requestCreate');
  }
}
