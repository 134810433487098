import { Component, HostBinding, OnInit } from '@angular/core';
import { BaseBlockComponent, PasswordSetConfirmationFields } from '@upero/misc';
import { Store } from '@upero/store';
import {AppInitService} from "@upero/routing";
import {Router} from "@angular/router";

@Component({
  selector: 'upero-password-set-confirmation',
  templateUrl: './password-set-confirmation.component.html',
  styleUrls: ['./password-set-confirmation.component.scss']
})
export class PasswordSetConfirmationComponent extends BaseBlockComponent<PasswordSetConfirmationFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  confirmationEmail = '';

  constructor(
    private store: Store,
    private router: Router,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.confirmationEmail = localStorage.getItem('confirmationEmail');
    this.store.set('navigationHistory', []);
  }

  backToLogin()
  {
    this.router.navigate(AppInitService.doBrandModUrl(['/login']));
  }

}
