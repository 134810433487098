import { InjectionToken, inject } from '@angular/core';
import { WINDOW } from './window-token';

export function getDataLayerFn(window: Window)
{
  return (window)
    // @ts-ignore
    ? window['dataLayer'] = window['dataLayer'] || []
    : null;
}

export const DATA_LAYER = new InjectionToken('data-layer', {
  providedIn: 'root',
  factory: () => getDataLayerFn(inject(WINDOW))
});
