import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upero-cta-button-text',
  templateUrl: './cta-button-text.component.html',
  styleUrls: ['./cta-button-text.component.scss']
})
export class CtaButtonTextComponent implements OnInit {
  @Input() block: any;
  @HostBinding('attr.class') get classes(): string {
    return 'col-' + this.block.cols;
  };

  @HostBinding('style') get styles() {
    return { height: this.block.rows * 100 + 'px' };
  };

  constructor() { }

  ngOnInit(): void {
  }

}
