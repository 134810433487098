import { UserData } from './user.data';

export enum DiscountType {
  Pound = 2,
  Percent = 1,
}

export type SkuPriceTotals = {
  netPreDiscount: number; // 0,
  discount: number; // 0,
  net: number; // 0
  vat: number; // 0
  gross: number; // 0
  qty: number; // 0
  rewardDiscount: number; // 0,
  productDiscount: number; // 0,
  voucherDiscount: number; // 0
  preDiscount: {
    net: number; // 0,
    vat: number; // 0,
    gross: number; // 0,
  };
};

export type SkuPrice = {
  totals: SkuPriceTotals;
  basePrice: number;
  net: number; // price after discount is applied
  vat: number;
  gross: number;
  preDiscount: {
    net: number;
    vat: number;
    gross: number;
  };
  discount: DiscountValue;
  discountToUse: 'none' | 'reward' | 'product';
};

export type DiscountValue = {
  preDiscountValue: number;
  reward: {
    percentage: number;
    value: number;
  };
  product: {
    percentage: number;
    value: number;
  };
};

export type BasketAddress = {
  // it might be the same as address but for now on it's own
  id: string;
  userId: string;
  add1: string;
  add2: string;
  add3: string;
  town: string;
  county: string;
  country: string;
  postcode: string;
  isBilling: boolean;
  defaultDeliveryAddress: boolean;
};

export const defaultBasketAddress: BasketAddress = {
  id: '',
  userId: '',
  add1: '',
  add2: '',
  add3: '',
  town: '',
  county: '',
  country: '',
  postcode: '',
  isBilling: false,
  defaultDeliveryAddress: false,
} as BasketAddress;

export type BasketItem = {
  pricing: SkuPrice;
  minQty: string;
  qty: number;
  skuId: string;
  sellUnit:
    | {
    name: string;
    id: string;
  }
    | undefined;
  product: {
    id: string; // '',
    typeId: string;
    name: string; // '',
    slug: string; // '',
    productCode: string; // ''
    image: string; // ''
    leadtimes: string;
    masonry: any;

    isBespoke?: boolean;
    supplier?: {
      id: string;
    };
    rangeId?: string;
    finishId?: any;
    range?: {
      id: string;
      tileText: string;
      description: string;
      bannerText: string;
    };
    type?: {
      sellUnits: Array<{ name: string, id: string }>;
      name: string;
      id: string;
    };
  };
  specificDateRequested: any;
  quantityMetres: number;
  sample: boolean;
  piecesRequired: number;
  singlePieceSize: number;
  unitPrice: number;
  unitPriceDiscount: number;
  unitPriceDiscountPerc: number;
  unitPriceGross: number;
  unitVatPrice: number;
  totalPrice: number;
  totalDiscount: number;
  totalPricePreDiscount: number;
  productDetailId: number; // 0,
  pricingMethod: {
    id: any;
  };
  weight: number;
  leadtime: number;
  separateShipment: boolean;
  potentialQty: number;
};

export type BasketValues = {
  discount: number;
  gross: number;
  net: number;
  vat: number;
  delivery: number;
  deliveryVat: number;
  netPreDiscount: number;
  productDiscount: number;
  rewardDiscount: number;
  voucherDiscount: number;
  adminDiscount: number;
  voucher?: number;
};

export const defaultSkuPriceTotals: SkuPriceTotals = {
  netPreDiscount: 0,
  discount: 0,
  net: 0,
  vat: 0,
  gross: 0,
  qty: 0,
  rewardDiscount: 0,
  productDiscount: 0,
  voucherDiscount: 0,
  preDiscount: {
    net: 0,
    vat: 0,
    gross: 0,
  },
} as SkuPriceTotals;

export const defaultDiscountValue: DiscountValue = {
  preDiscountValue: 0,
  reward: {
    percentage: 0,
    value: 0,
  },
  product: {
    percentage: 0,
    value: 0,
  },
};

export const defaultSkuPrice: SkuPrice = {
  totals: { ...defaultSkuPriceTotals },
  basePrice: 0,
  net: 0, // price after discount is applied
  vat: 0,
  gross: 0,
  preDiscount: {
    net: 0,
    vat: 0,
    gross: 0,
  },
  discount: { ...defaultDiscountValue },
  discountToUse: 'none',
};

export const defaultBasketItem: BasketItem = {
  product: { id: '', productCode: '', name: '', slug: '', image: '' },
  sellUnit: undefined,
  quantityMetres: 0,
  skuId: '',
  sample: false,
  qty: 0,
  piecesRequired: 0,
  singlePieceSize: 0,
  totalPrice: 0,
  unitPrice: 0,
  unitPriceDiscount: 0,
  unitPriceGross: 0,
  totalDiscount: 0,
  totalPricePreDiscount: 0,
  pricing: { ...defaultSkuPrice },
  productDetailId: 0,
  weight: 0,
  leadtime: 0,
} as BasketItem;

export type Basket = {
  source: 'public' | 'admin' | 'unknown'; // 'public'
  type: 'order' | 'quote'; // 'public'
  items: Array<BasketItem>; // []
  itemsOriginal?: Array<BasketItem>;
  samples: Array<BasketItem>; // []
  potentialQty: number; // 0
  rewardsDiscountPercentage: number; // 0
  voucher: {
    code: string;
    discountType: DiscountType;
    discountValue: number;
    name: string;
    isExpired: string;

    allowOtherDiscounts?: number,
    createdAt?: string
    createdBy?: number
    deleted?: number,
    endDate?: string
    expiryDate?: string
    id?: number
    startDate?: string,
    status?: number
  } | undefined;
  voucherCodeEntered: string; // ''
  deliveryInstructions: string; // '';
  deliveryPostcode: string; // '';
  differentDeliveryAddress: boolean; // false;
  sampleOnlys: boolean; // false;
  token: string | undefined; // undefined;
  customer?: UserData & {
    exists: boolean;
    [key: string]: any;
  }; // = undefined;
  deliveryAddress: BasketAddress | undefined; // = undefined;
  billingAddress: BasketAddress | undefined; // undefined;
  createdBy: UserData | undefined; // undefined;
  sampleOnly: boolean; // false;
  guest: boolean; // true;
  intent: any;
  paymentMethod: 'CC' | 'BACS' | 'XE' | 'PP';
  shipments: any[];
  discount: { type: 'pound' | 'perc'; value: number }; // { type: 'pound', value: 0,  };
  restrictions: {
    slope: boolean;
    gravel: boolean;
    boards: boolean;
    offload: boolean;
  }; // { slope: false, gravel: false, boards: false, offload: false, }
  values: BasketValues; // = { discount: 0, gross: 0, net: 0, vat: 0, voucherDiscount: 0, delivery: 0, deliveryVat: 0, };
  payment: {
    paymentMethod: any;
  };
  xeroQuoteId: string;
};

export const defaultBasketValues: BasketValues = {
  discount: 0,
  gross: 0,
  net: 0,
  vat: 0,
  voucherDiscount: 0,
  delivery: 0,
  deliveryVat: 0,
  netPreDiscount: 0
} as BasketValues;

export const CalculateDiscountPrice = (pricing: SkuPrice, qty): SkuPrice => {
  pricing.net = +pricing.preDiscount.net;
  if (pricing.discount.product && pricing.discount.product.percentage > 0) {
    pricing.discountToUse = 'product';
    pricing.net = +pricing.preDiscount.net - +pricing.discount.product.value;
  }
  if (pricing.discount.reward && +pricing.discount.reward.percentage > +pricing.discount.product.percentage) {
    pricing.discountToUse = 'reward';
    pricing.net = +pricing.preDiscount.net - +pricing.discount.reward.value;
  }

  pricing.vat = +((+pricing.net / 100) * 20);
  pricing.gross = +pricing.net + +pricing.vat;

  pricing.totals = CalculatePricingTotals(pricing, qty);

  return pricing;
};

export const CalculatePricingTotals = (pricing, qty) => {
  pricing.totals.netPreDiscount = +pricing.basePrice * qty;
  pricing.totals.productDiscount = 0;
  pricing.totals.rewardDiscount = 0;

  if (pricing.discountToUse !== 'none') {
    if (pricing.discountToUse === 'product') {
      pricing.totals.productDiscount = +pricing.discount.product.value * pricing.totals.qty;
    }
    if (pricing.discountToUse === 'reward') {
      pricing.totals.rewardDiscount = +pricing.discount.reward.value * pricing.totals.qty;
    }
    pricing.totals.discount = +pricing.discount[pricing.discountToUse].value * qty;
  }

  pricing.totals.net = pricing.net * qty;
  pricing.totals.vat = +((+pricing.totals.net / 100) * 20);
  pricing.totals.gross = +pricing.totals.net + +pricing.totals.vat;

  return pricing.totals;
};
