import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { CmsService, ProductService } from "@upero/services";
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from "@angular/router";
import { Store } from "@upero/store";
import {filter, takeUntil, tap} from "rxjs/operators";
import { BaseBlockComponent, ProductSpacesFields } from "@upero/misc";

@Component({
  selector: 'upero-product-spaces',
  templateUrl: './product-spaces.component.html',
  styleUrls: ['./product-spaces.component.scss'],
})
export class ProductSpacesComponent extends BaseBlockComponent<ProductSpacesFields> implements OnInit, OnDestroy
{
  currentBrand = this.store.selectForLocal('currentBrand');
  productGroups = [];
  buttonClass: string;
  public destroyed = new Subject();

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private store: Store,
    private router: Router,
    private cmsService: CmsService
  )
  {
    super();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
  }

  ngOnInit(): void
  {
    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.getProductGroups();
      this.sendPageIntoData();
    });

    this.getProductGroups();
    this.sendPageIntoData();

    const btnStyle = this.block.fields.productButton['style'] !== 'fill' ? this.block.fields.productButton['style'] + '-' : '';

    if (this.currentBrand) {
      this.buttonClass = 'btn-' + btnStyle + this.currentBrand.settings.colors[this.block.fields.productButton['intent']];
    } else {
      this.buttonClass = 'btn-' + btnStyle + this.block.fields.productButton['intent'];
    }
  }

  getProductGroups()
  {
    this.productService
      .getProductBySpace(this.route.snapshot.params.slug)
      .subscribe(data => this.productGroups = data.data);
  }

  sendPageIntoData() {
    const currentSpace = this.currentBrand.spaces.find(item => item.slug === this.route.snapshot.params.slug);
    if (currentSpace) {
      this.cmsService.broadCastBlockData.next({
        target: 'page-intro',
        data: {
          backgroundImgSrc: currentSpace.bannerUrl,
          title: currentSpace.name,
          text: currentSpace.bannerText
        }
      });
    }
  }
}
