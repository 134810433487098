<div class="ps-4 py-5" [ngClass]="block['fields']['backgroundColor']">
  <div class="keen-slider" #sliderRef style="min-height: 500px">
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
    <div class="keen-slider__slide">
      <div class="card">
        <div class="card-img mb-2">
          <img src="https://placehold.jp/400x400.png" />
        </div>
        <div class="fs-5 text-white text-center mb-2">
          Sub-Brand Name
        </div>
        <div class="fs-6 text-white text-center w-75 mx-auto mb-4">
          Insert description over two lines here
        </div>
        <div class="w-100 d-flex justify-content-center">
          <button class="btn btn-outline-primary">
            Find out more
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

