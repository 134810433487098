<div
  *ngIf="showConsentDialog"
  [@fadeIn]
  class="cookie-consent border border-bottom-0 border-dark bg-light rounded-top d-flex flex-column flex-lg-row justify-content-between align-items-center mx-auto p-3 p-xl-4 shadow">
  <div class="text-center text-md-start small mb-3 mb-lg-0">
    Our site uses cookies to give you the best experience.
    <br />
    <a
      (click)="toggleConsentManageModal()"
      href="javascript:void(0)"
      class="text-decoration-underline"
      >Manage your cookies here</a
    >
    or continue shopping if you are happy.
  </div>
  <upero-button
    [type]="'function'"
    (clickCallback)="accept()"
    [text]="'Continue'"
    [buttonClass]="'primary'"></upero-button>
</div>

<div
  *ngIf="(overlays$ | async).cookieConsentManageModal"
  class="modal"
  tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Privacy Settings</h5>
        <button
          (click)="toggleConsentManageModal()"
          type="button"
          class="btn-close"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>
          This tool helps you to select and deactivate various tags / trackers / analysis tools used on our website. For
          more information please visit our
          <a
            [uperoBrandRouterLink]="'/privacy-policy'"
            class="text-decoration-underline"
            >privacy policy</a
          >.
        </p>
        <div class="form-check form-switch mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            disabled />
          <label class="form-check-label">Technically required</label>
        </div>
        <div
          (click)="toggleGoogleAnalytics()"
          class="form-check form-switch mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            [checked]="options.googleAnalytics" />
          <label class="form-check-label">Google Analytics</label>
        </div>
        <div
          (click)="toggleFacebookPixel()"
          class="form-check form-switch mb-3">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            [checked]="options.facebookPixel" />
          <label class="form-check-label">Facebook Pixel</label>
        </div>
        <div
          (click)="toggleMsAdvertising()"
          class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            [checked]="options.msAdvertising" />
          <label class="form-check-label">MS Advertising</label>
        </div>
      </div>
      <div class="modal-footer">
        <button
          (click)="acceptSelection()"
          type="button"
          class="btn btn-outline-primary btn-sm">
          Save selection
        </button>
        <button
          (click)="acceptAll()"
          type="button"
          class="btn btn-primary btn-sm">
          Accept all
        </button>
      </div>
    </div>
  </div>
</div>
