import {
  AfterViewInit,
  Component,
  ElementRef, HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID, Type,
  ViewChild
} from '@angular/core';
import KeenSlider from 'keen-slider';
import { isPlatformBrowser } from '@angular/common';
import { empty, iif, Observable } from "rxjs";
import {
  BaseBlockComponent,
  BlockData,
  debounce,
  GallerySliderFields
} from '@upero/misc';
import { Store } from '@upero/store';
import { CmsService } from '@upero/services';


@Component({
  selector: 'upero-gallery-slider',
  templateUrl: './gallery-slider.component.html',
  styleUrls: ['./gallery-slider.component.scss']
})
export class GallerySliderComponent extends BaseBlockComponent<GallerySliderFields> implements OnInit, AfterViewInit, OnDestroy
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  deviceType$ = this.store.select<string>('deviceType');
  currentBrand = this.store.selectForLocal('currentBrand');
  currentSlide = 1;
  dotHelper: Array<number> = [];
  slider: any = null;
  fullScreen = false;
  interval: any = 0;
  pause = false;
  isBrowser = false;
  arrowColour = '#263847';

  arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
  arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';


  constructor(
    private store: Store,
    private cmsService: CmsService,
    @Inject(PLATFORM_ID) platformId: any
  )
  {
    super();

    this.isBrowser = isPlatformBrowser(platformId);
    this.initSlider = debounce(this.initSlider, 100);
  }

  ngOnInit(): void
  {
    this.initSlider();
  }

  ngAfterViewInit()
  {

  }

  set block1(block: BlockData<GallerySliderFields>)
  {

  }

  ngOnDestroy(): void
  {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  initSlider()
  {
    let loop = false;
    this.block.fields.slidesPerView = 1;

    if (this.block.fields.loopImages) {
      loop = true;
    }

    iif(
      () => this.isBrowser,
      new Observable((observer) =>
      {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, {
          slidesPerView: this.block.fields.slidesPerView,
          spacing: 0,
          loop,
          slideChanged: s =>
          {
            this.currentSlide = s.details().relativeSlide;
          },
          dragStart: () =>
          {
            this.setPause(true);
          },
          dragEnd: () =>
          {
            this.setPause(false);
          },
        });

        this.dotHelper = [];
        if (this.slider.details().size) {
          this.dotHelper = [...Array(this.slider.details().size).keys()];
        }

        if (this.store.selectForLocal('deviceType') === 'desktop') {
          this.setInterval();
        }

        observer.next();
        observer.complete();
      }),
      empty()
    ).subscribe();
  }

  resetInterval()
  {
    clearInterval(this.interval);
  }

  setPause(active)
  {
    this.pause = active;
    this.setInterval();
  }

  setInterval()
  {
    if (this.isBrowser) {
      this.resetInterval();
      this.interval = setInterval(() =>
      {
        if (!this.pause) {
          this.slider.next();
        }
      }, 9000);
    }
  }

  prevSlide(event: MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();
    this.clearSelection();
    this.slider.prev();
    return false;
  }

  nextSlide(event: MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();
    this.clearSelection();
    this.slider.next();
    return false;
  }

  clearSelection()
  {
    const sel = window.getSelection();
    if (sel && sel.empty) {
      sel.removeAllRanges();
    }
  }

  arrowPreviousState(reset)
  {
    if (reset) {
      this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left-bold.svg';
    } else {
      this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
    }
  }

  arrowNextState(reset)
  {
    if (reset) {
      this.arrowRightSrc = 'assets/images/icon-arrow-slider-right-bold.svg';
    } else {
      this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
    }
  }
}
