import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { Store } from '@upero/store';
import { BrandFields, BaseBlockComponent } from '@upero/misc';

@Component({
  selector: 'upero-brand-video',
  templateUrl: './brand-video.component.html',
  styleUrls: ['./brand-video.component.scss']
})
export class BrandVideoComponent extends BaseBlockComponent<BrandFields>
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  deviceType$ = this.store.select<string>('deviceType');
  isVideoPlaying = false;

  constructor(private store: Store)
  {
    super();
  }

  toggleVideo(event)
  {
    event.preventDefault();
    if (this.isVideoPlaying) {
      this.videoPlayer.nativeElement.pause();
      this.isVideoPlaying = false;
    } else {
      this.videoPlayer.nativeElement.play();
      this.isVideoPlaying = true;
    }
  }
}
