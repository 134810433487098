<ng-container>
    <div class="d-flex flex-column p-3" (click)="$event.stopPropagation()">
        <div class="d-flex justify-content-between align-items-center border-bottom border-1 border-light pb-2 mb-2">
            <div class="fs-6 fw-bold">Notifications</div>
            <div class="nav-link small p-0" (click)="markAllAsRead()" *ngIf="(notifications$ | async)?.unread > 1">Mark all as read</div>
        </div>

        <div class="d-flex flex-column">
            <ng-container *ngIf="(notifications$ | async)?.data.length > 0">
                <div class="notification position-relative p-3 shadow-sm mb-3"
                     *ngFor="let notification of (notifications$ | async)?.data; let i = index"
                     (click)="this.notificationClickHandler(notification)"
                 >
                    <svg-icon src="assets/images/icon-menu-close.svg"
                              (click)="this.delete(notification, $event)"
                              [class]="'hover'"
                              [svgClass]="'icon--close btn--close'"
                    ></svg-icon>
                    <div class="d-flex flex-column mt-3">
                        <div class="d-flex justify-content-between mb-1">
                            <div [ngClass]="{'fw-bold': !notification.isRead }">
                                {{ notification.subject }}
                            </div>

                            <div class="text-muted">
                                {{ notification.createdAt | dateAgo }}
                            </div>
                        </div>
                        <div class="details--mobile text-muted" [innerHTML]="notification.content"></div>

                        <ng-container *ngIf="notification.quoteRequestId">
                            <a href="javascript:void(0)"
                               [uperoBrandRouterLink]="['/account/proposals/', notification.quoteRequestId]"
                            >View quote request</a>
                        </ng-container>

                        <ng-container *ngIf="notification.caseStudyId">
                            <a href="javascript:void(0)"
                               [uperoBrandRouterLink]="['/account/casestudies/', notification.caseStudyId]"
                            >View case study</a>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(notifications$ | async)?.data.length === 0">
                <div class="w-100 d-flex justify-content-center align-items-center bg-light py-3 my-3">
                    <span class="fw-bold">No notifications yet</span>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
