import { Component, Inject, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { Store } from '@upero/store';
import { CookieService } from 'ngx-cookie-service';
import {
  CookieConsentService,
  FacebookPixelService,
  GoogleAnalyticsService,
  MsAdvertisingService,
  OverlayService,
} from '@upero/services';
import { GTAG_FN } from '../../../../../services/src/lib/token/gtag-token';
import { GOOGLE_ANALYTICS_SETTINGS_TOKEN } from '../../../../../services/src/lib/token/google-analytics-settings-token';

@Component({
  selector: 'upero-cookie-consent-dialog',
  templateUrl: './cookie-consent-dialog.component.html',
  styleUrls: ['./cookie-consent-dialog.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: '0' })),
      transition('void => *', [animate('300ms 2000ms ease-in')]),
      transition('* => void', [animate('300ms ease-out')]),
    ]),
  ],
})
export class CookieConsentDialogComponent implements OnInit {
  overlays$ = this.store.select<any>('overlays');
  showConsentDialog = false;
  options = {
    googleAnalytics: true,
    facebookPixel: true,
    msAdvertising: true,
  };

  constructor(
    private store: Store,
    private router: Router,
    private overlayService: OverlayService,
    private cookieService: CookieService,
    @Inject(GTAG_FN) private gtagFn,
    @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private readonly settings,
    protected gaService: GoogleAnalyticsService,
    protected fbqService: FacebookPixelService,
    protected consentService: CookieConsentService,
    protected msAdvertisingService: MsAdvertisingService
  ) {}

  ngOnInit(): void {
    this.showConsentDialog = this.consentService.getConsentDialogEnabled();
    if (this.consentService.getFbqEnabledCookie() !== '0') {
      this.fbqService.initialize();
    }
    if (this.consentService.getGaEnabledCookie() !== '0') {
      this.gaService.initialize();
    }

    if (this.consentService.getMsqEnabledCookie() !== '0') {
      this.msAdvertisingService.initialize();
    }
  }

  accept() {
    this.gaService.initialize();
    this.fbqService.initialize();
    this.gaService.pageView(this.router.url);
    this.consentService.setConsentDialogEnabled(false);
    this.consentService.setGaEnabledCookie(true);
    this.consentService.setFbqEnabledCookie(true);
    this.consentService.setMsqEnabledCookie(true);
    this.showConsentDialog = false;
  }

  acceptSelection() {
    if (this.options.googleAnalytics) {
      this.gaService.initialize();
      this.gaService.pageView(this.router.url);
      this.consentService.setGaEnabledCookie(true);
    } else {
      this.consentService.setGaEnabled(false);
      this.consentService.setGaEnabledCookie(false);
      this.gaService.removeGaScript();
    }

    if (this.options.facebookPixel) {
      this.fbqService.initialize();
      this.consentService.setFbqEnabledCookie(true);
    } else {
      this.consentService.setFbqEnabled(false);
      this.consentService.setFbqEnabledCookie(false);
      this.fbqService.removePixelScript();
    }

    if (this.options.msAdvertising) {
      this.msAdvertisingService.initialize();
      this.consentService.setMsqEnabledCookie(true);
    } else {
      this.consentService.setMsqEnabledCookie(false);
      this.msAdvertisingService.removeMsScript();
    }

    if (this.store.selectForLocal('overlays').cookieConsentManageModal) {
      this.overlayService.toggle('cookieConsentManageModal');
    }

    this.consentService.setConsentDialogEnabled(false);
    this.showConsentDialog = false;
  }

  acceptAll() {
    this.gaService.initialize();
    this.fbqService.initialize();
    this.gaService.pageView(this.router.url);
    this.overlayService.toggle('cookieConsentManageModal');
    this.consentService.setGaEnabledCookie(true);
    this.consentService.setFbqEnabledCookie(true);
    this.consentService.setConsentDialogEnabled(false);
    this.showConsentDialog = false;
  }

  decline() {
    this.consentService.setConsentDialogEnabled(false);
    this.showConsentDialog = false;
  }

  toggleGoogleAnalytics() {
    this.options.googleAnalytics = !this.options.googleAnalytics;
  }

  toggleFacebookPixel() {
    this.options.facebookPixel = !this.options.facebookPixel;
  }

  toggleMsAdvertising() {
    this.options.msAdvertising = !this.options.msAdvertising;
  }

  toggleConsentManageModal() {
    this.overlayService.toggle('cookieConsentManageModal');
  }
}
