<div class='row g-0 h-100'>
  <div class='col-12 col-lg-6' [ngClass]="block['fields']['image-position'] === 'left' ? 'order-lg-0' : 'order-lg-1'">
    <div class="image-as-bg">
      <img [src]="block['fields']['image-link']" />
    </div>
  </div>
  <div class='col-12 col-lg-6' [ngClass]="block['fields']['image-position'] === 'left' ? 'order-lg-1' : 'order-lg-0'">
    <div class='d-flex flex-column align-items-center justify-content-center h-100 text-center'>
      <div class='fs-3 fw-bold mb-3'>
        {{block['fields']['heading']}}
      </div>
      <div class='fs-5 mb-4'>
        {{block['fields']['subline']}}
      </div>
      <a class='btn' [ngClass]="block['fields']['button-class']" [uperoBrandRouterLink]="block['fields']['button-link']">
        {{block['fields']['button-text']}}
      </a>
    </div>
  </div>
</div>
