import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@upero/store';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class RewardService
{

  constructor(
    private http: ApiAuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType,
    private store: Store
  )
  {
  }

  userSummary(userId)
  {
    return this.http
      .get(this.env.apiPath + 'rewards/user/summary/' + userId)
      .pipe(map((data: any) => data));
  }

  rewardsTriggered(params)
  {
    return this.http
      .post(this.env.apiPath + 'rewards/triggered', { params })
      .pipe(map((data: any) => data));
  }

  getUserGiftTypes(userId)
  {
    return this.http
      .get(this.env.apiPath + 'rewards/user/gifttypes/' + userId)
      .pipe(map((data: any) => data));
  }

  addUserGiftType(typeId, userId)
  {
    return this.http
      .put(this.env.apiPath + 'rewards/user/gifttypes/' + typeId + '/' + userId, {})
      .pipe(map((data: any) => data));
  }

  removeUserGiftType(typeId, userId)
  {
    return this.http
      .delete(this.env.apiPath + 'rewards/user/gifttypes/' + typeId + '/' + userId)
      .pipe(map((data: any) => data));
  }

  rewardTriggersByUser(userId)
  {
    return this.http
      .get(this.env.apiPath + 'rewards/triggers/' + userId)
      .pipe(map((data: any) => data));
  }

  createRewardTrigger(trigger, user)
  {
    const dataToSend = {
      trigger,
      user
    };

    return this.http
      .post(this.env.apiPath + 'rewards/trigger/reward', dataToSend)
      .pipe(map((data: any) => data));
  }

  inviteUserByEmail(emails, user)
  {
    const dataToSend = {
      emails,
      user
    };

    return this.http
      .post(this.env.apiPath + 'rewards/email/invite', dataToSend)
      .pipe(map((data: any) => data));
  }
}
