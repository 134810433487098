import { Inject, Injectable, isDevMode, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { GOOGLE_ANALYTICS_SETTINGS_TOKEN } from '../token/google-analytics-settings-token';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { GTAG_FN } from '../token/gtag-token';
import { GaActionEnum } from '../enums/ga-action.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private readonly doc: Document;
  private renderer: Renderer2;

  constructor(
    @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private readonly settings: any,
    @Inject(DOCUMENT) private readonly documentReference: any,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(GTAG_FN) private readonly gtagFn: any,
    @Optional() private router: Router,
    private rendererFactory: RendererFactory2
  ) {
    // DOCUMENT cannot be injected directly as Document type, see https://github.com/angular/angular/issues/20351
    // It is therefore injected as any and then cast to Document
    this.doc = documentReference as Document;
    this.renderer = rendererFactory.createRenderer(null, null);

    /*
    if (router) {
        // Log page views after router navigation ends
        router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {

            if (this.isLoaded()) {
                this.pageView(router.url);
            }

        });
    }
    */
  }

  initialize(trackingCode: { trackingCode: string, initCommands: [], isEnabled: boolean } = this.settings): void {
    if (!isPlatformBrowser(this.platformId) || trackingCode.isEnabled === false) {
      return;
    }

    if (this.isLoaded()) {
      if (isDevMode()) {
        console.warn('Tried to initialize a Pixel instance while another is already active.');
      }
      return;
    }
    this.addGaScript(trackingCode);
  }

  addGaScript(trackingCode: { trackingCode: string, initCommands: [], isEnabled: boolean }) {
    const uri = `https://www.googletagmanager.com/gtag/js?id=${trackingCode.trackingCode}`;

    // Add here new gtag commands for configuring the Google Analytics Tracking code
    const initialCommands = [
      { command: 'consent', values: ['default', { ad_storage: 'granted', analytics_storage: 'granted' }] },
      { command: 'js', values: [new Date()] },
      { command: 'config', values: [trackingCode.trackingCode, { cookieFlags: 'SameSite=None; Secure' }] },
    ];

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!trackingCode.trackingCode) {
      if (isDevMode()) {
        console.error('Empty tracking code for Google Analytics. Make sure to provide one in the environment.');
      }

      return;
    }

    if (!this.gtagFn) {
      if (isDevMode()) {
        console.error('Was not possible create or read gtag.');
      }

      return;
    }

    if (!this.doc) {
      if (isDevMode()) {
        console.error('Was not possible to access Document interface.');
      }

      return;
    }

    this.settings.initCommands = [...initialCommands, ...(this.settings.initCommands || [])];

    for (const command of this.settings.initCommands) {
      this.gtag(command.command, ...command.values);
    }

    const scriptElement: HTMLScriptElement = this.doc.createElement('script');
    this.renderer.setAttribute(scriptElement, 'id', 'ga-script');
    scriptElement.async = true;
    scriptElement.src = uri;
    const head: HTMLHeadElement = this.doc.getElementsByTagName('head')[0];
    head.appendChild(scriptElement);

    // Add phone call tracking for Upero
    const scriptElementPhone = this.doc.createElement('script');
    var inlineScript = document.createTextNode(`
      gtag('config', 'AW-10971064130/DwBHCKKk85gYEMLOtO8o', {
        'phone_conversion_number': '01483 968218'
      });
    `);
    scriptElementPhone.appendChild(inlineScript);
    head.appendChild(scriptElementPhone);
  }

  removeGaScript(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const pixelElement = this.doc.getElementById('ga-script');
    if (pixelElement) {
      pixelElement.remove();
    }
  }

  private isLoaded(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const pixelElement = this.doc.getElementById('ga-script');
      return !!pixelElement;
    }
    return false;
  }

  private toKeyValue(map: Map<string, any>): { [param: string]: any } | void {
    return map.size > 0
      ? Array.from(map).reduce((obj, [key, value]) => Object.defineProperty(obj, key, { value, enumerable: true }), {})
      : undefined;
  }

  gtag(...args: any[]) {
    this.gtagFn && this.gtagFn(...args.filter((x) => x !== undefined));
  }

  event(action: GaActionEnum | string, category?: string, label?: string, options?: any) {
    try {
      const opt = new Map<string, any>([]);
      if (category) {
        opt.set('event_category', category);
      }

      if (label) {
        opt.set('event_label', label);
      }

      if (options) {
        Object.entries(options).map(([key, value]) => opt.set(key, value));
      }

      const params = this.toKeyValue(opt);
      if (params) {
        this.gtag('event', action as string, params);
      } else {
        this.gtag('event', action as string);
      }
    } catch (error) {
      if (isDevMode()) {
        console.error(error);
      }
    }
  }

  pageView(path: string, title?: string, location?: string, options?: any) {
    try {
      const opt = new Map<string, any>([['page_path', path]]);
      if (title) {
        opt.set('page_title', title);
      }
      if (location || this.documentReference) {
        opt.set('page_location', location || this.documentReference.location.href);
      }
      if (options) {
        Object.entries(options).map(([key, value]) => opt.set(key, value));
      }
      this.gtag('config', this.settings.trackingCode, this.toKeyValue(opt));
    } catch (error) {
      if (isDevMode()) {
        console.error(error);
      }
    }
  }

  addTransaction(id: number, options: Map<string, any>) {
    try {
      const params = this.toKeyValue(options);
      if (params) {
        this.gtag('event', 'Ecommerce:addTransaction', params);
      } else {
        this.gtag('event', 'Ecommerce:addTransaction');
      }
    } catch (error) {
      if (isDevMode()) {
        console.error(error);
      }
    }
  }

  eventPurchaseEcommerce(basket, transaction_id, items) {
    this.event(GaActionEnum.PURCHASE, 'Ecommerce', '', {
      transaction_id: transaction_id,
      affiliation: 'upero.co.uk',
      value: basket.values.gross,
      tax: basket.values.vat,
      shipping: basket.values.delivery,
      discount: basket.values.rewardDiscount + basket.values.productDiscount + basket.values.voucherDiscount,
      coupon: basket.voucherCodeEntered,
      items: items,
    });
  }

  eventPurchaseEcommerceSamples(basket, transaction_id, items) {
    this.event(GaActionEnum.PURCHASE_SAMPLES, 'Ecommerce', '', {
      transaction_id: transaction_id,
      affiliation: 'upero.co.uk',
      value: basket.values.gross,
      tax: basket.values.vat,
      shipping: basket.values.delivery,
      discount: basket.values.rewardDiscount + basket.values.productDiscount + basket.values.voucherDiscount,
      coupon: basket.voucherCodeEntered,
      items: items,
    });
  }

  eventConversionEcommerce(transaction_id, value) {
    this.event(GaActionEnum.CONVERSION, 'Ecommerce', '', {
      send_to: 'AW-991523855/3rm1CIbBu44YEI_o5dgD',
      currency: 'GBP',
      transaction_id: transaction_id,
      value: value,
    });
  }
}
