import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import { FormsModule } from '@angular/forms';
import { BrandsModule } from '@upero/brands';
import { SharedModule } from '@upero/shared';

@NgModule({
    imports: [CommonModule, FormsModule, SharedModule, BrandsModule],
    declarations: [ChatComponent],
    exports: [ChatComponent]
})
export class ChatModule
{
}
