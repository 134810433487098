import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';

@Injectable({
  providedIn: 'root'
})
export class CourierService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  calculateDeliveryCosts(basket)
  {
    return this.http
      .post(this.env.apiPath + 'courier/delivery/costs', { basket })
      .pipe(map((data: any) => data));
  }


  get(postcode)
  {
    return this.http
      .get(this.env.apiPath + 'courier/postcode/' + postcode)
      .pipe(map((data: any) => data));
  }

  delete(postcode)
  {
    return this.http
      .delete(this.env.apiPath + 'courier/postcode/' + postcode.postcode)
      .pipe(map((data: any) => data));
  }

  update(postcode)
  {
    return this.http
      .put(this.env.apiPath + 'courier/postcode/' + postcode.postcode, postcode)
      .pipe(map(async (data: any) => data));
  }

  create(postcode)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode', postcode)
      .pipe(map((data: any) => data));
  }

  search(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/search', { params })
      .pipe(map((data: any) => data));
  }

  updateZone(zone)
  {
    return this.http
      .put(this.env.apiPath + 'courier/postcode/zones/' + zone.id, zone)
      .pipe(map(async (data: any) => data));
  }

  createZone(zone)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/zones', zone)
      .pipe(map((data: any) => data));
  }

  searchZones(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/zones/search', { params })
      .pipe(map((data: any) => data));
  }

  getZone(zone)
  {
    return this.http
      .get(this.env.apiPath + 'courier/postcode/zones/' + zone)
      .pipe(map((data: any) => data));
  }

  deleteZone(zone)
  {
    return this.http
      .delete(this.env.apiPath + 'courier/postcode/zones/' + zone.id)
      .pipe(map((data: any) => data));
  }

  searchZonePostcodes(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/zone/postcode/search', { params })
      .pipe(map((data: any) => data));
  }

  addPostcodeToZone(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/zone/postcode/add', { params })
      .pipe(map((data: any) => data));
  }

  addPostcodeToZoneByRegion(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/zone/postcode/add/region', { params })
      .pipe(map((data: any) => data));
  }

  addPostcodeToZoneByArray(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/zone/postcode/add/array', { params })
      .pipe(map((data: any) => data));
  }

  removePostcodeFromZone(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/postcode/zone/postcode/remove', { params })
      .pipe(map((data: any) => data));
  }

  updateVehicle(vehicle)
  {
    return this.http
      .put(this.env.apiPath + 'courier/vehicles/' + vehicle.id, vehicle)
      .pipe(map(async (data: any) => data));
  }

  createVehicle(vehicle)
  {
    return this.http
      .post(this.env.apiPath + 'courier/vehicles', vehicle)
      .pipe(map((data: any) => data));
  }


  searchVehicles(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/vehicles/search', { params })
      .pipe(map((data: any) => data));
  }

  getVehicle(vehicleId)
  {
    return this.http
      .get(this.env.apiPath + 'courier/vehicle/' + vehicleId)
      .pipe(map((data: any) => data));
  }

  getVehicleTypes()
  {
    return this.http
      .get(this.env.apiPath + 'courier/vehicletypes')
      .pipe(map((data: any) => data));
  }

  getPricingTypes()
  {
    return this.http
      .get(this.env.apiPath + 'courier/pricingtypes')
      .pipe(map((data: any) => data));
  }

  searchSupplierCouriers(params)
  {
    return this.http
      .post(this.env.apiPath + 'courier/supplier/linked', { params })
      .pipe(map((data: any) => data));
  }
}
