<div class="d-flex flex-column h-100">
    <div
        class="d-flex flex-column flex-grow-1 align-items-center justify-content-center bg-cover-image"
    >
        <div class="container my-4">
            <div class="row g-0">
                <div
                    class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 shadow-lg"
                >
                    <div class="d-flex flex-column bg-primary py-4">
                        <div class="px-4 mb-3">
                            <a [uperoBrandRouterLink]="['/']">
                                <svg-icon
                                    src="assets/images/icon-arrow-left.svg"
                                    [class]="'hover'"
                                    [svgClass]="'icon--button'"
                                ></svg-icon>
                            </a>
                        </div>
                        <div class="d-flex flex-column my-auto">
                            <div
                                class="d-flex flex-column justify-content-center text-center"
                            >
                                <svg-icon
                                    src="assets/images/ys-logo-text-reversed.svg"
                                    [svgClass]="'logo--text'"
                                ></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column align-items-center bg-white">
                        <div class="w-100 p-4 p-lg-5">
                            <div class="ff-spectral fs-4 text-center">
                                Password Reset
                            </div>
                            <hr />
                            <p *ngIf="notAuthorized" class="text-danger">
                                The reset password link has expired. Please
                                request a new one via the
                                <a [uperoBrandRouterLink]="'/password/reset'"
                                    >reset password page</a
                                >.
                            </p>
                            <p *ngIf="!notAuthorized">
                                Enter your new password and we'll update our
                                records. You will then be able to log into your
                                account.
                            </p>
                            <form
                                [formGroup]="form"
                                autocomplete="off"
                                novalidate
                                (ngSubmit)="onSubmit()"
                                class="form--max-width mx-auto"
                            >
                                <div class="mb-3">
                                    <input
                                        type="password"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                form.controls.password
                                                    .invalid &&
                                                form.controls.password.dirty
                                        }"
                                        formControlName="password"
                                        placeholder="New password"
                                    />
                                    <div class="invalid-feedback">
                                        {{
                                            regexService.getPasswordError(
                                                form.controls.password.value,
                                                8
                                            )
                                        }}
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <input
                                        type="password"
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                (form.controls.passwordConfirm
                                                    .invalid &&
                                                    form.controls
                                                        .passwordConfirm
                                                        .dirty) ||
                                                (form.hasError(
                                                    'passwordNotMatch'
                                                ) &&
                                                    form.controls
                                                        .passwordConfirm.dirty)
                                        }"
                                        formControlName="passwordConfirm"
                                        placeholder="Confirm new password"
                                    />
                                    <div class="invalid-feedback">
                                        {{ 'Passwords do not match' }}
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button
                                        [disabled]="
                                            form.invalid || notAuthorized
                                        "
                                        type="submit"
                                        class="btn btn-primary"
                                    >
                                        Reset password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
