import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { finalize } from "rxjs/operators";
//import { OverlayService } from '@upero/services';

/*import { Store } from '../store';
import { OverlayService } from '../services/overlay.service';*/

@Injectable()
export class SingleRequestInterceptor implements HttpInterceptor
{
  private routeMap = [];
  private activeRequests = 0;

  constructor(//private overlayService: OverlayService
  )
  {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>>
  {
    this.activeRequests++;

    if (this.activeRequests === 1) {
      // causes error that needs to be handled or fixed
      //this.overlayService.toggle('processing');
    }
    if (this.routeMap.indexOf(request.url as never) !== -1) {
      return EMPTY;
    }

    this.routeMap.push(request.url as never);

    return next.handle(request)
      .pipe(
        finalize(() =>
        {
          this.routeMap.splice(this.routeMap.indexOf(request.url as never), 1);
          this.activeRequests--;
          if (this.activeRequests === 0) {
            //this.overlayService.toggle('processing');
          }
        }),
      );
  }
}
