import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@upero/store';
import { BasketService, UserService } from '@upero/services';
import { SignUpOrLoginFields, BaseBlockComponent } from '@upero/misc';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-sign-up-or-login',
  templateUrl: './sign-up-or-login.component.html',
  styleUrls: ['./sign-up-or-login.component.scss']
})
export class SignUpOrLoginComponent extends BaseBlockComponent<SignUpOrLoginFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  navigationHistory = [];
  params: any;

  constructor(
    private userService: UserService,
    private basketService: BasketService,
    private router: Router,
    private store: Store,
  )
  {
    super();
  }

  ngOnInit()
  {
    this.navigationHistory = this.store.selectForLocal('navigationHistory');
  }

  authenticated(e)
  {
    if (e) {
      const basket = this.store.selectForLocal('basket');
      const user = this.store.selectForLocal('user');
      let deliveryAddresses = [];

      basket.customer = user;
      basket.deliveryAddress = undefined;
      basket.billingAddress = undefined;

      if (user.address) {
        basket.billingAddress = user.address;
      }

      this.userService
        .findAddressesByCustomer(user.id)
        .subscribe((data: any) =>
        {
          deliveryAddresses = data.data;

          if (deliveryAddresses.length) {
            basket.deliveryAddress = deliveryAddresses[0];
            basket.deliveryPostcode = deliveryAddresses[0].postcode;
          }
          this.store.set('deliveryAddresses', deliveryAddresses);
          this.basketService.saveBasketChanges(basket, 'modalAuth');
          if (this.navigationHistory.length > 1) {
            this.router.navigateByUrl(AppInitService.doBrandModByUrl(this.navigationHistory[1]));
          } else {
            this.router.navigate(AppInitService.doBrandModUrl(['/']));
          }
        });
    }
  }

  goToRegister()
  {
    this.router.navigate(AppInitService.doBrandModUrl(['/register']));
  }

}
