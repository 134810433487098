import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RegexService
{
  private _mobileNumberRegex = new RegExp(
    /^(07{1}|\+44{1})([0-9]{3}|\s[0-9]{3}|[0-9]{4}|\s[0-9]{4})([0-9]{6}|\s[0-9]{6})$/
  );

  // Used to add validator to form control for entity specific regex

  public validateByRegex(regexPattern: RegExp): ValidatorFn
  {
    return Validators.pattern(regexPattern);
  }

  // Used to add global mobile number validator to form control

  public validateMobileField(): ValidatorFn
  {
    return Validators.pattern(this._mobileNumberRegex);
  }

  // Used to add global password validator to form control

  public validatePasswordField(): ValidatorFn
  {
    const passwordRegex = new RegExp(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&\/\\])[A-Za-z\d@$!%*#?&\.\/\\]{8,}$/
    );

    return Validators.pattern(passwordRegex);
  }

  // Used to set dynamic error text for password fields based on failed condition that makes up passwordRegex

  public getPasswordError(value: string, maxLength: number): string
  {
    const capitalCharRegex = new RegExp(/[A-Z]/);

    const numberRegex = new RegExp(/[0-9]/);

    const specialCharRegex = new RegExp(/[@$!%*#?&]/);

    if (!capitalCharRegex.test(value)) {
      return 'Password must include 1 capital character';
    } else if (!numberRegex.test(value)) {
      return 'Password must include 1 number';
    } else if (!specialCharRegex.test(value)) {
      return 'Password must include 1 special character';
    } else if (value.length < maxLength) {
      return 'Password must include 8 characters';
    } else {
      return 'There is definitely something wrong with a password';
    }
  }
}
