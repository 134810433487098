import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Store} from "@upero/store";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'upero-upero-brands-carousel',
  templateUrl: './upero-brands-carousel.component.html',
  styleUrls: ['./upero-brands-carousel.component.scss']
})
export class UperoBrandsCarouselComponent implements OnInit {
  @Input() block: any;
  isBrowser = false;
  currentBrand = this.store.selectForLocal('currentBrand');

  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) platformId: any,
    ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      const galleryContainer = document.querySelector('.gallery-container');

      const galleryControls = ['previous', 'next'];
      const galleryItems = document.querySelectorAll('.gallery-item');

      const brandCarousel = new Carousel(galleryContainer, galleryItems, galleryControls);
      brandCarousel.useControls();
    }
  }

}


//const galleryControlsContainer = document.querySelector('.gallery-controls');

class Carousel {
  carouselControls;
  carouselArray;
  galleryControlsContainer

  constructor(container, items, controls) {
    this.carouselControls = controls;
    this.carouselArray = [...items];
    this.galleryControlsContainer = document.querySelector('.gallery-controls');
  }

  updateGallery() {
    this.carouselArray.forEach(el => {
      el.classList.remove('gallery-item-1');
      el.classList.remove('gallery-item-2');
      el.classList.remove('gallery-item-3');
      el.classList.remove('gallery-item-4');
      el.classList.remove('gallery-item-5');
    });

    this.carouselArray.slice(0, 5).forEach((el, i) => {
      el.classList.add(`gallery-item-${i + 1}`);
    });
  }

  setCurrentState(direction) {
    if (direction.className === 'gallery-controls-previous') {
      this.carouselArray.unshift(this.carouselArray.pop());
    } else {
      this.carouselArray.push(this.carouselArray.shift());
    }

    this.updateGallery();
  }

  useControls() {
    const triggers = [...this.galleryControlsContainer.childNodes];

    triggers.forEach(control => {
      control.addEventListener('click', e => {
        e.preventDefault();
        this.setCurrentState(control);
      });
    });
  }
}
