<ng-container *ngIf="topic">
    <div class="container-fluid p-4 bg-slate-5">
        <div class="row">
            <div class="col-12 col-lg-3 mb-4">
                <div class="help__sidebar pt-3 pe-3 pb-3 bg-white">
                    <p class="fs-4 mt-3 mb-3">All Topics</p>
                    <div class="d-flex flex-column">
                        <a  *ngFor="let t of topics"
                            href="javascript:void(0)"
                            class="topic-link fs-6 pb-2 mb-2"
                            [uperoBrandRouterLink]="['/help', t.slug]">
                            {{t.contents.title}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-9">
                <div class="bg-white mb-3" *ngFor="let q of entries">
                  <ng-container *ngIf="q.contents.topic == topic.id">
                    <div class="p-4">
                      <a class="mb-0 text-18 ff-spectral hover" (click)="toggleExpanded(q)">
                        <i class="fa fa-chevron-right me-2" *ngIf="!q.expanded"></i>
                        <i class="fa fa-chevron-down me-2" *ngIf="q.expanded"></i>
                        {{q.contents.title}}
                      </a>
                    </div>

                    <div class="help-answer" *ngIf="q.expanded">
                      <p class="mb-0" [innerHTML]="q.contents.content"></p>
                      <!--<a *ngIf="q.linkToRoute !== 0"
                         class="btn btn-outline-secondary"
                         [uperoBrandRouterLink]="'/' + q.linkToRouteUrl">
                          {{q.linkToRouteText}}
                      </a>-->
                    </div>
                  </ng-container>
                </div>
                <div class="no-results" *ngIf="!entries.length">
                    <p>Sorry, no questions for this topic</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
