import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Store } from '@upero/store';
import { environment as env } from '../environments/environment';

@Component({
  selector: 'upero-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'boilerplate';
  isBrowser;

  constructor(
      private store: Store,
      @Inject(PLATFORM_ID) private platformId: any
  )
  {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void
  {
    if (env.production === false) {
      console.log(this.store)
    } else {
      if (isPlatformBrowser(this.platformId)) {
        console.log = () => {}; // dissabling console log on prodution
      }
    }
  }
}
