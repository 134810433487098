import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Store } from '@upero/store';
import { ProductService } from '@upero/services';
import { BaseBlockComponent, GroupsDetailFields } from '@upero/misc';

@Component({
  selector: 'upero-product-groups',
  templateUrl: './product-groups.component.html',
  styleUrls: ['./product-groups.component.scss']
})
export class ProductGroupsComponent extends BaseBlockComponent<GroupsDetailFields> implements OnInit
{
  favourites$ = this.store.select<any[]>('favourites');
  currentBrand = this.store.selectForLocal('currentBrand');
  productGroups;
  products = [];
  isLoaded = false;
  buttonClass: string;
  public destroyed = new Subject<any>();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductService
  )
  {
    super();
  }

  ngOnInit(): void
  {
    const btnStyle = this.block.fields.groupButton['style'] !== 'fill' ? this.block.fields.groupButton['style'] + '-' : '';

    if (this.currentBrand) {
      this.buttonClass = 'btn-' + btnStyle + this.currentBrand.settings.colors[this.block.fields.groupButton['intent']];
    } else {
      this.buttonClass = 'btn-' + btnStyle + this.block.fields.groupButton['intent'];
    }

    this.getProductGroups();
  }

  getProductGroups()
  {
    this.productsService
      .getProductGroups()
      .subscribe(data => {
          this.productGroups = data.data;
          this.isLoaded = true;
        });
  }
}
