import {Pipe, PipeTransform} from '@angular/core';
import {Store} from "@upero/store";

@Pipe({
  name: 'pageLink'
})
export class PageLinkPipe implements PipeTransform {
  sitePages: any;

  constructor(private store: Store) {
    this.sitePages = this.store.selectForLocal('sitePages');
  }

  transform(value: number): string {
    const page = this.sitePages.find(page => page.id === value);
    return page.slug;
  }

}
