import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Input, OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild
} from '@angular/core';
import { Store } from "@upero/store";
import { isPlatformBrowser } from "@angular/common";
import { empty, iif, Observable } from "rxjs";
import KeenSlider from "keen-slider";
import { BaseBlockComponent, debounce, SpacesSlider } from "@upero/misc";

@Component({
    selector: 'upero-spaces-slider',
    templateUrl: './spaces-slider.component.html',
    styleUrls: ['./spaces-slider.component.scss'],
})
export class SpacesSliderComponent extends BaseBlockComponent<SpacesSlider> implements OnInit, AfterViewInit, OnDestroy
{
    @HostBinding('attr.class') get classes()
    {
        return this.cssClass;
    };

    @Input() spacing = 0;
    @Input() slidesPerView = 4;
    @Input() showDots = true;
    @Input() showNav = true;
    @Input() showExpandArrows = false;
    @Input() showOverflow = true;
    @Input() arrowColour = '#000';

    @ViewChild('sliderRef', { read: ElementRef }) sliderRef: ElementRef<HTMLElement>;

    private isBrowser: boolean;
    siteSettings;
    spaces: any;
    currentSlide = 1;
    dotHelper: Array<number> = [];
    slider: any = null;
    fullScreen = false;
    highlightAnimation = false;
    innerWidth$ = this.store.select<number>('innerWidth');
    arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
    arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
    currentBrand = this.store.selectForLocal('currentBrand');
    buttonClass: string;

    constructor(
        private store: Store,
        @Inject(PLATFORM_ID) platformId: any,
    )
    {
        super();
        this.isBrowser = isPlatformBrowser(platformId);
        this.siteSettings = this.store.selectForLocal('siteSettings');
        this.spaces = this.currentBrand ? this.currentBrand.spaces : null;
        this.initSlider = debounce(this.initSlider, 50);
    }

    ngOnInit(): void
    {
        const btnStyle = this.block.fields.buttonCta['style'] !== 'fill' ? this.block.fields.buttonCta['style'] + '-' : '';

        if (this.currentBrand) {
            this.buttonClass = 'btn-' + btnStyle + this.currentBrand.settings.colors[this.block.fields.buttonCta['intent']];
        } else {
            this.buttonClass = 'btn-' + btnStyle + this.block.fields.buttonCta['intent'];
        }

        this.setSliderOptions();
    }

    ngAfterViewInit(): void
    {
        this.initSlider();
    }

    private initSlider()
    {
        iif(
            () => this.isBrowser,
            new Observable((observer) =>
            {
                this.slider = new KeenSlider(this.sliderRef.nativeElement, {
                    slidesPerView: this.slidesPerView,
                    spacing: this.spacing,
                    slideChanged: s =>
                    {
                        this.currentSlide = s.details().relativeSlide;
                    }
                });

                if (this.slider.details().size) {
                    const dots = (this.slider.details().size - this.slidesPerView) + 1;
                    this.dotHelper = dots < 0 ? [] : [...Array(dots)];
                } else {
                    this.dotHelper = [];
                }
                observer.next();
                observer.complete();
            }),
            empty()
        ).subscribe();
    }

    ngOnDestroy(): void
    {
        if (this.slider) {
            this.slider.destroy();
        }
    }

    prevSlide(event: MouseEvent)
    {
        event.preventDefault();
        event.stopPropagation();
        this.clearSelection();
        this.slider.prev();
        if (this.currentSlide === 1) {
            this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
        }
        return false;
    }

    nextSlide(event: MouseEvent)
    {
        event.preventDefault();
        event.stopPropagation();
        this.clearSelection();
        this.slider.next();
        if ((this.currentSlide + this.slidesPerView) + 1 === this.spaces.length) {
            this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
        }
        return false;
    }

    clearSelection()
    {
        const sel = window.getSelection();
        if (sel && sel.empty) {
            sel.removeAllRanges();
        }
    }

    setSliderOptions()
    {
        const deviceType = this.store.selectForLocal('deviceType');

        if (deviceType === 'mobile') {
            this.slidesPerView = 1;
            this.showNav = false;
        }
        if (deviceType === 'tabletV' || deviceType === 'tabletH') {
            this.slidesPerView = 2;
            this.showNav = false;
        }
        if (deviceType === 'desktop') {
            this.slidesPerView = 4;
            this.showNav = true;
        }
    }

    onInViewportChange(inViewport: boolean)
    {
        if (!this.highlightAnimation) {
            this.highlightAnimation = inViewport;
        }
    }

    arrowPreviousState(reset)
    {
        if (this.currentSlide !== 0 && reset) {
            this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left-bold.svg';
        } else {
            this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
        }
    }

    arrowNextState(reset)
    {
        if ((this.currentSlide + this.slidesPerView) !== this.spaces.length && reset) {
            this.arrowRightSrc = 'assets/images/icon-arrow-slider-right-bold.svg';
        } else {
            this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
        }
    }
}
