import { Component, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, CareersService } from '@upero/services';
import { BaseBlockComponent, JobTilesFields } from '@upero/misc';
import { FileUploaderComponent } from '@upero/shared';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Component({
  selector: 'upero-job-tiles',
  templateUrl: './job-tiles.component.html',
  styleUrls: ['./job-tiles.component.scss']
})
export class JobTilesComponent extends BaseBlockComponent<JobTilesFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild(FileUploaderComponent) fileUpload: FileUploaderComponent;

  params = {
    id: '',
    freetext: '',
    page: 0,
    limit: 999,
    sort: 'job.title'
  };

  totalRecords = 0;
  jobs = [];
  formSubmitted = false;

  applicationForm: FormGroup;
  application = {
    vacancy: '',
    firstname: '',
    surname: '',
    email: '',
    telephone: '',
    linkedin: '',
    message: '',
    cv: ''
  };

  uploadConfig: any;

  constructor(
    private careersService: CareersService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.applicationForm = this.formBuilder.group({
      vacancy: [undefined, [Validators.required]],
      firstName: ['', [Validators.required, Validators.minLength(3)]],
      lastName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email, Validators.minLength(5)]],
      phone: ['', [Validators.required, Validators.minLength(8), Validators.pattern(new RegExp('[0-9 ]'))]],
      linkedin: ['', [Validators.minLength(8)]],
      message: ['', [Validators.required, Validators.minLength(10)]],
      cv: ['', [Validators.required]]
    });

    this.initUpload();
    this.searchJobs();
  }

  get field()
  {
    return this.applicationForm.controls;
  }

  searchJobs()
  {
    this.careersService.search(this.params).subscribe(data =>
      {
        this.totalRecords = data.data.totalRecords;
        this.jobs = data.data.data;

        if (this.jobs.length === 0) {
          delete this.applicationForm.controls.vacancy;
        }
      }
    );
  }

  prepareForm(el: HTMLElement, jobId: string)
  {
    this.applicationForm.patchValue({
      vacancy: jobId
    });
    this.applicationForm.controls.vacancy.markAsDirty();
    el.scrollIntoView({ behavior: 'smooth' });
  }

  onSubmit()
  {
    this.formSubmitted = true;
    if (this.applicationForm.invalid) {
      this.applicationForm.markAllAsTouched();
      return;
    }

    if (this.jobs.length > 0) {
      this.application.vacancy = this.applicationForm.controls.vacancy.value;
    }
    this.application.firstname = this.applicationForm.controls.firstName.value;
    this.application.surname = this.applicationForm.controls.firstName.value;
    this.application.email = this.applicationForm.controls.email.value;
    this.application.telephone = this.applicationForm.controls.phone.value;
    this.application.linkedin = this.applicationForm.controls.linkedin.value;
    this.application.message = this.applicationForm.controls.message.value;
    this.application.cv = this.applicationForm.controls.cv.value;

    this.careersService.sendApplicationForm(this.application).subscribe(data =>
    {
      this.applicationForm.reset();
      this.formSubmitted = false;
      this.fileUpload.files = [];
      this.alertService.notification(['Thank you for your interest and application for a job with us here at Upero! We will be in touch within the next 7 days.'], 4000);
    });
  }

  initUpload()
  {
    this.uploadConfig = {
      url: this.env.apiPath + 'job/upload',
      // tslint:disable-next-line:max-line-length
      acceptedFiles: 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      parallelUploads: 1,
      maxFiles: 1,
      maxFilesize: 10024,
      params: {
        type: 'cv',
      },
    };
  }

  onUploadSuccess(response)
  {
    if (response.data) {
      this.applicationForm.patchValue({
        cv: response.data
      });
    }
  }
}
