<ng-container *ngIf="apiLoaded | async">
    <google-map
        [options]="mapOptions"
        height="100%"
        width="100%">
        <map-marker *ngFor="let markerPosition of markerPositions"
                    [position]="markerPosition"
                    [options]="markerOptions">
        </map-marker>
    </google-map>
</ng-container>
