import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { State } from './state';
import { Inject, Injectable } from '@angular/core';
import { STORE_INITIAL_STATE } from './Injection.tokens';


@Injectable()
export class Store
{
  private subject;
  private store;

  constructor(@Inject(STORE_INITIAL_STATE) initialState: State)
  {
    this.subject = new BehaviorSubject<State>(initialState);
    this.store = this.subject.asObservable().pipe(distinctUntilChanged());
  }

  get value()
  {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T>
  {
    return this.store.pipe(pluck(name));
  }

  selectForLocal<T = any>(name: string): T
  {
    const d = this.subject.value;
    return d[name] as T;
  }

  set(name: string, thisState: any)
  {
    this.subject.next({
      ...this.value,
      [name]: thisState,
    });
  }
}
