import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Store} from "@upero/store";

@Component({
  selector: 'upero-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() button?;
  @Input() link?;
  @Input() text?;
  @Input() type?;
  @Input() isDisabled?;
  @Input() isExternal?;
  @Input() buttonClass?: string;
  @Input() buttonStyle?: string = '';
  @Output() clickCallback = new EventEmitter();
  currentBrand = this.store.selectForLocal('currentBrand');


  constructor(private store: Store) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setButtonClass();
  }

  setButtonClass() {
    let btnStyle = '';

    if (this.button && this.button['style'] !== 'fill') {
      btnStyle = this.button['style'] + '-'
    } else if (this.buttonStyle) {
      btnStyle = this.buttonStyle + '-'
    }

    if (this.currentBrand) {
      this.buttonClass = this.buttonClass ? 'btn-' + btnStyle + this.currentBrand.settings[this.buttonClass] : 'btn-' + btnStyle + this.currentBrand.settings[this.button['intent']];
    } else {
      this.buttonClass = this.buttonClass ? 'btn-' + btnStyle + this.buttonClass : 'btn-' + btnStyle + this.button['intent'];
    }
  }

  clickEmit() {
    this.clickCallback.emit();
  }
}
