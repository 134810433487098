import { Component, HostBinding } from '@angular/core';
import { LoginFormComponent as LoginFormComponentBase } from '@upero/shared';

@Component({
  selector: 'upero-proxy-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends LoginFormComponentBase
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };
}
