import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BaseBlockComponent, BasketDeliveryFields } from '@upero/misc';

@Component({
  selector: 'upero-proxy-basket-delivery',
  templateUrl: './basket-delivery.component.html',
  styleUrls: ['./basket-delivery.component.scss']
})
export class BasketDeliveryComponent extends BaseBlockComponent<BasketDeliveryFields> implements AfterViewInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('uperoBasketDelivery', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver)
  {
    super();
  }

  async ngAfterViewInit()
  {
    if (this.viewContainerRef) {
      const { BasketDeliveryComponent } = await import('@upero/basket');
      this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(BasketDeliveryComponent));
    }
  }
}
