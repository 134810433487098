import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@upero/store';
import { AlertService, AuthService, NotificationService, OverlayService } from '@upero/services';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss']
})
export class AccountHeaderComponent implements OnInit
{
  user$ = this.store.select<any>('user');
  basket$ = this.store.select<any>('basket');
  notifications$ = this.store.select<any>('notifications');
  chatStore$ = this.store.select<any>('chatStore');
  settings$ = this.store.select<any>('settings');
  deviceType$ = this.store.select<string>('deviceType');
  favourites$ = this.store.select<string>('favourites');
  currentBrand = this.store.selectForLocal('currentBrand');

  confirmationData = {
    open: false,
    title: '',
    detail: '',
    data: undefined,
  };

  constructor(
    private store: Store,
    private authService: AuthService,
    private alertService: AlertService,
    private router: Router,
    private overlayService: OverlayService,
    private notificationService: NotificationService,
  )
  {
  }

  ngOnInit(): void
  {
  }

  toggleMenu()
  {
    this.store.set('mobileMenuAccount', true);
  }

  toggleUserMenu()
  {
    this.store.set('mobileMenuUser', true);
  }

  toggleNotifications()
  {
    this.overlayService.toggle('notifications');
  }

  toggleChat()
  {
    this.overlayService.toggle('chat');
  }

  logout()
  {
    const notifications = { unread: 0, data: [] };
    const chatStore = {
      unread: 0,
      groups: [],
      group: undefined,
      replyingTo: undefined,
      groupToOpen: undefined,
      messages: [],
    };

    this.store.set('chatStore', chatStore);
    this.store.set('notifications', notifications);

    this.notificationService.stopListening();
    this.authService.logout();
    this.router.navigate(AppInitService.doBrandModUrl(['login']));
  }

  confirmationComplete(e)
  {
    this.confirmationData.open = false;
    if (e.action) {
      this.logout();
    }
  }
}
