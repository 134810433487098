import { Component, OnInit } from '@angular/core';
import { OverlayService, UserService } from '@upero/services';
import { Store } from '@upero/store';

@Component({
  selector: 'upero-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss']
})
export class AddressEditComponent implements OnInit
{

  address;
  user;

  constructor(
    private userService: UserService,
    private store: Store,
    private overlayService: OverlayService,
  )
  {
  }

  ngOnInit(): void
  {
    this.address = this.store.selectForLocal('address');
  }

  setRestrictionValue(f, v)
  {
    this.address.restrictions[f] = v;
    if (!this.address.restrictions.gravel) {
      this.address.restrictions.boards = false;
    }
  }

  toggleRestrictionValue(f)
  {
    this.address.restrictions[f] = !this.address.restrictions[f];
  }

  updateAddress(address)
  {
    this.address = address;
  }

  async saveAddress()
  {
    this.userService.updateAddress(this.address).subscribe(data => this.overlayService.toggle('addressesManage'));
  }

  close()
  {
    this.overlayService.toggle('addressEdit');
  }
}
