import {Inject, Injectable} from '@angular/core';
import {AuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType} from "@upero/misc";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private onlyAuthHttp: AuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  ) {
  }

  getSiteSettings(): Observable<any>
  {
    // Todo change to single site endpoint once available
    return this.onlyAuthHttp.get(this.env.apiPath + 'cms-sites')
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

}
