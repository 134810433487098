export interface User {
    id: string;
    colour: string;
    username: string;
    createdAt: string;
    accountType: string;
    lastSeen: string;
    stripeId: string;
    source: string;
    password: string;
    referralCode: string;
    sessionId: string;
    callRing: number;
    terms: boolean;
    marketing: boolean;
    extendedExpiration: boolean;
    guest: number;
    rewardDiscount: number;
    adminMaxDiscount: number;
    adminMaxRewardPoints: number;
    online: number;
    contact: ContactInfo;
    permissions: object;
}

export interface ContactInfo {
    id: string;
    userId: string;
    createdAt: string;
    companyId: string;
    addressId: string;
    firstname: string;
    surname: string;
    avatar: string;
    email: string;
    mobile: string;
    landline: string;
    jobTitle: string;
    createdBy: string;
    status: number;
    deleted: number;
}

export enum UserAccountType {
    Customer = 'customer',
    Trade = 'trade',
    Supplier = 'supplier',
    Courier = 'courier',
    Admin = 'admin',
    Guest = 'guest',
}

export enum ActionStatus {
    New = 'new',
    Done = 'done',
}

export type UserData = {
    id: string | null;
    username: string;
    password: string;
    extendedExpiration: boolean;
    accountType: UserAccountType;
    terms: boolean;
    marketing: boolean;
    autoConfirm: boolean;
    guest?: 1 | 0;
    source: 'public' | '';
    contact: {
        id: string | null;
        userId: string | null;
        companyId: string | null;
        addressId: string | null;
        company: string;
        address: string;
        firstname: string;
        surname: string;
        email: string;
        mobile: string;
        mobDialCode?: string;
        landline: string | null;
        avatar: string | null;
        jobTitle: string | null;
        fullAddress: string; // one line address, used as a note
        companyName: string; // one line company, used as a note
        country?: string;
        town?: string;
        postcode?: string;
        isWarm?: 0 | 1;
    };
    address: AddressData | null;
    company: CompanyData | null;
    prospect: 0 | 1;
    favorite: 0 | 1;
    avatarColour: string;
    isPremium?: 0 | 1;
    companyName: string;
    prospectCompanyName: string;
};

export const defaultUserData: UserData = {
    id: null,
    username: '',
    password: '',
    extendedExpiration: false,
    accountType: UserAccountType.Customer,
    terms: false,
    marketing: false,
    autoConfirm: false,
    source: 'public',
    contact: {
        id: '',
        userId: '',
        companyId: '',
        addressId: '',
        company: '',
        address: '',
        firstname: '',
        surname: '',
        email: '',
        mobile: '',
        mobDialCode: '',
        landline: '',
        avatar: '',
        jobTitle: '',
        fullAddress: '',
        companyName: '',
    },
    address: null,
    company: null,
    prospect: 0,
    favorite: 0,
} as UserData;

export const defaultProspectData = {
    ...defaultUserData,
    prospect: 1,
};

export type CompanyData = {
    id: string | null;
    userId: string | null;
    addressId: string | null;
    primaryUserId: string | null;
    accountType: UserAccountType;
    name: string;
    url: string | null;
    companyNumber: string | null;
    vatNumber: string | null;
    email: string | null;
    mobile: string | null;
    mobDialCode: string | null;
    landline: string | null;
};

export const DefaultCompanyData = {
    id: '',
    userId: '',
    addressId: '',
    primaryUserId: '',
    accountType: UserAccountType.Trade,
    name: '',
    url: '',
    companyNumber: '',
    vatNumber: '',
    email: '',
    mobile: '',
    landline: '',
} as CompanyData;

export enum AddressType {
    Billing = 'billing',
    Delivery = 'delivery',
    Invoice = 'invoice',
}

export type AddressData = {
    id: string | null;
    userId?: string;
    add1: string | null;
    add2: string | null;
    add3: string | null;
    town: string | null;
    county: string | null;
    country: string;
    postcode: string | null;
    isBilling: boolean;
    defaultDeliveryAddress: boolean;
};

export const DefaultAddressData = {
    id: null,
    userId: '',
    add1: null,
    add2: null,
    add3: null,
    town: null,
    county: null,
    country: 'GB',
    postcode: null,
    isBilling: true,
    defaultDeliveryAddress: true,
} as AddressData;

export type ActionData = {
    text: string;
    title?: string;
    actionType: string;
    allocatedTo?: string;
    customerId?: string;
};

export type NotesData = {
    id?: string;
    createdBy?: string;
    content: string;
    recordId: string;
    type: UserAccountType;
};

export enum UserQuoteStatus {
    Draft = 'Draft',
    Sent = 'Sent',
    Accepted = 'Accepted',
    Declined = 'Declined',
}
