import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MsAdvertisingService {
  static readonly SCRIPT_ID = 'ms-script';
  private readonly doc: Document;
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private readonly documentReference: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private rendererFactory: RendererFactory2
  ) {
    this.doc = documentReference as Document;
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  initialize() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.isLoaded()) {
      console.warn('Tried to initialize a MS Bing Script instance while another is already active.');
      if (isDevMode()) {
        console.warn('Tried to initialize a MS Bing Script in Dev Mode');
      }
      return;
    }

    this.addMsScript();
  }

  eventPurchaseEcommerce(prodIds: string[]) {
    if (!isPlatformBrowser(this.platformId) || !this.isLoaded()) {
      return;
    }

    (<any>window).uetq = (<any>window).uetq || [];
    for (let i = 0; i < prodIds.length; i++) {
      const prodId = prodIds[i];
      (<any>window).uetq.push('event', 'PRODUCT_PURCHASE', {
        ecomm_prodid: prodId,
        ecomm_pagetype: 'PURCHASE',
        revenue_value: 1,
        currency: 'USD',
      });
    }
  }

  private isLoaded(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const msBingElement = this.doc.getElementById(MsAdvertisingService.SCRIPT_ID);
      return !!msBingElement;
    }
    return false;
  }

  private addMsScript() {
    const scriptElement: HTMLScriptElement = this.doc.createElement('script');
    this.renderer.setAttribute(scriptElement, 'id', MsAdvertisingService.SCRIPT_ID);

    var inlineScript = document.createTextNode(`
      (function(w,d,t,r,u)
      {
          var f,n,i;
          w[u]=w[u]||[],f=function()
          {
              var o={ti:"97027548", enableAutoSpaTracking: true};
              o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
          },
          n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
          {
              var s=this.readyState;
              s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
          },
          i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
      })
      (window,document,"script","//bat.bing.com/bat.js","uetq");
    `);
    scriptElement.appendChild(inlineScript);

    const head: HTMLHeadElement = this.doc.getElementsByTagName('head')[0];
    head.appendChild(scriptElement);
  }

  removeMsScript() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const msScriptElement = this.doc.getElementById('ms-script');
    if (msScriptElement) {
      msScriptElement.remove();
    }
  }
}
