<div class="h-100 w-100 position-relative">
  <header class="z-index-3 w-100 transparent">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div class="w-100 py-4 d-flex justify-content-between">
        <div class="flex">
          <a href="/"><img class="w-auto" style="height: 40px;" src="/assets/upero/images/Upero_Logo_RGB_Multi_6.png" alt="Upero"></a>
        </div>
        <div class="ms-5 pe-2">
          <a [routerLink]="['/paving']" class="btn btn-primary">Shop Now</a>
        </div>
      </div>
    </nav>
  </header>
  <video id="home-video" class="w-100 h-33" autoplay muted>
    <img class="h-full w-full object-cover" src="/assets/upero/images/poster-noscript.jpg" alt="Upero logo animation">
  </video>
  <div id="muter" class="position-absolute cursor-pointer">
    <svg id="icon-volume-on" style="display: none;width: 1.75rem;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="volume-off" class="text-success h-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M215 71l-89 89H24a24 24 0 0 0-24 24v144a24 24 0 0 0 24 24h102.06L215 441c15 15 41 4.47 41-17V88c0-21.47-26-32-41-17z"></path></svg>
    <svg id="icon-volume-off" style="display: block;width: 1.75rem;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="volume-mute" class="text-success h-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM461.64 256l45.64-45.64c6.3-6.3 6.3-16.52 0-22.82l-22.82-22.82c-6.3-6.3-16.52-6.3-22.82 0L416 210.36l-45.64-45.64c-6.3-6.3-16.52-6.3-22.82 0l-22.82 22.82c-6.3 6.3-6.3 16.52 0 22.82L370.36 256l-45.63 45.63c-6.3 6.3-6.3 16.52 0 22.82l22.82 22.82c6.3 6.3 16.52 6.3 22.82 0L416 301.64l45.64 45.64c6.3 6.3 16.52 6.3 22.82 0l22.82-22.82c6.3-6.3 6.3-16.52 0-22.82L461.64 256z"></path></svg>
  </div>
</div>
