import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';

@Injectable({
  providedIn: 'root'
})
export class QuoteService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  create(quote)
  {
    return this.http
      .post(this.env.apiPath + 'quote', { quote })
      .pipe(map((data: any) => data));
  }

  update(quote)
  {
    return this.http
      .put(this.env.apiPath + 'quote', { quote })
      .pipe(map((data: any) => data));
  }

  get(id)
  {
    return this.http
      .get(this.env.apiPath + 'quote/request/' + id)
      .pipe(map((data: any) => data));
  }

  getOpenJobs(params)
  {
    return this.http
      .post(this.env.apiPath + 'quote/open', { params })
      .pipe(map((data: any) => data));
  }

  sendResponse(quoteResponse)
  {
    return this.http
      .post(this.env.apiPath + 'quote/respond', { quoteResponse })
      .pipe(map((data: any) => data));
  }

  search(params)
  {
    return this.http
      .post(this.env.apiPath + 'quote/requests/search', { params })
      .pipe(map((data: any) => data));
  }

  searchResponses(params)
  {
    return this.http
      .post(this.env.apiPath + 'quote/responses/search', { params })
      .pipe(map((data: any) => data));
  }

  getResponsesByCompanyId(requestId, customersUserId, tradeCompanyId)
  {
    return this.http
      .get(this.env.apiPath + 'quote/responses/' + requestId + '/' + customersUserId + '/' + tradeCompanyId)
      .pipe(map((data: any) => data));
  }

  accept(responseId)
  {
    return this.http
      .get(this.env.apiPath + 'quote/responses/accept/' + responseId)
      .pipe(map((data: any) => data));
  }

  reject(responseId)
  {
    return this.http
      .get(this.env.apiPath + 'quote/responses/decline/' + responseId)
      .pipe(map((data: any) => data));
  }


  getTypes()
  {
    return this.http
      .get(this.env.apiPath + 'quote/types')
      .pipe(map((data: any) => data));
  }

  getComments(requestId, companyId)
  {
    return this.http
      .get(this.env.apiPath + 'quote/comments/' + requestId + '/' + companyId)
      .pipe(map((data: any) => data));
  }

  addComment(comment)
  {
    const user = this.store.selectForLocal('user');
    const dataToSend = {
      comment,
      user
    };
    return this.http
      .post(this.env.apiPath + 'quote/comment', dataToSend)
      .pipe(map((data: any) => data));
  }
}
