<div class="bg-light">
    <div class="container py-5">
        <!-- Filters are hidden until we have enough case studies
        <div *ngIf="(deviceType$| async) !== 'mobile'" class="cs__filters d-flex flex-column flex-nowrap flex-md-row flex-md-wrap flex-md-row flex-xl-nowrap justify-content-xl-between pb-4 mb-4">
            <div *ngFor="let filter of filters; let last = last;" class="cs__filter cs__filter&#45;&#45;gap mb-3 mb-xl-0">
                <ng-select
                    [clearable]="false"
                    [searchable]="false"
                    [placeholder]="filter.placeholder"
                    class="dark">
                    <ng-option *ngFor="let opt of filter.options;" [value]="opt">{{opt.name}}</ng-option>
                </ng-select>
            </div>
        </div>-->
        <div *ngIf="items.length" class="px-3">
            <div class="row">
                <div
                    class="col-12 col-md-6 col-lg-4 mb-5"
                    *ngFor="let s of items"
                    [@fadeIn]>
                    <div class="d-flex flex-column h-100 bg-white shadow-sm">
                        <a
                            [uperoBrandRouterLink]="[block.fields.pageSlug, s.contents.slug]"
                            class="gallery-image-tile">
                            <img [src]="s.contents.featuredImage" [appScrset]="s.contents.featuredImage" [width]="1000" class="image-as-bg">
                        </a>
                        <div class="d-flex flex-column justify-content-center text-center h-100 p-3 p-md-4">
                            <a [uperoBrandRouterLink]="[block.fields.pageSlug, s.contents.slug]">
                                <h2 class="cs__title fs-4 mb-3">{{s.contents.title}}</h2>
                            </a>
                            <div class="cs_paragraph mb-3" *ngIf="s.contents.preview_paragraph">{{s.contents.preview_paragraph}}</div>
                            <upero-button [button]="block.fields.ctaButton" [link]="'/' + block.fields.pageSlug + '/' + s.contents.slug"></upero-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-3 mb-lg-5 text-center">
            <a
                *ngIf="moreEntries"
                (click)="showMore($event)"
                [uperoBrandRouterLink]="['/case-studies', 'page', nextPage]"
                class="btn btn-primary">
                Show more
            </a>
        </div>

        <div class="text-center" *ngIf="!items.length">
            <h2>Sorry - no case studies to show at the moment</h2>
        </div>
    </div>
</div>
