import { Directive, Input } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AppInitService } from '@upero/routing';

@Directive({
  selector: '[uperoBrandRouterLink]'
})
export class BrandRouteLinkDirective extends RouterLinkWithHref
{
  @Input()
  set uperoBrandRouterLink(commands: any[] | string) {
    if (Array.isArray(commands)) {
      this.routerLink = AppInitService.doBrandModUrl(commands, true);
    } else {
      this.routerLink = AppInitService.doBrandModByUrl(commands, true);
    }
  }
}
