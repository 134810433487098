import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {Store} from "@upero/store";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'upero-upero-video',
  templateUrl: './upero-video.component.html',
  styleUrls: ['./upero-video.component.scss']
})
export class UperoVideoComponent implements OnInit {
  @Input() block: any;
  isBrowser = false;
  currentBrand = this.store.selectForLocal('currentBrand');
  volumeOn = false;

  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) platformId: any,
    ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      if (window.innerWidth <= 500) {
        this.appendPoster('mobile');
        this.appendSource('mobile', 'mp4', 'video/mp4');
        this.appendSource('mobile', 'webm', 'video/webm');
      } else if (500 < window.innerWidth && window.innerWidth <= 768) {
        this.appendPoster('tablet');
        this.appendSource('tablet', 'mp4', 'video/mp4');
        this.appendSource('tablet', 'webm', 'video/webm');
      } else {
        this.appendPoster('desktop');
        this.appendSource('desktop', 'mp4', 'video/mp4');
        this.appendSource('desktop', 'webm', 'video/webm');
      }
      document.getElementById('muter').addEventListener('click', e => {
        if (this.volumeOn) {
          document.getElementById('icon-volume-on').style.display = 'none';
          document.getElementById('icon-volume-off').style.display = 'block';
          // @ts-ignore
          document.getElementById('home-video').muted = true;
        } else {
          document.getElementById('icon-volume-on').style.display = 'block';
          document.getElementById('icon-volume-off').style.display = 'none';
          // @ts-ignore
          document.getElementById('home-video').muted = false;
        }
        this.volumeOn = !this.volumeOn;
      });
    }
  }

  appendSource(device, format, type) {
    if (this.isBrowser) {
      const source = document.createElement('source');
      source.src = '/assets/upero/videos/video-' + device + '.' + format;
      source.type = type;
      document.getElementById('home-video').appendChild(source)
    }
  }

  appendPoster(device) {
    if (this.isBrowser) {
      document.getElementById('home-video').setAttribute('poster', '/assets/upero/images/poster-' + device + '.jpg');
    }
  }

}
