import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { distinctUntilChanged, Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GaService, RewardService, UserService } from '@upero/services';
import { ToolsService } from './tools.service';
import { Store } from '@upero/store';
import {
  ApiAuthHttpClient,
  AuthHttpClient,
  ENVIRONMENT_CONFIG,
  EnvironmentType,
  UserQuoteStatus,
  defaultSkuPriceTotals,
  SkuPriceTotals,
  Basket,
  BasketItem,
  defaultBasketItem,
  DiscountType,
  defaultBasketValues,
  CalculateDiscountPrice,
} from '@upero/misc';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  private _userBaskets = [];
  private basketRequest: Subscription = null;

  constructor(
      private http: ApiAuthHttpClient,
      private authHttp: AuthHttpClient,
      private store: Store,
      private toolsService: ToolsService,
      private rewardsService: RewardService,
      private userService: UserService,
      private gaService: GaService,
      @Inject(PLATFORM_ID) platformId: {},
      @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  ) {
    this.store
        .select<any>('user')
        .pipe(distinctUntilChanged())
        .subscribe((storeUser: any) => {
          let basket = this.store.selectForLocal<Basket>('basket');
          if (basket.items.length === 0) {
            // the case when basket not loaded yet
            return;
          }

          const sub = storeUser ? this.rewardsService.userSummary(storeUser.id) : of(undefined);

          sub.subscribe((user: any) => {
            let rewardDiscount = 0;

            if (storeUser?.rewardDiscount) {
              rewardDiscount = +storeUser.rewardDiscount;
            }
            if (user) {
              rewardDiscount = +user.currentPercentage;
            }

            basket = this.store.selectForLocal<Basket>('basket');
            basket.items.map((item) => {
              item.pricing = CalculateDiscountPrice(item.pricing, item.qty);
              item.pricing.discount.reward.percentage = rewardDiscount;
              item.pricing.discount.reward.value =
                  (+item.pricing.discount.preDiscountValue / 100) * item.pricing.discount.reward.percentage;

              item.pricing.totals = this.calculateLineTotals(item, item.sample);
              return item;
            });

            basket = this.calculateBasketObjectTotal(basket, storeUser);
            this.saveBasketChanges(basket);
          });
        });

    if (isPlatformBrowser(platformId)) {
      if (localStorage.getItem('tempGuestUserId')) {
        this.userService
            .findOne(localStorage.getItem('tempGuestUserId'))
            .subscribe(({ data }: any) => this.store.set('guestUser', data));
      }
    }
  }

  getBasketId() {
    let basketId;
    const ysBasketId = localStorage.getItem('ysBasketId');
    if (ysBasketId) {
      basketId = ysBasketId;
    } else {
      basketId = this.toolsService.newUUID();
      localStorage.setItem('ysBasketId', basketId);
    }
    this.store.set('basketId', basketId);
    return basketId;
  }

  public getQuoteBasket(basketId: string): Observable<any> {
    return this.http.get(this.env.apiPath + 'basket/quote/' + basketId).pipe(map((data: any) => data.data));
  }

  public getQuotesBasket(userId: string): Observable<any> {
    return this.http.get(this.env.apiPath + 'basket/quotes/' + userId).pipe(map((data: any) => data.data));
  }

  public updateBasketQuote(basketId: string, status: UserQuoteStatus): Observable<any> {
    return this.http
        .put(this.env.apiPath + 'basket/quote/status', {
          basketId,
          quoteStatus: status,
        })
        .pipe(map((data: any) => data.data));
  }

  public getBasketData(basketId: string, setToStore = true): Observable<any> {
    return this.http.get(this.env.apiPath + 'basket/' + basketId);
  }

  public getBasket(basketId: string, setToStore = true): Observable<Basket> {
    return this.getBasketData(basketId).pipe(
        map(({ data }: any) => {
          let basketData: Basket;
          if (data) {
            basketData = data as Basket;
            if (!basketData.samples) {
              basketData.samples = [];
            }
            if (!basketData.restrictions) {
              basketData.restrictions = {
                slope: false,
                gravel: false,
                boards: false,
                offload: false,
              };
            }
            if (setToStore) {
              this.store.set('basket', basketData);
              localStorage.setItem('ysBasket', JSON.stringify(basketData));
            }
          } else {
            basketData = this.store.selectForLocal<Basket>('basket');
            if (!basketData.samples) {
              basketData.samples = [];
            }
            if (!basketData.restrictions) {
              basketData.restrictions = {
                slope: false,
                gravel: false,
                boards: false,
                offload: false,
              };
            }
            if (setToStore) {
              this.store.set('basket', basketData);
              localStorage.setItem('ysBasket', JSON.stringify(basketData));
            }
          }
          return basketData;
        })
    );
  }

  async addToBasket(item: BasketItem, replaceIndex?) {
    let maxSamples = 3;

    const user = this.store.selectForLocal('user');

    if (user && user.accountType === 'trade') {
      maxSamples = 10;
    }

    let basket: Basket = this.store.selectForLocal<Basket>('basket');
    if (!basket.samples) {
      basket.samples = [];
    }
    if (!basket.samples.length && !basket.items.length && !user) {
      // clearing user data from the storages
      (await (await this.toolsService.clearTempStorages()).clearUserStorages()).sessionId();
      this.toolsService.logoutSessionId();
      basket = this.emptyBasket();
    }
    // check for duplicate products and add qty if found (non bespoke only);
    const dupeFound = +replaceIndex >= 0 ? false : this.dupeCheck(item, basket);

    const lastBasketItemAdded = {
      isDupe: dupeFound,
      isSample: item.sample,
      sampleCount: basket.samples.length,
      item,
    };
    this.store.set('lastBasketItemAdded', lastBasketItemAdded);

    if (item.sample) {
      if (!dupeFound && basket.samples.length < +maxSamples) {
        if (+replaceIndex >= 0) {
          basket.samples.splice(replaceIndex, 1, item);
        } else {
          item.pricing.totals = this.calculateLineTotals(item, true);

          basket.samples.push(item);
        }
      }
    }
    if (!item.sample) {
      if (!dupeFound) {
        if (+replaceIndex >= 0) {
          basket.items.splice(replaceIndex, 1, item as BasketItem);
        } else {
          basket.items.push(item as BasketItem);
        }
      }
    }

    basket = this.checkForSampleOnly(basket);
    this.calculateBasketTotal();
    this.gaService.addToBasket(item);
  }

  dupeCheck(item, basket: Basket): boolean {
    let dupeFound = false;

    if (!item.sample) {
      for (let i = 0; i < basket.items.length; i++) {
        if (
            basket.items[i].product.productCode + '-' + basket.items[i].skuId ===
            item.product.productCode + '-' + item.skuId &&
            !basket.items[i].sample
        ) {
          basket.items[i].qty = +basket.items[i].qty + +item.qty;

          basket.items[i].piecesRequired = Math.ceil(+basket.items[i].qty / +basket.items[i].singlePieceSize);
          basket.items[i].totalPrice = +(+basket.items[i].qty * +basket.items[i].unitPrice).toFixed(2);

          basket.items[i].unitPriceDiscount = +(
              +basket.items[i].unitPriceGross - +basket.items[i].unitPrice
          ).toFixed(2);
          basket.items[i].totalDiscount = +basket.items[i].unitPriceDiscount * +basket.items[i].qty;
          basket.items[i].totalPricePreDiscount = +basket.items[i].unitPriceGross * +basket.items[i].qty;

          basket.items[i].pricing.totals = this.calculateLineTotals(basket.items[i], false);

          dupeFound = true;
        }
      }
    } else {
      for (let i = 0; i < basket.samples.length; i++) {
        if (
            basket.samples[i].sample &&
            basket.samples[i].product.finishId === item.product.finishId &&
            basket.samples[i].product.rangeId === item.product.rangeId
        ) {
          dupeFound = true;
        }
      }
    }

    return dupeFound;
  }

  addBespokeItems(lists) {
    let basket: Basket = this.store.selectForLocal<Basket>('basket');

    for (let l = 0; l < lists.length; l++) {
      for (let i = 0; i < lists[l].items.length; i++) {
        const sku: BasketItem = {
          ...defaultBasketItem,
          qty: 10,
        };

        sku.product = lists[l].items[i];
        sku.product.isBespoke = true;
        sku.sample = false;
        sku.product.id = '0';
        sku.product.productCode = '';
        sku.product.name = '';
        sku.product.slug = '';
        sku.product.supplier = {
          id: lists[l].items[i].range.supplier,
        };
        sku.product.rangeId = sku.product.range.id;

        sku.sellUnit = lists[l].items[i].sellUnit;
        sku.product.image = lists[l].items[i].range.imageUrl;
        sku.product.name = `Bespoke ${lists[l].items[i].range.name} ${sku.product.type.name}`;
        sku.product.productCode = 'bespoke';
        if (sku.product.type.sellUnits.length) {
          sku.sellUnit = sku.product.type.sellUnits[0];
        }
        sku.skuId = '';
        sku.productDetailId = 0;
        sku.qty = +lists[l].items[i].quantity;
        sku.leadtime = +lists[l].items[i].leadtime;
        // pallet weight was being calculated incorrectly as it was multiplying by the QTY in the courier API method
        // sku.weight = +item.weightPerSellUnit * +item.quantity;
        sku.weight = +lists[l].items[i].weightPerSellUnit;
        sku.sellUnit = lists[l].items[i].sellUnit;
        sku.unitPrice = +lists[l].items[i].unitCost;
        sku.totalPrice = +lists[l].items[i].unitCostBreakdown.totalCost;

        sku.piecesRequired = +lists[l].items[i].unitCostBreakdown.piecesRequired;
        sku.singlePieceSize = +lists[l].items[i].unitCostBreakdown.singlePieceSize;
        sku.weight = +lists[l].items[i].weightTotal;
        sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.squareMetres;

        if (lists[l].items[i].sellUnit.id === 'PLM') {
          sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.linearMetres;
        }
        if (lists[l].items[i].sellUnit.id === 'PSM') {
          sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.squareMetres;
        }
        if (lists[l].items[i].sellUnit.id === 'P') {
          sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.squareMetres;
        }

        delete sku.product.range;

        // this.addToBasket(sku);
        basket.items.push(sku);
        basket = this.checkForSampleOnly(basket);
      }
    }
    this.calculateBasketTotal();
  }

  saveBasketChanges(basket: Basket, src?) {
    this.store.set('basket', basket); // !!!! THIS LINE HAS TO BE ABOVE before saving to DB
    this.saveBasketToStorage(basket).then();
  }

  setGuest(v: boolean) {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    basket.guest = v;
    this.saveBasketChanges(basket, 'internal');
  }

  getStripeSession() {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    return this.http.post(this.env.apiPath + 'orders/stripe-session', basket).pipe(map((data: any) => data));
  }

  calculateBasketObjectTotal(basket: Basket, user): Basket {
    basket.rewardsDiscountPercentage = user ? user.rewardDiscount : 0;

    basket.values.gross = 0;
    basket.values.vat = 0;
    basket.values.net = 0;
    basket.values.netPreDiscount = 0;
    basket.values.productDiscount = 0;
    basket.values.voucherDiscount = 0;
    basket.values.rewardDiscount = 0;
    basket.values.discount = 0;
    basket.values.voucher = 0;

    if (!basket.items.length) {
      basket.values.delivery = 0;
      basket.values.deliveryVat = 0;
    }
    if (basket.sampleOnly) {
      basket.values.delivery = 0;
      basket.values.deliveryVat = 0;
    }

    basket.items.forEach((item) => {
      basket.values.net += +item.pricing.totals.net;
      basket.values.gross += +item.pricing.totals.gross;
      basket.values.netPreDiscount += +item.pricing.totals.netPreDiscount;

      basket.values.discount += +item.pricing.totals.discount;
      if (item.pricing.discountToUse === 'product') {
        basket.values.productDiscount += +item.pricing.totals.discount;
      }
      if (item.pricing.discountToUse === 'reward') {
        basket.values.rewardDiscount += +item.pricing.totals.discount;
      }
    });

    if (basket.voucher) {
      let voucherDiscount = 0;
      if (basket.voucher.discountType === DiscountType.Pound) {
        voucherDiscount = basket.voucher.discountValue;
      } else if (basket.voucher.discountType === DiscountType.Percent) {
        voucherDiscount = +(basket.values.netPreDiscount * (basket.voucher.discountValue / 100)).toFixed(2);
      }
      if (voucherDiscount > basket.values.discount) {
        // if voucher discount is higher then all others
        basket.values.voucherDiscount = basket.values.discount = voucherDiscount;
        basket.values.rewardDiscount = basket.values.productDiscount = 0;
        basket.values.net = basket.values.netPreDiscount - basket.values.voucherDiscount;
      }
    }

    basket.values.vat = +(basket.values.net * 0.2 + basket.values.deliveryVat).toFixed(2);
    basket.values.gross = basket.values.net + +basket.values.vat + basket.values.delivery;

    return basket;
  }

  calculateBasketTotal() {
    const user = this.store.selectForLocal('user');
    let basket: Basket = this.store.selectForLocal<Basket>('basket');

    basket = this.calculateBasketObjectTotal(basket, user);

    this.saveBasketChanges(basket, 'internal');
  }

  calculateLineTotals(itemTotals, isSample): SkuPriceTotals {
    const totals: SkuPriceTotals = { ...defaultSkuPriceTotals };

    if (isSample) {
      return totals;
    }

    totals.qty = itemTotals.qty;
    totals.preDiscount.net = +itemTotals.pricing.preDiscount.net * totals.qty;
    totals.netPreDiscount = totals.preDiscount.net;
    totals.preDiscount.vat = +itemTotals.pricing.preDiscount.vat * totals.qty;
    totals.preDiscount.gross = +itemTotals.pricing.preDiscount.gross * totals.qty;

    totals.net = +itemTotals.pricing.net * totals.qty;
    totals.vat = +totals.net * 0.2;
    totals.gross = +itemTotals.pricing.net + totals.vat;

    if (itemTotals.pricing.discountToUse === 'reward') {
      totals.rewardDiscount = +itemTotals.pricing.discount.reward.value * totals.qty;
      totals.discount = totals.rewardDiscount;
    }
    if (itemTotals.pricing.discountToUse === 'product') {
      totals.productDiscount = +itemTotals.pricing.discount.product.value * totals.qty;
      totals.discount = totals.productDiscount;
    }

    return totals;
  }

  deleteBasketItem(idx) {
    let basket: Basket = this.store.selectForLocal<Basket>('basket');

    this.gaService.removeFromBasket(basket.items[idx]);

    basket.items.splice(idx, 1);

    basket = this.checkForSampleOnly(basket);
    this.calculateBasketTotal();
  }

  emptyBasketItems() {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    while (basket.items.length > 0) {
      this.deleteBasketItem(0);
    }
  }

  deleteSampleItem(idx) {
    let basket: Basket = this.store.selectForLocal<Basket>('basket');
    basket.samples.splice(idx, 1);

    basket = this.checkForSampleOnly(basket);
    this.calculateBasketTotal();
  }

  toggleSeparateShipment(idx) {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    basket.items[idx].separateShipment = !basket.items[idx].separateShipment;
    return basket;
  }

  checkForSampleOnly(basket: Basket): Basket {
    basket.sampleOnly = !basket.items.length;
    return basket;
  }

  setAddress(address) {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    const addressType = this.store.selectForLocal('addressType');

    if (addressType === 'billing') {
      basket.billingAddress = address;
    } else {
      basket.deliveryAddress = address;
    }
    this.store.set('basket', basket);
    this.calculateBasketTotal();
  }

  emptyBasket() {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    basket.source = 'public';
    basket.items = [];
    basket.samples = [];
    basket.potentialQty = 0;
    basket.voucher = undefined;
    basket.voucherCodeEntered = '';
    basket.deliveryInstructions = '';
    basket.differentDeliveryAddress = false;
    basket.deliveryInstructions = '';
    basket.token = undefined;
    basket.customer = undefined;
    basket.deliveryAddress = undefined;
    basket.billingAddress = undefined;
    basket.createdBy = undefined;
    basket.sampleOnly = false;

    this.store.set('deliveryAddresses', []);
    this.store.set('basketId', '');
    this.store.set('basket', basket);
    this.calculateBasketTotal();

    return basket;
  }

  clearBasket() {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    basket.source = 'public';
    basket.items = [];
    basket.samples = [];
    basket.potentialQty = 0;
    basket.voucher = undefined;
    basket.voucherCodeEntered = '';
    basket.deliveryInstructions = '';
    basket.differentDeliveryAddress = false;
    basket.deliveryInstructions = '';
    basket.token = undefined;
    basket.sampleOnly = false;
    basket.discount = {
      type: 'pound',
      value: 0,
    };
    basket.values = {
      ...defaultBasketValues,
    };

    this.store.set('basket', basket);
    localStorage.setItem('ysBasket', JSON.stringify(basket));
  }

  renewBasket() {
    const currentBasketId = this.getBasketId();

    const basketId = this.toolsService.newUUID();
    localStorage.setItem('ysBasketId', basketId);
    this.store.set('basketId', basketId);

    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    const userId = localStorage.getItem('user');

    localStorage.setItem('basketRef', JSON.stringify(basket));

    const basketRef = localStorage.getItem('basketRef');

    const userBasket = this._userBaskets.find((userBasket) => userBasket.userId === userId);

    if (!userBasket) {
      this._userBaskets.push({ userId, basket: JSON.parse(basketRef) });
    } else {
      const userBasketIndex = this._userBaskets.findIndex((userBasket) => userBasket.userId === userId);

      const latestUserBasket = JSON.parse(localStorage.getItem('basketRef'));

      this._userBaskets[userBasketIndex].basket = latestUserBasket;
    }

    this.store.set('userBaskets', this._userBaskets);
    localStorage.setItem('userBaskets', JSON.stringify(this._userBaskets));

    this.clearBasket();

    return this.http.delete(this.env.apiPath + 'basket/' + currentBasketId).subscribe();
  }

  clearUserFromBasket() {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    basket.deliveryInstructions = '';
    basket.token = undefined;
    basket.deliveryAddress = undefined;
    basket.billingAddress = undefined;
    basket.deliveryPostcode = '';
    basket.differentDeliveryAddress = false;
    basket.customer = undefined;
    basket.createdBy = undefined;
    basket.guest = true;
    basket.shipments = [];
    basket.values.delivery = 0;
    basket.values.deliveryVat = 0;
    basket.paymentMethod = 'CC';
    delete basket.intent;
    this.store.set('basket', basket);
    this.calculateBasketTotal();
  }

  deleteIntent() {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    delete basket.intent;
    this.store.set('basket', basket);
  }

  async saveBasketToStorage(basket) {
    localStorage.setItem('ysBasket', JSON.stringify(basket));

    const basketId = this.getBasketId();

    const dataToSend: any = {
      basket,
    };
    if (basketId) {
      dataToSend.basketId = basketId;
    }
    if (this.basketRequest) {
      this.basketRequest.unsubscribe();
    }

    return new Promise((res, rej) => {
      this.basketRequest = this.authHttp
          .post(this.env.apiPath + 'basket', dataToSend)
          .subscribe(({ data }: any) => res(data));
    });
  }

  applyVoucher(voucherCode) {
    return this.http.get(this.env.apiPath + 'voucher/code/' + voucherCode).pipe(map((data: any) => data));
  }

  removeVoucher() {
    const basket: Basket = this.store.selectForLocal<Basket>('basket');
    basket.voucher = undefined;
    basket.voucherCodeEntered = '';
    this.store.set('basket', basket);
    // Todo implement remove voucher Endpoint
    this.calculateBasketTotal();
  }

  processBasket(basket?: Basket) {
    basket = basket ?? this.store.selectForLocal<Basket>('basket');
    const basketId = this.getBasketId();
    const dataToSend = {
      basket,
      basketId,
    };

    // if (this.env.allowSeparateShipments) {
    //     return this.http
    //         .post(this.env.apiPath + 'orders/create/separate-shipments/', dataToSend)
    //         .pipe(map((data: any) => data));
    // } else {
    //     return this.http.post(this.env.apiPath + 'orders/create/', dataToSend).pipe(map((data: any) => data));
    // }
    return this.http.post(this.env.apiPath + 'orders/create/', dataToSend).pipe(map((data: any) => data));
  }

  processBasket2Order(basket?): Observable<any> {
    return this.processBasket(basket).pipe(
        tap(() => {
          // do cleaning basket stuff
          this.deleteIntent();
          this.renewBasket();
        }),
        map(({ data: orderResult }: any) => orderResult)
    );
  }

  buildProductsArray(basketItems, brand) {
    const products = [];

    for (const basketItem of basketItems) {
      let rangeName = '';

      if (basketItem.product.range) {
        rangeName = basketItem.product.range.name;
      }

      products.push({
        ...(basketItem.sample
          ? {
              name: basketItem.product.name + (basketItem.sample ? '-sample' : ''),
              price: 0,
            }
          : {
              name: basketItem.product.name,
              price: basketItem.unitPrice,
              discount: basketItem.totalDiscount,
            }
        ),
        id: basketItem.skuId,
        brand,
        list_name: rangeName,
        category: basketItem.product.typeId,
        variant: basketItem.product.typeId,
        quantity: basketItem.qty
      });
    }

    return products;
  }

  buildProductsArrayFbq(basket) {
    const products = [];

    for (let i = 0; i < basket.items.length; i++) {
      let rangeName = '';
      if (basket.items[i].product.range) {
        rangeName = basket.items[i].product.range.name;
      }

      const product = {
        content_name: basket.items[i].product.name,
        id: basket.items[i].product.id,
        sku: basket.items[i].skuId,
        quantity: basket.items[i].qty,
      };
      products.push(product);
    }

    // and samples
    for (let i = 0; i < basket.samples.length; i++) {
      let rangeName = '';

      if (basket.samples[i].product.range) {
        rangeName = basket.samples[i].product.range.name;
      }

      const product = {
        name: `${basket.samples[i].product.name} - sample`,
        id: basket.samples[i].product.id,
        sku: basket.samples[i].skuId,
        price: 0,
        brand: 'Upero',
        list_name: rangeName,
        category: basket.samples[i].product.typeId,
        variant: basket.samples[i].product.typeId,
        quantity: basket.samples[i].qty,
      };
      products.push(product);
    }

    return products;
  }
}
