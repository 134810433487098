<div class="d-flex flex-column justify-content-center align-items-center h-100"
     [ngClass]="block['fields']['backgroundClass']">

  <h2 class="fw-bold text-black mb-4">
    {{block['fields']['title']}}
  </h2>
  <a [uperoBrandRouterLink]="block['fields']['button-link']" class="btn fw-bold" [ngClass]="block['fields']['button-class']">
    {{block['fields']['title']}}
  </a>
</div>
