import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BasketService, ChatService, NotificationService } from '@upero/services';
import { BaseBlockComponent, SignoutFields } from '@upero/misc';
import { AppInitService } from '@upero/routing';

@Component({
    selector: 'upero-signout',
    templateUrl: './signout.component.html',
    styleUrls: ['./signout.component.scss']
})
export class SignoutComponent extends BaseBlockComponent<SignoutFields> implements OnInit {

    constructor(
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService,
        private basketService: BasketService,
        private chatService: ChatService,
    ) {
      super();
    }

    ngOnInit(): void {
    }

    async signOut() {
        this.chatService.sendOfflineSignal();
        this.notificationService.stopListening();
        this.basketService.removeVoucher();
        this.authService.logout().subscribe();
        await this.basketService.calculateBasketTotal();
        this.router.navigate(AppInitService.doBrandModUrl(['/']));
    }
}
