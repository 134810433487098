<div *ngIf="gallery" class="position-relative overflow-hidden bg-primary">
    <div class="keen-slider overflow-visible" #sliderRef>
        <div
            class="keen-slider__slide preview-tile d-flex flex-column align-items-center"
            [ngClass]="{'active' : gi === currentSlide}"
            *ngFor="let pi of gallery.contents.images; let gi = index; let first = first">
            <div class="bg-image">
                <img class="image-as-bg" [src]="pi.url" [appScrset]="pi.url" [width]="510">
                <div *ngIf="first" class="content">
                    <div class="text-center mb-2">
                        <h2 class="fs-4 mb-0 text-white text-shadow">{{gallery.contents.title}}</h2>
                    </div>
                    <div class="text-center mb-5">
                        <a class="btn btn-wide btn-white"
                           [uperoBrandRouterLink]="['/products', gallery.contents.productLink]">
                            View Product
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showDots" class="dots d-flex justify-content-center py-4">
            <button
                (click)="slider.moveToSlideRelative(i)"
                *ngFor="let slide of dotHelper; let i = index"
                [class]="'dot ' + (i === currentSlide ? 'active' : '')"
            ></button>
        </div>
    </div>

    <ng-container *ngIf="showNav">
        <svg-icon
            src="assets/images/icon-arrow-slider-left.svg"
            [ngClass]="'arrow arrow--left ' + ( currentSlide === 0 ? 'arrow--disabled' : '')"
            [style.fill]="arrowColour"
            (click)="slider.prev()"
            aria-hidden="true"
            focusable="false">
        </svg-icon>
        <svg-icon
            *ngIf="slider"
            src="assets/images/icon-arrow-slider-right.svg"
            [ngClass]="'arrow arrow--right ' + (slider.details().size - 1 === currentSlide ? 'arrow--disabled' : '')"
            [style.fill]="arrowColour"
            (click)="slider.next()"
            aria-hidden="true"
            focusable="false">
        </svg-icon>
    </ng-container>
</div>
