import { NgModule } from '@angular/core';
import { BlocksService } from './blocks.service';
import { BlockLoaderComponent } from './block-loader/block-loader.component';
import { ImageComponent } from './image/image.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { UspComponent } from './usp/usp.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { CtaBannerComponent } from './cta-banner/cta-banner.component';
import { CtaBannerImageComponent } from './cta-banner-image/cta-banner-image.component';
import { GridComponent } from './grid/grid.component';
import { TrustpilotSliderComponent } from './trustpilot-slider/trustpilot-slider.component';
import { CtaContactComponent } from './cta-contact/cta-contact.component';
import { FooterComponent } from './footer/footer.component';
import { TestComponent } from './test/test.component';
import { CtaButtonTextComponent } from './cta-button-text/cta-button-text.component';
import { ProductsGridComponent } from './products-grid/products-grid.component';
import { ImageTextSideComponent } from './image-text-side/image-text-side.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PageIntroComponent } from './page-intro/page-intro.component';
import { RouterModule } from '@angular/router';
import { TrustpilotWidgetComponent } from './trustpilot-widget/trustpilot-widget.component';
import { MiscModule } from '@upero/misc';
import { FaqComponent } from './faq/faq.component';
import { AdviceComponent } from './advice/advice.component';
import { BrandVideoComponent } from './brand-video/brand-video.component';
import { WysiwygComponent } from './wysiwyg/wysiwyg.component';
import { CmsGridComponent } from './cms-grid/cms-grid.component';
import { JobTilesComponent } from './job-tiles/job-tiles.component';
import { SharedModule } from '@upero/shared';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ProductRangesComponent } from './product-ranges/product-ranges.component';
import { ProductTypesComponent } from './product-types/product-types.component';
import { LoyalityPageIntroComponent } from './loyality-page-intro/loyality-page-intro.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { NewsletterCtaComponent } from './newsletter-cta/newsletter-cta.component';
import { SpecialOffersComponent } from './special-offers/special-offers.component';
import { TabsTermsComponent } from './tabs-terms/tabs-terms.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { SignUpOrLoginComponent } from './sign-up-or-login/sign-up-or-login.component';
import { GallerySliderComponent } from './gallery-slider/gallery-slider.component';
import { ProductTypesSliderComponent } from './product-types-slider/product-types-slider.component';
import { QualityEtchedComponent } from './quality-etched/quality-etched.component';
import { PromoCaseStudyComponent } from './promo-case-study/promo-case-study.component';
import { BasketComponent } from './basket/basket.component';
import { SignoutComponent } from './signout/signout.component';
import { RegisterComponent } from './register/register.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import {
    RegistrationFormTradeAccountComponent
} from './registration-form-trade-account/registration-form-trade-account.component';
import { ProductRangeDetailComponent } from './product-range-detail/product-range-detail.component';
import { ProductGroupDetailComponent } from './product-group-detail/product-group-detail.component';
import { FaqDetailComponent } from './faq-detail/faq-detail.component';
import { AdviceDetailComponent } from './advice-detail/advice-detail.component';
import { BasketBillingComponent } from './basket-billing/basket-billing.component';
import { BasketPaymentComponent } from './basket-payment/basket-payment.component';
import { BasketOrderConfirmationComponent } from './basket-order-confirmation/basket-order-confirmation.component';
import { BasketReloadComponent } from './basket-reload/basket-reload.component';
import { BlogCategoriesComponent } from './blog-categories/blog-categories.component';
import { ArticleComponent } from './article/article.component';
import { BlogCategoryComponent } from './blog-category/blog-category.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { BespokeFormPageComponent } from './bespoke-form-page/bespoke-form-page.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import {
    PasswordResetConfirmationComponent
} from './password-reset-confirmation/password-reset-confirmation.component';
import { PasswordSetComponent } from './password-set/password-set.component';
import { PasswordSetConfirmationComponent } from './password-set-confirmation/password-set-confirmation.component';
import { BespokeBuilderComponent } from './bespoke-builder/bespoke-builder.component';
import { CollectionEntriesGridComponent } from './collections-entries-grid/collections-entries-grid.component';
import { CollectionsEntrySingleComponent } from './collections-entry-single/collections-entry-single.component';
import { ImageTextBlockComponent } from './image-text-block/image-text-block.component';
import { PartnersCarouselComponent } from './partners-carousel/partners-carousel.component';
import { BrandsSliderComponent } from './brands-slider/brands-slider.component';
import { SpacesSliderComponent } from './spaces-slider/spaces-slider.component';
import { FeaturedProductsSliderComponent } from './featured-products-slider/featured-products-slider.component';
import { ProductSpacesComponent } from './product-spaces/product-spaces.component';
import { BrandsModule } from '@upero/brands';
import { SpacesComponent } from './spaces/spaces.component';
import { ProductGroupsComponent } from './product-groups/product-groups.component';
import { UperoVideoComponent } from './upero-video/upero-video.component';
import { UperoBrandsCarouselComponent } from './upero-brands-carousel/upero-brands-carousel.component';
import { UperoFooterComponent } from './upero-footer/upero-footer.component';
import { BlogPostSingleComponent } from './blog-post-single/blog-post-single.component';
import {
    BasketDeliveryInformationComponent
} from './basket-delivery-information/basket-delivery-information.component';
import { BasketDeliveryComponent } from './basket-delivery/basket-delivery.component';
import { BasketOrderApprovalComponent } from './basket-order-approval/basket-order-approval.component';
import { NgReplacePipeModule } from 'angular-pipes';
import { InViewportModule } from '@thisissoon/angular-inviewport/dist';
import { AccountConfirmationComponent } from './account-confirmation/account-confirmation.component';

@NgModule({
    imports: [
        GoogleMapsModule,
        RouterModule,
        MiscModule,
        SharedModule,
        BrandsModule,
        NgReplacePipeModule,
        InViewportModule
    ],
    providers: [BlocksService],
    declarations: [
        AccountConfirmationComponent,
        BlockLoaderComponent,
        ImageComponent,
        NavbarComponent,
        ImageSliderComponent,
        UspComponent,
        RangeSliderComponent,
        CtaBannerComponent,
        CtaBannerImageComponent,
        GridComponent,
        TrustpilotSliderComponent,
        TrustpilotWidgetComponent,
        CtaContactComponent,
        FooterComponent,
        TestComponent,
        CtaButtonTextComponent,
        ProductsGridComponent,
        ImageTextSideComponent,
        ContactFormComponent,
        GoogleMapsComponent,
        PageIntroComponent,
        FaqComponent,
        AdviceComponent,
        CollectionEntriesGridComponent,
        BrandVideoComponent,
        WysiwygComponent,
        CmsGridComponent,
        JobTilesComponent,
        BreadcrumbComponent,
        ProductRangesComponent,
        ProductTypesComponent,
        LoyalityPageIntroComponent,
        FavouritesComponent,
        NewsletterCtaComponent,
        SpecialOffersComponent,
        TabsTermsComponent,
        LoginFormComponent,
        SignUpOrLoginComponent,
        GallerySliderComponent,
        ProductTypesSliderComponent,
        QualityEtchedComponent,
        PromoCaseStudyComponent,
        BasketComponent,
        BasketBillingComponent,
        BasketPaymentComponent,
        BasketDeliveryInformationComponent,
        BasketDeliveryComponent,
        SignoutComponent,
        RegisterComponent,
        RegistrationFormComponent,
        RegistrationFormTradeAccountComponent,
        ProductRangeDetailComponent,
        ProductGroupDetailComponent,
        FaqDetailComponent,
        AdviceDetailComponent,
        BasketOrderConfirmationComponent,
        BasketReloadComponent,
        BlogCategoriesComponent,
        ArticleComponent,
        BlogCategoryComponent,
        ProductDetailsComponent,
        BespokeFormPageComponent,
        BespokeBuilderComponent,
        PasswordResetComponent,
        PasswordResetConfirmationComponent,
        PasswordSetComponent,
        PasswordSetConfirmationComponent,
        CollectionsEntrySingleComponent,
        ImageTextBlockComponent,
        PartnersCarouselComponent,
        BrandsSliderComponent,
        SpacesSliderComponent,
        FeaturedProductsSliderComponent,
        ProductSpacesComponent,
        SpacesComponent,
        ProductGroupsComponent,
        UperoVideoComponent,
        UperoBrandsCarouselComponent,
        UperoFooterComponent,
        BlogPostSingleComponent,
        BasketOrderApprovalComponent
    ],
    exports: [BlockLoaderComponent, GoogleMapsComponent],
})
export class BlocksModule
{
}
