import { InjectionToken } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries

export type EnvironmentType = {
  production: boolean,
  version: string,
  imageUploadPath: string,
  cndReplacePaths: string[],
  apiPath: string,
  socketPath: string,
  ga: { trackingCode: string, initCommands: Array<string>, isEnabled: boolean },
  fbPixelId: { pixelID: string, isEnabled: boolean },
  siteId: number,
  domain: string,
};

export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentType>(
  'Project environment configuration',
);
