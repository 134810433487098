import { Component, HostBinding, Input } from '@angular/core';
import { BaseBlockComponent, CmsGridFields } from '@upero/misc';

@Component({
  selector: 'upero-cms-grid',
  templateUrl: './cms-grid.component.html',
  styleUrls: ['./cms-grid.component.scss']
})
export class CmsGridComponent extends BaseBlockComponent<CmsGridFields>
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @HostBinding('style') get styles()
  {
    return { height: this.block.rows * 205 + 'px' };
  };

  constructor()
  {
    super();
    this.defaultFields = CmsGridFields.default();
  }
}
