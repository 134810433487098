<section class="bg-slate">
    <div class="container pt-3 pb-5 py-md-0 py-xxl-3 mb-5 my-md-0">
        <div class="row">
            <div class="col-12 col-md-6 col-xxl-7 d-flex align-items-center mb-5 mb-md-0">
                <div class="position-relative text-center w-100 h-100 px-4 py-md-5 py-lg-5">
                    <ng-container *ngIf="(deviceType$| async) !== 'mobile'">
                        <img src="https://ys-assets.b-cdn.net/cms/images/assets/quality-etched.png"
                             [appScrset]="'https://ys-assets.b-cdn.net/cms/images/assets/quality-etched.png'"
                             [width]="1000"
                             class="img-fluid img-cover"/>
                    </ng-container>
                    <ng-container *ngIf="(deviceType$| async) === 'mobile'">
                        <img src="https://ys-assets.b-cdn.net/cms/images/assets/quality-etched-mobile.png"
                             [appScrset]="'https://ys-assets.b-cdn.net/cms/images/assets/quality-etched.png'"
                             [width]="1000"
                             class="img-fluid img-cover"/>
                        <h2
                            class="heading--mobile fs-1 fw-500 w-100 text-white text-shadow text-center">
                            Quality is etched<br/>into everything we do
                        </h2>
                    </ng-container>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xxl-5 d-flex align-items-center justify-content-center pe-xxl-5 ">
                <div class="px-3 py-md-5 p-lg-5 me-xxl-5 text-center text-md-start">
                    <h2 *ngIf="(deviceType$| async) !== 'mobile'" class="fw-500 text-white mb-4">Quality is etched<br/>into
                        everything we do</h2>
                    <p class="text-white mb-4 mb-md-5">
                        When it comes to stone, you can rest assured that ours is second to none. British made and
                        crafted, we give you quality that’s guaranteed, an unrivalled 5 star service and free nationwide
                        delivery and our foundations are as solid as the stone that we have been meticulously crafting
                        for over 10 years.
                    </p>
                    <a class="btn btn-wide btn-light fw-bold"
                       [uperoBrandRouterLink]="['/why-choose-us']">Why choose us?</a>
                </div>
            </div>
        </div>
    </div>
</section>
