<section [ngClass]="{ 'overflow-hidden': showOverflow }">
  <div [ngStyle]="{backgroundColor: block.fields.backgroundColour}">
    <div class="container-fluid container--1920 g-0 px-4 py-5 px-md-5">
      <h2 class="fs-5 fw-600 text-white text-center text-xl-start px-1 px-lg-2 mb-5">
        Our Brands
      </h2>
      <div class="overflow-wrapper">
        <div class="position-relative">
          <div class="keen-slider" #sliderRef [ngClass]="{'overflow-visible': showOverflow}">
            <div
              class="keen-slider__slide text-center px-1 px-lg-2"
              [ngClass]="{'active' : gi === currentSlide}"
              *ngFor="let brand of brands; let gi = index"
              (click)="currentSlide = gi">
              <a [uperoRouterLinkSwipeProtected]="['/', brand.slug]">
                <div class="square-image">
                  <img class="image-as-bg hover"
                       [src]="brand.logo ? brand.logo : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                       [appScrset]="brand.logo ? brand.logo : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                       [width]="700"
                       [alt]="brand.name">
                </div>
              </a>
              <div class="mt-4 mb-3">
                <a [uperoRouterLinkSwipeProtected]="['/', brand.slug]"
                   class="fs-5 fw-600 title text-white">
                  {{brand.name}}
                </a>
              </div>
              <a class="btn btn-wide btn-primary mb-3"
                 [uperoRouterLinkSwipeProtected]="['/', brand.slug]">
                View the brand
              </a>
            </div>
          </div>
          <ng-container *ngIf="showNav">
            <div (click)="prevSlide($event)"
                 (mouseenter)="arrowPreviousState(true)"
                 (mouseleave)="arrowPreviousState(false)"
                 [ngClass]="'arrow arrow--left arrow-title-slider ' + ( currentSlide === 0 ? 'arrow--disabled' : '')">
              <svg-icon
                [src]="arrowLeftSrc"
                [style.stroke]="arrowColour"
                [style.fill]="arrowColour">
              </svg-icon>
            </div>

            <div (click)="nextSlide($event)"
                 (mouseenter)="arrowNextState(true)"
                 (mouseleave)="arrowNextState(false)"
                 [ngClass]="'arrow arrow--right arrow-title-slider ' + ((currentSlide + slidesPerView) === brands.length ? 'arrow--disabled' : '')">
              <div class="arrow-animation__container arrow-animation--pulse"
                   [ngClass]="{'animation--start': highlightAnimation}"
                   snInViewport
                   (inViewportChange)="onInViewportChange($event)">
                <svg-icon
                  *ngIf="slider"
                  [src]="arrowRightSrc"
                  [style.stroke]="arrowColour"
                  [style.fill]="arrowColour">
                </svg-icon>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="showDots" class="d-flex justify-content-center d-xl-none pt-5">
          <button
            (click)="slider.moveToSlideRelative(i)"
            *ngFor="let slide of dotHelper; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"
          ></button>
        </div>
      </div>
    </div>
  </div>
</section>
