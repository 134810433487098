// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageJson from '../../../../package.json';

export const environment = {
  version: packageJson.version,
  production: false,
  //imageUploadPath: 'https://www.yorkstonesupplies.co.uk/api/',
  // apiPath: 'https://www.yorkstonesupplies.co.uk/api/', // regular serve
  imageUploadPath: 'https://api.ysdev.co.uk/', // development
  cndReplacePaths: ['https://api.ysdev.co.uk/-not-replacing-dev', 'https://ys-assets.b-cdn.net/'],
  apiPath: 'https://api.ysdev.co.uk/', // development
  socketPath: 'https://api.ysdev.co.uk/',
  // apiPath: 'http://localhost:3000/',
  //apiPath: 'http://localhost:4200/dev-api/', // ONLY SSR serve
  //socketPath: 'https://www.yorkstonesupplies.co.uk/api/',
  stripeKey: 'pk_test_51Lyu9cJzDw4QhyWFMGQgVo2XTfcYCJ03ysOBdIGqK800LdObRPuvTYtVamX3Javtk1KQuBebWSh2SKSb6KTcb0r300sQMgU7VO',
  ga: { trackingCode: '', initCommands: [], isEnabled: false },
  fbPixelId: { pixelID: '', isEnabled: false },
  siteId: 1,
  emulateSslForLocalDev: true,
  domain: 'up',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
