<div class="gallery__container">
  <div class="navigation-wrapper">
    <div class="keen-slider d-flex flex-column justify-content-end align-items-center" #sliderRef>
        <ng-container *ngIf="block.fields.slides && isBrowser">
          <div class="keen-slider__slide preview-tile d-flex flex-column justify-content-center align-items-center"
            [ngClass]="{'active' : gi === currentSlide}"
            *ngFor="let image of block.fields.slides; let gi = index; let first = first">
            <img class="image-as-bg" [appScrset]="image.image" [width]="1920" [doCndReplace]="true">
            <!--
            <div *ngIf="first"
                 class="icon-slider-wrapper--trustpilot d-flex flex-column align-items-center align-items-md-end mb-4 mb-md-0">
              <svg-icon src="assets/images/icon-trustpilot-text-white.svg"
                        [svgClass]="'icon-slider--trustpilot'"></svg-icon>
              <div class="ff-spectral text-white mt-2" [ngClass]="{'small': (deviceType$| async) === 'mobile'}">
                Excellent on Trustpilot
              </div>
            </div>
            -->
            <div *ngIf="block.fields.showGradient" class="bg-gradient-overlay"></div>
            <div class="slider__container d-flex flex-column justify-content-center align-items-start my-auto">
              <div *ngIf="image.text"
                   class="slider__title ff-spectral text-white text-shadow fw-bold mb-3 mb-sm-5 mb-xl-3 mb-xxl-2"
                   [innerHTML]="image.text"></div>
              <upero-button [button]="image['slideButton']" [type]="'url'"></upero-button>
            </div>
            <div *ngIf="(deviceType$| async) !== 'mobile'" class="slider__container__spacer"></div>
          </div>

          <ng-container *ngIf="block && block.fields.slides.length > 1">
            <div (click)="prevSlide($event)"
                 (mouseenter)="arrowPreviousState(true)"
                 (mouseleave)="arrowPreviousState(false)"
                 class="arrow-homepage-slider arrow--left">
              <svg-icon
                [src]="arrowLeftSrc"
                [style.stroke]="block.fields.arrowColour"
                [style.fill]="block.fields.arrowColour">
              </svg-icon>
            </div>

            <div (click)="nextSlide($event)"
                 (mouseenter)="arrowNextState(true)"
                 (mouseleave)="arrowNextState(false)"
                 class="arrow-homepage-slider arrow--right">
              <svg-icon
                *ngIf="slider"
                [src]="arrowRightSrc"
                [style.stroke]="block.fields.arrowColour"
                [style.fill]="block.fields.arrowColour">
              </svg-icon>
            </div>
          </ng-container>

          <div class="dots position-relative my-4" *ngIf="block && block.fields.slides.length > 1">
            <button
              (click)="slider.moveToSlideRelative(i)"
              *ngFor="let slide of dotHelper; let i = index"
              [class]="'dot ' + (i === currentSlide ? 'active' : '')"
            ></button>
          </div>
        </ng-container>
    </div>
  </div>

  <div class="navigation-wrapper" *ngIf="!isBrowser && block.fields.slides">
    <div class="keen-slider d-flex flex-column justify-content-end align-items-center">
      <div class="keen-slider__slide preview-tile d-flex flex-column justify-content-center align-items-center active ssr">
        <img class="image-as-bg" [src]="block.fields.slides[0].imageUrl" [appScrset]="block.fields.slides[0].imageUrl" [width]="1920">
        <div class="icon-slider-wrapper--trustpilot d-flex flex-column align-items-center align-items-md-end mb-4 mb-md-0">
          <svg-icon src="assets/images/icon-trustpilot-text-white.svg"
                    [svgClass]="'icon-slider--trustpilot'"></svg-icon>
          <div class="ff-spectral text-white mt-2" [ngClass]="{'small': (deviceType$| async) === 'mobile'}">
            Excellent on Trustpilot
          </div>
        </div>
        <div class="bg-gradient-overlay"></div>
        <div class="slider__container d-flex flex-column justify-content-center align-items-center my-auto">
          <div *ngIf="block.fields.slides[0].text"
               class="slider__title ff-spectral text-white text-shadow mb-3 mb-sm-5 mb-xl-4"
               [innerHTML]="block.fields.slides[0].text"></div>
          <a *ngIf="block.fields.slides[0].linkPageId > 0"
             class="btn btn-warning fw-bold"
             [href]="block.fields.slides[0].routeUrl"
             [uperoBrandRouterLink]="[block.fields.slides[0].routeUrl]">
            {{block.fields.slides[0].linkText}}
          </a>
        </div>
        <div *ngIf="(deviceType$| async) !== 'mobile'" class="slider__container__spacer"></div>
      </div>

      <ng-container *ngIf="block.fields.slides && block.fields.showNav">
        <div class="arrow-homepage-slider arrow--left">
          <svg-icon
            [src]="arrowLeftSrc"
            [style.stroke]="block.fields.arrowColour"
            [style.fill]="block.fields.arrowColour">
          </svg-icon>
        </div>

        <div class="arrow-homepage-slider arrow--right">
          <svg-icon
            *ngIf="slider"
            [src]="arrowRightSrc"
            [style.stroke]="block.fields.arrowColour"
            [style.fill]="block.fields.arrowColour">
          </svg-icon>
        </div>
      </ng-container>

      <div class="dots position-relative my-4" *ngIf="block.fields && block.fields.showDots">
        <button
          (click)="slider.moveToSlideRelative(i)"
          *ngFor="let slide of dotHelper; let i = index"
          [class]="'dot ' + (i === currentSlide ? 'active' : '')"
        ></button>
      </div>
    </div>
  </div>
</div>
