import { Component, HostBinding, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseBlockComponent, CaseStudyFields } from '@upero/misc';
import { CmsService, CaseStudyService } from '@upero/services';
import { Store } from '@upero/store';
import { CollectionsService } from '@upero/collections';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-case-study',
  templateUrl: './collections-entries-grid.component.html',
  styleUrls: ['./collection-entries-grid.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: '0' })),
      transition('void <=> *', [
        animate('600ms ease-in')
      ]),
    ]),
  ]
})
export class CollectionEntriesGridComponent extends BaseBlockComponent<CaseStudyFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  deviceType$ = this.store.select<string>('deviceType');

  params = {
    limit: 12,
    page: 0,
  };

  items = [];

  nextPage: number;
  moreEntries = true;

  constructor(
    private cmsService: CmsService,
    private caseStudyService: CaseStudyService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private collectionsService: CollectionsService,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.activatedRoute.params.subscribe(params =>
    {
      const page = params.page;
      if (page) {
        this.params.page = page;
        this.nextPage = +page + 1;

        if (page === '0') {
          this.router.navigate(AppInitService.doBrandModUrl(['/case-studies']));
        }
      } else {
        this.nextPage = 1;
      }
    });

    this.search();
  }

  search()
  {
    this.collectionsService.getCollectionEntriesByType(this.block.fields.collectionType, 1)
      .subscribe((response: []) => {
        this.items = response;

        if (response.length < this.params.limit) {
          this.moreEntries = false;
        }
      })
  }

  showMore(event)
  {
    event.preventDefault();
    if (this.moreEntries) {
      this.params.page++;
      this.collectionsService.getCollectionEntriesByTypePaged(this.block.fields.collectionType, 1, this.params.page, this.params.limit).subscribe((response: [])  =>
      {
        const caseStudies = response;
        if (caseStudies.length > 0) {
          caseStudies.forEach(caseStudy =>
          {
            this.items.push(caseStudy);
          });

          this.location.go('/case-studies/page/' + this.nextPage);
          this.nextPage++;

          if (response.length < this.params.limit) {
            this.moreEntries = false;
          }
        } else {
          this.moreEntries = false;
        }
      });
    }
  }
}
