import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService, CmsService } from '@upero/services';
import { BaseBlockComponent, NewsletterCtaFields } from '@upero/misc';

@Component({
  selector: 'upero-newsletter-cta',
  templateUrl: './newsletter-cta.component.html',
  styleUrls: ['./newsletter-cta.component.scss']
})
export class NewsletterCtaComponent extends BaseBlockComponent<NewsletterCtaFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  newsletterForm: FormGroup;
  newsletterFormData = {
    email: '',
  };
  submitted = false;
  duplicateEmail = false;

  constructor(
    private formBuilder: FormBuilder,
    private cmsService: CmsService,
    private alertService: AlertService,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.newsletterForm = this.formBuilder.group({
      confirm: [false, Validators.requiredTrue],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get field()
  {
    return this.newsletterForm.controls;
  }

  onSubmit()
  {
    this.submitted = true;

    if (this.newsletterForm.invalid) {
      return;
    }

    this.newsletterFormData.email = this.newsletterForm.controls.email.value;
    this.cmsService.sendSubscriberForm(this.newsletterFormData).subscribe(data =>
      {
        if (data.success) {
          this.newsletterForm.reset();
          this.submitted = false;
          this.duplicateEmail = false;
          this.alertService.notification(
            ['We promise to send you latest news, competitions, offers and more, straight to your inbox!'],
            4000,
            'Thanks for subscribing!'
          );
        }
      },
      error =>
      {
        this.newsletterForm.reset();
        this.submitted = false;
        this.duplicateEmail = true;
      });
  }

}
