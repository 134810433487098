import { Component, Input } from '@angular/core';

@Component({
  selector: 'upero-thumbnail-tile',
  templateUrl: './thumbnail-tile.component.html',
  styleUrls: ['./thumbnail-tile.component.scss']
})
export class ThumbnailTileComponent
{
  @Input() imageUrl; // pg.imageUrl
  @Input() urlBase; // '/product-groups'
  @Input() slug; // pg.slug
  @Input() name; // pg.name
  @Input() tileText; // pg.tileText
  @Input() hasProducts; // pg.hasProducts
  @Input() pricesFrom; // pg.pricesFrom
  @Input() ctaTxt; // block.fields['productButton'].text
  @Input() ctaBtnClass; // buttonClass
  @Input() ctaBtnSize; // this.block.fields.productButton['size']
  @Input() isPriceFromVisible=true;

  constructor()
  {
  }
}
