import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BgSetterDirective } from './directives/bg-setter.directive';
import { TextColorSetterDirective } from './directives/text-color-setter.directive';
import { SvgColorSetterDirective } from './directives/svg-color-setter.directive';
import { ButtonComponent } from "./components/button/button.component";
import { PipesModule } from "@upero/pipes";
import { RouterModule } from "@angular/router";
import { BtnColorSetterDirective } from './directives/btn-color-setter.directive';
import { LinkColorSetterDirective } from './directives/link-color-setter.directive';

@NgModule({
    imports: [CommonModule, PipesModule, RouterModule],
    declarations: [
        BgSetterDirective,
        TextColorSetterDirective,
        SvgColorSetterDirective,
        ButtonComponent,
        BtnColorSetterDirective,
        LinkColorSetterDirective
    ],
    exports: [
        BgSetterDirective,
        TextColorSetterDirective,
        SvgColorSetterDirective,
        ButtonComponent,
        BtnColorSetterDirective,
        LinkColorSetterDirective
    ]
})
export class BrandsModule
{
}
