import { Inject, Injectable } from '@angular/core';
import {map, Observable, ReplaySubject} from 'rxjs';
import { AuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class PagesService
{
  private slugCache = {};

  constructor(
    private onlyAuthHttp: AuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  ) {
  }

  getPageSlugsFlat(): Observable<any>
  {
    return this.onlyAuthHttp.get(this.env.apiPath + 'cms-pages/flat')
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getPages(): Observable<any>
  {
    return this.onlyAuthHttp.get(this.env.apiPath + 'cms-pages')
      .pipe(
        map((data: any) => {
            return data.data;
          },
        ));
  }

  getPage(id: number): Observable<any>
  {
    return this.onlyAuthHttp.get(this.env.apiPath + 'cms-pages/' + id);
  }

  getPageBySlug(slug: string): Observable<any>
  {
    if (!this.slugCache[slug]) {
      this.slugCache[slug] = new ReplaySubject<any>();
      this.onlyAuthHttp
        .get(this.env.apiPath + 'cms-pages?slug=' + slug + '&siteId=' + this.env.siteId)
        .subscribe(result => this.slugCache[slug].next(result));
    }

    return this.slugCache[slug];
  }

  getPageBlocks(id: number): Observable<any>
  {
    return this.onlyAuthHttp.get(this.env.apiPath + 'cms-pages/' + id + '/page-blocks');
  }

}
