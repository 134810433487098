import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ENVIRONMENT_CONFIG, EnvironmentType, ApiAuthHttpClient } from '@upero/misc';
import { Store } from '@upero/store';

@Injectable({
  providedIn: 'root'
})
export class BlogService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  getCategories()
  {
    return this.http
      .get(this.env.apiPath + 'blog/categories')
      .pipe(map((data: any) => data.data));
  }

  getCategory(slug)
  {
    return this.http
      .get(this.env.apiPath + 'blog/categories/' + slug)
      .pipe(map((data: any) => data.data));
  }

  getArticlesByCategory(params)
  {
    return this.http
      .post(this.env.apiPath + 'blog/categories/search', { params })
      .pipe(map((data: any) => data.data));
  }

  getArticle(slug)
  {
    return this.http
      .get(this.env.apiPath + 'blog/article/' + slug)
      .pipe(map((data: any) => data.data));
  }
}
