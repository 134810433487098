import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ProductService } from '@upero/services';
import { Store } from '@upero/store';
import { BaseBlockComponent, ProductTypesSliderFields } from '@upero/misc';

@Component({
  selector: 'upero-product-types-slider',
  templateUrl: './product-types-slider.component.html',
  styleUrls: ['./product-types-slider.component.scss']
})
export class ProductTypesSliderComponent extends BaseBlockComponent<ProductTypesSliderFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @Input()
  set backgroundColour(bgColor: string) {
    this.block.backgroundColour = bgColor;
  }

  slidesPerView = 4;
  productGroups = [];
  showOverflow = true;
  deviceType$ = this.store.select<string>('deviceType');

  constructor(
    private productsService: ProductService,
    private store: Store
  )
  {
    super();
    this.defaultFields = ProductTypesSliderFields.default();
  }

  ngOnInit(): void
  {
    const deviceType = this.store.selectForLocal('deviceType');

    if (deviceType === 'mobile') {
      this.slidesPerView = 1;
    }
    if (deviceType === 'tabletV') {
      this.slidesPerView = 2;
    }
    if (deviceType === 'tabletH') {
      this.slidesPerView = 4;
    }
    this.productsService.getProductGroups().subscribe(data => {
      const productGroups = [];
      const productGroupIds = this.block.fields.productGroupIds.flatMap(group => group.id);
      data.data.forEach(group => {
        if (productGroupIds.includes(group.id)) {
          productGroups.push(group);
        }

        if (group.subGroups.length > 0) {
          group.subGroups.forEach(subGroup => {
            if (productGroupIds.includes(subGroup.id)) {
              productGroups.push(subGroup);
            }
          });
        }
      })
      this.productGroups = productGroups;
    });
  }
}
