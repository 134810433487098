import { Injectable } from '@angular/core';
import { BehaviorSubject, first } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToolsService
{
  private toolsEvents$: BehaviorSubject<'clearTempStorages' | 'clearUserStorages' | null> = new BehaviorSubject(null);
  readonly toolsEvents$$ = this.toolsEvents$.asObservable(); // only reading this

  constructor() {}

  sessionId() {
    const sessionId = localStorage.getItem('tmpSessionId');
    if (sessionId) {
      return sessionId;
    }

    const newSessionId = this.newUUID();
    // set the tmpSessionId that will persist login and reference work done whilst signed out
    localStorage.setItem('tmpSessionId', newSessionId);
    return this.setSessionId(newSessionId);
  }

  setSessionId(sessionId) {
    localStorage.setItem('sessionId', sessionId);
    return sessionId;
  }

  logoutSessionId() {
    localStorage.setItem('sessionId', localStorage.getItem('tmpSessionId'));

    return this;
  }

  clearTempStorages(ignore: Array<string> = []): Promise<ToolsService> {
    return new Promise((res, rej) => {
      this.toolsEvents$$.pipe(filter(item => item === 'clearTempStorages'), first()).subscribe(() => {
        Object.keys(localStorage).forEach((item) => {
          if (item.indexOf('temp') !== -1 && ignore.indexOf(item) === -1) {
            localStorage.removeItem(item);
          }
        });
        res(this)
      });
      this.toolsEvents$.next('clearTempStorages');
    });
  }

  clearUserStorages(): Promise<ToolsService> {
    return new Promise((res, rej) => {
      this.toolsEvents$$.pipe(filter(item => item === 'clearUserStorages'), first()).subscribe(() => res(this));
      this.toolsEvents$.next('clearUserStorages');
    });
  }

  newUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  getMonths() {
    return [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ];
  }

  getYears(noOfYears) {
    const dateToday = new Date();
    const currentYear = dateToday.getFullYear();
    const years = [];
    for (let i = currentYear; i < currentYear + noOfYears; i++) {
      years.push(i);
    }
    return years;
  }

  getMonthNames() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  addDaysToDate(theDate, days, excludeWeekends) {
    let endDate = new Date(),
      count = 0;
    while (count < days) {
      endDate = new Date(theDate.setDate(theDate.getDate() + 1));
      if (excludeWeekends) {
        if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
          count++;
        }
      } else {
        count++;
      }
    }
    return endDate;
  }
}
