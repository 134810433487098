<div class="container mb-5">
    <div class="row justify-content-center">
        <div class="col-lg-8">
            <div *ngIf="content" class="bg-light p-4" #scrollTabAnchor>
                <div class="row">
                    <div class="col-12 col-md-4 order-0 order-md-reset">
                        <button (click)="setNavTab('consumers')"
                                role="button"
                                class="btn btn-tab ff-spectral fs-6 fw-bold w-100 px-4 py-3 px-md-1 py-md-4"
                                [ngClass]="{'active': navTab === 'consumers'}">
                            <svg-icon *ngIf="navTab !== 'consumers' && (deviceType$| async) === 'mobile'"
                                      class="me-3"
                                      src="assets/images/icon-chevron-right.svg"></svg-icon>
                            <svg-icon *ngIf="navTab === 'consumers' && (deviceType$| async) === 'mobile'"
                                      class="me-3"
                                      src="assets/images/icon-chevron-down.svg"></svg-icon>
                            {{content.customer.title}}
                        </button>
                    </div>

                    <div class="col-12 col-md-4 order-2 order-md-reset">
                        <button (click)="setNavTab('business')"
                                role="button"
                                class="btn btn-tab ff-spectral fs-6 fw-bold w-100 px-4 py-3 px-md-1 py-md-4"
                                [ngClass]="{'active': navTab === 'business'}">
                            <svg-icon *ngIf="navTab !== 'business' && (deviceType$| async) === 'mobile'"
                                      class="me-3"
                                      src="assets/images/icon-chevron-right.svg"></svg-icon>
                            <svg-icon *ngIf="navTab === 'business' && (deviceType$| async) === 'mobile'"
                                      class="me-3"
                                      src="assets/images/icon-chevron-down.svg"></svg-icon>
                            {{content.trade.title}}
                        </button>
                    </div>

                    <div *ngIf="navTab === 'consumers'" class="tab--custom col-12 order-1 order-md-reset">
                        <div class="bg-white p-4 px-5" [innerHTML]="content.customer.text"></div>
                    </div>

                    <div *ngIf="navTab === 'business'" class="tab--custom col-12 order-3 order-md-reset">
                        <div class="bg-white p-4 px-5" [innerHTML]="content.trade.text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
