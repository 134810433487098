import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { Store } from '@upero/store';
import { OverlayService } from '@upero/services';
import { AppInitService } from '@upero/routing';
import { debounce } from '@upero/misc';

interface MenuItem
{
  name: string;
  slug: string
}

@Component({
  selector: 'upero-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
  animations: [
    trigger('slideLeftRight', [
      state('void', style({ transform: 'translateX(-100%)', opacity: '0.9' })),
      transition('void <=> *', [
        animate('200ms')
      ])
    ]),
    trigger('slideMenuPage', [
      state('void', style({ transform: 'translateX(-100%)', opacity: '0' })),
      transition(':enter', [
        animate('200ms')
      ])
    ]),
  ]
})
export class MenuMobileComponent implements OnInit, OnDestroy
{
  @Input() items;
  @Input() productGroups;
  settings$ = this.store.select<any>('settings');
  basket$ = this.store.select<any>('basket');
  deviceType$ = this.store.select<string>('deviceType');
  favourites$ = this.store.select<string>('favourites');
  currentBrand = this.store.selectForLocal('currentBrand');
  user$ = this.store.select<any>('user');
  activeSubMenu: Array<{
    name: string,
    route: Array<string>,
    subSubMenu?: Array<{ name: string, route: Array<string> }>
  }> = [];
  activeSubSubMenu: Array<{
    name: string,
    route: Array<string>
  }> = [];
  isOpen = true;
  selectedRoute: string | null = null;

  onSlideLeftRightEnd()
  {
    if (!this.isOpen) {
      this.close();
    }
  }

  constructor(
    private store: Store,
    private overlayService: OverlayService,
    public router: Router,
    private renderer: Renderer2,
  )
  {
    this.startClosing = debounce(this.startClosing, 300);
  }

  ngOnInit(): void
  {
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  ngOnDestroy()
  {
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }

  backToMainMenu()
  {
    this.activeSubMenu = [];
  }

  back2LevelMenu()
  {
    this.activeSubSubMenu = [];
  }

  toggleSubMenu(subMenu: Array<any>)
  {
    this.activeSubMenu = subMenu;
  }

  toggleMenuUser()
  {
    this.startClosing();
    this.store.set('mobileMenuUser', true);
  }

  showProductGroups($event)
  {
    $event.preventDefault();
    this.activeSubMenu = this.productGroups.map(group => ({
      name: group.name,
      route: ['/product-groups', group.slug],
      subSubMenu:
        group.subGroups.length === 0
          ? []
          : group.subGroups.map(group => ({ name: group.name, route: ['/product-groups', group.slug] }))
    }));
  }

  showSpaces($event)
  {
    $event.preventDefault();
    this.activeSubMenu = this.currentBrand.spaces.map(space => ({
      name: space.name,
      route: ['/spaces', space.slug ? space.slug : '']
    }));
  }

  showSubSubMenu($event, subMenu)
  {
    $event.preventDefault();
    $event.stopPropagation();
    this.activeSubSubMenu = subMenu;
  }

  startClosing()
  {
    this.isOpen = false;
  }

  close()
  {
    this.store.set('mobileMenu', false);
    this.overlayService.closeAll();
    if (this.selectedRoute) {
      this.router.navigateByUrl(AppInitService.doBrandModByUrl(this.selectedRoute));
    }
  }

  goToRouteWithDelay(event, route: string)
  {
    event.preventDefault();
    this.selectedRoute = route;
    this.startClosing();
  }
}
