import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class SeoService
{

  constructor(
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private doc,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  getMetaTags()
  {
    // const metaEl: HTMLMetaElement = this.metaService.getTag('name= "keywords"');
  }

  updateTitle(title)
  {
    this.titleService.setTitle('Upero');
  }

  addMetaTag(name, content)
  {
    this.metaService.updateTag({ name, content });
  }

  createLinkForCanonicalURL()
  {
    // remove the previous
    const element = this.doc.getElementById('canseo');
    if (element) {
      element.remove();
    }

    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('id', 'canseo');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);

    let url = this.doc.URL;

    if (this.doc.URL.indexOf('/?') > -1) {
      url = this.doc.URL.substring(0, this.doc.URL.indexOf('/?'));
    }

    link.setAttribute('href', url.endsWith('/') ? url.slice(0, -1) : url);
  }
}
