import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { delay, forkJoin, Observable, Subject, take } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Socket } from 'ngx-socket-io';
import { OverlayService, UserService } from '@upero/services';
import { Store } from '@upero/store';
import { ApiAuthHttpClient } from '@upero/misc';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { debounce } from '@upero/misc';
import { ChatMessage } from '../ws-models/chat.model';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  static CUSTOMER_SERVICE_ID = 'd6e37e44-ae9b-4c45-89f5-a3508970b876';
  chatRooms = [];

  public scrollToBottom$ = new Subject<void>();

  private socket: Socket;
  isBrowser = false;

  constructor(
    private store: Store,
    private http: ApiAuthHttpClient,
    private overlayService: OverlayService,
    private injector: Injector,
    private userSerive: UserService,

    @Inject(PLATFORM_ID) platformId: {},
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.socket = this.injector.get<Socket>(Socket);
      this.socket.on('connect', () => {
        for (const room of this.chatRooms) {
          this.joinRoom(room, true);
        }
      });
    }
    this.addChatMessage = debounce(this.addChatMessage, 110);
  }

  sendChat(message) {
    message.sessionId = '';
    message.domainId = this.env.domain;
    if (message.recipients) {
      message.recipients = message.recipients.map((r) => {
        return { ...r, id: r.userId };
      });
    }
    message.chatId = message.groupId;

    if (this.isBrowser) {
      const sessionId = this.store.selectForLocal('sessionId');

      if (sessionId) {
        message.sessionId = sessionId;
      }

      message.source = 'client';

      return this.http.post<void>(this.env.apiPath + 'chat-admin/message', { message }).subscribe();
    }
  }

  sendOnlineSignal() {
    const user = this.store.selectForLocal('user');
    if (user) {
      this.socket.emit('userOnlineStatus', { id: user.id, online: true });
    }
  }

  sendOfflineSignal() {
    const user = this.store.selectForLocal('user');
    if (user) {
      this.socket.emit('userOnlineStatus', { id: user.id, online: false });
    }
  }

  public sendTypingSignal(data): void {
    this.socket.emit('userTyping', data);
  }

  public receiveTypingSignal(): Observable<void> {
    return this.socket.fromEvent('userTyping').pipe(
      tap((data: any) => {
        const chatStore = this.store.selectForLocal('chatStore');
        if (!chatStore.group.typing) {
          chatStore.group.typing = [];
        }
        const user = this.getChatUser();
        if (user.id !== data.user.id) {
          if (data.groupId === chatStore.group.id) {
            if (data.typing) {
              let userFound = false;
              for (let i = 0; i < chatStore.group.typing.length; i++) {
                if (chatStore.group.typing[i].id === data.user.id) {
                  userFound = true;
                }
              }
              if (!userFound) {
                chatStore.group.typing.push(data.user);
              }
            }
            if (!data.typing) {
              for (let i = 0; i < chatStore.group.typing.length; i++) {
                if (chatStore.group.typing[i].id === data.user.id) {
                  chatStore.group.typing.splice(i, 1);
                }
              }
            }
          }
          this.store.set('chatStore', chatStore);
        }
      })
    );
  }

  receiveChat() {
    if (this.isBrowser) {
      this.getGroups().subscribe();

      this.socket.fromEvent('chatMessageAdded').subscribe((message: any) => {
        if (message.type === 'chat' || message.type === 'file') {
          this.addChatMessage(message); // delaying processing for a 100ms until image is ready
        }

        if (message.type === 'chatClose') {
          const chatStore = this.store.selectForLocal('chatStore');

          for (let i = 0; i < chatStore.groups.length; i++) {
            if (chatStore.groups[i].id === message.groupId) {
              chatStore.groups[i].isClosed = 1;

              if (!chatStore.group) {
                chatStore.group = chatStore.groups[i];
                this.getByGroupId(chatStore.groups[i].id, false).subscribe((data) => {
                  chatStore.messages = data.data;
                });
              }
            }
          }
          if (chatStore.group && chatStore.group.id === message.groupId) {
            chatStore.group.isClosed = 1;
          }

          this.store.set('chatStore', chatStore);
        }

        if (message.type === 'chatOpen') {
          const chatStore = this.store.selectForLocal('chatStore');
          for (let i = 0; i < chatStore.groups.length; i++) {
            if (chatStore.groups[i].id === message.groupId) {
              chatStore.groups[i].isClosed = 0;
            }
          }
          if (chatStore.group && chatStore.group.id === message.groupId) {
            chatStore.group.isClosed = 0;
          }

          this.store.set('chatStore', chatStore);

          this.getGroups().subscribe(() => {});
        }
      });
      // return this.socket.fromEvent('chat');
    }
  }

  addChatMessage(message) {
    const chatStore = this.store.selectForLocal('chatStore');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < chatStore.groups.length; i++) {
      if (message.chatId === chatStore.groups[i].id && message.source !== 'client') {
        if (!chatStore.group || chatStore.groups[i].id !== chatStore.group.id) {
          chatStore.groups[i].unread++;
        }
      }
    }

    if (chatStore.group) {
      if (message.chatId === chatStore.group.id) {
        message.isActionModeEnable = false;
        message.isEditMode = false;
        if (message.source === 'client') {
          // message received is delivered from server
          chatStore.messages[chatStore.messages.length - 1] = message;
        } else {
          chatStore.messages.push(message);
        }
      }
    }

    chatStore.unread = 0;
    chatStore.groups.forEach((g) => {
      chatStore.unread = +chatStore.unread + +g.unread;
    });

    this.store.set('chatStore', chatStore);

    this.getGroups().subscribe(() => {
      this.scrollBottom();
    });
  }

  getByGroupId(groupId, saveToStore) {
    return this.http.get(this.env.apiPath + 'chat/public/' + groupId).pipe(
      map((data: any) => {
        if (saveToStore) {
          const chatStore = this.store.selectForLocal('chatStore');
          const chatData = data.data;
          chatData.map((cData) => {
            cData.isEditMode = false;
            cData.isActionModeEnable = false;
          });
          chatStore.messages = chatData;

          this.store.set('chatStore', chatStore);
        }

        return data;
      })
    );
  }

  getGroups() {
    const userId = this.store.selectForLocal('user')?.id || localStorage.getItem('tmpSessionId');
    return this.http.get(this.env.apiPath + 'chat-admin/groups/' + userId).pipe(
      map((data: any) => {
        const chatStore = this.store.selectForLocal('chatStore');
        chatStore.groups = data.data;
        chatStore.unread = 0;

        chatStore.groups.forEach((g) => {
          this.joinRoom(g.id);
          if (+g.unread > 0) {
            chatStore.unread = +chatStore.unread + +g.unread;
          }
        });
        this.joinRoom(userId);

        this.store.set('chatStore', chatStore);
        return data;
      })
    );
  }

  joinRoom(roomName: string, reInit = false) {
    if (this.chatRooms.indexOf(roomName) === -1) {
      // initial join
      this.chatRooms.push(roomName);
      this.socket.emit('join', { domain: this.env.domain, room: roomName });
    } else if (reInit) {
      this.socket.emit('join', { domain: this.env.domain, room: roomName });
    }
  }

  markAsRead(groupId) {
    const userId = this.store.selectForLocal('user')?.id || localStorage.getItem('tmpSessionId');
    return this.http.delete(this.env.apiPath + 'chat/' + groupId + '/' + userId).pipe(map((data: any) => data));
  }

  scrollBottom() {
    this.scrollToBottom$.next();
  }

  startChatGroupFromWithinChat(recipient, recipientType, sender) {
    const isGuest = !sender.username;
    const email = isGuest ? sender.email : sender.contact.email;
    const senderName = isGuest ? sender.name : sender.contact.firstname + ' ' + sender.contact.surname;
    const dataToSend = {
      channelName: senderName,
      user: {
        id: sender.id,
        name: senderName,
        senderType: '',
        email,
        firstname: isGuest ? sender.name.split(' ')[0] : sender.contact.firstname,
        surname: isGuest ? sender.name.split(' ')[1] : sender.contact.surname,
      },
      recipient: {
        id: recipient.id,
        recipientType,
      },
    };

    this.userSerive.createLeadBasedOnGuestData({
      email,
      firstname: dataToSend.user.firstname,
      surname: dataToSend.user.surname,
      source: 'public',
    });

    return this.http
      .post(this.env.apiPath + 'chat-admin/group/create-group', dataToSend)
      .pipe(map((data: any) => data));
  }

  initChatWithCustomerService(introMessage: string) {
    // @todo maybe it could be simplified, but I don't know whole ligic here so leaving to the person who knows it.
    const sender = this.store.selectForLocal('user');
    const chatStore = this.store.selectForLocal('chatStore');

    forkJoin({
      chat: this.startChatGroupFromWithinChat({ id: ChatService.CUSTOMER_SERVICE_ID }, '', sender).pipe(
        map((data: any) => data.data)
      ),
      groups: this.getGroups(),
    }).subscribe(({ chat: data }) => {
      chatStore.subjects = data.subjects;
      this.store.set('chatStore', chatStore);
      const group = chatStore.groups.filter((grp: any) => grp.id === data.groupId)[0];

      this.getByGroupId(group.id, true).subscribe(() => {
        chatStore.group = group;
        chatStore.group.unread = 0;
        chatStore.unread = 0;
        chatStore.groups.forEach((g) => {
          chatStore.unread = +chatStore.unread + +g.unread;
        });
        this.store.set('chatStore', chatStore);
        this.markAsRead(group.id).subscribe();
        const message = {
          groupId: group.id,
          createdAt: new Date(),
          type: 'chat',
          user: {
            id: ChatService.CUSTOMER_SERVICE_ID,
            name: 'Customer Services',
            companyId: '',
          },
          isActionModeEnable: false,
          isEditMode: false,
          content: introMessage,
        };
        chatStore.messages.push(message);
        this.store.set('chatStore', chatStore);
        this.sendChat(message);
        this.getGroups().pipe(delay(1000), take(1)).subscribe();
      });

      this.overlayService.toggle('chat');
    });
  }

  openChat(groupId) {
    return this.http.get(this.env.apiPath + 'chat-admin/groups/open/' + groupId).pipe(map((data: any) => data));
  }

  sendTranscript(group) {
    const user = this.store.selectForLocal('user');
    let email = '';

    if (user) {
      email = user.username;
    } else {
      email = group.createdByEmail;
    }

    const dataToSend = {
      groupId: group.id,
      email,
    };
    return this.http.post(this.env.apiPath + 'chat/groups/transcript', dataToSend).pipe(map((data: any) => data));
  }

  getChatUser() {
    const user = this.store.selectForLocal('user');
    let chatUser;

    if (!user) {
      chatUser = {
        id: localStorage.getItem('tmpSessionId'),
        name: localStorage.getItem('tmpSessionName'),
      };
    } else {
      chatUser = {
        id: user.id,
        name: `${user.contact.firstname} ${user.contact.surname}`,
      };
    }
    return chatUser;
  }

  public receiveDeleteSignal(): Observable<ChatMessage> {
    return this.socket.fromEvent<ChatMessage>('didMessageDelete').pipe(
      tap((message) => {
        const chatStore = this.store.selectForLocal('chatStore');
        const index = (chatStore.messages as any[]).findIndex((msg) => msg.id === message.id);
        if (index >= 0) {
          (chatStore.messages as any[]).splice(index, 1);
          this.store.set('chatStore', chatStore);
        }
      })
    );
  }

  public receiveEditSignal(): Observable<ChatMessage> {
    return this.socket.fromEvent<ChatMessage>('didMessageEdit').pipe(
      tap((message) => {
        const chatStore = this.store.selectForLocal('chatStore');
        const index = (chatStore.messages as any[]).findIndex((msg) => msg.id === message.id);
        if (index >= 0) {
          (chatStore.messages as any[])[index].content = message.content;
          (chatStore.messages as any[])[index].edited = message.edited;
          (chatStore.messages as any[])[index].editedAt = message.editedAt;
          this.store.set('chatStore', chatStore);
        }
      })
    );
  }

  public sendDeleteSignal(message: any): void {
    this.socket.emit('doMessageDelete', message); // { id: messageId, channelData: this.getChannelData() });
  }

  editMessage(message) {
    return this.http.put<void>(this.env.apiPath + 'chat-admin/message', { message }).subscribe();
  }
}
