<div class="spacer"></div>
<section #headerMobile id="header" class="header">
    <div class="container-mobile-menu shadow-sm d-flex flex-column justify-content-center h-100 px-4">
        <div class="row">
            <div class="col-3 d-flex align-items-center col-md-4">
                <div class="w-100 d-flex">
                    <a class="header-sub-item-icon" (click)="toggleMenu()">
                        <svg-icon src="assets/images/icon-menu.svg"></svg-icon>
                    </a>

                    <a class="header-sub-item-icon me-0"
                       gaEvent="click_email_address" gaCategory="Contact" gaBind="click"
                       fbqEvent="click_email_address" fbqBind="click"
                       href="tel:{{siteSettings.settings.contact.phone}}">
                        <svg-icon src="assets/images/icon-phone.svg"></svg-icon>
                    </a>
                </div>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center col-md-4 mx-auto">
                <a class="d-flex flex-column align-items-center justify-content-center" [uperoBrandRouterLink]="['/']">
                    <svg-icon src="assets/images/upero.svg" [svgClass]="'header__logo-img'"></svg-icon>

                    <div *ngIf="currentBrand" class="ff-cou text-dark ms-2 fw-light small">
                      {{currentBrand.name}}
                    </div>
                </a>
            </div>
            <div *ngIf="!(user$ | async)" class="col-3 col-md-4 d-flex align-items-center">
                <div class="w-100 d-flex justify-content-end">
                    <a *ngIf="(deviceType$| async) !== 'mobile'" [uperoBrandRouterLink]="['/my-favourites']"
                       class="header-sub-item-icon">
                      <svg-icon src="assets/images/icon-heart.svg"></svg-icon>
                      <span class="badge-count"
                            *ngIf="(favourites$ | async).length">{{(favourites$ | async).length}}</span>
                    </a>
                    <a class="header-sub-item-icon " [uperoBrandRouterLink]="['/basket']">
                        <svg-icon src="assets/images/icon-cart.svg"></svg-icon>
                        <div class="badge-count"
                             *ngIf="(basket$ | async).items.length || (basket$ | async).samples.length">{{(basket$ | async).items.length + (basket$ | async).samples.length}}</div>
                    </a>
                     <a uperoDropdown class="header-sub-item-icon me-0 dropdown" href="javascript:void(0)">
                        <svg-icon src="assets/images/icon-user.svg"></svg-icon>
                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-user shadow-sm px-4 py-3">
                            <a [uperoBrandRouterLink]="['/login']"
                               class="dropdown-item fw-bold small px-0 mb-2">
                                Sign in
                            </a>
                            <a [uperoBrandRouterLink]="['/register']"
                               class="dropdown-item fw-bold px-0 small mb-2">
                                Create account
                            </a>
                        </div>
                    </a>
                </div>
            </div>
            <div *ngIf="(user$ | async)" class="col-3 col-md-4 d-flex align-items-center">
                <div class="w-100 d-flex justify-content-end">
                    <a *ngIf="(deviceType$| async) !== 'mobile'" [uperoBrandRouterLink]="['/my-favourites']"
                       class="header-sub-item-icon">
                        <svg-icon src="assets/images/icon-heart.svg"></svg-icon>
                        <span class="badge-count"
                              *ngIf="(favourites$ | async).length">{{(favourites$ | async).length}}</span>
                    </a>
                    <a class="header-sub-item-icon" [uperoBrandRouterLink]="['/basket']">
                        <svg-icon src="assets/images/icon-cart.svg"></svg-icon>
                        <div class="badge-count"
                             *ngIf="(basket$ | async).items.length || (basket$ | async).samples.length">{{(basket$ | async).items.length + (basket$ | async).samples.length}}</div>
                    </a>
                    <a class="header-sub-item-icon me-0" (click)="toggleMenuUser()">
                        <svg-icon src="assets/images/icon-user.svg"></svg-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
