<div class="cms-grid"
     *ngIf="block.fields.type === 'grid'"
     [ngStyle]="{'background-image': 'url(' + block.backgroundImage + ')'}"
     [ngClass]="[block.fields.container, block.fields.paddingH, block.fields.paddingV, block.backgroundColour ? block.backgroundColour : '']"
    >
    <div class="row equal h-100" [ngClass]="{'g-0' : !block.fields.gridGutters, 'grid-row-mobile-reverse': block.fields.reverseOnMobile}">
        <ng-container *ngFor="let g of block.fields.grid">
            <div class="col-md-6 d-flex flex-column align-items-center justify-content-center snippet-container"
                *ngIf="!g.backgroundImage"
                [ngClass]="[g.paddingH, g.paddingV, g.backgroundColour, g.classes]"
                [innerHTML]="g.content | safeHTML">
            </div>
            <div class="col-md-6 d-flex flex-column align-items-center snippet-container justify-content-center cms-image-tile"
                *ngIf="g.backgroundImage"
                [ngStyle]="{'background-image': 'url(' + g.backgroundImage + ')'}"
                [ngClass]="[g.paddingH, g.paddingV, g.backgroundColour, g.classes]"
                [innerHTML]="g.content | safeHTML">
            </div>
        </ng-container>
    </div>
</div>
