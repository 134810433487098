<div class="contact-cta  h-100 py-5" [ngClass]="block.fields.backgroundClass">
  <div class="d-flex flex-column justify-content-center align-items-center py-5">
    <h2 class="fs-3 fw-600 text-white mb-3">
      {{block.fields.title}}
    </h2>
    <div class="fs-4 text-white text-center mb-4">
      Call
      <a class="text-white text-decoration-underline"
         href="tel: {{siteSettings.settings.contact.phone}}">
        {{siteSettings.settings.contact.phone}}
      </a>
      or email
      <a class="text-white text-decoration-underline"
         href="mailTo: {{siteSettings.settings.contact.email}}">
        {{siteSettings.settings.contact.email}}
      </a>
    </div>
    <upero-button [button]="block.fields.buttonCta"></upero-button>
  </div>
</div>
