import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {BlogService, BreadcrumbsService} from '@upero/services';
import { ArticleFields, BaseBlockComponent } from '@upero/misc';
import {defer, first, iif, map, mergeMap, of} from "rxjs";
import {Store} from "@upero/store";
import {CollectionsService} from "@upero/collections";
import {PagesService} from "@upero/pages";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'upero-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent extends BaseBlockComponent<ArticleFields> implements OnInit, OnDestroy
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  article;
  category;
  otherArticles = [];
  navigationSubscription;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private collectionsService: CollectionsService,
    private pagesService: PagesService,
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.collectionsService
      .getPageBySlugType(this.route.snapshot.params.slug, this.block.fields.collectionType)
      .pipe(
        mergeMap((data: any) =>
          iif(
            () => data.length > 0,
            of(data),
            defer(() => this.pagesService.getPageBySlug('404')),
          )
        ),
        map(data => data[0]),
        first()
      )
      .subscribe(entry => {
        this.article = entry;
        this.titleService.setTitle(entry.contents.title);
        this.breadcrumbsService.changeBreadcrumb([
          {text: entry.contents.title, route: ['/case-studies']},
        ]);

      });
  }

  ngOnDestroy()
  {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  getArticle()
  {
/*    this.blogService.getArticle(this.route.snapshot.params.slug).subscribe(data =>
    {
      this.article = data;
      this.getCategory();
    });*/
  }

  getCategory()
  {
    /*this.blogService.getCategory(this.route.snapshot.params.slug).subscribe(data =>
    {
      this.category = data;
      this.otherArticles = [];
      this.category.articles.forEach(otherArticle =>
      {
        if (otherArticle.slug !== this.article.slug) {
          this.otherArticles.push(otherArticle);
        }
      });
    });*/
  }
}
