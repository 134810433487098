import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@upero/store';
import {
  ActivatedRoute,
  ActivationEnd,
  Router
} from '@angular/router';
import {
  AppService,
  BreadcrumbsService,
  ChatService,
  CmsService,
  MenuService,
  OverlayService,
  SeoService
} from '@upero/services';
import { Meta, Title } from '@angular/platform-browser';
import { defer, filter, first, iif, map, mergeMap, of, startWith, Subject, takeUntil } from 'rxjs';
import { PagesService } from "@upero/pages";
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'upero-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit, OnDestroy
{
  overlays$ = this.store.select<any>('overlays');
  asideOpen$ = this.store.select<boolean>('asideOpen');
  alerts$ = this.store.select<any>('alerts');
  innerWidth$ = this.store.select<number>('innerWidth');
  deviceType$ = this.store.select<string>('deviceType');
  mobileMenu$ = this.store.select<boolean>('mobileMenu');
  mobileMenuUser$ = this.store.select<boolean>('mobileMenuUser');
  siteSettings = this.store.selectForLocal('siteSettings');

  settings$ = this.store.select('settings');
  page: any;

  title = 'public';
  isMobile = true;
  routerObserver;
  private destroy$ = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize()
  {
    this.appService.getDeviceSize();
  }

  @HostListener('keydown.escape', ['$event']) onKeydownHandler()
  {
    this.overlayService.closeAll();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler()
  {
    this.chatService.sendOfflineSignal();
  }

  constructor(
    private appService: AppService,
    private chatService: ChatService,
    private breadcrumbsService: BreadcrumbsService,
    private store: Store,
    public overlayService: OverlayService,
    private router: Router,
    private seoService: SeoService,
    private cmsService: CmsService,
    private menuService: MenuService,
    private titleService: Title,
    private route: ActivatedRoute,
    private pagesService: PagesService,
    private metaService: Meta,
    @Inject(PLATFORM_ID) platformId: any
  )
  {
    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd),
        startWith(this.router),
        takeUntil(this.destroy$)
      )
      .subscribe((event) =>
      {
        if (event instanceof ActivationEnd) {
          let brandToStore: any = null;

          const path = event.snapshot.routeConfig?.path ?? '';
          let url = '';

          event.snapshot.url.forEach((segment, index) =>
          {
            if (index === 0) {
              brandToStore = this.siteSettings.brands.find(brand => brand.slug === segment.path);
            }
            url += '/' + segment.path;
          })

          if (brandToStore || path === '') {
            if (isPlatformBrowser(platformId)) {
              localStorage.setItem('currentBrand', brandToStore?.id ?? null);
            }
            this.store.set('currentBrand', brandToStore);
          }

          this.pagesService
            .getPageBySlug(path === '**' ? '404' : path)
            .pipe(
              mergeMap(data =>
                iif(
                  () => data.data.length > 0,
                  of(data),
                  defer(() => this.pagesService.getPageBySlug((brandToStore ? brandToStore.slug + '/' : '') + '404')),
                )
              ),
              map(data => data.data[0]),
              first()
            )
            .subscribe(page =>
            {
              this.page = page;
              this.titleService.setTitle(this.page.seoTitle ? this.page.seoTitle : this.page.title);
              this.metaService.addTag({ name: 'description', content: this.page.seoDescription });
              this.seoService.createLinkForCanonicalURL();
              this.breadcrumbsService.changeBreadcrumb([
                { text: this.page.title, route: url },
              ]);

            });
        }
      });
  }

  ngOnInit(): void
  {
    this.menuService.menuGroups();
    this.appService.initApp();
  }

  ngOnDestroy()
  {
    // preventing memory leak
    this.destroy$.next()
  }

  menuByRoute(route)
  {
    this.menuService.menuByRoute(route);
  }

  getPageContent(route)
  {
    const routeToFind = route.split('?')[0];
    const pages = this.store.selectForLocal('pages');
    if (pages) {
      this.page = pages[routeToFind];
      if (this.page) {
        this.titleService.setTitle(this.page.seoTitle ? this.page.seoTitle : this.page.title);
        this.metaService.addTag({ name: 'description', content: this.page.seoDescription });
        this.seoService.createLinkForCanonicalURL();
      }
    }
  }
}

