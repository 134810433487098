export interface State
{
  bespokePricingLog: any[];
  innerWidth: number;
  deviceType: string;
  settings: any;
  basketId: string;
  basket: any;
  lastBasketItemAdded: any;
  deliveryAddresses: any[];
  address: any;
  addressType: string;
  chatStore: any;
  notifications: any;
  signInRoute: string;
  RegisteredInBasketFlow: boolean;
  navigation: any;
  navigationHistory: string[];
  accountType: string;
  protectedRoute: boolean;
  confirmationEmail: string;
  favourites: any[];
  companyUsers: any;
  companyRequests: any[];
  menus: any[];
  menu: any;
  subMenu: any[];
  activeMenu: any;
  activeRoute: string;
  pages: any;
  pageSnippets: any[];
  alerts: any;
  device: any;
  guestUser: any;
  user: any;
  userToEdit: any;
  asideOpen: boolean;
  growler: any;
  overlays: any;
  overlayData: any;
  cms: any;
  categories: any[];
  mobileMenu: boolean;
  mobileMenuUser: boolean;
  mobileMenuAccount: boolean;
  search: boolean;
  searchText: string;
  searchResults: any[];
  token: string | null;
  loggedIn: boolean;
  enableQuoteBackend: boolean;
  productToView: any;
}
