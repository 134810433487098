import { Component, OnDestroy, OnInit, } from '@angular/core';
import { BaseBlockComponent, IntoPageFields } from '@upero/misc';
import { CmsService } from '@upero/services';
import { filter, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'upero-page-intro',
  templateUrl: './page-intro.component.html',
  styleUrls: ['./page-intro.component.scss']
})
export class PageIntroComponent extends BaseBlockComponent<IntoPageFields> implements OnDestroy, OnInit
{
  private _destroyed = new Subject();

  constructor(
    private cmsService: CmsService
  )
  {
    super();
    cmsService.broadCastBlockData
      .pipe(
        filter(data => data !== null && this.block && data.target === this.block.type),
        takeUntil(this._destroyed)
      )
      .subscribe((data =>
      {
        const pageIntroData = data.data as IntoPageFields;
        this.block.fields.backgroundImgSrc = pageIntroData.backgroundImgSrc ? pageIntroData.backgroundImgSrc : this.block.fields.backgroundImgSrc;
        this.block.fields.title = pageIntroData.title ? pageIntroData.title : this.block.fields.title;
        this.block.fields.text = pageIntroData.text ? pageIntroData.text : this.block.fields.text;
      }))
    //this.defaultFields = IntoPageFields.default();
  }

  ngOnInit()
  {

  }

  ngOnDestroy(): void
  {
    this._destroyed.next(true);
  }
}
