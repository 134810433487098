import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@upero/store';
import { BrandUrlPipe } from './brand-url.pipe';

@Pipe({ name: 'modBrandStrUrl' }) // log variable into the console
export class BrandStrUrlPipe implements PipeTransform {
  private brandUrlPipe;

  constructor(public store: Store) {
    this.brandUrlPipe = new BrandUrlPipe(store);
  }

  transform(urlStr: string, forceFromRoot: boolean = false): string {
    return urlStr ? this.brandUrlPipe.transform(urlStr.split('/'), forceFromRoot).join('/') : urlStr;
  }
}
