export enum GaActionEnum {
    ADD_PAYMENT_INFO = 'add_payment_info',
    ADD_TO_CART = 'add_to_cart',
    ADD_TO_WISHLIST = 'add_to_wishlist',
    BEGIN_CHECKOUT = 'begin_checkout',
    CHECKOUT_PROGRESS = 'checkout_progress',
    GENERATE_LEAD = 'generate_lead',
    LOGIN = 'login',
    PURCHASE = 'purchase',
    PURCHASE_SAMPLES = 'purchase_samepls',
    CONVERSION = 'conversion',
    REFUND = 'refund',
    REMOVE_FROM_CART = 'remove_from_cart',
    SEARCH = 'search',
    SELECT_CONTENT = 'select_content',
    SET_CHECKOUT_OPTION = 'set_checkout_option',
    SHARE = 'share',
    SIGN_UP = 'sign_up',
    VIEW_ITEM = 'view_item',
    VIEW_ITEM_LIST = 'view_item_list',
    VIEW_PROMOTION = 'view_promotion',
    VIEW_SEARCH_RESULT = 'view_search_results',
    VIEW_SEARCH_RESULTS = 'view_search_results',
}
