<div
  *ngIf="showButtons"
  #fixedElements
  class="fixed-elements">
  <div
    *ngIf="isShow"
    [@enterAnimation]
    class="mb-2">
    <div
      (click)="gotoTop()"
      class="go-to-top shadow"
      [ngClass]="currentBrand ? 'bg-' + currentBrand.settings.colors.primary : 'bg-white'">
      <svg-icon
        src="assets/images/icon-chevron-up.svg"
        [svgClass]="currentBrand ? 'icon_top__white' : 'icon_top__black'"></svg-icon>
    </div>
  </div>
  <div class="whatsup-icon-holder">
    <a
      href="https://wa.me/+441483968218"
      style="display: inline-block; margin-right: 16px; color: #25d366; font-size: 30px">
      <i class="fa-brands fa-whatsapp"></i>
    </a>
  </div>
  <div
    *ngIf="!(overlays$ | async).chat && (isShowChat || showChatInitially)"
    [@enterAnimation]
    [ngClass]="currentBrand ? 'bg-' + currentBrand.settings.colors.primary : 'bg-white'"
    class="quickChatTrigger shadow"
    (click)="triggerQuickChat()">
    <div
      class="badge-count"
      *ngIf="(chatStore$ | async).unread">
      {{ (chatStore$ | async).unread }}
    </div>

    <svg-icon
      src="assets/images/icon-chat-message.svg"
      [svgClass]="currentBrand ? 'icon_chat' : ''"></svg-icon>
  </div>
</div>
