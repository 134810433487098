<!-- Feature section with grid -->
<div class="position-relative pt-3 pt-sm-4 pt-lg-5">
  <div class="mx-auto px-2 text-center px-sm-4 px-lg-5">
    <h1 class="m-3 pb-5 fw-bolder">We’re Upero – here to help you find the very best in outdoor and indoor design.</h1>
    <p class="ff-spectral">
      From authentic English stone paving to elegant furniture and exquisite detailing, Upero is home to carefully curated, well-made products, for the price conscious design lover.
    </p>
    <div class="m-1 m-md-3 m-lg-5 pb-5">
      <div class="gallery">
        <div class="gallery-container">
          <div data-index="1" class="gallery-item gallery-item-1">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-home.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 p-2 cs">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="2" class="gallery-item gallery-item-2">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-timber.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 p-2 cs">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="3" class="gallery-item gallery-item-3">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <a class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-paving.png" alt="Upero Home"></span>
              </a>
            </div>
          </div>
          <div data-index="4" class="gallery-item gallery-item-4">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-tiles.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 p-2 cs">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="5" class="gallery-item gallery-item-5">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-furniture.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 p-2 cs">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="6" class="gallery-item">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-appliances.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 cs p-2">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="7" class="gallery-item">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-decor.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 cs p-2">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="8" class="gallery-item">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-interiors.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 cs p-2">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="9" class="gallery-item">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-kitchens.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 cs p-2">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="10" class="gallery-item">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-lightning.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 cs p-2">Coming soon</span>
              </div>
            </div>
          </div>
          <div data-index="11" class="gallery-item">
            <div class="bg-white rounded shadow-lg border p-3 p-md-5 h-100 w-100">
              <div class="d-flex justify-content-center flex-column" [routerLink]="['/paving']">
                <span><img class="w-auto mx-auto logo" src="/assets/upero/images/sub-logo-outdoor.png" alt="Upero Home"></span>
                <span class="coming-soon op-7 cs  p-2">Coming soon</span>
              </div>
            </div>
          </div>
        </div>
        <div class="gallery-controls">
          <button class="gallery-controls-previous"></button>
          <button class="gallery-controls-next"></button>
        </div>
      </div>
    </div>
  </div>
</div>
