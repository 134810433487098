<div *ngIf="routes.length > 0" class="border-bottom-2" [ngClass]="{'bc-absolute': block.fields.absolute }">
    <div class="container container--breadcrumbs">
        <div id="breadcrumblist" itemscope itemtype="http://schema.org/BreadcrumbList"
             class="breadcrumb d-flex flex-nowrap align-items-center mb-0">
             <a [uperoBrandRouterLink]="'/'" itemscope itemprop="home-item" [ngClass]="block.fields.textColor ? 'text-' + block.fields.textColor : 'text-dark'" itemtype="http://schema.org/ListItem">
                <span itemprop="name">Home</span>
            </a>
            <div class="wrap">
                <span class="links"></span>
            </div>
            <ng-container *ngFor="let routeItem of routes; let first = first; let last = last; let index = index">
                <a *ngIf="!last" [uperoBrandRouterLink]="routeItem.route" [ngClass]="block.fields.textColor ? 'text-' + block.fields.textColor : 'text-dark'" itemscope itemprop="link-item" itemtype="http://schema.org/ListItem">
                    <span itemprop="link-item-name">{{ routeItem.text }}</span>
                </a>
                <div *ngIf="!last" class="wrap"></div>
                <p *ngIf="last" class="text-muted mb-0" itemscope itemprop="last-link-item" itemtype="http://schema.org/ListItem">
                    {{ routeItem.text }}
                </p>
            </ng-container>
        </div>
    </div>
</div>
