<div class='w-100 bg-light py-5'>
  <div class='container'>
    <div class="row g-5 ">
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
      <div class="col-3">
        <div class="bg-as-image mb-3">
          <img src="https://placehold.jp/300x300.png" />
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class='fs-5 fw-bold text-black mb-3'>
            Product Name
          </div>
          <div class='fs-6 mb-3' [ngClass]="block['fields']['price-text-color']">
            Prices from XXX
          </div>
          <button class="btn"  [ngClass]="block['fields']['button-color']">
            Find out more
          </button>
        </div>
      </div>
    </div>
  </div>
</div>




