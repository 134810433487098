<div class="ratio ratio-16x9 position-relative">
    <video
        #videoPlayer
        (playing)="isVideoPlaying = true"
        controls='true'
        loop
        playsinline
        poster="https://ys-assets.b-cdn.net/cms/images/assets/videoframe.png">
        <ng-container *ngIf="(deviceType$| async) === 'mobile'">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-sm.mp4" type="video/mp4">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-sm.mov" type="video/mp4">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-sm.webm" type="video/webm">
        </ng-container>
        <ng-container *ngIf="(deviceType$| async) === 'tabletV' || (deviceType$| async) === 'tabletH'">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-md.mp4" type="video/mp4">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-md.mov" type="video/mp4">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-md.webm" type="video/webm">
        </ng-container>
        <ng-container *ngIf="(deviceType$| async) === 'desktop'">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-lg.mp4" type="video/mp4">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-lg.mov" type="video/mp4">
            <source src="https://ys-assets.b-cdn.net/cms/videos/brand-lg.webm" type="video/webm">
        </ng-container>
    </video>
    <div *ngIf="!isVideoPlaying" class="custom-button-overlay d-flex justify-content-center align-items-center">
        <div class="position-relative d-flex align-items-center">
            <div class="icon--play">
                <svg-icon src="assets/images/icon-play.svg" [svgClass]="'icon--play'"></svg-icon>
            </div>
            <div class="icon--text ff-spectral fw-bold text-uppercase text-white">
                Watch our Team<br>
                in Action
            </div>
        </div>
    </div>
</div>
