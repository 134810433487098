<div class="intro position-relative ff-spectral w-100 d-flex flex-column justify-content-center align-items-center text-center"
     *ngIf="!this.block.fields.hiddenIfEmpty || (block.fields.title || block.fields.text || block.fields.backgroundImgSrc)"
>
  <div class="position-relative z-index-3">
    <h1 class="fw-bold text-white text-shadow mb-4 mb-xl-3">{{block.fields.title}}</h1>
    <div class="intro__text fs-6 fs-lg-4 text-white text-shadow mx-auto">
      {{block.fields.text}}
    </div>
  </div>
  <div class="intro__overlay bg-black position-absolute"></div>
  <img class="image-as-bg" [src]="block.fields.backgroundImgSrc" [appScrset]="block.fields.backgroundImgSrc" [width]="1920">
</div>
