<div class="modal" tabindex="-1">
  <div class="modal-dialog modal-fullscreen-lg-down modal-xl">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="ms-auto">
          <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
        </div>
        <h3 class="modal-title fw-bold text-center">
          <ng-container *ngIf="type === 'billing'">Add New Address</ng-container>
          <ng-container *ngIf="type === 'delivery'">Delivery Address</ng-container>
        </h3>
      </div>
      <div class="modal-body modal--max-width p-3 p-lg-5">
        <upero-address-form
          [requiredFields]="{ add1: true, town: true, postcode: true }"
          (addressCreated)="addressCreated(type, $event)"
          layout="layout-2"
        ></upero-address-form>
      </div>
      <div class="modal-footer">
        <div class="d-flex modal--max-width justify-content-between px-3 px-lg-5">
          <button (click)="close()" type="button" class="btn btn-outline-primary">Cancel</button>
          <button (click)="saveAddress()" type="button" class="btn btn-primary">Add address</button>
        </div>
      </div>
    </div>
  </div>
</div>
