<div class="position-relative">
  <div class="keen-slider" #sliderRef [ngClass]="{'overflow-visible': showOverflow}">
    <div
      class="keen-slider__slide d-flex flex-column align-items-center text-center px-1 px-lg-2"
      [ngClass]="{'active' : gi === currentSlide}"
      *ngFor="let pg of tiles; let gi = index"
      (click)="currentSlide = gi">
      <a [uperoRouterLinkSwipeProtected]="['/product-groups', pg.slug]" class="h-100 w-100">
        <div class="square-image">
          <img class="image-as-bg hover"
               [src]="pg.imageUrl ? pg.imageUrl : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
               [appScrset]="pg.imageUrl ? pg.imageUrl : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
               [width]="700"
               [alt]="pg.name">
        </div>
      </a>
      <div class="mt-4 mb-3">
        <a [uperoRouterLinkSwipeProtected]="['/spaces', pg.slug]"
           class="fs-5 title text-dark">
          {{pg.name}}
        </a>
      </div>
      <p class="op-8 text-dark px-4 mb-4" [innerHTML]="pg.tileText"></p>
      <!--
      <p class="d-flex justify-content-center align-items-center text-dark">
        <span class="small me-2">Prices from</span>
        <span class="fs-5 fw-bold">
                        <ng-container *ngIf="pg.hasProducts">
                            {{pg.pricesFrom | currency: 'GBP'}}
                        </ng-container>
                    </span>
      </p>
      -->
      <a *ngIf="!block" class="btn btn-wide btn-primary mt-auto mb-3"
         [uperoRouterLinkSwipeProtected]="['/spaces', pg.slug]">
        View the space
      </a>
      <div *ngIf="block" class="mt-auto mb-3">
        <upero-button [button]="block.fields.buttonCta" [routerLink]="[currentBrand ? '/' + currentBrand.slug + '/product-groups/' + pg.slug : '/products-groups/' + pg.slug]" class="me-3"></upero-button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showNav">
    <div (click)="prevSlide($event)"
         (mouseenter)="arrowPreviousState(true)"
         (mouseleave)="arrowPreviousState(false)"
         [ngClass]="'arrow arrow--left arrow-title-slider ' + ( currentSlide === 0 ? 'arrow--disabled' : '')">
      <svg-icon
        [src]="arrowLeftSrc"
        [style.stroke]="arrowColour"
        [style.fill]="arrowColour">
      </svg-icon>
    </div>

    <div (click)="nextSlide($event)"
         (mouseenter)="arrowNextState(true)"
         (mouseleave)="arrowNextState(false)"
         [ngClass]="'arrow arrow--right arrow-title-slider ' + ((currentSlide + slidesPerView) === tiles.length ? 'arrow--disabled' : '')">
      <div class="arrow-animation__container arrow-animation--pulse"
           [ngClass]="{'animation--start': highlightAnimation}"
           snInViewport
           (inViewportChange)="onInViewportChange($event)">
        <svg-icon
          *ngIf="slider"
          [src]="arrowRightSrc"
          [style.stroke]="arrowColour"
          [style.fill]="arrowColour">
        </svg-icon>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="showDots" class="d-flex justify-content-center d-xl-none pt-5">
  <button
    (click)="slider.moveToSlideRelative(i)"
    *ngFor="let slide of dotHelper; let i = index"
    [class]="'dot ' + (i === currentSlide ? 'active' : '')"
  ></button>
</div>
