<div class="w-100 bg-slate-5">
    <div *ngIf="user" class="d-flex">
        <div class="sidebar">
            <upero-customer-menu
                *ngIf="user.accountType === 'customer'"
                (navigationChange)="navigationChange($event)"
                [user]="user"></upero-customer-menu>
            <upero-company-menu
              *ngIf="user.accountType !== 'customer'"
              (navigationChange)="navigationChange($event)"
              [user]="user"></upero-company-menu>
        </div>
        <div class="outlet position-relative">
            <upero-account-header *ngIf="(deviceType$| async) === 'desktop'"></upero-account-header>
            <upero-account-header-mobile
                *ngIf="(deviceType$| async) === 'mobile' || (deviceType$| async) === 'tabletV' || (deviceType$| async) === 'tabletH'">
            </upero-account-header-mobile>
            <div class="container min-height g-md-3 g-xl-5 py-5">
                <router-outlet></router-outlet>
                <upero-fixed-elements></upero-fixed-elements>
                <upero-chat *ngIf="(overlays$ | async).chat"></upero-chat>
            </div>
        </div>
    </div>
</div>
<upero-account-menu-mobile *ngIf="(mobileMenuAccount$ | async)" [user]="user"></upero-account-menu-mobile>
<upero-menu-mobile
    *ngIf="(mobileMenu$ | async) && ((deviceType$ | async) === 'mobile' || (deviceType$ | async) === 'tabletV' || (deviceType$ | async) === 'tabletH')"
    [items]="items"
    [productGroups]="productGroups"
></upero-menu-mobile>
<upero-menu-mobile-user
    *ngIf="(mobileMenuUser$ |async) && ((deviceType$ | async) === 'mobile' || (deviceType$ | async) === 'tabletV' || (deviceType$ | async) === 'tabletH')"></upero-menu-mobile-user>

<ng-container #uperoRequestCreate>
    <!--<upero-request-create *ngIf="(overlays$ | async).requestCreate"></upero-request-create>-->
</ng-container>
<ng-container #uperoUserEdit>
    <!--<upero-user-edit *ngIf="(overlays$ | async).userEdit"></upero-user-edit>-->
</ng-container>


<div class="modal-backdrop fade show modal-slide-show"
  (click)="overlayService.closeAll()"
  *ngIf="(asideOpen$ | async)">
  <upero-processing *ngIf="(overlays$ | async).processing"></upero-processing>
</div>
<upero-alert
  [data]="(alerts$ | async)"
  *ngIf="
        (alerts$ | async).errors.length ||
        (alerts$ | async).notifications.length ||
        (alerts$ | async).simple ||
        (alerts$ | async).confirmAction ||
        (alerts$ | async).customAlert
"></upero-alert>
<upero-cookie-consent-dialog></upero-cookie-consent-dialog>
