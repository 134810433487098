import { Pipe, PipeTransform } from '@angular/core';
import Autolinker, { AutolinkerConfig } from 'autolinker';

/*
    Uses Autolinker - to open links in a new window use: | autolinker::{newWindow: true}"
 */

@Pipe({
  name: 'autolinker'
})
export class AutolinkerPipe implements PipeTransform {

    transform(value: string, options?: AutolinkerConfig): string {
        return Autolinker.link(value, options);
    }

}
