<footer class="bg-dark">
  <div class="mx-auto py-3 px-1 overflow-hidden">
    <div class="m-3 d-flex justify-content-center">
      <a href="https://www.facebook.com/weareupero" target="_blank" rel="noopener" class="text-gray-400">
        <div class="social-icon"><i class="fab fa-facebook-f"></i></div>
      </a>

      <a href="https://www.instagram.com/weareupero" target="_blank" rel="noopener" class="text-gray-400 ps-4 pe-4">
        <div class="social-icon me-0"><i class="fab fa-instagram"></i></div>
      </a>

      <a href="https://twitter.com/weareupero" target="_blank" rel="noopener" class="text-gray-400 hover:text-gray-300">
        <div class="social-icon"><i class="fab fa-twitter"></i></div>
      </a>

    </div>
    <nav class="d-flex justify-content-center" aria-label="Footer">
      <div class="px-2 py-1">
        <a [uperoBrandRouterLink]="'/paving/privacy-policy'" class="small link-white ng-star-inserted">
          Privacy Policy
        </a>
      </div>

      <div class="px-2 py-1">
        <a [uperoBrandRouterLink]="'/paving/cookie-policy'" class="small link-white ng-star-inserted">
          Cookie policy
        </a>
      </div>

      <div class="px-2 py-1">
        <a [uperoBrandRouterLink]="'/paving/terms-and-conditions'" class="small link-white ng-star-inserted">
          Terms & Conditions
        </a>
      </div>
    </nav>
    <p class="mt-8 text-center text-base text-gray-400">
      &copy; 2022 Upero all rights reserved.
    </p>
  </div>
</footer>
