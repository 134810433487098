<div
    *ngIf="isBrowser"
    id="trustbox"
    class="trustpilot-widget"
    data-locale="en-GB"
    data-template-id="53aa8807dec7e10d38f59f32"
    data-businessunit-id="571e39130000ff00058c00ac"
    data-style-height="90px"
    data-style-width="100%"
    data-theme="dark">
    <a
        href="https://uk.trustpilot.com/review/yorkstonesupplies.co.uk"
        target="_blank"
        rel="noopener">
        Trustpilot
    </a>
</div>
