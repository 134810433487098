<div *ngIf="images" class="position-relative overflow-hidden bg-dark">
    <div class="keen-slider overflow-visible" #sliderRef>
        <div
            class="keen-slider__slide preview-tile d-flex flex-column align-items-center px-4 py-4 py-lg-5"
            [ngClass]="{'active' : gi === currentSlide}"
            (click)="setCurrentSlide(gi)"
            *ngFor="let pi of images; let gi = index; let first = first">
            <div class="bg-image shadow">
                <img class="image-as-bg" [src]="pi.url" [appScrset]="pi.url" [width]="1000">
                <!--<div *ngIf="first && previewParagraph" class="intro-box ff-spectral text-white p-4">
                    {{ previewParagraph }}
                </div>-->
            </div>
            <div *ngIf="pi.comment" class="credits text-muted small">
                {{ pi.comment }}
            </div>
        </div>
    </div>

    <ng-container *ngIf="showNav">
        <svg-icon
            src="assets/images/icon-arrow-slider-left.svg"
            [ngClass]="'arrow arrow--left ' + ( currentSlide === 0 ? 'arrow--disabled' : '')"
            [style.fill]="arrowColour"
            (click)="slider.prev()"
            aria-hidden="true"
            focusable="false">
        </svg-icon>
        <svg-icon
            *ngIf="slider"
            src="assets/images/icon-arrow-slider-right.svg"
            [ngClass]="'arrow arrow--right ' + (slider.details().size - 1 === currentSlide ? 'arrow--disabled' : '')"
            [style.fill]="arrowColour"
            (click)="slider.next()"
            aria-hidden="true"
            focusable="false">
        </svg-icon>
    </ng-container>

    <div *ngIf="showDots && dotHelper.length > 1" class="d-flex justify-content-center pb-4 pb-lg-5">
        <button
            (click)="slider.moveToSlideRelative(i)"
            *ngFor="let slide of dotHelper; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"
        ></button>
    </div>
</div>
