import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit, PLATFORM_ID,
  ViewChild
} from '@angular/core';
import KeenSlider from 'keen-slider';
import { empty, iif, Observable } from "rxjs";
import { isPlatformBrowser } from "@angular/common";
import { Store } from '@upero/store';
import { debounce } from '@upero/misc';

@Component({
  selector: 'upero-advice-gallery-slider',
  templateUrl: './advice-gallery-slider.component.html',
  styleUrls: ['./advice-gallery-slider.component.scss']
})
export class AdviceGallerySliderComponent implements OnInit, OnInit, AfterViewInit, OnDestroy
{
  @Input() gallery;

  loop = false;
  arrowColour = '#fff';
  spacing = 0;
  slidesPerView = 1;
  showDots = false;
  showNav = true;
  initial = 0;
  currentSlide = 1;
  lastSlide: number;
  dotHelper: Array<number> = [];
  slider: any = null;
  fullScreen = false;
  showExpandArrows = true;
  innerWidth$ = this.store.select<number>('innerWidth');

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  private isBrowser: boolean;

  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) platformId: any
  )
  {
    this.isBrowser = isPlatformBrowser(platformId);
    this.initSlider = debounce(this.initSlider, 50);
  }

  ngOnInit(): void
  {
    this.setSliderOptions();
  }

  ngAfterViewInit(): void
  {
    this.initSlider();
  }

  private initSlider()
  {
    iif(
      () => this.isBrowser,
      new Observable((observer) =>
      {
        this.slider = new KeenSlider(this.sliderRef.nativeElement, {
          slidesPerView: this.slidesPerView,
          spacing: this.spacing,
          loop: this.loop,
          initial: this.initial,
          slideChanged: s =>
          {
            this.currentSlide = s.details().relativeSlide;
          }
        });

        if (this.slider.details().size) {
          const dots = (this.slider.details().size - this.slidesPerView) + 1;
          this.dotHelper = dots < 0 ? [] : [...Array(dots)];
          this.lastSlide = this.slider.details().size - 1;
        } else {
          this.dotHelper = [];
        }
        observer.next();
        observer.complete();
      }),
      empty()
    ).subscribe();
  }

  ngOnDestroy(): void
  {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  setSliderOptions()
  {
    const deviceType = this.store.selectForLocal('deviceType');

    if (deviceType === 'mobile') {
      this.showDots = true;
      this.showNav = false;
    }
    if (deviceType === 'tabletV' || deviceType === 'tabletH') {
      this.showDots = true;
      this.showNav = false;
    }
    if (deviceType === 'desktop') {
      this.showDots = true;
      this.showNav = true;
    }
  }
}
