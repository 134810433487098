<div class="bg-slate-5">
    <div class="container py-5">
        <div *ngIf="items.length">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 mb-5"
                    [@fadeIn] *ngFor="let item of items"
                >
                    <a class="d-flex flex-column h-100 bg-white shadow-sm hover"
                       *ngIf="item.contents.type === 'article'"
                       [uperoBrandRouterLink]="[block.fields.pageSlug, item.contents.slug]"
                    >
                        <div class="gallery-image-tile">
                            <img [src]="item.contents.imageUrl" [appScrset]="item.contents.imageUrl" [width]="510" [alt]="item.contents.title" class="image-as-bg">
                        </div>
                        <div class="d-flex flex-column justify-content-center text-center h-100 p-3 p-md-4">
                            <h2 class="cs__title fs-4 mb-3">{{item.contents.title}}</h2>
                            <a class="btn btn-wide align-self-center"
                               [ngClass]="currentBrand ? 'btn-' + currentBrand.settings.colors.primary : 'btn-primary'"
                               [uperoBrandRouterLink]="[block.fields.pageSlug, item.contents.slug]">
                                Read more
                            </a>
                        </div>
                    </a>

                    <div class="h-100 shadow-sm" *ngIf="item.contents.type === 'gallery'">
                        <upero-advice-gallery-slider [gallery]="item"></upero-advice-gallery-slider>
                    </div>
                </div>
            </div>

            <div class="mb-3 mb-lg-5 text-center">
                <a
                    *ngIf="moreEntries"
                    (click)="showMore($event)"
                    [uperoBrandRouterLink]="['/advice', 'page', nextPage]"
                    class="btn btn-primary">
                    Show more
                </a>
            </div>
        </div>
        <div class="text-center" *ngIf="!items.length">
          <h2>Sorry - no advice posts to show at the moment</h2>
        </div>
    </div>
</div>
