import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthExpiredService {
  private expired$ = new Subject<void>();
  public authExpired$ = this.expired$.asObservable();
  constructor() {}

  setExpired() {
    this.expired$.next();
  }
}
