<article *ngIf="entry">
  <div class="intro" [style]="{'background-image': 'url('+ entry.contents.featuredImage +')'}">

  </div>

  <div class="container">

    <h1 class="text-center mt-5 mb-4">{{ entry.contents.title }}</h1>

    <div class="lead text-center mb-5">{{ entry.contents.preview_paragraph }}</div>

    <div class="row justify-content-center">
      <div class="col-12 col-xl-10 col-xxl-8">
        <div class="mb-4" [innerHTML]="entry.contents.content"></div>
      </div>
    </div>

  </div>

</article>
