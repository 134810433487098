import { NgModule, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformServer } from '@angular/common';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ScrsetDirective } from './directives/srcset/scrset.directive';
import { AwesomeTooltipDirective } from './directives/tooltip/tooltip.directive';
import {
    RouteLinkSwipeProtectedDirective
} from './directives/route-link-swipe-protected/route-link-swipe-protected.directive';
import { DropdownDirective } from './directives/dropdown/dropdown.directive';
import { AdviceGallerySliderComponent } from './components/advice-gallery-slider/advice-gallery-slider.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '@upero/pipes';
import { FileDndDirective } from './directives/FileDnd/file-uploader-dnd.directive';
import { SpecialOffersSliderComponent } from './components/special-offers-slider/special-offers-slider.component';

import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { AlertComponent } from './components/alert/alert.component';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';
import { TileSliderKeenComponent } from './components/tile-slider-keen/tile-slider-keen.component';
import { ImageSliderFwComponent } from './components/image-slider/image-slider-fw.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ProductTypesSliderComponent } from './components/product-types-slider/product-types-slider.component';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { TransferState } from '@angular/platform-browser';
import { AddressesComponent } from './components/addresses/addresses.component';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { AddressEditComponent } from './components/address-edit/address-edit.component';
import { FixedElementsComponent } from './components/fixed-elements/fixed-elements.component';
import { ProcessingComponent } from './components/processing/processing.component';
import { CookieConsentDialogComponent } from './components/cookie-consent-dialog/cookie-consent-dialog.component';
import { MaxQtyModalComponent } from './components/max-qty-modal/max-qty-modal.component';
import { BrandsModule } from '@upero/brands';
import { BrandRouteLinkDirective } from './directives/brand-route-link/brand-route-link.directive';
import { ThumbnailTileComponent } from './components/thumbnail-tile/thumbnail-tile.component';
import { MenuMobileUserComponent } from './components/menu-mobile-user/menu-mobile-user.component';
import { InlineSpinnerComponent } from './components/inline-spinner/inline-spinner.component';
import { PageNotFoundComponent } from 'libs/blocks/src/lib/page-not-found/page-not-found.component';
import { InViewportModule } from '@thisissoon/angular-inviewport/dist';
import { SvgBrowserLoader, SvgServerLoader } from '@upero/misc';

export function svgLoaderFactory(
    http: HttpClient,
    transferState: TransferState,
    platformId: any
): SvgServerLoader | SvgBrowserLoader
{
    if (isPlatformServer(platformId)) {
        return new SvgServerLoader('../browser/assets/images', transferState);
    } else {
        return new SvgBrowserLoader(http, transferState);
    }
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientJsonpModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        PipesModule,
        InViewportModule,
        AngularSvgIconModule.forRoot({
            loader: {
                provide: SvgLoader,
                useFactory: svgLoaderFactory,
                deps: [ HttpClient, TransferState, PLATFORM_ID ],
            }
        }),
        BrandsModule
    ],
    declarations: [
        PaginationComponent,
        ScrsetDirective,
        AwesomeTooltipDirective,
        RouteLinkSwipeProtectedDirective,
        BrandRouteLinkDirective,
        DropdownDirective,
        FileDndDirective,
        AdviceGallerySliderComponent,
        FileUploaderComponent,
        SpecialOffersSliderComponent,
        NotificationsListComponent,
        AlertComponent,
        HeaderMobileComponent,
        MenuMobileComponent,
        MenuMobileUserComponent,
        TileSliderKeenComponent,
        ImageSliderFwComponent,
        AddressFormComponent,
        LoginFormComponent,
        ProductTypesSliderComponent,
        AddressesComponent,
        AddAddressComponent,
        AddressEditComponent,
        FixedElementsComponent,
        ProcessingComponent,
        CookieConsentDialogComponent,
        MaxQtyModalComponent,
        ThumbnailTileComponent,
        InlineSpinnerComponent,
        PageNotFoundComponent,
    ],
    exports: [
        ThumbnailTileComponent,
        InlineSpinnerComponent,
        PaginationComponent,
        ScrsetDirective,
        AwesomeTooltipDirective,
        RouteLinkSwipeProtectedDirective,
        BrandRouteLinkDirective,
        DropdownDirective,
        FileDndDirective,
        AdviceGallerySliderComponent,
        FileUploaderComponent,
        SpecialOffersSliderComponent,
        NotificationsListComponent,
        AlertComponent,
        HeaderMobileComponent,
        MenuMobileComponent,
        MenuMobileUserComponent,
        TileSliderKeenComponent,
        ImageSliderFwComponent,
        AddressFormComponent,
        AddressesComponent,
        AddAddressComponent,
        AddressEditComponent,
        FixedElementsComponent,
        ProcessingComponent,
        CookieConsentDialogComponent,
        MaxQtyModalComponent,

        LoginFormComponent,
        ProductTypesSliderComponent,

        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        PipesModule,
        AngularSvgIconModule,
        InViewportModule,
        FormsModule,
        PageNotFoundComponent,
    ],
})
export class SharedModule
{
}
