<article *ngIf="entry">
  <div class="cs__intro position-relative w-100 d-flex justify-content-center align-items-center">
    <div class="cs__intro__box position-absolute p-4 text-white">
      <h1 class="ff-spectral fs-4 fw-bold text-white mb-3">{{ entry.contents.title }}</h1>
      <div class="cs__intro__list">
        <p class="fs-6 mb-2" *ngFor="let product of entry.products">{{ product.name }}</p>
      </div>
    </div>
    <img class="image-as-bg" [src]="entry.contents.featuredImage" [appScrset]="entry.contents.featuredImage" [width]="1920">
  </div>

  <div class="container py-5">
    <div class="row">
      <div class="col-12 col-md-5 col-xl-4 col-xxl-4 offset-xxl-1 px-xxl-5 mb-4 mb-md-0">
        <div class="cs__key-facts w-100 h-100 bg-black p-3 p-lg-5">
          <div class="cs__key-facts__title fs-4 ff-spectral text-white pb-4 mb-0">Key Facts</div>
          <div class="cs__key-fact d-flex flex-column flex-xl-row w-100 py-3 text-white">
            <span class="cs__key-fact__title mb-2 mb-xl-0">Project:</span>
            <span class="cs__key-fact__content fw-bolder">{{ entry.contents.title }}</span>
          </div>
          <div class="cs__key-fact d-flex flex-column flex-xl-row w-100 py-3 text-white">
            <span class="cs__key-fact__title">Location:</span>
            <span class="cs__key-fact__content fw-bolder">{{ entry.contents.location }}</span>
          </div>
          <div class="cs__key-fact d-flex flex-column flex-xl-row w-100 py-3 text-white">
            <span class="cs__key-fact__title">Budget:</span>
            <span class="cs__key-fact__content fw-bolder">&pound; {{ entry.contents.budget }}</span>
          </div>
          <div class="cs__key-fact d-flex flex-column flex-xl-row w-100 py-3 text-white">
            <span class="cs__key-fact__title">Quantity:</span>
            <span
              class="cs__key-fact__content fw-bolder">{{ entry.contents.quantity }} {{ entry.contents.quantity_unit }}</span>
          </div>
          <div class="cs__key-fact d-flex flex-column flex-xl-row w-100 py-3 text-white">
            <span class="cs__key-fact__title">Products used:</span>
            <div class="cs__key-fact__content fw-bolder d-flex flex-column">
              <a class="text-white" *ngFor="let product of entry.contents.products"
                 [uperoBrandRouterLink]="['products/' + product.slug]">{{ product.name }}</a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(deviceType$| async) !== 'mobile'" class="col-12 col-md-7 col-xl-8 col-xxl-6">
        <div class="fs-3 mb-4">{{ entry.contents.preview_paragraph }}</div>
        <div [innerHTML]="entry.contents.content"></div>
      </div>
    </div>
  </div>
<!--
  <aside>
    <app-case-study-public-slider [images]="entry.images"></app-case-study-public-slider>
  </aside>-->

  <div *ngIf="(deviceType$| async) === 'mobile'" class="container container--article">
    <div class="fs-3 mb-4">{{ entry.contents.preview_paragraph }}</div>
    <div [innerHTML]="entry.contents.content"></div>
  </div>

<!--  <app-share-icons></app-share-icons>

  <div *ngIf="caseStudy.products.length">
    <app-case-study-public-related-products
      [products]="caseStudy.products"
    ></app-case-study-public-related-products>
  </div>-->
</article>
