import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@upero/store';
import { ApiAuthHttpClient } from '@upero/misc';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Injectable({
  providedIn: 'root'
})
export class AdviceService
{

  constructor(
    private store: Store,
    private http: ApiAuthHttpClient,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  findAll(params)
  {
    const dataToSend = {
      params,
    };
    return this.http
      .post(this.env.apiPath + 'advice/all', dataToSend)
      .pipe(map((data: any) => data));
  }

  findOneBySlug(slug)
  {
    return this.http
      .get(this.env.apiPath + 'advice/' + slug)
      .pipe(map((data: any) => data));
  }
}
