import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService
{
  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  intentCreate(intent)
  {
    return this.http
      .post(this.env.apiPath + 'payments/intent', { intent })
      .pipe(map((data: any) => data));
  }

  intentUpdate(intent)
  {
    return this.http
      .put(this.env.apiPath + 'payments/intent', { intent })
      .pipe(map((data: any) => data));
  }

  attachPaymentMethodToCustomer(paymentMethodId, user)
  {
    const dataToSend = {
      paymentMethodId,
      userId: user.id,
      stripeId: user.stripeId
    };

    return this.http
      .post(this.env.apiPath + 'payments/method/attach', dataToSend)
      .pipe(map((data: any) => data));
  }

  cards(userId)
  {
    return this.http
      .get(this.env.apiPath + 'payments/cards/' + userId)
      .pipe(map((data: any) => data));
  }

  card(id)
  {
    return this.http
      .get(this.env.apiPath + 'payments/card/' + id)
      .pipe(map((data: any) => data));
  }

  tokenCreate(card)
  {
    return this.http
      .post(this.env.apiPath + 'payments/token', { card })
      .pipe(map((data: any) => data));
  }

  search(params)
  {
    return this.http
      .post(this.env.apiPath + 'payments', { params })
      .pipe(map((data: any) => data));
  }
}
