import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upero-image-text-side',
  templateUrl: './image-text-side.component.html',
  styleUrls: ['./image-text-side.component.scss']
})
export class ImageTextSideComponent implements OnInit {
  @Input() block: any;
  @HostBinding('attr.class') get classes(): string {
    return 'col-' + this.block.cols;
  };
  @HostBinding('style') get styles() {
    return { height: this.block.rows * 100 + 'px' };
  };

  constructor() { }

  ngOnInit(): void {
  }

}
