<div class="modal" tabindex="-1">
    <div class="modal-dialog modal-fullscreen-lg-down modal-xl">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <div class="ms-auto">
                    <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <h3 class="modal-title fw-bold text-center">
                    Edit Address
                </h3>
            </div>
            <div class="modal-body modal--max-width p-3 p-lg-5">
                <upero-address-form
                    [requiredFields]="{ add1: true, town: true, postcode: true }"
                    (addressCreated)="updateAddress($event)"
                    [address]="address"
                    layout="layout-2"
                ></upero-address-form>

                <h2 class="mt-5">Delivery Details</h2>
                <div class="d-flex align-items-center mb-4">
                    <svg-icon src="assets/images/icon-circle-delivery.svg"
                              [class]="'d-none d-sm-block me-4'"
                              [svgClass]="'icon--big'"></svg-icon>
                    <div class="text-muted fs-5 lh-lg">
                        Please note: Our standard delivery method is for a tail-lift
                        offload, other delivery methods are available at an additional cost.
                    </div>
                </div>

                <div class="row g-0 mb-3 mb-sm-0">
                    <div class="col-12 col-sm-6 pb-1 pe-sm-1">
                        <div class="d-flex align-items-center bg-light p-3">
                            <svg-icon src="assets/images/icon-order.svg"
                                      [class]="'me-3'"
                                      [svgClass]="'icon--small'"></svg-icon>
                            <div>Is the road approaching the delivery site on a slope?</div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 pb-1 pe-1">
                        <div class="p-3 d-flex align-items-center h-100 hover" [ngClass]="address.restrictions.slope ? 'bg-blue' : 'bg-light'"
                            (click)="setRestrictionValue('slope', true)">
                            <div class="form-check">
                                <input class="form-check-input hover"
                                       type="radio"
                                       [checked]="address.restrictions.slope">
                                <label class="form-check-label hover">
                                    Yes
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3 pb-1">
                        <div class="p-3 d-flex align-items-center h-100 hover" [ngClass]="!address.restrictions.slope ? 'bg-blue' : 'bg-light'"
                            (click)="setRestrictionValue('slope', false)">
                            <div class="form-check">
                                <input class="form-check-input hover"
                                       type="radio"
                                       [checked]="!address.restrictions.slope">
                                <label class="form-check-label hover">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row g-0 mb-3 mb-sm-0">
                    <div class="col-12 col-sm-6 pb-1 pe-sm-1">
                        <div class="d-flex align-items-center bg-light p-3">
                            <svg-icon src="assets/images/icon-order.svg"
                                      [class]="'me-3'"
                                      [svgClass]="'icon--small'"></svg-icon>
                            <div>Do you have a gravel driveway?</div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3  pb-1 pe-1">
                        <div class="p-3 d-flex align-items-center h-100 hover" [ngClass]="address.restrictions.gravel ? 'bg-blue' : 'bg-light'"
                            (click)="setRestrictionValue('gravel', true)" >
                            <div class="form-check">
                                <input class="form-check-input hover"
                                       type="radio"
                                       [checked]="address.restrictions.gravel">
                                <label class="form-check-label hover">
                                    Yes
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-3  pb-1">
                        <div class="p-3 d-flex align-items-center h-100 hover" [ngClass]="!address.restrictions.gravel ? 'bg-blue' : 'bg-light'"
                            (click)="setRestrictionValue('gravel', false)">
                            <div class="form-check">
                                <input class="form-check-input hover"
                                       type="radio"
                                       [checked]="!address.restrictions.gravel">
                                <label class="form-check-label hover">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row g-0 mb-3 mb-sm-0" *ngIf="address.restrictions.gravel">
                    <div class="col-12 col-sm-6 pb-1 pe-sm-1">
                        <div class="d-flex align-items-center p-3 bg-light">
                            <svg-icon src="assets/images/icon-order.svg"
                                      [class]="'me-3'"
                                      [svgClass]="'icon--small'"></svg-icon>
                            <div>Please confirm if you are able to place builders ply boards down so that our tail-lift
                                offload courier can deliver to your property</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 pb-1">
                        <div (click)="toggleRestrictionValue('boards')"
                             class="p-3 d-flex align-items-center h-100 hover"
                             [ngClass]="address.restrictions.boards ? 'bg-blue' : 'bg-light'">
                            <input id="terms" type="checkbox"
                                   class="custom-checkbox hover"
                                   [checked]="address.restrictions.boards"
                                   (click)="toggleRestrictionValue('boards')"/>
                            <label class="fs-small hover" for="terms">
                                I can confirm it is ok to place builders ply boards down
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-between">
                <div class="d-flex modal--max-width justify-content-between px-3 px-lg-5">
                    <button (click)="close()" type="button" class="btn btn-outline-primary">Cancel</button>
                    <button (click)="saveAddress()" type="button" class="btn btn-primary">Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
