import { State } from '@upero/store';

export const InitialState: State = {
  bespokePricingLog: [],
  innerWidth: 0,
  deviceType: 'mobile',
  settings: {},
  navigation: {
    current: '',
    last: '',
    next: '',
  },
  RegisteredInBasketFlow: false,
  navigationHistory: [],
  signInRoute: 'account',
  favourites: [],
  chatStore: {
    unread: 0,
    groups: [],
    group: undefined,
    replyingTo: undefined,
    groupToOpen: undefined,
    messages: [],
    subjects: [],
  },
  companyUsers: {
    data: [],
    params: {
      company: '',
      name: '',
      email: '',
      jobTitle: '',
      town: '',
      postcode: '',
      mobile: '',
      landline: '',
      status: '',
      freeText: '',
      companyId: '',
      accountType: 'supplier',
      limit: 10,
      limits: [10, 25, 50, 75, 100],
      page: 0,
      pages: 0,
      pageArray: [],
      totalRecords: 0,
      sort: 'companies.name',
      sorts: [],
    },
  },
  notifications: { unread: 0, data: [] },
  address: undefined,
  deliveryAddresses: [],
  addressType: '',
  basketId: '',
  lastBasketItemAdded: undefined,
  basket: {
    type: 'order',
    lastStep: 'entry',
    source: 'public',
    potentialQty: 0,
    items: [],
    samples: [],
    rewardsDiscountPercentage: 0,
    voucher: undefined,
    voucherCodeEntered: '',
    deliveryInstructions: '',
    differentDeliveryAddress: false,
    deliveryAddress: undefined,
    deliveryPostcode: '',
    deliveryRestrictions: [],
    billingAddress: undefined,
    customer: undefined,
    guest: true,
    allowBoards: false,
    gravelDriveway: false,
    discount: {
      type: 'pound',
      value: 0,
    },
    values: {
      discount: 0,
      gross: 0,
      net: 0,
      vat: 0,
      voucherDiscount: 0,
      delivery: 0,
      deliveryVat: 0,
    },
    token: undefined,
    paymentMethod: 'CC',
    shipments: [],
    restrictions: {
      slope: false,
      gravel: false,
      boards: false,
    },
    deliveryOptions: {
      allowPremium: true,
      premium: 0,
      economy: 0,
      geo: {
        travelKM: 0,
        travelMiles: 0,
        travelMinutes: 0,
        lat: undefined,
        lng: undefined,
      },
    },
  },
  accountType: '',
  protectedRoute: false,
  confirmationEmail: '',
  companyRequests: [],
  menus: [],
  menu: {
    header: undefined,
    footerQL: undefined,
    ttd: undefined,
  },
  subMenu: [],
  activeMenu: undefined,
  activeRoute: '',
  pages: undefined,
  pageSnippets: [],
  device: undefined,
  guestUser: undefined,
  user: undefined,
  userToEdit: undefined,
  asideOpen: false,
  growler: {
    errors: [],
    notifications: [],
    simple: '',
    confirmAction: undefined,
  },
  alerts: {
    errors: [],
    notifications: [],
    simple: null,
    confirmAction: null,
    customAlert: null
  },
  overlays: {
    userEdit: false,
    processing: false,
    loginModal: false,
    registerModal: false,
    addedToBasket: false,
    priceBandModal: false,
    bespokeBuilderModal: false,
    cookieConsentManageModal: false,
    busyIndicator: false
  },
  overlayData: undefined,
  cms: undefined,
  categories: [],
  mobileMenu: false,
  mobileMenuUser: false,
  mobileMenuAccount: false,
  search: false,
  searchText: '',
  searchResults: [],
  token: null,
  loggedIn: false,
  enableQuoteBackend: false,
  productToView: undefined
};
