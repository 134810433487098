<section [ngClass]="{ 'overflow-hidden': showOverflow }">
  <div [ngClass]="block.backgroundColour">
    <div class="container-fluid container--1920 g-0 px-4 py-5 px-md-5">
      <h2 class="fs-4 text-white text-center text-xl-start px-1 px-lg-2 mb-5">
        Our Spaces
      </h2>
      <div class="overflow-wrapper">
        <upero-tile-slider-keen
          *ngIf="productGroups.length > 0"
          [tiles]="productGroups"
          [showOverflow]="showOverflow"></upero-tile-slider-keen>
      </div>
    </div>
  </div>
</section>
