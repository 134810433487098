import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AuthService, OverlayService } from '@upero/services';
import { NotificationService } from '@upero/services';
import { Store } from '@upero/store';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-account-header-mobile',
  templateUrl: './account-header-mobile.component.html',
  styleUrls: ['./account-header-mobile.component.scss']
})
export class AccountHeaderMobileComponent
{
  user$ = this.store.select<any>('user');
  basket$ = this.store.select<any>('basket');
  notifications$ = this.store.select<any>('notifications');
  chatStore$ = this.store.select<any>('chatStore');
  settings$ = this.store.select<any>('settings');
  deviceType$ = this.store.select<string>('deviceType');
  favourites$ = this.store.select<string>('favourites');
  currentBrand = this.store.selectForLocal('currentBrand');

  constructor(
    private store: Store,
    private authService: AuthService,
    private alertService: AlertService,
    public router: Router,
    private overlayService: OverlayService,
    private notificationService: NotificationService,
  )
  {
  }

  toggleMenu()
  {
    this.store.set('mobileMenu', true);
  }

  toggleUserMenu()
  {
    this.store.set('mobileMenuUser', true);
  }

  toggleNotifications()
  {
    this.overlayService.toggle('notifications');
  }

  toggleChat()
  {
    this.overlayService.toggle('chat');
  }

  logout()
  {
    const notifications = { unread: 0, data: [] };
    const chatStore = {
      unread: 0,
      groups: [],
      group: undefined,
      replyingTo: undefined,
      groupToOpen: undefined,
      messages: [],
    };

    this.store.set('chatStore', chatStore);
    this.store.set('notifications', notifications);

    this.notificationService.stopListening();
    this.authService.logout();
    this.router.navigate(AppInitService.doBrandModUrl(['login']));
  }
}
