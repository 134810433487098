<section id="footer">
  <div *ngIf="!router.isActive('/account',false)" class="bg-dark text-white py-4">
    <div class="container-fluid">
      <div class="row g-0">
        <div class="col-12 col-md-6 col-lg-4 order-1 order-md-0">
          <div class="d-flex flex-column justify-content-between h-100 px-4 px-xs-5 py-3">
            <div>
              <div class="fw-500 mb-3">Site Map</div>
              <div class="row g-3 mb-3">
                <div
                  class="col-6 menu"
                  *ngFor="let item of items">
                  <a *ngIf="!item.contents.external"
                     [uperoBrandRouterLink]="[item.contents.link]"
                     class="small link-white">
                    {{item.contents.title}}
                  </a>
                  <a *ngIf="item.contents.external"
                     target="_blank"
                     rel="noopener"
                     [href]="[item.contents.link]"
                     class="small link-white">
                    {{item.contents.title}}
                  </a>
                </div>
              </div>
            </div>

            <div class="trustpilot-container">
              <upero-trustpilot-widget></upero-trustpilot-widget>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8 order-0 order-md-1">
          <div class="row g-0 h-100">
            <div class="col-12 col-lg-6 order-1 order-lg-0 d-md-none d-lg-block">
              <div class="d-flex flex-column justify-content-between border-left h-100 px-4 px-xs-5 py-3">
                <div>
                  <div class="fw-500 mb-3">Contact Us</div>
                  <div *ngIf="siteSettings.settings.contact" class="mb-3">
                    <p class="fw-500 mb-3">
                      <a class="text-white"
                         gaEvent="click_phone_number" gaCategory="Contact" gaBind="click"
                         fbqEvent="click_phone_number" fbqBind="click"
                         target="_blank"
                         href="tel:{{siteSettings.settings.contact.phone}}">{{siteSettings.settings.contact.phone}}</a>
                    </p>
                    <p class="fw-500 mb-3">
                      <a class="text-white"
                         gaEvent="click_email_address" gaCategory="Contact" gaBind="click"
                         fbqEvent="click_email_address" fbqBind="click"
                         href="mailto:{{siteSettings.settings.contact.email}}">{{siteSettings.settings.contact.email}}</a>
                    </p>
                    <address class="small lh-lg mb-3">
                      {{siteSettings.settings.contact.name}}
                      <br/>
                      {{siteSettings.settings.contact.address}}
                    </address>
                    <!--<a class="text-slate-40 small op-6" target="_blank" rel="noreferrer"
                       [href]="(settings$ | async).googleMapsURL">View on Google Maps</a>-->
                  </div>
                </div>
                <div class="payment-container d-flex justify-content-start justify-content-md-between">
                  <div class="flex-grow-1 me-1 me-sm-1">
                    <svg-icon src="assets/images/icon-visa.svg"
                              [svgClass]="'payment-method'"></svg-icon>
                  </div>
                  <div class="flex-grow-1 me-1 me-sm-1">
                    <svg-icon src="assets/images/icon-mastercard.svg"
                              [svgClass]="'payment-method'"></svg-icon>
                  </div>
                  <div class="flex-grow-1 me-1 me-sm-1">
                    <svg-icon src="assets/images/icon-maestro.svg"
                              [svgClass]="'payment-method'"></svg-icon>
                  </div>
                  <div class="flex-grow-1 me-1 me-sm-1">
                    <svg-icon src="assets/images/icon-amex.svg"
                              [svgClass]="'payment-method'"></svg-icon>
                  </div>
                  <div class="flex-grow-1 me-1 me-sm-1">
                    <svg-icon src="assets/images/icon-apple-pay.svg"
                              [svgClass]="'payment-method'"></svg-icon>
                  </div>
                  <div class="flex-grow-1 me-1 me-sm-1">
                    <svg-icon src="assets/images/icon-google-pay.svg"
                              [svgClass]="'payment-method'"></svg-icon>
                  </div>
                  <div class="flex-grow-1 me-0">
                    <svg-icon src="assets/images/icon-bacs.svg"
                              [svgClass]="'payment-method'"></svg-icon>
                  </div>
                </div>
                <hr class="mobile-divider">
              </div>
            </div>
            <div class="col-12 col-lg-6 order-0 order-lg-1">
              <div class="d-flex flex-column justify-content-between border-left h-100 px-4 px-xs-5 py-3">
                <form class="needs-validation" [formGroup]="newsletterForm" (ngSubmit)="onSubmit()"
                      novalidate>
                  <div class="small fw-500 mb-3">For special offers and all our latest
                    news,<br> simply enter your email address below;
                  </div>
                  <div class="mb-3">
                    <div class="mb-3 d-flex align-items-center">
                      <input id="confirm" type="checkbox" formControlName="confirm"
                             class="custom-checkbox"/>
                      <label for="confirm"
                             [ngClass]="{ 'is-invalid': submitted && field.confirm.errors }"
                             class="form-check-label">
                        <span>I have read the GDPR policy and agree to the <a [uperoBrandRouterLink]="'/terms-and-conditions'" [target]="'_blank'" class="form-check-label text-decoration-underline">Terms &amp; Conditions</a></span>
                      </label>
                    </div>
                    <input [ngClass]="{ 'is-invalid': submitted && (field.email.errors || duplicateEmail) }"
                           type="email"
                           formControlName="email"
                           class="form-control"
                           (click)="hideErrors()"
                           placeholder="Email address"/>
                    <button type="submit" class="btn btn-wide btn-outline-white fw-bold">Sign-up
                    </button>
                    <p *ngIf="duplicateEmail" class="duplicateError">The email address you have
                      entered is already signed up - please use another address if needed.</p>
                    <p *ngIf="submitted && field.confirm.errors" class="duplicateError">Please accept the terms and conditions.</p>
                    <p *ngIf="submitted && field.email.errors" class="duplicateError">Please enter a valid email address.</p>
                  </div>
                </form>
                <div
                  class="follow-container d-flex flex-column flex-sm-row justify-content-between justify-content-sm-start align-items-start align-items-sm-center">
                  <div class="pe-xl-0 mb-3 mb-sm-0">
                    <p class="small me-3 d-flex align-items-center text-nowrap op-6 mb-0">
                      Follow us</p>
                  </div>
                  <div
                    *ngIf="siteSettings.settings.socials"
                    class="d-flex justify-content-between justify-content-sm-start justify-content-md-end justify-content-xl-start w-100 my-auto">
                    <a target="_blank" rel="noreferrer" [href]="siteSettings.settings.socials.facebook">
                      <div id="social-icons" class="social-icon">
                        <i class="fab fa-facebook-f"></i>
                      </div>
                    </a>
                    <a target="_blank" rel="noreferrer" [href]="siteSettings.settings.socials.twitter">
                      <div class="social-icon">
                        <i class="fab fa-twitter"></i>
                      </div>
                    </a>
                    <a target="_blank" rel="noreferrer" [href]="siteSettings.settings.socials.pinterest">
                      <div class="social-icon">
                        <i class="fab fa-pinterest-p"></i>
                      </div>
                    </a>
                    <a target="_blank" rel="noreferrer" [href]="siteSettings.settings.socials.instagram">
                      <div class="social-icon me-0">
                        <i class="fab fa-instagram"></i>
                      </div>
                    </a>
                  </div>
                </div>
                <hr class="mobile-divider">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="currentBrand ? 'bg-' + currentBrand.settings.footerSubClass : 'bg-primary'">
    <div class="container">
      <div class="row op-5 sub-menu px-4 py-3">
        <div class="col-12 col-xl-auto d-flex justify-content-around order-0 order-xl-1 mb-3 mb-xl-0">
          <div class="sub-menu d-flex flex-wrap">
            <ng-container
              *ngFor="let item of subItems">
              <a *ngIf="!item.contents.external"
                 [uperoBrandRouterLink]="[item.contents.link]"
                 class="sub-menu__item link-white me-lg-3 mb-3 mb-lg-0">
                {{item.contents.title}}
              </a>
              <a *ngIf="item.contents.external"
                 target="_blank"
                 rel="noopener"
                 [href]="[item.contents.link]"
                 class="sub-menu__item link-white me-lg-3 mb-3 mb-lg-0">
                {{item.contents.title}}
              </a>
            </ng-container>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-auto order-1 order-xl-0 me-xl-auto mb-1 mb-xl-0 text-white">
          &copy;{{currentDate| date:'yyyy'}} | {{siteSettings.name}}
        </div>
        <div
          class="col-12 col-lg-6 col-xl-auto order-2 order-xl-2 ms-xl-auto text-start text-lg-end text-xl-start text-11">
                    <span
                      *ngIf="siteSettings.settings.company && siteSettings.settings.company.companyNo">Company registration number {{siteSettings.settings.company.companyNo}}</span>
          <span *ngIf="siteSettings.settings.company && siteSettings.settings.company.vatNo"
                class="d-none d-sm-inline ms-2 me-2">|</span>
          <span *ngIf="siteSettings.settings.company && siteSettings.settings.company.vatNo"
                class="d-flex d-sm-inline">Vat number {{siteSettings.settings.company.vatNo}}</span>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-container *ngIf="(activeRoute$ | async) === 'home' || (activeRoute$ | async) === ''">
  <div itemtype="http://schema.org/Organization" itemscope>
    <meta itemprop="name" content="Upero" />
    <meta itemprop="description" content="UK's largest York Stone supplier" />
    <meta itemprop="image" content="https://www.yorkstonesupplies.co.uk/assets/images/ys-logo-text.svg" />
    <meta itemprop="logo" content="https://www.yorkstonesupplies.co.uk/assets/images/ys-logo-text.svg" />
    <meta itemprop="url" content="https://www.yorkstonesupplies.co.uk/" />
    <meta itemprop="telephone" content="01483 930 104" />
    <meta itemprop="sameAs" content="
        https://twitter.com/Yorkstonepaving,
        https://www.facebook.com/YorkstoneSupplies,
        https://www.instagram.com/yorkstonesupplies/,
        https://www.pinterest.co.uk/yorkstonesales/
        " />
    <div itemprop="address" itemtype="http://schema.org/PostalAddress" itemscope>
      <link itemprop="streetAddress" content="Unit 14, Phoenix House, Littlemeads Industrial Estate, Alfold Road" />
      <meta itemprop="addressLocality" content="Cranleigh" />
      <meta itemprop="postalCode" content="GU6 8ND" />
      <meta itemprop="addressCountry" content="United Kingdom" />
    </div>
  </div>
</ng-container>
