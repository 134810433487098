import {Component, HostBinding, OnInit} from '@angular/core';
import {BaseBlockComponent, ImageTextBlock} from "@upero/misc";
import {Store} from "@upero/store";

@Component({
  selector: 'upero-image-text-block',
  templateUrl: './image-text-block.component.html',
  styleUrls: ['./image-text-block.component.scss'],
})
export class ImageTextBlockComponent extends BaseBlockComponent<ImageTextBlock> implements OnInit {
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  currentBrand = this.store.selectForLocal('currentBrand');

  constructor(private store: Store,) {
    super();
  }

  ngOnInit(): void {}
}
