<form [formGroup]="form" autocomplete="off" id="address-form">
  <ng-container *ngIf="!manualAddress">
    <div class="row mb-3">
      <div class="col-6 col-lg-4 col-xl-3">
        <input type="search" [autocomplete]="'off'" class="form-control rounded-3 me-3" placeholder="Postcode"
               formControlName="postcodePicker" (blur)="getAddress()" (keyup.enter)="getAddress()" [ngClass]="{
                            'border-0': layout === 'layout-1',
                            'is-invalid':
                                form.controls.postcodePicker?.invalid &&
                                form.controls.postcodePicker?.touched
                        }" />
        <div class="invalid-feedback"> {{
          this.form.controls.postcodePicker.hasError('maxlength')
            ? this.formTitle + ' should be less than or equal to 10 characters'
            : this.form.controls.postcodePicker.hasError('required')
              ? this.formTitle + ' is required'
              : this.formTitle + ' should be more than 1 character'
          }}</div>
      </div>
      <div class="col-6 col-lg-8 col-xl-9">
        <ng-select placeholder="Select address" (change)="handleAddressChange($event)" [clearable]="false"
                   [searchable]="false" class="blank">
          <ng-option *ngFor="let addr of addressResults" [value]="addr">{{ addr.line_1 }}, {{ addr.locality
            }},
            {{ addr.county }}</ng-option>
        </ng-select>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="this.selAddress || manualAddress">
    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <input type="text" class="form-control rounded-3" formControlName="county" placeholder="County/State"
               [ngClass]="{
                        'is-invalid':
                            form.controls.county.invalid &&
                            form.controls.county.touched,
                        'border-0': layout === 'layout-1'
                    }" />
      </div>
      <div class="col-12 col-md-6">
        <div class="col-12">
          <input type="text" class="form-control rounded-3" formControlName="town" placeholder="Town / city"
                 [ngClass]="{
                        'is-invalid':
                            form.controls.town.invalid &&
                            form.controls.town.touched,
                        'border-0': layout === 'layout-1'
                    }" />
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <input type="text" class="form-control rounded-3" formControlName="add1" placeholder="Address line 1"
               [ngClass]="{
                        'is-invalid': form.controls.add1.invalid && form.controls.add1.touched,
                        'border-0': layout === 'layout-1'
                    }" />
        <div class="invalid-feedback">
          {{
          this.form.controls.add1.hasError('maxlength')
            ? 'Address line 1 should be less than or equal to 80 characters'
            : this.form.controls.add1.hasError('required')
              ? 'Address line 1 is required'
              : 'Address line 1 should be more than 1 character'
          }}
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <input type="text" class="form-control rounded-3" formControlName="add2" placeholder="Address line 2"
               [ngClass]="{
                        'is-invalid':
                            form.controls.add2.invalid &&
                            form.controls.add2.touched,
                        'border-0':
                            layout === 'layout-1' && form.controls.add2.valid
                    }" />
        <div class="invalid-feedback">
          {{
          this.form.controls.add2.hasError('maxlength')
            ? 'Address line 2 should be less than or equal to 80 characters'
            : this.form.controls.add2.hasError('required')
              ? 'Address line 2 is required'
              : 'Address line 2 should be more than 1 character'
          }}
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <input type="text" class="form-control rounded-3" formControlName="add3" placeholder="Address line 3"
               [ngClass]="{
                        'is-invalid':
                            form.controls.add3.invalid &&
                            form.controls.add3.touched,
                        'border-0':
                            layout === 'layout-1' && form.controls.add3.valid
                    }" />
        <div class="invalid-feedback">
          {{
          this.form.controls.add3.hasError('maxlength')
            ? 'Address line 3 should be less than or equal to 80 characters'
            : this.form.controls.add3.hasError('required')
              ? 'Address line 3 is required'
              : 'Address line 3 should be more than 1 character'
          }}
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="manualAddress">
      <div class="col-12">
        <input type="text" class="form-control rounded-3" formControlName="postcode"
               placeholder="Postcode/Zip code" [ngClass]="{
                        'is-invalid':
                            form.controls.postcode.invalid &&
                            form.controls.postcode.touched,
                        'border-0':
                            layout === 'layout-1' && form.controls.postcode.valid
                    }" />
        <div class="invalid-feedback">
          {{
          this.form.controls.postcode.hasError('maxlength')
            ? 'Postcode should be less than or equal to 10 characters'
            : this.form.controls.postcode.hasError('required')
              ? 'Postcode is required'
              : 'Postcode should be more than 1 character'
          }}
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="manualAddress">
      <div class="col-12">
        <ng-select placeholder="Select country" [ngModel]="address.country"
                   [ngModelOptions]="{ standalone: true }" (change)="handleCountryChange($event)" [clearable]="false"
                   [searchable]="false" class="blank">
          <ng-option *ngFor="let country of countries" [value]="country.id">{{ country.id }} - {{
            country.countryName }}</ng-option>
        </ng-select>
      </div>
    </div>
  </ng-container>

  <div class="mt-4">
    <a href="javascript:void(0);" (click)="manualAddress = !manualAddress">{{
      !manualAddress
        ? 'Enter address manually'
        : 'Search by postcode (UK only)'
      }}</a>
  </div>

</form>
