<section *ngIf="products.length"
    [ngClass]="{ 'overflow-hidden': showOverflow }"
>
    <div [ngClass]="backgroundColour">
        <div class="container-fluid container--1920 g-0 p-5">
            <h2 class="fs-5 text-center text-xl-start px-1 px-lg-2 mb-5">Special Offers</h2>
            <div *ngIf="products && (deviceType$| async) !== 'desktop' ||  products.length > 4 && !noSlider"
                 class="overflow-wrapper" [ngClass]="{'mt-5' : !showHeading}">
                <upero-special-offers-slider
                    [showOverflow]="showOverflow"
                    [tiles]="products"></upero-special-offers-slider>
            </div>

            <div *ngIf="products && ((deviceType$| async) === 'desktop' && products.length <= 4 || noSlider)" class="row g-0">
                <ng-container *ngFor="let pg of products; let last = last">
                    <div
                        class="col-12 col-sm-6 col-xl-3 text-center px-1 px-lg-2" [ngClass]="{ 'mb-5': !last}">
                        <div  class="img-bg-wrapper position-relative d-inline-block">
                            <a [uperoBrandRouterLink]="['/products', pg.slug]">
                                <img class="image-as-bg hover"
                                     [src]="pg.productImages.length ? pg.productImages[0].url : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                                     [appScrset]="pg.productImages.length ? pg.productImages[0].url : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                                     [width]="310"
                                     [alt]="pg.name">
                            </a>
                            <div class="corner">
                                <span>
                                   <svg-icon src="assets/images/icon-special-offer.svg"
                                             [svgClass]="'group-icon'"></svg-icon><br/>
                                    {{pg.productLineDiscount}}% off
                                </span>
                            </div>
                        </div>
                        <div class="mt-4 mb-3">
                            <a class="fs-5 fw-400 text-dark"
                               [uperoBrandRouterLink]="['/products', pg.slug]">
                                {{pg.name}}
                            </a>
                        </div>
                        <p class="d-flex justify-content-center align-items-baseline">
                            <span class="small me-2">Prices from</span>
                            <span class="text-danger text-decoration-line-through me-2">
                            {{pg.preDiscountPrice | currency: 'GBP' : 'symbol' : '1.0-0' }}
                        </span>
                            <span class="fs-4 fw-fat me-2">
                            {{pg.pricesFrom | currency: 'GBP' : 'symbol' : '1.0-0'}}
                        </span>
                            <button (click)="toggleFavourite(pg.id)" class="d-inline-block btn-blank">
                                <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) < 0"
                                          src="assets/images/icon-heart.svg"
                                          [svgClass]="'product-details__favorite__svg'"></svg-icon>
                                <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) >= 0"
                                          src="assets/images/icon-heart.svg"
                                          [svgClass]="'product-details__favorite__svg--active'"></svg-icon>
                            </button>
                        </p>
                        <upero-button [type]="'href'" [link]="'/products/' + pg.slug" [text]="'View product'" [buttonClass]="'primary'"></upero-button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>
<div *ngIf="products.length === 0" class="container">
  <div class="text-center p-4 my-4">
    <h2>Sorry - no special offers to show at the moment</h2>
  </div>
</div>
