<section *ngIf="productRange && isLoaded">
    <div class="container g-0 p-5">
        <div class="row g-0">
            <ng-container *ngFor="let pg of products; let last = last">
                <div
                    class="col-12 col-sm-6 col-md-6 col-xl-3 text-center px-4" [ngClass]="{ 'mb-5': !last}">
                    <a [uperoBrandRouterLink]="['/products', pg.slug]"
                       class="d-inline-block position-relative">
                        <img class="image-as-bg hover"
                             [src]="pg.productImages.length ? pg.productImages[0].url : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                             [appScrset]="pg.productImages.length ? pg.productImages[0].url : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                             [width]="310">
                        <div *ngIf="pg.specialOffer" class="corner">
                           <span class="bg-primary-light">
                               <svg-icon src="assets/images/icon-special-offer.svg" [svgClass]="'group-icon'"></svg-icon><br/>
                               {{pg.discount}}% off
                           </span>
                        </div>
                    </a>
                    <div class="mt-4 mb-3">
                        <a class="ff-spectral fs-6 fw-bold"
                           [uperoBrandRouterLink]="['/products', pg.slug]">
                            {{pg.name}}
                        </a>
                    </div>
                    <p class="op-8 type-description mb-4" [innerHTML]="pg.previewText"></p>
                    <p class="d-flex justify-content-center align-items-center">
                        <span class="small me-2">Prices from</span>
                        <span class="fs-5 fw-bold me-2">
                        {{pg.pricesFrom | currency: 'GBP' : 'symbol'}}
                    </span>
                        <button (click)="toggleFavourite(pg.id)" class="d-inline-block btn-blank">
                            <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) < 0"
                                      src="assets/images/icon-heart.svg"
                                      [svgClass]="'product-details__favorite__svg'"></svg-icon>
                            <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) >= 0"
                                      src="assets/images/icon-heart.svg"
                                      [svgClass]="'product-details__favorite__svg--active'"></svg-icon>
                        </button>
                    </p>
                    <a class="btn btn-wide btn-primary fw-bold"
                       [uperoBrandRouterLink]="['/products', pg.slug]">
                        View Product
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<ng-container *ngIf="!productRange && isLoaded">
    <upero-page-not-found></upero-page-not-found>
</ng-container>
