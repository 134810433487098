import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@upero/store';
import { QualityEtchedFields, BaseBlockComponent } from '@upero/misc';

@Component({
  selector: 'upero-quality-etched',
  templateUrl: './quality-etched.component.html',
  styleUrls: ['./quality-etched.component.scss']
})
export class QualityEtchedComponent extends BaseBlockComponent<QualityEtchedFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  deviceType$ = this.store.select<string>('deviceType');

  constructor(private store: Store)
  {
    super();
  }

  ngOnInit(): void
  {
  }
}
