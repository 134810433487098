<div class="container-fluid px-4 py-4 px-md-5" [ngStyle]="{backgroundColor: block.fields.backgroundColor}">
  <div class="row g-sm-0 gx-xl-4">
    <div class="col-12 col-xl-5 col-xxl-5 mb-lg-3 mb-xl-0">
      <div class=" d-flex flex-column justify-content-center h-100">
        <div class="fs-3 text-center fw-500 mb-3 mb-xl-3" [innerHTML]="block['fields']['title']"></div>
        <div class="text-center fs-5 text-muted mb-lg-2" [innerHTML]="block['fields']['description']"></div>
        <div class="d-flex justify-content-center w-100 mb-3 mb-sm-4 mb-md-3 mb-xl-0">
          <upero-button [button]="block.fields['ctaButton']"></upero-button>
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-7 ps-xl-5 ps-xxl-0">
      <div
          class="row gx-5 gy-0 g-sm-0 row-icons">
          <div *ngFor="let item of block['fields']['items']"
               class="col-6 col-sm-3 d-flex flex-column text-center justify-content-center align-items-center h-100">
            <div class="position-relative">
              <icon class="fs-icon" [ngClass]="item['icon']['class']"></icon>
              <div class="icon-bg-circle" [style]="{'background-color': item.icon_bg_color}"></div>
            </div>
            <div class="mt-3">
              {{item.description}}
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
