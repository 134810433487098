<div class="d-flex flex-column h-100">
    <div class="d-flex flex-column flex-grow-1 align-items-center justify-content-center bg-cover-image">
        <div class="container my-4">
            <div class="row g-0">
                <div class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 shadow-lg">
                  <div class="d-flex flex-column bg-light py-4">
                    <div class="px-4 mb-3">
                      <svg-icon (click)="backToLogin()" src="assets/images/icon-arrow-left.svg" [class]="'hover'" [svgClass]="'icon--button'"></svg-icon>
                    </div>
                    <div class="d-flex flex-column my-auto">
                      <div class="d-flex flex-column justify-content-center text-center">
                        <svg-icon src="assets/images/upero.svg"
                                  [svgClass]="'logo--text'"></svg-icon>
                      </div>
                    </div>
                  </div>
                    <div class="d-flex flex-column align-items-center bg-white">
                        <div class="w-100 p-4 p-lg-5">
                            <div class="fs-4 text-center">
                                Forgot your password?
                            </div>
                            <hr>
                            <div class="text-center mb-4">
                              Thank you for your request, please check your email inbox and follow instructions to reset your password for
                              <span> {{confirmationEmail}}</span>
                            </div>
                            <div class="d-flex justify-content-center">
                              <upero-button [button]="block.fields.buttonLogin"></upero-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

