<section>
  <div class="container g-0 p-5">
    <div class="row g-0">
      <ng-container *ngFor="let space of spaces; let last = last">
        <upero-thumbnail-tile class="col-12 col-sm-6 col-md-6 col-xl-3 text-center px-4"
          [ngClass]="{ 'mb-5': !last}"
          [imageUrl]="space.imageUrl"
          [slug]="space.slug"
          [name]="space.name"
          [tileText]="space.tileText"
          [isPriceFromVisible]="space.isPriceFromVisible"
          [ctaTxt]="block.fields['productButton'].text"
          [urlBase]="'/spaces'"
          [ctaBtnClass]="buttonClass"
          [ctaBtnSize]="this.block.fields.productButton['size']"
        ></upero-thumbnail-tile>
      </ng-container>
    </div>
  </div>
</section>
