import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, AuthService, ChatService, NotificationService, UserService } from '@upero/services';
import { Store } from '@upero/store';
import { BaseBlockComponent, LoginFormFields } from '@upero/misc';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends BaseBlockComponent<LoginFormFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };
  @Input() authenticatedRedirect: Array<string> = null;

  @Output() authenticated = new EventEmitter();
  @Output() switchToRegister = new EventEmitter();
  @Output() forgottenPassword = new EventEmitter();

  form: FormGroup;
  submitted: boolean = false;
  showErrorMessage: boolean = true;

  user = {
    username: '',
    password: '',
    sessionId: '',
    extendedExpiration: true,
  };

  passwordValidated = true;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private chatService: ChatService,
    private store: Store,
  )
  {
    super();
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }


  ngOnInit(): void
  {
    this.form.get('username').valueChanges.subscribe((value) =>
    {
      this.form.get('password').updateValueAndValidity();
    });

    this.form.valueChanges.subscribe(selectedValue =>
    {
      this.showErrorMessage = false;
    })

  }

  login()
  {
    this.alertService.clearMessage();
    this.user.sessionId = localStorage.getItem('tmpSessionId');

    this.authService.login(this.user).subscribe(
      (data) =>
      {
        this.passwordValidated = true;

        this.userService.getByToken().subscribe((userData) =>
        {
          if (userData.data.guest) {
            this.passwordValidated = false;
            this.form.controls.password.setErrors({
              incorrect: true,
            });
          } else {
            this.chatService.receiveChat();
            this.authenticated.emit(true);
            if (this.authenticatedRedirect) {
              this.router.navigate(this.authenticatedRedirect);
            }
          }
        });
      },
      (error) =>
      {
        this.showErrorMessage = true;
        this.passwordValidated = false;
        this.form.controls.password.setErrors({ incorrect: true });
      }
    );
  }

  clearPasswordValidated()
  {
    this.passwordValidated = true;
  }

  toggleExtendedExpiration()
  {
    if (this.user.extendedExpiration) {
      this.user.extendedExpiration = false;
    } else {
      this.user.extendedExpiration = true;
    }
  }

  onSubmit()
  {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }
    this.user.username = this.form.value.username;
    this.user.password = this.form.value.password;
    this.login();
  }

  findInvalidControls()
  {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  doRegistration()
  {
    this.switchToRegister.emit();
  }

  triggerForgottenPassword()
  {
    this.form.reset();
    this.forgottenPassword.emit();
    this.router.navigate(AppInitService.doBrandModUrl(['/password', 'reset']));
  }
}
