import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';

@Injectable({
  providedIn: 'root'
})
export class RequestsService
{

  params = {
    companyId: '',
    message: '',
    type: undefined,
    status: '',
    limit: 15,
    limits: [15, 25, 50, 75, 100],
    page: 0,
    pages: 0,
    pageArray: [],
    totalRecords: 0,
    sort: 'company_requests.createdAt DESC',
    sorts: [],
  };

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  create(requestData)
  {

    return this.http
      .post(this.env.apiPath + 'company-request', { requestData })
      .pipe(map((data: any) => data));
  }

  update(requestData)
  {
    return this.http
      .put(this.env.apiPath + 'company-request', { requestData })
      .pipe(map((data: any) => data));
  }

  get(requestId)
  {
    return this.http
      .get(this.env.apiPath + 'company-request/' + requestId)
      .pipe(map((data: any) => data));
  }

  requestTypes(type)
  {
    return this.http
      .get(this.env.apiPath + 'company-request/types')
      .pipe(map((data: any) => data));
  }

  search()
  {
    return this.http
      .post(this.env.apiPath + 'company-request/search', { params: this.params })
      .pipe(
        map((data: any) =>
          {
            this.store.set('companyRequests', data.data.data);
            return data;
          }
        )
      );
  }
}
