import { Component, HostBinding, OnInit } from '@angular/core';
import { FavouritesService, ProductService } from '@upero/services';
import { Store } from '@upero/store';
import { BaseBlockComponent, SpecialOffersFields } from '@upero/misc';

@Component({
  selector: 'upero-special-offers',
  templateUrl: './special-offers.component.html',
  styleUrls: ['./special-offers.component.scss']
})
export class SpecialOffersComponent extends BaseBlockComponent<SpecialOffersFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  backgroundColour = 'bg-grey';
  showHeading = false;
  noSlider = false;

  deviceType$ = this.store.select<string>('deviceType');
  favourites$ = this.store.select<any[]>('favourites');
  products = [];
  showOverflow = true;

  constructor(
    private productsService: ProductService,
    private store: Store,
    private favouritesService: FavouritesService
  )
  {
    super();
    this.defaultFields = SpecialOffersFields.default();
    this.getProducts();
  }

  ngOnInit(): void
  {
  }

  getProducts()
  {
    this.productsService.specialOffers().subscribe(data => this.products = data.data);
  }

  toggleFavourite(productId)
  {
    const favourites = this.store.selectForLocal('favourites');
    if (favourites.indexOf(productId) < 0) {
      this.favouritesService.addToFavourites(productId);
    } else {
      this.favouritesService.deleteFavourite(productId);
    }
  }
}
