import { Component, OnInit } from '@angular/core';
import { ProductService } from "@upero/services";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@upero/store";
import { BaseBlockComponent, SpacesFields } from "@upero/misc";

@Component({
  selector: 'upero-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss'],
})
export class SpacesComponent extends BaseBlockComponent<SpacesFields> implements OnInit
{
  productGroups = [];
  spaces: Array<{
    imageUrl: string | null,
    slug: string
    name: string
    tileText: string
    isPriceFromVisible: false
  }> = [];
  buttonClass: string;

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private store: Store
  )
  {
    super();
  }

  ngOnInit(): void
  {
    const currentBrand = this.store.selectForLocal('currentBrand');
    const siteSettings = this.store.selectForLocal('siteSettings');
    const btnStyle = this.block.fields.productButton['style'] !== 'fill' ? this.block.fields.productButton['style'] + '-' : '';

    if (currentBrand) {
      this.buttonClass = 'btn-' + btnStyle + currentBrand.settings.colors[this.block.fields.productButton['intent']];
    } else {
      this.buttonClass = 'btn-' + btnStyle + this.block.fields.productButton['intent'];
    }

    for (const brand of siteSettings.brands) {
      for (const space of brand.spaces) {
        this.spaces.push({
          imageUrl: space.imageUrl,
          slug: space.slug,
          name: space.name,
          tileText: space.tileText,
          isPriceFromVisible: false
        });
      }
    }
  }
}
