import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Store } from '@upero/store';
import { OverlayService } from '@upero/services';
import { setTimeout$ } from '@upero/misc';

@Component({
  selector: 'upero-fixed-elements',
  templateUrl: './fixed-elements.component.html',
  styleUrls: ['./fixed-elements.component.scss'],
  animations: [
    trigger('enterAnimation', [
      state('void', style({ opacity: '0' })),
      transition('void <=> *', [
        animate('300ms')
      ])
    ])
  ]
})
export class FixedElementsComponent implements OnInit, AfterViewInit
{
  @Input() showChatInitially = true;
  @Input() topPosToStartShowing = 300;
  @Input() topPosToStartShowingChat = 300;
  @ViewChild('fixedElements') fixedElements: ElementRef<HTMLElement>;
  isBrowser = false;
  deviceType = this.store.selectForLocal('deviceType');
  overlays$ = this.store.select<any>('overlays');
  chatStore$ = this.store.select<any>('chatStore');
  currentBrand;
  isShow: boolean;
  isShowChat: boolean;
  footerIcons: HTMLElement;
  showButtons = false;


  @HostListener('window:scroll')
  @HostListener('window:resize')
  checkScroll()
  {
    if (this.isBrowser) {
      this.deviceType = this.store.selectForLocal('deviceType');
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || 0;
      this.isShow = scrollPosition >= this.topPosToStartShowing;

      if (!this.showChatInitially) {
        this.isShowChat = scrollPosition >= this.topPosToStartShowingChat;
      }

      if (this.deviceType === 'desktop') {
        this.footerIcons = document.getElementById('social-icons');

        if (this.footerIcons && this.fixedElements) {
          const iconsClientRect = this.footerIcons.getBoundingClientRect();
          const bodyRect = document.body.getBoundingClientRect();
          if (iconsClientRect.height > 0 && iconsClientRect.top + iconsClientRect.height * 2 <= bodyRect.height) {
            this.renderer.setStyle(
              this.fixedElements.nativeElement, 'padding-bottom',
              bodyRect.height - iconsClientRect.top - iconsClientRect.height * 2 + 19 + 'px'
            );
          } else {
            this.renderer.setStyle(this.fixedElements.nativeElement, 'padding-bottom', 0);
          }
        }
      }
    }
  }

  constructor(
    private store: Store,
    private overlayService: OverlayService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) platformId: any,
  )
  {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void
  {
    setTimeout$(() =>
    {
      this.currentBrand = this.store.selectForLocal('currentBrand');
      this.showButtons = true;
      this.checkScroll();
    }, 3000);
  }

  ngAfterViewInit()
  {
    if (this.isBrowser) {
      // @ts-ignore
      const resizeObserver = new ResizeObserver(() =>
        this.checkScroll()
      );
      resizeObserver.observe(document.body);
    }
  }

  gotoTop()
  {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  triggerQuickChat()
  {
    this.overlayService.toggleChat();
  }
}
