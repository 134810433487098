<div class="form--min-height bg-dark create-login-account-main position-relative">
    <img class="image-as-bg" [src]="'https://api.ysdev.co.uk/cms/images/2bb3a83b744558db9476f4b10526c89ce.jpg'">
    <div class="container h-100 py-3 py-md-5 z-index-2">
        <div class="row g-0 shadow-lg h-100 mb-4 mb-lg-0">
            <div class="col-12 col-lg-6 card--min-height create-account-main">
                <div class="d-flex flex-column justify-content-center align-items-center bg-white h-100 p-4">
                    <div class="d-flex align-items-center fs-1 ff-spectral fw-bold text-center h-33">
                        Create your account
                    </div>
                    <div class="card--max-width w-100 h-100 d-flex flex-column justify-content-between">
                        <div class="d-flex align-items-center fs-4 ff-spectral card--max-width text-center h-33">
                            Open a free private or trade account with us today, with lots of benefits from discounts to cashback!
                         </div>
                         <div class="d-flex justify-content-center align-items-end align-content-lg-start h-33 Sign-up-btn">
                             <a [uperoBrandRouterLink]="['/register']" class="btn btn-info fw-bold mt-3">
                                 Sign-up
                             </a>
                         </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 card--min-height account-login-main">
                <div class="bg-light h-100">
                    <div class="d-flex flex-column justify-content-center align-items-center bg-light h-100 p-4">
                        <div class="d-flex align-items-center fs-1 fw-bold text-center h-33">
                            Log-in to your account
                        </div>
                        <div class="card--max-width w-100 h-100">
                            <upero-login-form (authenticated)="authenticated($event)" (switchToRegister)="goToRegister()"></upero-login-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
