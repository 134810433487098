import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'upero-products-grid',
  templateUrl: './products-grid.component.html',
  styleUrls: ['./products-grid.component.scss']
})
export class ProductsGridComponent implements OnInit {
  @Input() block: any;
  @HostBinding('attr.class') get classes(): string {
    return 'col-' + this.block.cols;
  };

  constructor() { }

  ngOnInit(): void {
  }

}
