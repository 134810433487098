import { ActivatedRoute } from '@angular/router';
import { Component, HostBinding, OnInit } from '@angular/core';
import { AdviceService, BreadcrumbsService, SeoService } from '@upero/services';
import { Store } from '@upero/store';
import { AdviceDetailFields, BaseBlockComponent } from '@upero/misc';
import {defer, first, iif, map, mergeMap, of} from "rxjs";
import {Title} from "@angular/platform-browser";
import {CollectionsService} from "@upero/collections";
import {PagesService} from "@upero/pages";

@Component({
  selector: 'upero-advice-detail',
  templateUrl: './advice-detail.component.html',
  styleUrls: ['./advice-detail.component.scss']
})
export class AdviceDetailComponent extends BaseBlockComponent<AdviceDetailFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  currentBrand = this.store.selectForLocal('currentBrand');
  entry;

  constructor(
    private route: ActivatedRoute,
    private collectionsService: CollectionsService,
    private pagesService: PagesService,
    private store: Store,
    private breadcrumbsService: BreadcrumbsService,
    private seoService: SeoService,
    private titleService: Title,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.collectionsService
      .getPageBySlugType(this.route.snapshot.params.slug, this.block.fields.collectionType)
      .pipe(
        mergeMap((data: any) =>
          iif(
            () => data.length > 0,
            of(data),
            defer(() => this.pagesService.getPageBySlug('404')),
          )
        ),
        map(data => data[0]),
        first()
      )
      .subscribe(entry => {
        this.entry = entry;
        this.titleService.setTitle(entry.title);
        this.breadcrumbsService.changeBreadcrumb([
          {text: entry.title, route: ['/advice']},
        ]);
      });
  }

  setMetaTags(data)
  {
    this.seoService.updateTitle(data.metaTitle ? data.metaTitle : data.title);
    if (!data.metaDescription || data.metaDescription === '') {
      data.metaDescription = data.content.replace(/<[^>]*>/g, '').substring(0, 155);
    }
    this.seoService.addMetaTag('description', data.metaDescription);
    this.seoService.createLinkForCanonicalURL();
  }
}
