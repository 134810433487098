import { Injectable } from '@angular/core';

class BlockFieldsBase
{
  public getDescription()
  {
    Object.keys(this).forEach(item =>
    {
      if (this[item] instanceof Array) {
        console.log(item + ' is Array');
      }
      if (typeof this[item] == 'number') {
        console.log(item + ' is number');
      }
      if (typeof this[item] == 'string') {
        console.log(item + ' is string');
      }
    })
  }
}


export type BlockData<T> = {
  title: string;
  background: string;
  backgroundColour?: string;
  backgroundImage?: string;
  x: number;
  y: number;
  cols: number;
  rows: number;
  type: string;
  deleted: boolean;
  fields: T;
  xOffset?: number;
}

const defaultBlockData = <T>(fields) => ({
  title: '',
  background: '',
  x: 0,
  y: 0,
  cols: 0,
  rows: 0,
  type: '',
  deleted: false,
  fields: fields,
} as BlockData<T>);

@Injectable()
export abstract class BaseBlockComponent<T>
{
  // @HostBinding not abble to inherit this one
  private _block: BlockData<T>;
  private _defaultFields: T | null = null;

  set defaultFields(defaultData: T){
    this._defaultFields = defaultData;
    if (!this._block) {
      this._block = defaultBlockData(defaultData);
    }
  }

  get cssClass(): string
  {
    if (!this.block) {
      return '';
    }
    return 'col-' + this.block.cols + (this.block.xOffset ? ' offset-sm-' + this.block.xOffset : '');
  }

  set block(block: BlockData<T>)
  {
    this._block = block;
    if (this._defaultFields) {
      Object.keys(this._defaultFields).forEach(item => {
        if (this._block.fields[item] === undefined || this._block.fields[item] === null) { // null should be removed I think need it for now only until admins works
          this._block.fields[item] = this._defaultFields[item];
        }
      });
    }
  }

  get block(): BlockData<T>
  {
    return this._block;
  }
}

/* Custom Component Data */

export class ContactFields extends BlockFieldsBase
{
  title: string;
  inquiryOptions: Array<{ id: number, name: string }>; // default value
  submitButton: any;
  phone: string;
  email: string;
  count: number;

  public static default = () => (
    {
      title: 'Enquiry Form',
      inquiryOptions: [
        { id: 1, name: 'Ordering' },
        { id: 2, name: 'Delivery' },
        { id: 3, name: 'Trade account' },
        { id: 4, name: 'Product help' },
        { id: 5, name: 'General enquiry' },
      ],
      phone: '01483 930 104',
      email: 'sales@yorkstonesupplies.co.uk',
      count: 3
    } as ContactFields
  );
}

export class GoogleMapsFields extends BlockFieldsBase
{
  public height: number;

  public static default = () => (
    {
      height: 3
    } as GoogleMapsFields
  );
}

export class IntoPageFields extends BlockFieldsBase
{
  title: string;
  text: string;
  backgroundImgSrc: string;
  hiddenIfEmpty: boolean;

  public static default = () => (
    {
      title: 'Get in touch',
      text: 'If you want help finding the right stone, need to discuss an order, would like to order a complimentary sample, or something else entirely, just get in touch using the form below.',
      backgroundImgSrc: 'https://ys-assets.b-cdn.net/cms/images/264f7ba102a3856984c3207765d5b1546.jpg?width=1920',
      hiddenIfEmpty: false
    } as IntoPageFields
  );
}

export class FooterFields extends BlockFieldsBase
{
  collectionType: string;
  subLinksCollectionType: string;
}

export class HeaderFields extends BlockFieldsBase
{
  navbar_class: 'dark';
  collectionType: string;
  hideStoreIcons: boolean;
}

export class TruspilotSliderFields extends BlockFieldsBase
{

}

export class FaqFields extends BlockFieldsBase
{
  topics: string;
  pageSlug: string;
}

export class FavouritesFields extends BlockFieldsBase
{
  buttonSignIn: any;
  buttonCreateAccount: any;
  notLoggedInBg: string;
}

export class CtaContactFields extends BlockFieldsBase
{
  backgroundClass: string;
  title: string;
  buttonLink: string;
  subLine: string;
  buttonCta: any;
}

export class AdviceFields extends BlockFieldsBase
{
  collectionType: string;
  pageSlug: string;
}

export class AdviceDetailFields extends BlockFieldsBase
{
  collectionType: string;
  productLink: string;
}

export class FaqDetailFields extends BlockFieldsBase
{
  topics: string;
  collectionType: string;
}

export class LoyalityPageIntroFields extends BlockFieldsBase
{
}

export class PageNotFoundFieldsFields extends BlockFieldsBase
{
}

export class QualityEtchedFields extends BlockFieldsBase
{
}

export class SignUpOrLoginFields extends BlockFieldsBase
{
  backgroundImage: string;
}

export class PasswordResetFields extends BlockFieldsBase
{
  buttonSubmit: any;
}

export class PasswordResetConfirmationFields extends BlockFieldsBase
{
}

export class PasswordSetFields extends BlockFieldsBase
{
}

export class PasswordSetConfirmationFields extends BlockFieldsBase
{
  buttonLogin: any;
}

export class BlogCategoryFields extends BlockFieldsBase
{
}

export class BlogCategoriesFields extends BlockFieldsBase
{
}

export class ArticleFields extends BlockFieldsBase
{
  collectionType: string;
}

export class ProductRangeDetailFields extends BlockFieldsBase
{
  rangeSlug: string;
}

export class ProductGroupDetailFields extends BlockFieldsBase
{
  productButton: any;
}

export class GroupsDetailFields extends BlockFieldsBase
{
  groupButton: any;
}

export class RegistrationFormTradeAccountFields extends BlockFieldsBase
{
  buttonSubmit: any;
}

export class RegisterFields extends BlockFieldsBase
{
  submitButtonCustomer: any;
  submitButtonTrade: any;
}

export class AccountConfirmation extends BlockFieldsBase
{
}

export class RegistrationFormFields extends BlockFieldsBase
{
  buttonSubmit: any;
}

export class SignoutFields extends BlockFieldsBase
{
}

export class PromoCaseStudyFields extends BlockFieldsBase
{
  title: string;
  caseStudy: number;
  previewParagraph: string;
}

export class ProductTypesSliderFields extends BlockFieldsBase
{
  title: string;
  backgroundColour?: string;

  productGroupIds: any[];

  buttonCta: any;

  public static default = () => (
    {

    } as ProductTypesSliderFields
  );
}

export class UspFields extends BlockFieldsBase
{
  items: Array<{icon_name: string, description: string, icon_bg_color: string}>
  backgroundColor: string;
  button_class: string;
}

export class ImageTextBlock extends BlockFieldsBase
{
  backgroundClass: string;
  colorClass: string;
  boxed: boolean;
  fontSizeTitle: string;
  fontSizeText: string;
  blockHeight: string;
}


export class PartnersCarouselFields extends BlockFieldsBase
{
  title: '';
  subline: '';
  items: Array<{ image: string, link: string }>;
}

export class ProductDetailsFields extends BlockFieldsBase
{
}

export class BespokeFormPageFields extends BlockFieldsBase
{
}

export class BasketFields extends BlockFieldsBase
{
}

export class BaseBlockFields extends BlockFieldsBase
{
}

export class BasketReloadFields extends BlockFieldsBase
{
}

export class BasketPaymentFields extends BlockFieldsBase
{
}

export class BasketDeliveryInformationFields extends BlockFieldsBase
{
}

export class BasketDeliveryFields extends BlockFieldsBase
{
}

export class BasketOrderConfirmationFields extends BlockFieldsBase
{
}

export class BasketOrderApprovalFields extends BlockFieldsBase
{
}

export class BasketBillingFields extends BlockFieldsBase
{
}

export class UserGuestRegisterFields extends BlockFieldsBase
{
}

export class TabsTermsFields extends BlockFieldsBase
{
}

export class NewsletterCtaFields extends BlockFieldsBase
{
}

export class JobTilesFields extends BlockFieldsBase
{
  buttonSubmit: any;
}

export class CaseStudyFields extends BlockFieldsBase
{
  collectionType: string;
  pageSlug: string;
  ctaButton: any;
}

export class BespokeFormFields extends BlockFieldsBase
{
  sendButton: string;
  cancelButton: string;
}

export class CollectionsEntrySingleFields extends BlockFieldsBase
{
  collectionType: string;
}

export class BlogEntrySingleFields extends BlockFieldsBase
{
  collectionType: string;
}

export class BrandFields extends BlockFieldsBase
{
}

export class ProductRangesFields extends BlockFieldsBase
{
  buttonText: string;
  buttonColour: string;

  public static default = () => (
    {
      buttonText: 'View the range',
      buttonColour: 'btn-primary',
    } as ProductRangesFields
  );
}

export class GallerySliderFields extends BlockFieldsBase
{
  slidesPerView: number;
  slides: any[];
  loopImages: boolean;
  showDots: boolean;
  showNav: boolean;
  arrowColour: string;
  buttonClass: string;
  buttonLink: string;
  showGradient: boolean;
}
export class FeaturedProductsSliderFields extends BlockFieldsBase
{
  title: string;
  slidesPerView: number;
  slides: any[];
  loopImages: boolean;
  showDots: boolean;
  showNav: boolean;
  arrowColour: string;
  buttonClass: string;
  buttonLink: string;
  backgroundColour: string;
  productGroupId: any;
  buttonCta: any;
}

export class BrandSliderFields extends BlockFieldsBase
{
  slidesPerView: number;
  slides: any[];
  loopImages: boolean;
  showDots: boolean;
  showNav: boolean;
  arrowColour: string;
  backgroundColour: string;
}

export class SpacesSlider extends BlockFieldsBase
{
  title: string;
  slidesPerView: number;
  slides: any[];
  loopImages: boolean;
  backgroundColor: string;
  buttonCta: any;
  textColor: string;
}


export class SpecialOffersFields extends BlockFieldsBase
{
  showHeading: boolean;
  noSlider: boolean;

  public static default = () => (
    {
      showHeading: false,
      noSlider: false
    } as SpecialOffersFields
  );
}

export class ProductTypesFields extends BlockFieldsBase
{
}

export class ProductSpacesFields extends BlockFieldsBase
{
  productButton: any;
}

export class SpacesFields extends BlockFieldsBase
{
  productButton: any;
}

export class LoginFormFields extends BlockFieldsBase
{
}

export class BreadcrumbFields extends BlockFieldsBase
{
  absolute: boolean;
  textColor: string;

  public static default = () => (
    {
      absolute: false
    } as BreadcrumbFields
  );
}

export class WysiwygFields extends BlockFieldsBase
{
  container: string;
  paddingH: string;
  paddingV: string;
  type: string;
  contentColumn: string;
  content: string;
  backgroundColor: string;
  textColor: string;
  fontSize: string;

  public static default = () => (
    {
      container : "d-flex align-items-center h-100",
      paddingH : "py-5",
      paddingV : "bg-grey px-5",
      type : "wysiwyg",
      contentColumn : "",
      content : "<p class=\"ff-spectral fs-5 fw-bold\" style=\"text-align: center;\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum id molestie enim. Morbi dignissim augue quis semper egestas. Donec efficitur nulla ac ex gravida, pellentesque ornare est condimentum. Morbi dapibus lorem justo, quis dignissim mauris varius sit amet.</p>"
    } as WysiwygFields
  );
}

export class CmsGridFields extends BlockFieldsBase
{
  container: string;
  paddingH: string;
  paddingV: string;
  type: string;
  gridGutters: boolean;
  reverseOnMobile: boolean;
  grid: Array<{
    backgroundImage: string;
    paddingH: string;
    paddingV: string;
    backgroundColour: string;
    classes: string;
    content: string;
  }>;

  public static default = () => (
    {
      container: 'h-100',
      paddingH: '',
      paddingV: '',
      type: 'grid',
      gridGutters: false,
      reverseOnMobile: true,
      grid: [
        {
          backgroundImage: 'https://ys-assets.b-cdn.net/cms/images/0dacc256de66d106f102647410401de882c.png',
          paddingH: '',
          paddingV: '',
          backgroundColour: '',
          classes: 'grid-tile',
          content: '&nbsp;'
        },
        {
          backgroundImage: null,
          paddingH: 'px-7 py-100 px-100',
          paddingV: '',
          backgroundColour: '',
          classes: 'grid-tile',
          content: '<h2 style="text-align: center;">Born in Britain</h2>\n' +
            '<p style="text-align: center;">&nbsp;</p>\n' +
            '<p style="text-align: center;">We’ve been supporting British workers and industry since we first began. We only source stone from the best quarries in Yorkshire, and we only use the best stonemasons to work it, using innovative processing techniques that help us keep our carbon footprint to a minimum. All our stone is dug, cut and carved right here in Yorkshire. And it always will be.</p>'
        }
      ]
    } as CmsGridFields
  );
}
