import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FACEBOOK_PIXEL_SETTINGS_TOKEN } from '../token/facebook-pixel-settings-token';
import { GOOGLE_ANALYTICS_SETTINGS_TOKEN } from '../token/google-analytics-settings-token';
import { CookieService } from 'ngx-cookie-service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  constructor(
    private cookieService: CookieService,
    @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private readonly gaSettings: any,
    @Inject(FACEBOOK_PIXEL_SETTINGS_TOKEN) private readonly fbqSettings: any,
    @Inject(PLATFORM_ID) private readonly platformId: any
  ) {}

  getGaEnabled() {
    return this.gaSettings.isEnabled;
  }

  setGaEnabled(value: boolean) {
    this.gaSettings.isEnabled = value;
  }

  getFbqEnabled() {
    return this.fbqSettings.isEnabled;
  }

  setFbqEnabled(value: boolean) {
    this.fbqSettings.isEnabled = value;
  }

  getConsentDialogEnabled() {
    if (isPlatformBrowser(this.platformId)) {
      return !this.cookieService.get('consentDialogEnabled');
    }
    return null;
  }

  setConsentDialogEnabled(value: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set('consentDialogEnabled', value ? '1' : '0');
    }
  }

  getGaEnabledCookie() {
    if (isPlatformBrowser(this.platformId)) {
      return this.cookieService.get('gaEnabled');
    }
    return null;
  }

  setGaEnabledCookie(value: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set('gaEnabled', value ? '1' : '0');
    }
  }

  getFbqEnabledCookie() {
    if (isPlatformBrowser(this.platformId)) {
      return this.cookieService.get('fbqEnabled');
    }
    return null;
  }

  setFbqEnabledCookie(value: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set('fbqEnabled', value ? '1' : '0');
    }
    return null;
  }

  getMsqEnabledCookie() {
    if (isPlatformBrowser(this.platformId)) {
      return this.cookieService.get('msqEnabled');
    }
    return null;
  }

  setMsqEnabledCookie(value: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieService.set('msqEnabled', value ? '1' : '0');
    }
    return null;
  }
}
