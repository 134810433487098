import { Inject, Injectable } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';
import { ToolsService } from './tools.service';
import { Store } from '@upero/store';
import { BasketService, FavouritesService, UserService } from '@upero/services';
import { CookieService } from 'ngx-cookie-service';
import { ENVIRONMENT_CONFIG, EnvironmentType, IS_LOGGED_IN, USER_COOKIE } from '@upero/misc';
import { ApiAuthHttpClient } from '@upero/misc';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authInterval;

  constructor(
    private apiAuthHttp: ApiAuthHttpClient, // errors, single and authentication request handled
    private store: Store,
    private cookieService: CookieService,
    private userService: UserService,
    private basketService: BasketService,
    private toolsService: ToolsService,
    private favouritesService: FavouritesService,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  ) {
    this.toolsService.toolsEvents$$
      .pipe(filter((item) => item === 'clearUserStorages'))
      .subscribe(() => this.clearUserStore().clearUserCookies());
  }

  getTmpSessionId() {
    const sessionId = localStorage.getItem('sessionIdTmp');
    if (!sessionId) {
      const newSessionId = this.toolsService.newUUID();
      localStorage.setItem('sessionIdTmp', this.toolsService.newUUID());
      return newSessionId;
    } else {
      return sessionId;
    }
  }

  login(user) {
    const sessionId = this.store.selectForLocal('sessionId');

    if (sessionId) {
      user.sessionId = sessionId;
    }

    return this.apiAuthHttp.post(this.env.apiPath + 'users/login', user).pipe(
      map(async (data: any) => {
        this.initUserAuth(user);
        return data;
      })
    );
  }

  initUserAuth(user) {
    this.store.set('loggedIn', true);
    this.cookieService.set(USER_COOKIE, JSON.stringify(user), undefined, '/');
    this.cookieService.set(IS_LOGGED_IN, JSON.stringify(true), undefined, '/');
    this.toolsService.setSessionId(user.id);
    localStorage.setItem('user', user.id);

    // sync favourites
    this.favouritesService.saveRemote();

    this.basketService.setGuest(false);
  }

  logout() {
    return this.apiAuthHttp.post(this.env.apiPath + 'users/logout', {}).pipe(tap(() => this.cleanUserData()));
  }

  cleanUserData() {
    this.basketService.clearUserFromBasket();
    this.toolsService.logoutSessionId();
    this.clearUserStore().clearUserCookies();
    return this;
  }

  clearUserStore() {
    this.store.set('user', undefined);
    this.store.set('guestUser', undefined);
    this.store.set('token', undefined);
    return this;
  }

  clearUserCookies() {
    this.cookieService.delete(IS_LOGGED_IN, '/');
    this.cookieService.delete(USER_COOKIE, '/');
    return this;
  }

  passwordReset(email) {
    const dataToSend = {
      email,
    };
    return this.apiAuthHttp.post(this.env.apiPath + 'users/password/reset', dataToSend).pipe(map((data: any) => data));
  }

  passwordSet(resetData) {
    return this.apiAuthHttp.post(this.env.apiPath + 'users/password/set', resetData).pipe(map(async (data: any) => data));
  }

  authTimeout() {
    /*        this.authInterval = setInterval(() => {
                let ttl = this.store.selectForLocal('ttl');
                ttl--;
                this.store.set('ttl', ttl);
                if (ttl === 0) {
                    this.logout();
                }
            }, 50000);*/
  }

  resetAuthTimeout() {
    this.store.set('ttl', 1000); // reset to 10
  }

  clearAuthTimeout() {
    clearInterval(this.authInterval);
  }
}
