import { Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Directive({
  selector: 'img, [uperoScrset]'
})
export class ScrsetDirective implements OnInit, OnChanges
{
  @Input() appScrset;
  @Input() width;
  @Input() height;
  @Input() aspectRatio;
  @Input() crop;
  @Input() doCndReplace: boolean = false;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2,
    @Inject(ENVIRONMENT_CONFIG) public env: EnvironmentType
  )
  {
    if (this.el.nativeElement.tagName === 'IMG') {
      this.el.nativeElement.setAttribute('loading', 'lazy');
    }
  }

  ngOnInit()
  {
    this.renderImage();
  }

  ngOnChanges(changes: SimpleChanges)
  {
    if (changes) {
      this.renderImage();
    }
  }

  renderImage()
  {
    if (this.appScrset && this.el.nativeElement) {
      const imgSrc = this.doCndReplace ? this.appScrset.replace(this.env.cndReplacePaths[0], this.env.cndReplacePaths[1]) : this.appScrset;
      if (!this.width) {
        this.width = this.el.nativeElement.width;
      }

      if (!this.height) {
        this.height = this.el.nativeElement.height;
      }

      if (this.el.nativeElement.tagName === 'IMG') {
        if (this.width) {
          let srcSet = '';

          srcSet = srcSet.concat(imgSrc + `?width=` + this.width + ` ` + this.width + `w, `);

          if (349 <= this.width) {
            srcSet = srcSet.concat(imgSrc + `?width=349 349w,`);
          }

          if (575 <= this.width) {
            srcSet = srcSet.concat(imgSrc + `?width=575 575w,`);
          }

          if (767 <= this.width) {
            srcSet = srcSet.concat(imgSrc + `?width=767 767w,`);
          }

          if (991 <= this.width) {
            srcSet = srcSet.concat(imgSrc + `?width=991 991w,`);
          }

          if (1199 <= this.width) {
            srcSet = srcSet.concat(imgSrc + `?width=1199 1199w,`);
          }

          if (1399 <= this.width) {
            srcSet = srcSet.concat(imgSrc + `?width=1399 1399w,`);
          }

          if (1900 <= this.width) {
            srcSet = srcSet.concat(imgSrc + `?width=1900 1900w`);
          }

          this.renderer.setAttribute(this.el.nativeElement, 'srcset', srcSet);
          this.renderer.setAttribute(this.el.nativeElement, 'sizes', '(max-width: ' + this.width + ') 100vw, ' + this.width);
          if (this.width) {
            if (this.aspectRatio) {
              this.renderer.setAttribute(this.el.nativeElement, 'src', imgSrc + `?`+ `aspect_ratio=` + this.aspectRatio + `&` + `width=` + this.width);
            } else if (this.crop) {
              this.renderer.setAttribute(this.el.nativeElement, 'src', imgSrc + `?`+ `crop=` + this.crop + `&` + `width=` + this.width);
            } else {
              this.renderer.setAttribute(this.el.nativeElement, 'src', imgSrc + `?width=` + this.width);
            }
          }
        }
      } else {
        const srcSet =
          `-webkit-image-set(` +
          `url("` + imgSrc + `?width=` + this.width + `") 1x, ` +
          `url("` + imgSrc + `?width=` + this.width * 2 + `") 2x, ` +
          `)`;
        this.renderer.setStyle(this.el.nativeElement, 'background-image', srcSet);
      }

    }
  }
}
