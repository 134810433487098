import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Store } from '@upero/store';
import { OverlayService } from '@upero/services';

@Component({
  selector: 'upero-account-menu-mobile',
  templateUrl: './account-menu-mobile.component.html',
  styleUrls: ['./account-menu-mobile.component.scss'],
  animations: [
    trigger('slideLeftRight', [
      state('void', style({ transform: 'translateX(-100%)', opacity: '0.9' })),
      transition('void <=> *', [
        animate('200ms')
      ])
    ])
  ]
})
export class AccountMenuMobileComponent implements OnInit, OnDestroy
{
  @Input() user;
  @Output() navigationChange = new EventEmitter();
  settings$ = this.store.select<any>('settings');
  basket$ = this.store.select<any>('basket');
  deviceType$ = this.store.select<string>('deviceType');
  favourites$ = this.store.select<string>('favourites');
  isOpen = true;

  step = 'dashboard';
  overlays$ = this.store.select<any>('overlays');
  overlayData;

  onSlideLeftRightEnd()
  {
    if (!this.isOpen) {
      this.close();
    }
  }

  constructor(
    private store: Store,
    private overlayService: OverlayService,
    public router: Router,
    private renderer: Renderer2
  )
  {
  }

  ngOnInit(): void
  {
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  ngOnDestroy()
  {
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }

  startClosing()
  {
    this.isOpen = false;
  }

  close()
  {
    this.store.set('mobileMenuAccount', false);
    this.overlayService.closeAll();
  }

  goToRouteWithDelay(route)
  {
    this.startClosing();
    this.router.navigate([route]);
  }

  setStep(v)
  {
    this.step = v;
    this.navigationChange.emit(v);
  }

  editUser()
  {
    this.store.set('userToEdit', this.store.selectForLocal('user'));
    this.setStep('user-edit');
  }

  addUser()
  {
    this.overlayService.toggle('userEdit');
  }

  requestCreate()
  {
    this.overlayData = {
      id: '',
      companyId: '',
      createdBy: '',
      type: 0,
      message: '',
    };

    this.store.set('overlayData', this.overlayData);
    this.overlayService.toggle('requestCreate');

  }
}
