import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import KeenSlider from 'keen-slider';

@Component({
  selector: 'upero-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() block: any;
  @HostBinding('attr.class') get classes(): string {
    return 'col-' + this.block.cols;
  };
  @HostBinding('style') get styles() {
    return { height: this.block.rows * 100 + 'px' };
  };

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement> | undefined
  slider: any | undefined;
  currentSlide = 1;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.sliderRef) {
      this.slider = new KeenSlider(this.sliderRef.nativeElement)
    }
  }

  ngOnDestroy(): void {
    if (this.slider) {
      this.slider.destroy()
    }
  }
}
