<div *ngIf="!confirmationData.open" class="modal" tabindex="-1">
  <div class="modal-dialog modal-fullscreen-lg-down modal-xl">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="ms-auto">
          <button (click)="close()" type="button" class="btn-close" aria-label="Close"></button>
        </div>
        <h3 class="modal-title fw-bold text-center">
          Manage Addresses
        </h3>
      </div>
      <div class="modal-body modal--max-width p-3 p-lg-5">
        <div class="d-flex justify-content-center mt-3 mt-lg-0 mb-4 mb-lg-5">
          <button
            (click)="addAddress()"
            type="button"
            class="btn btn-primary">
            Add new address
          </button>
        </div>
        <div *ngFor="let address of addresses" class="row g-0 mb-3 mb-sm-0">
          <div class="col-12 col-sm-6 pb-1 pe-sm-1">
            <div class="d-flex flex-column bg-light p-3">
              <div class="fw-bold">
                {{address.add1}}
                <span *ngIf="address.add2">, {{address.add2}}</span>
                <span *ngIf="address.add3">, {{address.add3}}</span>
                , {{address.town}}
                <span *ngIf="address.county">, {{address.county}}</span>
                , {{address.postcode}} - {{address.country}}
              </div>
              <div class="small text-muted">
                <p class="m-0 mt-1">Is the road approaching the delivery site on a slope?
                  <span *ngIf="address.restrictions.slope" class="text-uppercase">Yes</span>
                  <span *ngIf="!address.restrictions.slope" class="text-uppercase">No</span>
                </p>
                <p class="m-0 mt-1">Do you have a gravel driveway?
                  <span *ngIf="address.restrictions.gravel" class="text-uppercase">Yes</span>
                  <span *ngIf="!address.restrictions.gravel" class="text-uppercase">No</span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-4 col-sm-2 pb-1 pe-1">
            <div class="p-3 d-flex flex-column justify-content-center align-items-center h-100"
                 [ngClass]="address.isBilling ? 'bg-blue' : 'bg-light'">
              <div (click)="setBillingAddress(address)" class="form-check">
                <input class="form-check-input"
                       type="radio"
                       [checked]="address.isBilling">
                <label class="form-check-label">
                  Billing
                </label>
              </div>
            </div>
          </div>
          <div class="col-4 col-sm-2 pb-1 pe-1">
            <div class="p-3 d-flex flex-column justify-content-center align-items-center h-100"
                 [ngClass]="basket.deliveryAddress.id === address.id ? 'bg-blue' : 'bg-light'">
              <div (click)="setDeliveryAddress(address)" class="form-check">
                <input class="form-check-input"
                       type="radio"
                       [checked]="basket.deliveryAddress.id === address.id">
                <label class="form-check-label">
                  Delivery
                </label>
              </div>
            </div>
          </div>
          <div class="col-4 col-sm-2 pb-1">
            <div class="bg-light p-3 d-flex justify-content-center align-items-center h-100">
              <button (click)="editAddress(address)" class="btn-blank">
                <svg-icon src="assets/images/icon-edit.svg"
                          [svgClass]="'icon--small'" [class]="'me-2'"></svg-icon>
              </button>
              <button (click)="deleteConfirm(address)" class="btn-blank">
                <svg-icon src="assets/images/icon-trash.svg"
                          [svgClass]="'icon--small'" [class]="'ms-2'"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="d-flex modal--max-width justify-content-between px-3 px-lg-5">
          <button (click)="close()" type="button" class="btn btn-outline-primary">Cancel</button>
          <button (click)="save()" type="button" class="btn btn-primary">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
