<ng-container *ngIf="page">
  <ng-container *ngIf="page.layout === 'default' || !page.layout">

    <div class="w-100 h-100">
      <upero-block-loader *ngIf="page" [pageBlocks]="page.blocks"></upero-block-loader>
    </div>

  </ng-container>

</ng-container>

<upero-alert
  [data]="(alerts$ | async)"
  *ngIf="
        (alerts$ | async).errors.length ||
        (alerts$ | async).notifications.length ||
        (alerts$ | async).simple ||
        (alerts$ | async).confirmAction ||
        (alerts$ | async).customAlert
"></upero-alert>
<upero-fixed-elements></upero-fixed-elements>
<upero-chat *ngIf="(overlays$ | async).chat"></upero-chat>
<upero-cookie-consent-dialog></upero-cookie-consent-dialog>
<div class="modal-backdrop fade show modal-slide-show"
     (click)="overlayService.closeAll()"
     *ngIf="(asideOpen$ | async)">
  <upero-processing *ngIf="(overlays$ | async).processing"></upero-processing>
</div>
