import { delay, first } from 'rxjs/operators';
import { of } from 'rxjs';

export function setTimeout$(cb: () => void, timer: number)
{
  of(true).pipe(delay(timer), first()).subscribe(cb);
}

export function getSetTimeout$(cb: () => void, timer: number)
{
  return of(true).pipe(delay(timer), first()).subscribe(cb);
}

export function debounce(fn: Function, tm: number) {
  let timeout = undefined;
  return async function(...rest) {
    const that = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(that, rest), tm)
  }
}

