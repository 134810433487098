<div class="position-relative">
    <div class="keen-slider" #sliderRef [ngClass]="{'overflow-visible': showOverflow}">
        <div
            class="keen-slider__slide text-center px-1 px-lg-2"
            [ngClass]="{'active' : gi === currentSlide}"
            *ngFor="let pg of tiles; let gi = index">
            <div  class="square-image position-relative d-inline-block">
                <a [uperoRouterLinkSwipeProtected]="['/products', pg.slug]">
                    <img class="image-as-bg hover"
                         [src]="pg.productImages.length ? pg.productImages[0].url : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                         [appScrset]="pg.productImages.length ? pg.productImages[0].url : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                         [alt]="pg.name"
                         [width]="1000">
                </a>
                <div class="corner">
                   <span>
                       <svg-icon src="assets/images/icon-special-offer.svg" [svgClass]="'group-icon'"></svg-icon><br/>
                        {{pg.productLineDiscount}}% off
                   </span>
                </div>
            </div>
            <div class="mt-4 mb-3">
                <a class="fs-6 fw-bold title"
                   [uperoRouterLinkSwipeProtected]="['/products', pg.slug]">{{pg.name}}</a>
            </div>
            <p class="d-flex justify-content-center align-items-center">
                <span class="small me-2">Prices from</span>
                <span class="text-danger text-decoration-line-through me-2">
                {{pg.preDiscountedPrice | currency: 'GBP' : 'symbol' : '1.0-0' }}
                </span>
                <span class="fs-5 fw-bold me-2">
                    {{ pg.pricesFrom | currency: 'GBP' : 'symbol' : '1.0-0' }}
                </span>
                <button (click)="toggleFavourite(pg.id)" class="d-inline-block btn-blank">
                    <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) < 0"
                              src="assets/images/icon-heart.svg"
                              [svgClass]="'product-details__favorite__svg'"></svg-icon>
                    <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) >= 0"
                              src="assets/images/icon-heart.svg"
                              [svgClass]="'product-details__favorite__svg--active'"></svg-icon>
                </button>
            </p>
            <a class="btn btn-wide btn-primary fw-bold mb-3"
               [uperoRouterLinkSwipeProtected]="['/products', pg.slug]">
                View Product
            </a>
        </div>
    </div>
    <ng-container *ngIf="showNav">
        <div (click)="prevSlide($event)"
             (mouseenter)="arrowPreviousState(true)"
             (mouseleave)="arrowPreviousState(false)"
             [ngClass]="'arrow arrow--left arrow-special-offers ' + ( currentSlide === 0 ? 'arrow--disabled' : '')">
            <svg-icon
                [src]="arrowLeftSrc"
                [style.stroke]="arrowColour"
                [style.fill]="arrowColour">
            </svg-icon>
        </div>

        <div (click)="nextSlide($event)"
             (mouseenter)="arrowNextState(true)"
             (mouseleave)="arrowNextState(false)"
             [ngClass]="'arrow arrow--right arrow-special-offers ' + ((currentSlide + slidesPerView) === tiles.length ? 'arrow--disabled' : '')">
            <div class="arrow-animation__container arrow-animation--pulse"
                 [ngClass]="{'animation--start': highlightAnimation}"
                 snInViewport
                 (inViewportChange)="onInViewportChange($event)">
                <svg-icon
                    *ngIf="slider"
                    [src]="arrowRightSrc"
                    [style.stroke]="arrowColour"
                    [style.fill]="arrowColour">
                </svg-icon>
            </div>
        </div>
    </ng-container>
</div>
<div class="d-flex justify-content-center d-xl-none pt-5" *ngIf="showDots">
    <button
        (click)="slider.moveToSlideRelative(i)"
        *ngFor="let slide of dotHelper; let i = index"
        [class]="'dot ' + (i === currentSlide ? 'active' : '')"
    ></button>
</div>

