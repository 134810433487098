import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { CmsService } from '@upero/services';
import { Store } from '@upero/store';
import { BaseBlockComponent, TabsTermsFields } from '@upero/misc';

@Component({
  selector: 'upero-tabs-terms',
  templateUrl: './tabs-terms.component.html',
  styleUrls: ['./tabs-terms.component.scss']
})
export class TabsTermsComponent extends BaseBlockComponent<TabsTermsFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('scrollTabAnchor') scrollTabAnchor: ElementRef;
  deviceType$ = this.store.select<string>('deviceType');
  navTab = 'consumers';
  content;

  constructor(private cmsService: CmsService, private store: Store)
  {
    super();
  }

  ngOnInit(): void
  {
    this.cmsService.getTermsContent().subscribe((data) =>
    {
      this.content = data;
    });
  }

  setNavTab(v)
  {
    if (this.navTab === v) {
      this.navTab = '';
    } else {
      this.navTab = v;
      const top = this.scrollTabAnchor.nativeElement.getBoundingClientRect().top + window.scrollY - 200;
      window.scrollTo({
        top,
        behavior: 'smooth'
      });
    }
  }
}
