<div class="w-100" uperoBgSetter *ngIf="!(user$ | async)">
  <div class="container g-4">
    <div class="row g-4 py-4 my-auto justify-content-center">
      <div class="col-12 col-lg-auto text-center text-lg-end mb-3 my-lg-auto">
        <div>To permanently save your favourites please sign in or create an account</div>
      </div>
      <div class="col-12 col-lg-auto text-center text-lg-start my-auto">
        <upero-button [button]="block.fields.buttonSignIn" class="me-3"></upero-button>
        <upero-button [button]="block.fields.buttonCreateAccount"></upero-button>
      </div>
    </div>
  </div>
</div>

<section *ngIf="favourites.length">
    <div class="container g-0 p-5">
        <h1 class="fs-3 fw-bold">Favourite Products ({{favourites.length}})</h1>
        <div class="row g-0">
            <ng-container *ngFor="let pg of favourites; let last = last">
                <div
                    class="col-12 col-sm-6 col-md-6 col-xl-3 text-center px-4" [ngClass]="{ 'mb-5': !last}">
                    <a [uperoBrandRouterLink]="['/products', pg.slug]">
                        <img class="image-as-bg hover"
                             [src]="pg.imageUrl ? pg.imageUrl : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                             [appScrset]="pg.imageUrl ? pg.imageUrl : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                             [width]="310">
                    </a>
                    <div class="mt-4 mb-3">
                        <a class="fs-6 fw-400 text-dark"
                           [uperoBrandRouterLink]="['/products', pg.slug]">
                            {{pg.name}}
                        </a>
                    </div>
                    <p class="op-8 type-description mb-4" [innerHTML]="pg.description"></p>
                    <p class="d-flex justify-content-center align-items-center">
                        <span class="small me-2">Prices from</span>
                        <span class="fs-5 fw-bold me-2">
                            {{pg.pricesFrom | currency: 'GBP' : 'symbol' : '1.0-0'}}
                        </span>
                        <button (click)="toggleFavourite(pg.id)" class="d-inline-block btn-blank">
                            <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) < 0"
                                      src="assets/images/icon-heart.svg"
                                      [svgClass]="'product-details__favorite__svg'"></svg-icon>
                            <svg-icon *ngIf="(favourites$ | async).indexOf(pg.id) >= 0"
                                      src="assets/images/icon-heart.svg"
                                      [svgClass]="'product-details__favorite__svg--active'"></svg-icon>
                        </button>
                    </p>
                    <a class="btn"
                       [ngClass]="currentBrand ? 'btn-' + currentBrand.settings.colors.primary : 'btn-primary'"
                       [uperoBrandRouterLink]="['/products', pg.slug]">
                        View Product
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<section *ngIf="favourites.length === 0">
    <div class="container g-0 p-5">
        <div class="min-vh-50 d-flex flex-column align-items-center justify-content-center">
            <div class="text-center p-lg-5">
                <div class="d-flex align-items-center justify-content-center mb-4">
                    <svg-icon src="assets/images/icon-circle-info.svg" [svgClass]="'icon--info'"
                              [ngClass]="'me-3'"></svg-icon>
                    <div class="ff-spectral fw-bold fs-3">
                        No favourites saved
                    </div>
                </div>
                <div class="mb-4">
                    You have not added any products to your favourites yet.<br>
                    Look for the 'favourite' (
                    <svg-icon src="assets/images/icon-heart.svg" [svgClass]="'icon--small-heart'"></svg-icon>
                    )
                    icon on any product to add it to this list.
                </div>
                <div>
                    You can remove products from your favourites, view details,<br>
                    or order a sample at any time once added.
                </div>
            </div>
        </div>
    </div>
</section>
