import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BaseBlockComponent, BasketOrderApprovalFields } from '@upero/misc';
import { OrderApprovalComponent } from '../../../../basket/src/lib/order-approval/order-approval.component';

@Component({
  selector: 'upero-proxy-order-approval',
  templateUrl: './basket-order-approval.component.html',
  styleUrls: ['./basket-order-approval.component.scss']
})
export class BasketOrderApprovalComponent extends BaseBlockComponent<BasketOrderApprovalFields> implements AfterViewInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('uperoOrderApproval', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver)
  {
    super();
  }

  async ngAfterViewInit()
  {
    if (this.viewContainerRef) {
      const { OrderApprovalComponent } = await import('@upero/basket');
      this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(OrderApprovalComponent));
    }
  }
}
