import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from '@upero/services';
import { Observable, of } from 'rxjs';
import { AppInitService } from '@upero/routing';
import { IS_LOGGED_IN } from '@upero/misc';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate
{
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private appService: AppService,
  )
  {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean>
  {
    /*const authToken: string = this.cookieService.get(YsCookieService.AUTH_COOKIE);*/
    let isAuth;
    try {
      isAuth = JSON.parse(this.cookieService.get(IS_LOGGED_IN));
    } catch {
      isAuth = undefined;
    }
    // Initial if dictates whether user is authenticated or not

    // Each switch case used to manage route-specific behaviour
    if (isAuth) {
      switch (state.url) {
        case '/register':
          this.router.navigateByUrl(AppInitService.doBrandModByUrl('/'));
          return of(false);
        default:
          return of(true);
      }
    } else {
      switch (state.url) {
        case '/basket/billing':
        case '/account':
          this.appService.addRouteToTheHistory(state.url);
          this.router.navigate(AppInitService.doBrandModUrl(['/login']), { queryParams: { returnUrl: state.url } });
          return of(false);
        case '/register':
          return of(true);
        default:
          this.router.navigateByUrl(AppInitService.doBrandModByUrl('/'));
          return of(false);
      }
    }
  }
}
