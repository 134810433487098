import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseBlockComponent, PasswordResetFields } from '@upero/misc';
import { AuthService, OverlayService } from '@upero/services';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent extends BaseBlockComponent<PasswordResetFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  form: FormGroup;


  email = '';
  errorMessage = '';
  buttonDisabled: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    private overlayService: OverlayService
  )
  {
    super();
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void
  {
  }

  onSubmit()
  {
    this.overlayService.toggle('processing');
    this.errorMessage = '';
    this.email = this.form.value.email;
    this.authService.passwordReset(this.email).subscribe((data) =>
    {
      this.overlayService.toggle('processing');
      localStorage.setItem('confirmationEmail', this.email);
      if (data.data.accountFound) {
        this.router.navigate(AppInitService.doBrandModUrl(['/password', 'reset', 'confirmation']));
      } else {
        this.buttonDisabled = true;
        this.errorMessage = data.data.message;
      }
    });
  }

  backToLogin()
  {
    this.router.navigate(AppInitService.doBrandModUrl(['/login']));
  }

  changeInputValue()
  {
    this.buttonDisabled = false;
  }
}
