import {Directive, HostBinding} from '@angular/core';
import {Store} from "@upero/store";

@Directive({
  selector: '[uperoTextColorSetter]'
})
export class TextColorSetterDirective {
  currentBrand = this.store.selectForLocal('currentBrand');

  @HostBinding('class')
  get elementClass(): string {
    return this.currentBrand ? 'text-' + this.currentBrand.settings['primary'] : 'text-dark';
  }

  constructor(
    private store: Store,
  ) { }

}
