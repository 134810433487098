<div *ngIf="isOpen" [@slideLeftRight] (@slideLeftRight.done)="onSlideLeftRightEnd()"
     class="defaultAside aside-right aside-open shadow-lg overflow-auto bg-white" id="processHolder">

  <div class="container-mobile-menu">
    <div class="row">
      <div class="col-3 col-md-4">
        <a href="javascript:void(0)" class="menu-btn header-sub-item-icon hover" (click)="startClosing()">
          <svg-icon src="assets/images/icon-menu-close.svg"
                    [svgClass]="'header-sub-item-icon--close'"></svg-icon>
        </a>
      </div>
      <div class="col-6 col-md-4 mx-auto d-flex flex-column align-items-center">
        <a class="d-flex align-items-center" [uperoBrandRouterLink]="['/']">
          <svg-icon [svgClass]="'header__logo-img'"
                    src="assets/images/upero.svg"></svg-icon>
          <div *ngIf="currentBrand" class="ff-cou ms-3 fw-light fs-4 text-dark">
            {{currentBrand.name}}
          </div>
        </a>

        <ul class="navbar-nav nav-mobile" *ngIf="(user$ | async) && (user$ | async).accountType === 'customer'">
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account')">My Account</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/my-favourites')">Favourites</a>
          </li>

          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/orders')">My Orders</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/samples')">My Samples</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/rewards')">Rewards</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/payments')">Payment History</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/photos')">Submit Photos</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/settings')">My Profile</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/signout')">Sign out</a>
          </li>

        </ul>


        <ul class="navbar-nav nav-mobile" *ngIf="(user$ | async) && (user$ | async).accountType === 'trade'">
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account')">My Account</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/my-favourites')">Favourites</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/orders')">Orders</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/samples')">Samples</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/rewards')">Rewards</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/payments')">Payment History</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/photos')">Submit Photos</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/account/users')">Users</a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link ff-spectral hover"
               (click)="goToRouteWithDelay('/signout')">Sign out</a>
          </li>

        </ul>

      </div>
      <div class="col-3 col-md-4"></div>
    </div>
  </div>
</div>

