import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Store } from '@upero/store';
import { AppService, CmsService, SeoService } from '@upero/services';

@Component({
  selector: 'upero-blank-layout',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnDestroy, OnInit
{
  page;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store,
    public router: Router,
    private titleService: Title,
    private seoService: SeoService,
    private cmsService: CmsService,
    private appService: AppService,
  )
  {
    this._subscriptions.add(
      this.router.events.pipe(
        filter((event: any) => event instanceof NavigationEnd),
        tap((navigationEnd: NavigationEnd) =>
        {
          const activeRoute = navigationEnd.url.replace(/^\/|\/$/g, '');
          //this.getPages(activeRoute);
        }),
      ).subscribe());
  }

  ngOnInit(): void
  {
    this.appService.initApp();
  }

  ngOnDestroy(): void
  {
    this._subscriptions.unsubscribe();
  }

  getPages(route)
  {
    this._subscriptions.add(
      this.cmsService.pages()
        .pipe(tap(() =>
        {
          const pages = this.store.selectForLocal('pages');

          if (pages) {
            this.page = pages[route];
            if (this.page) {
              this.setMetaTags(this.page);
            }
          }
        })).subscribe());
  }

  setMetaTags(data)
  {
    this.titleService.setTitle(this.page.seoTitle ? this.page.seoTitle : this.page.title);
    this.seoService.addMetaTag('description', data.seoDescription);
    this.seoService.createLinkForCanonicalURL();
  }
}
