import { Component, ElementRef, HostBinding, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { Store } from '@upero/store';
import KeenSlider from 'keen-slider';
import { BaseBlockComponent, TruspilotSliderFields } from '@upero/misc';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'upero-trustpilot-slider',
  templateUrl: './trustpilot-slider.component.html',
  styleUrls: ['./trustpilot-slider.component.scss']
})
export class TrustpilotSliderComponent extends BaseBlockComponent<TruspilotSliderFields>
{
  isBrowser: boolean;
  arrowColour = '#fff';
  deviceType$ = this.store.select<string>('deviceType');

  tiles = [
    {
      header: 'Hebden buff',
      review: 'I Had 6 pieces of 400 mm x 1000 mm hebden buff sawn yorkstone delivered which was used for seating areas, it was excellent quality and was delivered on time, good communications and will use again.<br />Thanks'
    },
    {
      header: 'Knowledgable and helpful staff in the…',
      review: 'Knowledgable and helpful staff in the sales office. Quick delivery which turned up at the designated time. Stunning product that exceeded my expectations.'
    }
  ];
  spacing = 15;
  slidesPerView = 1;
  showDots = false;
  showNav = true;
  showExpandArrows = false;

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement> | undefined;

  currentSlide = 1;
  dotHelper: Array<number> = [];
  slider: any | undefined;

  arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
  arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';

  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) platformId: any,
  )
  {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void
  {
    if (this.sliderRef && this.isBrowser) {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        mode: "snap",
        /*        slides: {
                  perView: this.slidesPerView,
                  spacing: this.spacing,
                },*/
      });
      if (this.slider.track?.details.slides.length) {
        const dots = (+this.slider.track.details.slides.length - +this.slidesPerView) + 1;
        this.dotHelper = dots < 0 ? [] : [...Array(dots)];
      } else {
        this.dotHelper = [];
      }
    }
  }

  prevSlide(event: MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();
    this.clearSelection();
    if (this.slider) {
      this.slider.prev();
      if (this.currentSlide === 1) {
        this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
      }
    }
    return false;
  }

  nextSlide(event: MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();
    this.clearSelection();
    if (this.slider) {
      this.slider.next();
      if ((this.currentSlide + this.slidesPerView) + 1 === this.tiles.length) {
        this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
      }
    }
    return false;
  }

  clearSelection()
  {
    const sel = window.getSelection();
    if (sel && sel.empty) {
      sel.removeAllRanges();
    }
  }

  arrowPreviousState(reset: any)
  {
    if (this.currentSlide !== 0 && reset) {
      this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left-bold.svg';
    } else {
      this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
    }
  }

  arrowNextState(reset: any)
  {
    if ((this.currentSlide + this.slidesPerView) !== this.tiles.length && reset) {
      this.arrowRightSrc = 'assets/images/icon-arrow-slider-right-bold.svg';
    } else {
      this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
    }
  }

  moveToIndex(i: number)
  {
    if (this.slider) {
      this.slider.moveToIdx(i);
    }
  }

  ngOnDestroy(): void
  {
    if (this.slider) {
      this.slider.destroy();
    }
  }

}
