import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultComponent } from "@upero/layouts";
import { PagesService, SettingsService } from "@upero/pages";
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from "@upero/misc";
import { forkJoin } from "rxjs";
import { Store } from "@upero/store";
import { BrandUrlPipe } from '../../../pipes/src/lib/brand-url.pipe';
import { BrandStrUrlPipe } from '../../../pipes/src/lib/brand-str-url.pipe';

@Injectable({
    providedIn: 'root'
})
export class AppInitService
{
    static STORE: Store;
    static brandUrlPipe: BrandUrlPipe;
    static brandStrUrlPipe: BrandStrUrlPipe;

    constructor(
        private router: Router,
        private pagesService: PagesService,
        private settingsService: SettingsService,
        private http: ApiAuthHttpClient,
        private injector: Injector,
        private store: Store,
        @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
    )
    {
        AppInitService.STORE = this.store;
        AppInitService.brandUrlPipe = new BrandUrlPipe(this.store);
        AppInitService.brandStrUrlPipe = new BrandStrUrlPipe(this.store);
    }

    init(): Promise<any>
    {
        return new Promise<void>(async (resolve, reject) =>
        {
            const router = this.injector.get(Router);

            const pages = this.pagesService.getPageSlugsFlat();

            // Todo change to single site endpoint once available
            const settings = this.settingsService.getSiteSettings();

            forkJoin([pages, settings]).subscribe(results =>
            {
                const pages = results[0];
                const sites = results[1];
                const site = sites.find((site) => site.id == this.env.siteId);

                pages.forEach(page =>
                {
                    router.config.push({
                        path: page.slug,
                        pathMatch: 'full',
                        component: DefaultComponent
                    });
                });

                router.config.push({
                    path: '**',
                    pathMatch: 'full',
                    component: DefaultComponent
                });

                this.store.set('siteSettings', site);
                this.store.set('sitePages', pages);

                return resolve();
            });
        });
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static doBrandModUrl(commands: any[], forceFromRoot = false): any[]
    {
        return AppInitService.brandUrlPipe.transform(commands, forceFromRoot);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static doBrandModByUrl(urlStr: string, forceFromRoot = false): string
    {
        return AppInitService.brandStrUrlPipe.transform(urlStr, forceFromRoot);
    }
}
