import { Component, HostBinding, OnInit } from '@angular/core';
import { LoyalityPageIntroFields, BaseBlockComponent } from '@upero/misc';

@Component({
  selector: 'upero-loyality-page-intro',
  templateUrl: './loyality-page-intro.component.html',
  styleUrls: ['./loyality-page-intro.component.scss']
})
export class LoyalityPageIntroComponent extends BaseBlockComponent<LoyalityPageIntroFields> implements OnInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  constructor()
  {
    super()
  }

  ngOnInit(): void
  {
  }

}
