<div *ngIf="(user$ | async)" class="menu bg-primary overflow-auto h-100 position-fixed">
    <div class="d-flex flex-column">
        <div class="storefront__header bg-orange d-flex align-items-center py-4">
            <button
                [uperoBrandRouterLink]="['/']"
                type="button"
                class="btn btn-sidebar btn-sidebar--top fs-6 text-start w-100 px-5 py-2">
                <svg-icon src="assets/images/icon-storefront.svg" [svgClass]="'icon--sidebar'"></svg-icon>
                <span class="ms-3">Storefront</span>
            </button>
        </div>
        <div class="title d-flex align-items-center op-4 px-5 py-2 mt-3">
            <div
                class="small text-white">
                Actions
            </div>
        </div>
        <button
            (click)="addUser()"
            type="button"
            [ngClass]="{'active': step === 'users'}"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            <svg-icon src="assets/images/icon-user.svg" [svgClass]="'icon--sidebar'"></svg-icon>
            <span class="ms-3">Add New User</span>
        </button>
        <hr class="bg-secondary op-9">
        <div class="title d-flex align-items-center op-4 px-5 py-2">
            <div
                class="small text-white">
                Navigation
            </div>
        </div>
        <button
            [routerLink]="['/account']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Overview
        </button>
        <button
            [routerLink]="['/account', 'orders']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Orders
        </button>
        <button
            [routerLink]="['/account', 'samples']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Samples
        </button>
        <button
            [routerLink]="['/account', 'rewards']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Rewards
        </button>
        <button
            [routerLink]="['/account', 'payments']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Payment History
        </button>
        <!-- <button
            [routerLink]="['/account', 'samples']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Samples
        </button> -->
        <!--
        <button
            [routerLink]="['/account/requests']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Requests
        </button>
        -->
        <button
            *ngIf="enableQuoteBackend"
            [routerLink]="['/account', 'jobs']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: true}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Find work
        </button>
        <button
            *ngIf="enableQuoteBackend"
            [routerLink]="['/account', 'proposals']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Proposals
        </button>
        <button
            *ngIf="enableQuoteBackend"
            [routerLink]="['/account', 'jobs', 'accepted']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Jobs
        </button>
        <button
            [routerLink]="['/account/settings']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            My Profile
        </button>
        <button
            [routerLink]="['/account/users']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}"
            type="button"
            class="btn btn-sidebar fs-6 text-start px-5 py-2">
            Users
        </button>
        <hr class="bg-secondary op-9">
        <div class="title d-flex align-items-center op-4 px-5 pt-2">
            <div class="small text-white">Company</div>
        </div>
        <div class="fs-4 text-white px-5 py-2">
            {{(user$ | async).company.name}}
        </div>
        <!--
        <div class="title d-flex align-items-center op-4 px-5 pt-2">
            <div class="small text-white">Rating</div>
        </div>
        <div class="px-5 py-2 mb-2">
            <app-rating-icons></app-rating-icons>
        </div>
        -->

        <ng-container *ngIf="(user$ | async)">
            <div class="title d-flex align-items-center op-4 px-5 pt-2">
                <div class="small text-white">Company Number </div>
            </div>
            <div class="fs-4 text-white px-5 py-2">
                {{(user$ | async)?.company.companyNumber}}
            </div>
            <div class="title d-flex align-items-center op-4 px-5 pt-2">
                <div class="small text-white">VAT Number</div>
            </div>
            <div class="fs-4 text-white px-5 py-2">
                {{(user$ | async)?.company.vatNumber}}
            </div>

            <hr class="bg-secondary op-9">

            <div  *ngIf="this.user.company.premium === 1 || user.company.premium==0" class="title d-flex align-items-center op-4 px-5 pt-2">
                <div class="small text-white">Account Type</div>
            </div>
            <div  *ngIf="this.user.company.premium === 1" class="fs-4 text-white px-5 py-2">
                Trade Premium
            </div>

            <div  *ngIf="this.user.company.premium === 0" class="fs-4 text-white px-5 py-2">
                Trade
            </div>



        </ng-container>
    </div>
</div>
