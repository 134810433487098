<div *ngIf="isOpen" [@slideLeftRight] (@slideLeftRight.done)="onSlideLeftRightEnd()"
     class="defaultAside aside-left aside-open shadow-none overflow-auto bg-white" id="processHolder">
    <div class="container-mobile-menu h-100">
        <div class="row h-100">
            <div class="col-3 col-md-4">
                <div class="w-100 d-flex">
                    <a href="javascript:void(0)"
                       class="menu-btn header-sub-item-icon header-sub-item-icon--fill"
                       (click)="startClosing()">
                        <svg-icon src="assets/images/icon-menu-close.svg"></svg-icon>
                    </a>
                    <a href="tel:{{(settings$ | async).telOne}}"
                       class="header-sub-item-icon me-0">
                        <svg-icon src="assets/images/icon-phone.svg"></svg-icon>
                    </a>
                </div>
            </div>
            <div class="col-6 col-md-4 mx-auto d-flex flex-column align-items-center">
                <a (click)="goToRouteWithDelay($event, '/')">
                  <svg-icon src="assets/images/upero.svg" [svgClass]="'header__logo-img'"></svg-icon>
                </a>
                <div *ngIf="currentBrand" class="ff-cou ms-3 fw-light fs-4 text-dark">
                  {{currentBrand.name}}
                </div>
                <ul *ngIf="this.activeSubMenu.length === 0" [@slideMenuPage] class="navbar-nav nav-mobile main-menu">
                    <ng-container *ngFor="let item of items; let m = index">
                        <ng-container *ngIf="item.contents.byProducts && productGroups">
                          <li>
                            <a class="nav-link nav-link--has-sub"
                               [href]="item.contents.link | modBrandStrUrl"
                               (click)="goToRouteWithDelay($event, item.contents.link)"
                               [class.active]="router.isActive(item.contents.link,false)"
                              >
                              <div class="d-flex justify-content-between">
                                {{ item.contents.title }}
                                <svg-icon (click)="showProductGroups($event)" src="assets/images/icon-arrow-right.svg"></svg-icon>
                              </div>
                            </a>
                          </li>
                        </ng-container>

                        <ng-container *ngIf="item.contents.bySpaces">
                          <li>
                            <a class="nav-link nav-link--has-sub"
                               [href]="item.contents.link | modBrandStrUrl"
                               (click)="goToRouteWithDelay($event, item.contents.link)"
                               [class.active]="router.isActive(item.contents.link,false)"
                            >
                              <div class="d-flex justify-content-between">
                                {{ item.contents.title }}
                                <svg-icon *ngIf="currentBrand.spaces.length > 0" (click)="showSpaces($event)" src="assets/images/icon-arrow-right.svg"></svg-icon>
                              </div>
                            </a>
                          </li>
                        </ng-container>

                        <ng-container *ngIf="item.isRoot === 1 && !item.contents.byProducts && !item.contents.bySpaces">
                          <!-- External link -->
                          <li class="nav-item flex-fill" *ngIf="item.external === 1">
                              <a class="nav-link text-dark" target="_blank" [href]="[item.externalURL]">{{item.contents.name}}</a>
                          </li>

                          <li *ngIf="!item.external">
                              <a class="nav-link nav-link--has-sub"
                                *ngIf="item.children.length > 0"
                                [href]="item.contents.link | modBrandStrUrl"
                                (click)="toggleSubMenu(item.children)">
                                <div class="d-flex justify-content-between">
                                  {{ item.contents.title }}
                                  <svg-icon src="assets/images/icon-arrow-right.svg"></svg-icon>
                                </div>
                              </a>
                            <a
                              *ngIf="item.children.length === 0"
                              class="nav-link"
                              [href]="item.contents.link | modBrandStrUrl"
                              (click)="goToRouteWithDelay($event, item.contents.link)"
                              [class.active]="router.isActive(item.contents.link,false)"
                            >{{item.contents.title}}
                            </a>
                          </li>
                        </ng-container>

                    </ng-container>


                    <li>
                      <a class="nav-link nav-link--has-sub"
                         [href]="'/my-favourites' | modBrandStrUrl"
                         (click)="goToRouteWithDelay($event, '/my-favourites')"
                         [class.active]="router.isActive('/my-favourites',false)"
                      >
                        <div class="d-flex justify-content-between">
                          My Favourites
                        </div>
                      </a>
                    </li>

                    <li class="border-top border-primary border-1 pt-3 mt-3">
                        <a class="nav-link" [href]="'/contact-us' | modBrandStrUrl"
                           (click)="goToRouteWithDelay($event, '/contact-us')">Get a free quote</a>
                    </li>
                </ul>

                <!-- 2 level -->
                <ul *ngIf="this.activeSubMenu.length > 0 && this.activeSubSubMenu.length === 0" [@slideMenuPage] class="navbar-nav nav-mobile submenu">
                    <li class="nav-item flex-fill">
                        <a
                            class="nav-link"
                            href="javascript:void(0)"
                            (click)="backToMainMenu()">
                            <svg-icon src="assets/images/icon-arrow-left-long.svg"></svg-icon>
                        </a>
                    </li>

                    <ng-container *ngFor="let subItem of this.activeSubMenu">
                        <li>
                            <a class="nav-link"
                               [href]="subItem.route.join('/') | modBrandStrUrl"
                               (click)="goToRouteWithDelay($event, subItem.route.join('/'))"
                               [class.active]="router.isActive(subItem.route.join('/'), true)">
                               <div class="d-flex justify-content-between" >
                                 {{ subItem.name }}
                                 <svg-icon *ngIf="subItem.subSubMenu?.length > 0" (click)="showSubSubMenu($event, subItem.subSubMenu)" src="assets/images/icon-arrow-right.svg"></svg-icon>
                               </div>
                            </a>
                        </li>
                    </ng-container>
                </ul>
                <!-- 3 level -->
                <ul *ngIf="this.activeSubMenu.length > 0 && this.activeSubSubMenu.length > 0" [@slideMenuPage] class="navbar-nav nav-mobile submenu">
                  <li class="nav-item flex-fill">
                    <a class="nav-link" href="javascript:void(0)"
                      (click)="back2LevelMenu()">
                      <svg-icon src="assets/images/icon-arrow-left-long.svg"></svg-icon>
                    </a>
                  </li>

                  <ng-container *ngFor="let subItem of this.activeSubSubMenu">
                    <li>
                      <a
                        class="nav-link"
                        [href]="subItem.route.join('/') | modBrandStrUrl"
                        (click)="goToRouteWithDelay($event, subItem.route.join('/'))"
                        [class.active]="router.isActive(subItem.route.join('/'), true)">
                        {{ subItem.name }}
                      </a>
                    </li>
                  </ng-container>
                </ul>

            </div>
            <div class="col-3 col-md-4">
                <div class="w-100 d-flex justify-content-end">
                    <a *ngIf="(deviceType$| async) !== 'mobile'" [uperoBrandRouterLink]="['/my-favourites']"
                       class="header-sub-item-icon">
                        <svg-icon src="assets/images/icon-heart.svg"></svg-icon>
                        <span class="badge-count"
                              *ngIf="(favourites$ | async).length">{{(favourites$ | async).length}}</span>
                    </a>
                    <a [uperoBrandRouterLink]="['/basket']" class="header-sub-item-icon">
                        <svg-icon src="assets/images/icon-cart.svg"></svg-icon>
                        <span class="badge-count" *ngIf="(basket$ | async).items.length || (basket$ | async).samples.length">{{(basket$ | async).items.length + (basket$ | async).samples.length}}</span>
                    </a>
                    <ng-container *ngIf="!(user$ | async)">
                        <a class="header-sub-item-icon me-0" [uperoBrandRouterLink]="['/login']">
                            <svg-icon src="assets/images/icon-user.svg"></svg-icon>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="(user$ | async)">
                        <a class="header-sub-item-icon me-0" (click)="toggleMenuUser()">
                            <svg-icon src="assets/images/icon-user.svg"></svg-icon>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
