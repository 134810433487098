<section>
    <div class="container g-0 p-5">
        <div class="row g-0">
            <ng-container *ngFor="let pg of productRanges; let last = last">
                <div
                    class="col-12 col-sm-6 col-md-6 col-xl-3 text-center px-4" [ngClass]="{ 'mb-5': !last}">
                    <a [uperoBrandRouterLink]="['/spaces', pg.slug]">
                        <img class="image-as-bg hover"
                             [src]="pg.imageUrl"
                             [appScrset]="pg.imageUrl ? pg.imageUrl : 'https://api.ysprod.co.uk/cms/images/assets/no-image.png'"
                             [width]="310">
                    </a>
                    <div class="mt-4 mb-3">
                        <a class="fs-6 fw-bold"
                           [uperoBrandRouterLink]="['/spaces', pg.slug]">{{pg.name}}</a>
                    </div>
                    <p class="op-8 type-description mb-4" [innerHTML]="pg.tileText"></p>
                    <p class="d-flex justify-content-center align-items-center">
                        <span class="small me-2">Prices from</span>
                        <span class="fs-5 fw-bold">
                            <ng-container *ngIf="pg.hasProducts">
                                {{pg.pricesFrom | currency: 'GBP'}}
                            </ng-container>
                            <ng-container *ngIf="!pg.hasProducts">
                                (No products)
                            </ng-container>
                        </span>
                    </p>
                    <a class="btn btn-wide {{ block.fields.buttonColour }} fw-bold"
                       [uperoBrandRouterLink]="['/spaces', pg.slug]">
                        {{ block.fields.buttonText }}
                    </a>
                </div>
            </ng-container>
        </div>
    </div>
</section>
