import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from './store';
import { State } from './state';
import { STORE_INITIAL_STATE } from './Injection.tokens';

@NgModule({
  imports: [CommonModule],
  providers: []
})
export class StoreModule {

  constructor(@Optional() @SkipSelf() parentModule?: StoreModule) {
    if (parentModule) {
      throw new Error(
        'StoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(initialState: State): ModuleWithProviders<StoreModule> {
    return {
      ngModule: StoreModule,
      providers: [
        Store,
        { provide: STORE_INITIAL_STATE, useValue: initialState }
      ]
    };
  }
}
