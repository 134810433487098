import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHTMLPipe } from './safe-html.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { LeadtimePipe } from './leadtime.pipe';
import { AutolinkerPipe } from './autolinker.pipe';
import { ConsolePipe } from './console.pipe';
import { PageLinkPipe } from './page-link.pipe';
import { AddressPipe } from './format-address.pipe';
import { hasFlagPipe } from './hasFlag.pipe';
import { BrandUrlPipe } from './brand-url.pipe';
import { BrandStrUrlPipe } from './brand-str-url.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SafeHTMLPipe,
    DateAgoPipe,
    StripHtmlPipe,
    LeadtimePipe,
    AutolinkerPipe,
    ConsolePipe,
    PageLinkPipe,
    AddressPipe,
    hasFlagPipe,
    BrandUrlPipe,
    BrandStrUrlPipe
  ],
  exports: [
    DateAgoPipe,
    SafeHTMLPipe,
    StripHtmlPipe,
    LeadtimePipe,
    AutolinkerPipe,
    ConsolePipe,
    PageLinkPipe,
    AddressPipe,
    hasFlagPipe,
    BrandUrlPipe,
    BrandStrUrlPipe
  ],
})
export class PipesModule {}
