<section *ngIf="productGroups && isLoaded">
    <div class="container g-0 p-5">
      <div class="row g-0">
        <ng-container *ngFor="let group of productGroups; let last = last">
          <upero-thumbnail-tile class="col-12 col-sm-6 col-md-6 col-xl-3 text-center px-4"
                                [ngClass]="{ 'mb-5': !last}"
                                [imageUrl]="group.imageUrl"
                                [slug]="group.slug"
                                [name]="group.name"
                                [tileText]="group.tileText"
                                [isPriceFromVisible]="false"
                                [ctaTxt]="block.fields.groupButton.text"
                                [urlBase]="'/product-groups'"
                                [ctaBtnClass]="buttonClass"
                                [ctaBtnSize]="this.block.fields.groupButton['size']"
          ></upero-thumbnail-tile>
        </ng-container>
      </div>
    </div>
</section>

<ng-container *ngIf="!productGroups && isLoaded">
  <upero-page-not-found></upero-page-not-found>
</ng-container>
