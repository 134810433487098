import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService, RegexService } from '@upero/services';
import { BaseBlockComponent, checkPasswords, PasswordSetFields } from '@upero/misc';
import { AppInitService } from '@upero/routing';

@Component({
  selector: 'upero-password-set',
  templateUrl: './password-set.component.html',
  styleUrls: ['./password-set.component.scss'],
})
export class PasswordSetComponent extends BaseBlockComponent<PasswordSetFields> implements OnInit, OnDestroy
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  form: FormGroup;
  resetData = {
    password: '',
    autoId: '',
  };
  notAuthorized = false;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    public regexService: RegexService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder
  )
  {
    super();
    this.form = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            this.regexService.validatePasswordField(),
          ],
        ],
        passwordConfirm: ['', [Validators.required]],
      },
      {
        validators: [checkPasswords()],
      }
    );
  }

  ngOnInit(): void
  {
    this.resetData.autoId = this.route.snapshot.params.slug;
  }

  ngOnDestroy(): void
  {
    this._subscriptions.unsubscribe();
  }

  onSubmit()
  {
    this.resetData.password = this.form.value.password;
    this._subscriptions
      .add(this.authService.passwordSet(this.resetData)
      .subscribe(() =>
        {
          this.router.navigate(AppInitService.doBrandModUrl(['/password', 'confirmation']));
        }
    ));
  }
}
