<section class="bg-slate-5">
    <div class="container">
        <div class="jobs__container">
            <div class="row">
                <div *ngFor="let job of jobs" class="col-12 col-md-6 col-lg-4">
                    <div class="job">
                        <h2 class="job__heading">{{ job.title }}</h2>
                        <p class="job__subline">{{ job.subtitle }}<br>{{ job.location }}</p>
                        <span class="job__position">Full-time position</span>
                        <button (click)="prepareForm(applicationFormRef, job.id)" class="btn btn-primary btn-cta">
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <h2 #applicationFormRef class="app__form__heading">Application Form</h2>
        <form class="app__form needs-validation" [formGroup]="applicationForm" (ngSubmit)="onSubmit()" novalidate>
            <div class="row">
                <ng-container *ngIf="jobs.length > 0">
                    <div class="col-12 col-lg-4">
                        <label class="app__label">
                            Vacancy
                        </label>
                    </div>
                    <div class="col-12 col-lg-8">
                        <ng-select
                            uperoBgSetter
                            [setClass]="'dropDownClass'"
                            formControlName="vacancy"
                            [clearable]="false"
                            [searchable]="false"
                            [placeholder]="'Please select'"
                            [ngClass]="{ 'is-invalid': (field.vacancy.touched || formSubmitted) && field.vacancy.errors }">
                            <ng-option *ngFor="let job of jobs" [value]="job.id ">{{ job.title }}
                                , {{ job.location }}</ng-option>
                        </ng-select>
                        <div *ngIf="field.vacancy.hasError && (field.vacancy.touched || formSubmitted)"
                             class="invalid-feedback mb-1">
                            <ng-container *ngIf="field.vacancy.hasError('required')">
                                Please select the vacancy.
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="jobs.length === 0">
                    <div class="col-12">
                        <label class="app__label fw-400 fs-4 text-center">
                            There are currently no vacancies available, but please submit your details below and we’ll
                            be in touch if anything pops up!
                        </label>
                    </div>
                </ng-container>
                <div class="col-12 col-lg-4 mt-34">
                    <label class="app__label">
                        About you
                    </label>
                </div>
                <div class="col-12 col-lg-8 mt-md-34">
                    <div class="app__form__group">
                        <input type="text" class="form-control" formControlName="firstName" placeholder="First name"
                               [ngClass]="{ 'is-invalid': (field.firstName.touched || formSubmitted) && field.firstName.errors }"/>
                        <div *ngIf="field.firstName.hasError && (field.firstName.touched || formSubmitted)"
                             class="invalid-feedback mb-1">
                            <ng-container *ngIf="field.firstName.hasError('required')">
                                Please provide your first name.
                            </ng-container>
                            <ng-container *ngIf="field.firstName.hasError('minlength')">
                                First name needs to be at least {{ field.firstName.errors.minlength.requiredLength }}
                                characters long.
                            </ng-container>
                        </div>
                        <input type="text" class="form-control mt-16" formControlName="lastName" placeholder="Last name"
                               [ngClass]="{ 'is-invalid': (field.lastName.touched || formSubmitted) && field.lastName.errors }"/>
                        <div *ngIf="field.lastName.hasError && (field.lastName.touched || formSubmitted)"
                             class="invalid-feedback mb-1">
                            <ng-container *ngIf="field.lastName.hasError('required')">
                                Please provide your last name.
                            </ng-container>
                            <ng-container *ngIf="field.lastName.hasError('minlength')">
                                Last name needs to be at least {{ field.lastName.errors.minlength.requiredLength }}
                                characters long.
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mt-34">
                    <label class="app__label">
                        Contact details
                    </label>
                </div>
                <div class="col-12 col-lg-8 mt-md-34">
                    <div class="app__form__group">
                        <input type="email" class="form-control" name="email" formControlName="email"
                               placeholder="Email address"
                               [ngClass]="{ 'is-invalid': (field.email.touched || formSubmitted) && field.email.errors }"/>
                        <div *ngIf="field.email.hasError && (field.email.touched || formSubmitted)"
                             class="invalid-feedback mb-1">
                            <ng-container *ngIf="field.email.hasError('required')">
                                Please provide your email address.
                            </ng-container>
                            <ng-container *ngIf="field.email.hasError('minlength')">
                                Email address needs to be at least {{ field.email.errors.minlength.requiredLength }}
                                characters long.
                            </ng-container>
                            <ng-container *ngIf="field.email.hasError('email') && !field.email.hasError('minlength')">
                                Please provide a valid email address.
                            </ng-container>
                        </div>
                        <input type="phone" class="form-control mt-16" formControlName="phone"
                               placeholder="Telephone number"
                               [ngClass]="{ 'is-invalid': (field.phone.touched || formSubmitted) && field.phone.errors }"/>
                        <div *ngIf="field.phone.hasError && (field.phone.touched || formSubmitted)"
                             class="invalid-feedback mb-1">
                            <ng-container *ngIf="field.phone.hasError('required')">
                                Please provide your phone number.
                            </ng-container>
                            <ng-container *ngIf="field.phone.hasError('minlength')">
                                Phone number needs to be at least {{ field.phone.errors.minlength.requiredLength }}
                                numbers long.
                            </ng-container>
                            <ng-container *ngIf="field.phone.hasError('pattern')">
                                Please provide a valid phone number (only numbers).
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4 mt-34">
                    <label class="app__label">
                        Message
                    </label>
                </div>
                <div class="col-12 col-lg-8 mt-md-34">
            <textarea type="text" class="form-control app__message" name="message" formControlName="message"
                      placeholder="Add a message"
                      [ngClass]="{ 'is-invalid': (field.message.touched || formSubmitted) && field.message.errors }"></textarea>
                    <div *ngIf="field.message.hasError && (field.message.touched || formSubmitted)"
                         class="invalid-feedback mb-1">
                        <ng-container *ngIf="field.message.hasError('required')">
                            Please provide a message.
                        </ng-container>
                        <ng-container *ngIf="field.message.hasError('minlength')">
                            The message needs to be at least {{ field.message.errors.minlength.requiredLength }}
                            characters long.
                        </ng-container>
                    </div>
                </div>

                <div class="col-12 col-lg-4 d-flex flex-column mt-34">
                    <label class="app__label">
                        Linkedin
                    </label>
                    <span class="app__label__subline">Optional</span>
                </div>
                <div class="col-12 col-lg-8 mt-md-34">
                    <div class="app__form__group">
                        <input type="text" class="form-control" formControlName="linkedin" placeholder="https://"
                               [ngClass]="{ 'is-invalid': (field.linkedin.touched || formSubmitted) && field.linkedin.errors }"/>
                        <div *ngIf="field.linkedin.hasError && (field.linkedin.touched || formSubmitted)"
                             class="invalid-feedback mb-1">
                            <ng-container *ngIf="field.linkedin.hasError('minlength')">
                                The Linkedin link needs to be at
                                least {{ field.linkedin.errors.minlength.requiredLength }} characters long.
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4 d-flex flex-column mt-34">
                    <label class="app__label">
                        CV Upload
                    </label>
                    <span class="app__label__subline">Include a copy of your CV<br> in PDF or Word format</span>
                </div>
                <div class="col-12 col-lg-8 mt-md-34">

                    <upero-file-uploader [ownerType]="'cv'" [uploadConfig]="uploadConfig" (success)="onUploadSuccess($event)"></upero-file-uploader>

                    <input type="text" class="d-none" formControlName="cv"/>
                    <div *ngIf="field.cv.hasError && formSubmitted" class="d-block invalid-feedback mb-1">
                        <ng-container *ngIf="field.cv.hasError('required')">
                            Please upload your CV
                        </ng-container>
                    </div>
                </div>

                <div class="col-12 col-lg-8 offset-lg-4 btn-cta__wrapper mt-34">
                    <upero-button [button]="block.fields.buttonSubmit" [type]="'submit'"></upero-button>
                </div>
            </div>
        </form>
    </div>
</section>
