import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultComponent } from './default/default.component';
import { RouterModule } from '@angular/router';
import { BlocksModule } from '@upero/blocks';
import { SharedModule } from '@upero/shared';
import { BlankComponent } from './blank/blank.component';
import { AccountComponent } from './account/account.component';
import { AccountMenuMobileComponent } from './account/account-menu-mobile/account-menu-mobile.component';
import { AccountHeaderMobileComponent } from './account/account-header-mobile/account-header-mobile.component';
import { AccountHeaderComponent } from './account/account-header/account-header.component';
import { CompanyMenuComponent } from './account/company-menu/company-menu.component';
import { CustomerMenuComponent } from './account/customer-menu/customer-menu.component';
import { ChatModule } from '@upero/chat';
import { UserModule } from '@upero/user';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BlocksModule,
        SharedModule,
        ChatModule,
        /*UserModule,*/
    ],
  declarations: [
    DefaultComponent,
    BlankComponent,
    AccountComponent,
    AccountMenuMobileComponent,
    AccountHeaderMobileComponent,
    AccountHeaderComponent,
    CompanyMenuComponent,
    CustomerMenuComponent
  ],
    exports: [
        DefaultComponent,
        BlankComponent,
        AccountComponent
    ],
  providers: []
})
export class LayoutsModule
{
}
