import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@upero/store';
import { BreadcrumbsService, CmsService, FavouritesService, ProductService, SeoService } from '@upero/services';
import { BaseBlockComponent, ProductGroupDetailFields } from '@upero/misc';

@Component({
  selector: 'upero-product-group-detail',
  templateUrl: './product-group-detail.component.html',
  styleUrls: ['./product-group-detail.component.scss']
})
export class ProductGroupDetailComponent extends BaseBlockComponent<ProductGroupDetailFields> implements OnInit, OnDestroy
{
  favourites$ = this.store.select<any[]>('favourites');
  currentBrand = this.store.selectForLocal('currentBrand');
  productGroup;
  products = [];
  isLoaded = false;
  buttonClass: string;
  public destroyed = new Subject<any>();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductService,
    private favouritesService: FavouritesService,
    private breadcrumbsService: BreadcrumbsService,
    private seoService: SeoService,
    private cmsService: CmsService
  )
  {
    super();
  }

  ngOnInit(): void
  {
    const btnStyle = this.block.fields.productButton['style'] !== 'fill' ? this.block.fields.productButton['style'] + '-' : '';

    if (this.currentBrand) {
      this.buttonClass = 'btn-' + btnStyle + this.currentBrand.settings.colors[this.block.fields.productButton['intent']];
    } else {
      this.buttonClass = 'btn-' + btnStyle + this.block.fields.productButton['intent'];
    }

    this.getProductGroups();

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe(() =>
    {
      this.getProductGroups();
    });
  }

  ngOnDestroy(): void
  {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  getProductGroups()
  {
    this.productsService.getProductGroups(this.route.snapshot.params.slug).subscribe(data =>
    {
          this.productGroup = data.data;
          this.isLoaded = true;
          if (this.currentBrand) {
            this.breadcrumbsService.changeBreadcrumb([
              { text: this.currentBrand.name, route: ['/' + this.currentBrand.slug]},
              { text: 'Shop by product', route: ['/' + this.currentBrand.slug, 'product-groups'] },
              { text: this.productGroup.name }
            ]);
          } else {
            this.breadcrumbsService.changeBreadcrumb([
              { text: 'Our Products', route: ['/product-groups'] },
              { text: this.productGroup.name }
            ]);
          }

          this.setMetaTags(data.data);
          this.productsByGroup(this.productGroup);
          this.cmsService.broadCastBlockData.next({
            target: 'page-intro',
            data: {
              backgroundImgSrc: this.productGroup.bannerUrl,
              title: this.productGroup.name,
              text: this.productGroup.bannerText
            }
          });
        });
  }

  productsByGroup(productGroup)
  {
    this.productsService.productsByGroup(productGroup).subscribe(data =>
    {
      this.products = data.data;
      // if (this.products.length === 1) {
      //     this.router.navigate(['/products', this.products[0].slug]);
      // }
    });
  }

  toggleFavourite(productId)
  {
    const favourites = this.store.selectForLocal('favourites');
    if (favourites.indexOf(productId) < 0) {
      this.favouritesService.addToFavourites(productId);
    } else {
      this.favouritesService.deleteFavourite(productId);
    }
  }

  setMetaTags(productGroup)
  {
    this.seoService.updateTitle(productGroup.seoTitle);
    this.seoService.addMetaTag('description', productGroup.seoDescription);
    this.seoService.createLinkForCanonicalURL();
  }

}
