import { MiscModule } from '@upero/misc';
import { AlertService } from '@upero/services';

// f.e. @AlertNotification(message: "Looking for trouble?")
export function AlertNotification(message: string, timer: number = 4000) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) =>
  {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
      const alertService = MiscModule.injector.get<AlertService>(AlertService);
      return new Promise<any>((resolve, reject) => {
        alertService.notification([message], timer)
          .then(async () => {
            resolve(await originalMethod.apply(this, args));
          });
      });
    }

    return descriptor;
  };
}
