<a *ngIf="button && button['linkType'] === 'page'"
   [routerLink]="button['href'] ? '/' + (button['href']|pageLink) : link"
   class="btn"
   [type]="type ? type : ''"
   [ngClass]="[
    button['linkType'] ?? '',
    buttonClass,
    button['size'],
    ]">
  {{text ? text : button['text']}}
</a>

<a *ngIf="button && button['linkType'] !== 'page' && type !== 'submit' && type !== 'function' && !isExternal"
   [routerLink]="(button['href'] ? button['href'] : link) | modBrandStrUrl"
   class="btn"
   [type]="type ? type : ''"
   [ngClass]="[
    button['linkType'] ?? '',
    buttonClass,
    button['size'],
    ]">
  {{text ? text : button['text']}}
</a>

<a *ngIf="button && button['href'] && button['linkType'] !== 'page' && type !== 'submit' && type !== 'function' && isExternal"
   [href]="button['href'] ? button['href'] : link"
   class="btn"
   [type]="type ? type : ''"
   [ngClass]="[
    button['linkType'] ?? '',
    buttonClass,
    button['size'],
    ]">
  {{text ? text : button['text']}}
</a>

<button *ngIf="button && !button['href'] && type === 'submit'"
   class="btn"
   [type]="type ? type : ''"
   [disabled]="isDisabled"
   [ngClass]="[
    button['linkType'] ?? '',
    buttonClass,
    button['size'],
    ]">
  {{text ? text : button['text']}}
</button>

<button *ngIf="type === 'function' && clickCallback"
   class="btn"
   type="button"
   [disabled]="isDisabled"
   (click)="clickEmit()"
   [ngClass]="[buttonClass]">
  {{text ? text : button['text']}}
</button>
