import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';


declare global
{
  interface Window
  {
    Trustpilot: any;
  }
}

@Component({
  selector: 'upero-trustpilot-widget',
  templateUrl: './trustpilot-widget.component.html',
  styleUrls: ['./trustpilot-widget.component.scss']
})
export class TrustpilotWidgetComponent implements AfterViewInit
{

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  )
  {
  }

  get isBrowser(): boolean
  {
    return isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit(): void
  {
    if (this.isBrowser) {
      const node = document.createElement('script');
      node.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      node.type = 'text/javascript';
      node.async = true;
      node.onload = () =>
      {
        const trustBoxRef = document.getElementById('trustbox');
        if (window.Trustpilot) {
          window.Trustpilot.loadFromElement(trustBoxRef);
        }
      };
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
