import { ComponentFactoryResolver, Inject, Injectable, PLATFORM_ID, Type, ViewContainerRef } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from '@upero/store';
import {
  ChatService,
  NotificationService,
  ToolsService,
  UserService, OverlayService, CmsService, AuthService
} from '@upero/services';
import { CookieService } from 'ngx-cookie-service';
import { NavigationEnd, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { USER_COOKIE } from '@upero/misc';

@Injectable({
  providedIn: 'root',
})
export class AppService
{
  isBrowser = false;
  sessionId = '';
  userId = '';
  currentURL = '';

  constructor(
    private store: Store,
    private cookieService: CookieService,
    private toolsService: ToolsService,
    private deviceService: DeviceDetectorService,
    private chatService: ChatService,
    private userService: UserService,
    private cmsService: CmsService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private overlayService: OverlayService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: any,
    private componentFactoryResolver: ComponentFactoryResolver
  )
  {
    this.isBrowser = isPlatformBrowser(platformId);

    router.events.subscribe((val) =>
    {
      if (val instanceof NavigationEnd) {
        let activeRoute = val.url.replace(/^\/|\/$/g, '');
        this.overlayService.closeAll();

        if (activeRoute === '') {
          activeRoute = 'home';
        }

        this.store.set('activeRoute', activeRoute);
        const navigationHistory = this.store.selectForLocal('navigationHistory');
        this.currentURL = val.urlAfterRedirects;

        if (
          val.urlAfterRedirects !== '/login' &&
          val.urlAfterRedirects !== '/register' &&
          val.urlAfterRedirects !== '/account/confirmation'
        ) {
          navigationHistory.unshift(val.urlAfterRedirects);
        }
        this.store.set('navigationHistory', navigationHistory);

        if (val.urlAfterRedirects.indexOf('/waox/') !== -1 && this.isBrowser) { // auto auth route that will be handled here
          const secretToken = val.urlAfterRedirects.split('waox/')[1];
          const sessionId = this.toolsService.sessionId() ?? undefined;

          if (secretToken) {
            cmsService.getUrlSettings(secretToken, sessionId).subscribe((data: { redirect: string, userId: string }) => {
              this.authService.initUserAuth({
                sessionId,
                id: data.userId
              });
              this.userService.getByToken()
                .subscribe((userData) => {
                    this.chatService.receiveChat();
                    this.notificationService.listenToNotifications(/*userData.id*/);
                    this.router.navigateByUrl(data.redirect);
                  }
                );
            });
          }
        }

      }
    });

    if (this.isBrowser) {
      this.epicFunction();
    }
  }

  addRouteToTheHistory(url: string)
  {
    const navigationHistory = this.store.selectForLocal('navigationHistory');
    navigationHistory.unshift(url);
    this.store.set('navigationHistory', navigationHistory);
  }

  initApp()
  {
    if (this.isBrowser) {
      firstValueFrom(this.cmsService.getSettings());
      this.checkCookie();
      this.getDeviceSize();

      this.store.set('innerWidth', window.innerWidth);

      this.sessionId = this.toolsService.sessionId();
      this.store.set('sessionId', this.sessionId);

      setInterval(() =>
      {
        this.chatService.sendOnlineSignal();
      }, 5000);


      const localFavourites = localStorage.getItem('favourites');
      if (localFavourites) {
        this.store.set('favourites', JSON.parse(localFavourites));
      }

      this.store.set('user', undefined);
      this.store.set('loggedIn', false);

      const ysUser: any = this.cookieService.get(USER_COOKIE);
      if (ysUser) {
        const x: any = JSON.parse(ysUser);
        this.userId = x.id;
        this.store.set('user', x);
        this.store.set('accountType', x.accountType);
        this.chatService.receiveChat();
        this.notificationService.listenToNotifications();
      } else {
        this.store.set('user', undefined);
        this.store.set('loggedIn', false);
        this.store.set('accountType', '');
        this.chatService.receiveChat();
      }

      const ysBasket = localStorage.getItem('ysBasket');
      if (ysBasket) {
        this.store.set('basket', JSON.parse(ysBasket));
      }
      this.userService.logSession(this.userId, this.sessionId).subscribe();

      // restoring brand name from the localstorage
      const brandId = localStorage.getItem('currentBrand');
      if (brandId) {
        const siteSettings = this.store.selectForLocal('siteSettings');
        const currentBrand = siteSettings.brands.find(brand => brand.id == brandId);
        if (currentBrand) {
          this.store.set('currentBrand', currentBrand);
        }
      }
    }
  }

  checkCookie()
  {
    let cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      document.cookie = "MONKEY_JAM";
      cookieEnabled = document.cookie.indexOf("MONKEY_JAM") != -1;
    }
    return cookieEnabled || alert('Cookies must be enabled for site functionality. Please enable cookies');
  }

  epicFunction()
  {
    const device = {
      info: this.deviceService.getDeviceInfo(),
      isMobile: this.deviceService.isMobile(),
      isTablet: this.deviceService.isTablet(),
      isTabletV: this.deviceService.isTablet(),
      isTabletH: false,
      isDesktopDevice: this.deviceService.isDesktop(),
      mobileVersion: true,
    };
    if (this.deviceService.isDesktop()) {
      device.mobileVersion = false;
      this.store.set('deviceType', 'desktop');
    }
    const innerWidth = window.innerWidth;
    if (device.isTablet || device.isMobile) {
      if (innerWidth > 767) {
        device.mobileVersion = true;
        device.isMobile = false;
        device.isTablet = true;
        device.isTabletV = true;
        device.isTabletH = false;
      }
      if (innerWidth > 1020) {
        device.mobileVersion = false;
        device.isMobile = false;
        device.isTablet = true;
        device.isTabletV = false;
        device.isTabletH = true;
      }
    }

    this.store.set('device', device);
  }

  getDeviceSize()
  {
    let deviceType = this.store.selectForLocal('deviceType');

    // Todo add innerHeight for mobile landscape breakpoints detection
    if (window.innerWidth <= 576) {
      deviceType = 'mobile';
    }
    if (window.innerWidth > 576 && window.innerWidth <= 768) {
      deviceType = 'tabletV';
    }
    if (window.innerWidth > 768 && window.innerWidth <= 1200) {
      deviceType = 'tabletH';
    }
    if (window.innerWidth > 1200) {
      deviceType = 'desktop';
    }
    this.store.set('innerWidth', window.innerWidth);
    this.store.set('deviceType', deviceType);
  }

  createDynamicComponent<T>(component: Type<T>, viewContainerRef: ViewContainerRef)
  {
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    return viewContainerRef.createComponent(factory);
  }
}
