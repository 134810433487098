<section id="help">
    <div class="container-fluid bg-slate-5 data-container">
        <div *ngIf="topics.length" class="help__container">
            <!--        <input type="text" class="form-control" [(ngModel)]="params.freetext" />-->
            <div class="row g-4">
                <ng-container
                *ngFor="let s of topics">
                    <div
                        class="col-12 col-md-6 col-xl-4 col-xxl-3 faq-col">
                        <a
                            class="data-tile-faq bg-white no-border hover d-flex justify-content-center align-items-center flex-column"
                            [uperoBrandRouterLink]="[block.fields.pageSlug, s.contents.slug]">
                            <h2 class="help__title fw-bold m-0">{{s.contents.title}}</h2>
                            <i class="fa-4x mt-4" [ngClass]="s.contents.icon.class"></i>
                        </a>

                    </div>

                </ng-container>
            </div>
        </div>
        <upero-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></upero-pagination>

        <!--<div *ngIf="popularQuestions.length" class="faq__container">
            <h2 class="faq__heading">Popular Questions</h2>
            <div class="question" *ngFor="let q of popularQuestions">
                <div class="d-flex fs-5 ff-spectral fw-500 hover" (click)="toggleExpanded(q)">
                    <svg-icon *ngIf="!q.expanded" src="assets/images/icon-chevron-right.svg"></svg-icon>
                    <svg-icon *ngIf="q.expanded" src="assets/images/icon-chevron-down.svg"></svg-icon>
                    <span class="question__title">{{q.question}}</span>
                </div>
                <div *ngIf="q.expanded" class="pt-3">
                    <div class="question__answer__text" [innerHTML]="q.answer"></div>
                    <a *ngIf="q.linkToRoute !== 0" class="btn btn-cta"
                       [uperoBrandRouterLink]="['/', q.linkToRouteUrl]">
                        {{q.linkToRouteText}}
                    </a>
                </div>
            </div>
        </div>-->

        <div class="text-center" *ngIf="!topics.length">
            <h2>Sorry - no help topics to show at the moment</h2>
        </div>
    </div>

</section>
