import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@upero/store';
import { map, tap } from 'rxjs/operators';
import { ENVIRONMENT_CONFIG, EnvironmentType, USER_COOKIE } from '@upero/misc';
import { ToolsService } from './tools.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiAuthHttpClient } from '@upero/misc';
import { firstValueFrom } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private apiAuthHttp: ApiAuthHttpClient, // errors, single and authentication request handled
    private store: Store,
    private cookieService: CookieService,
    private toolsService: ToolsService,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    if (isPlatformBrowser(platformId)) {
      if (this.env.version !== localStorage.getItem('version')) {
        localStorage.clear();
        this.cookieService.deleteAll();
      }
      localStorage.setItem('version', this.env.version);
    }
  }

  logSession(userId, sessionId) {
    const device = this.store.selectForLocal('device');

    const dataToSend = {
      device: device.info,
      userId,
      sessionId,
    };

    return this.apiAuthHttp.post(this.env.apiPath + 'users/device', dataToSend).pipe(map((data: any) => data));
  }

  async postcodeLookup(postcode) {
    const result: any = await firstValueFrom(
      this.apiAuthHttp.get(`${this.env.apiPath}postcode/${postcode}`).pipe(map(({ data }: any) => data))
    );

    return result;
  }

  countryList() {
    return this.apiAuthHttp.get(`${this.env.apiPath}countries`).pipe(map((data: any) => data.data));
  }

  createUserNew(user) {
    const dataToSend = {
      user,
      createdBy: this.store.selectForLocal('user'),
    };
    return this.apiAuthHttp.post(this.env.apiPath + 'users/new', dataToSend);
  }

  async createAddressNew(address, user) {
    const dataToSend = {
      address,
      user,
      createdBy: this.store.selectForLocal('user'),
    };
    const result: any = await this.apiAuthHttp.post(this.env.apiPath + 'users/address/new', dataToSend).toPromise();

    return result.data;
  }

  async createCompanyNew(company, user) {
    const dataToSend = {
      company,
      user,
      createdBy: this.store.selectForLocal('user'),
    };
    const result: any = await this.apiAuthHttp.post(this.env.apiPath + 'users/company/new', dataToSend).toPromise();
    return result.data;
  }

  RegistrationAdditionalDetails(address, company, user) {
    const dataToSend = {
      address,
      company,
      user,
    };
    return this.apiAuthHttp
      .post(this.env.apiPath + 'users/company/additional', dataToSend)
      .pipe(map((data: any) => data));
  }

  createUser(user) {
    return this.apiAuthHttp.post(this.env.apiPath + 'users/signup', user).pipe(map((data: any) => data));
  }

  async createUserAsync(user) {
    const result: any = await this.apiAuthHttp.post(this.env.apiPath + 'users/signup', user).toPromise();
    return result.data;
  }

  async checkUserExists(email) {
    const result: any = await this.apiAuthHttp.get(this.env.apiPath + 'users/exists/' + email).toPromise();
    return result.data;
  }

  checkReferralCode(referralCode, referralEmail) {
    const dataToSend = {
      referralCode,
      referralEmail,
    };
    return this.apiAuthHttp
      .post(this.env.apiPath + 'users/referralcode/check', dataToSend)
      .pipe(map((data: any) => data));
  }

  async createGuest(user) {
    const result: any = await this.apiAuthHttp.post(this.env.apiPath + 'users/signup', user).toPromise();
    return result.data;
  }

  async createAddress(address, createdBy) {
    const dataToSend = {
      address,
      createdBy,
    };
    const result: any = await this.apiAuthHttp.post(this.env.apiPath + 'users/address', dataToSend).toPromise();
    return result;
  }

  updateAddress(address) {
    const user = this.store.selectForLocal('user');
    const dataToSend = {
      address,
      user,
    };
    return this.apiAuthHttp
      .put(this.env.apiPath + 'users/address/' + address.id, dataToSend)
      .pipe(map((data: any) => data));
  }

  updatePrimaryUser(companyId, userId) {
    const dataToSend = {
      companyId,
      userId,
    };
    return this.apiAuthHttp
      .put(this.env.apiPath + 'users/company/' + companyId + '/primaryuser', dataToSend)
      .pipe(map((data: any) => data));
  }

  createCompany(company, createdBy) {
    const dataToSend = {
      company,
      createdBy,
    };
    return this.apiAuthHttp.post(this.env.apiPath + 'users/company', dataToSend).pipe(map((data: any) => data));
  }

  getByToken() {
    return this.apiAuthHttp.get(this.env.apiPath + 'users/profile').pipe(
      tap((data: any) => {
        this.store.set('user', data.data);
        this.cookieService.set(USER_COOKIE, JSON.stringify(data.data), undefined, '/');
        this.toolsService.setSessionId(data.data.id);
        localStorage.setItem('user', data.data.id);
      })
    );
  }

  update(user) {
    return this.apiAuthHttp.put(this.env.apiPath + 'users/' + user.id, user).pipe(
      map(async (data: any) => {
        this.store.set('user', user);
        this.cookieService.set(USER_COOKIE, JSON.stringify(user), undefined, '/');
        // await this.cookieService.setUser(user);
        localStorage.setItem('user', user.id);
        // await this.cookieService.set('user', user);
        return data;
      })
    );
  }

  updateContact(user) {
    return this.apiAuthHttp
      .put(this.env.apiPath + 'users/contact/' + user.id, user)
      .pipe(map(async (data: any) => data));
  }

  findAllByCompany(params) {
    const dataToSend = {
      params,
    };
    return this.apiAuthHttp.post(this.env.apiPath + 'users/search/users', dataToSend).pipe(map((data: any) => data));
  }

  findOne(id) {
    return this.apiAuthHttp.get(this.env.apiPath + 'users/' + id).pipe(map((data: any) => data));
  }

  delete(id) {
    return this.apiAuthHttp.delete(this.env.apiPath + 'users/' + id).pipe(map((data: any) => data));
  }

  search() {
    const companyUsers = this.store.selectForLocal('companyUsers');
    const user = this.store.selectForLocal('user');
    companyUsers.params.accountType = user.accountType;
    companyUsers.params.companyId = user.company.id;
    const dataToSend = {
      params: companyUsers.params,
    };
    return this.apiAuthHttp.post(this.env.apiPath + 'users/search', dataToSend).pipe(
      map((data: any) => {
        companyUsers.data = data.data.data;

        companyUsers.params.pages = Math.ceil(data.data.totalRecords / companyUsers.params.limit);
        companyUsers.params.totalRecords = data.data.totalRecords;
        companyUsers.params.pageArray = [];
        for (let i = 0; i < companyUsers.params.pages; i++) {
          companyUsers.params.pageArray.push(i);
        }
        this.store.set('companyUsers', companyUsers);

        return data;
      })
    );
  }

  searchCustomers(params) {
    const dataToSend = {
      params,
    };
    return this.apiAuthHttp
      .post(this.env.apiPath + 'users/search/customers', dataToSend)
      .pipe(map((data: any) => data));
  }

  findAddressesByCustomer(userId) {
    return this.apiAuthHttp.get(this.env.apiPath + 'users/addresses/' + userId).pipe(
      map((data: any) => {
        this.store.set('deliveryAddresses', data.data);
        return data;
      })
    );
  }

  addressDelete(addressId) {
    return this.apiAuthHttp.delete(this.env.apiPath + 'users/address/' + addressId).pipe(map((data: any) => data));
  }

  searchCompanies(params) {
    const dataToSend = {
      params,
    };
    return this.apiAuthHttp
      .post(this.env.apiPath + 'users/search/companies', dataToSend)
      .pipe(map((data: any) => data));
  }

  areasCovered(params) {
    const dataToSend = {
      params,
    };
    return this.apiAuthHttp
      .post(this.env.apiPath + 'users/search/companies/areascovered', dataToSend)
      .pipe(map((data: any) => data));
  }

  getCompanyAreasCovered(companyId) {
    return this.apiAuthHttp
      .get(this.env.apiPath + 'users/search/companies/areascovered/' + companyId)
      .pipe(map((data: any) => data));
  }

  searchSupplierCollectionCosts(params) {
    const dataToSend = {
      params,
    };
    return this.apiAuthHttp
      .post(this.env.apiPath + 'courier/supplier/collection/', dataToSend)
      .pipe(map((data: any) => data));
  }

  updateSupplierCollectionCosts(supplier, courierId) {
    const dataToSend = {
      courierId,
      supplier,
    };

    return this.apiAuthHttp
      .put(this.env.apiPath + 'courier/supplier/collection', dataToSend)
      .pipe(map((data: any) => data));
  }

  disablePremiumAnimation(companyId: number) {
    return this.apiAuthHttp
      .put(this.env.apiPath + 'users/premium-animation/' + companyId, {})
      .pipe(map((data: any) => data));
  }

  removeUserGuestStatus(userId: string) {
    return this.apiAuthHttp
      .put(this.env.apiPath + 'users/guest/remove/' + userId, {})
      .pipe(map(({ data }: any) => data));
  }

  async createLeadBasedOnGuestData(leadData) {
    this.apiAuthHttp
      .post(this.env.apiPath + 'users/create-lead', leadData)
      .pipe(map((data: any) => data))
      .subscribe();
  }
}
