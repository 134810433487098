<div class="container py-5">
    <div class="d-flex flex-column justify-content-center text-center">
        <svg-icon
            src="assets/images/icon-colour.rejected.svg"
            [svgClass]="'icon-alert'"
            [ngClass]="'mb-3'">
        </svg-icon>
        <div class="ff-spectral fw-bold display-3 mb-3">
            404
        </div>
        <h1 class="fw-bold display-3 mb-3">
            Page not found.
        </h1>
        <div class="ff-spectral fs-5 mb-5">
            Sorry, we can’t find the page you’re looking for. Here are some that might help.
        </div>
        <a [uperoBrandRouterLink]="['/']" class="text-decoration-underline fs-5 mb-3">
            Home
        </a>
        <a [uperoBrandRouterLink]="['/help']" class="text-decoration-underline fs-5 mb-3">
            Help
        </a>
        <a [uperoBrandRouterLink]="['/advice']" class="text-decoration-underline fs-5 mb-3">
            Advice
        </a>
        <a [uperoBrandRouterLink]="['/contact-us']" class="text-decoration-underline fs-5">
            Contact us
        </a>
    </div>
</div>
