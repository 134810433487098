import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'address'}) // Basic UK adress formating pipe from the YS DB object

export class AddressPipe implements PipeTransform {
    transform(value: any, seperator: string = ", ", result: "string" | "array" = "string") {
        if (
            typeof value === 'object' &&
            !Array.isArray(value) &&
            value !== null
        ) {
            const addressResult = [];
            if (value.add1) {
                addressResult.push(value.add1);
            }
            if (value.add2) {
                addressResult.push(value.add2);
            }
            if (value.add3) {
                addressResult.push(value.add3);
            }
            if (value.town) {
                addressResult.push(value.town);
            }
            if (value.county) {
                addressResult.push(value.county);
            }
            if (value.postcode) {
                addressResult.push(value.postcode);
            }
            if (value.country) {
                addressResult.push(value.country);
            }
            if (result === "string") {
                return addressResult.join(seperator);
            }

            return addressResult; // the it's an array
        }

        return value;
    }
}
