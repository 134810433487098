<div class='row g-0' [ngClass]="['bg-' + block.fields.backgroundClass, 'block-' + block.fields.blockHeight]">
  <div class='col-12 col-lg-6 h-100' [ngClass]="block['fields']['position'] === 'left' ? 'order-lg-0' : 'order-lg-1'">
    <div class='img-cover-wrapper d-flex justify-content-center align-items-center position-relative w-100 h-100'>
      <img class='img-cover' [ngClass]="{'px-5 pt-5 pb-3 pb-xl-5': block.fields.boxed}" [src]="block['fields']['image']" [appScrset]="block['fields']['image']" [width]="1200" [doCndReplace]="true">
    </div>
  </div>
  <div class='col-12 col-lg-6 h-100' [ngClass]="block['fields']['position'] === 'left' ? 'order-lg-1' : 'order-lg-0'">
    <div class='d-flex flex-column justify-content-center align-items-center text-center p-4 p-xl-5 w-100 h-100'>
      <div class='fw-600 mb-3'
           [ngClass]="[
           'text-' + block.fields.colorClass,
           block.fields.fontSizeTitle ? block.fields.fontSizeTitle : ''
           ]" [innerHTML]="block['fields']['title'] | safeHTML"></div>
      <div class='mb-3'
           [ngClass]="[
           'text-' + block.fields.colorClass,
           block.fields.fontSizeText ? block.fields.fontSizeText : '']"
           [innerHTML]="block['fields']['content'] | safeHTML"></div>
      <upero-button [button]="block.fields['ctaButton']" [isExternal]="true"></upero-button>
    </div>
  </div>
</div>
