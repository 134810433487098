import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';
import { Store } from '@upero/store';

@Injectable({
  providedIn: 'root'
})
export class PostcodeService
{

  constructor(
    private http: ApiAuthHttpClient,
    private store: Store,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  search(params)
  {
    return this.http
      .post(this.env.apiPath + 'postcode/search', { params })
      .pipe(map((data: any) => data));
  }

  addPostcode(params)
  {
    return this.http
      .post(this.env.apiPath + 'postcode/company/add', { params })
      .pipe(map((data: any) => data));
  }

  addPostcodeByRegion(params)
  {
    return this.http
      .post(this.env.apiPath + 'postcode/company/add/region', { params })
      .pipe(map((data: any) => data));
  }

  addPostcodeByArray(params)
  {
    return this.http
      .post(this.env.apiPath + 'postcode/company/add/array', { params })
      .pipe(map((data: any) => data));
  }

  removePostcode(params)
  {
    return this.http
      .post(this.env.apiPath + 'postcode/company/remove', { params })
      .pipe(map((data: any) => data));
  }
}
