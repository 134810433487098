<section *ngIf="caseStudy" class="container bg-white py-xxl-3 mb-5 my-md-0">
    <div class="row">
        <div class="col-12 col-md-6 col-xxl-5 d-flex align-items-center justify-content-center ps-xxl-5 order-1 order-md-0">
            <div class="px-3 py-md-5 p-lg-5 ms-xxl-5 text-center text-md-start">
                <h2 class="fs-3 fw-600 mb-3" [ngClass]="(deviceType$| async) === 'mobile' ? 'fw-500' : 'fw-bold'">{{block.fields.title}}</h2>
                <p class="small op-6">{{caseStudy.location}}</p>
                <div class="mb-4 mb-md-5" [innerHTML]="block.fields.previewParagraph"></div>
                <upero-button [button]="block.fields['ctaButton']" [link]="'/case-studies/' + caseStudy.slug"></upero-button>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xxl-7 d-flex align-items-center order-0 order-md-1 mb-5 mb-md-0">
            <div class="text-center w-100 h-100 px-3 py-md-5 py-lg-5">
                <img *ngIf="caseStudy.images" [src]="caseStudy.images[0].url" [width]="700" [appScrset]="caseStudy.images[0].url" class="img-fluid img-cover" />
            </div>
        </div>
    </div>
</section>
