import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  animate,
  animateChild,
  keyframes,
  query,
  sequence,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Unsubscribable } from 'rxjs';
import { debounceTime, delay, take, tap } from 'rxjs/operators';
import { ChatService, FacebookPixelService, GoogleAnalyticsService, OverlayService } from '@upero/services';
import { Store } from '@upero/store';
import { setTimeout$ } from '@upero/misc';

import { ENVIRONMENT_CONFIG, EnvironmentType } from '@upero/misc';

@Component({
  selector: 'upero-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  /*encapsulation: ViewEncapsulation.None, not sure why this was on, if needed we can turn it on again. */
  animations: [
    trigger('slideBottomTopWelcome', [
      transition('* => *', [
        sequence([
          animate(
            '200ms',
            keyframes([
              style({ opacity: 0, transform: 'translateY(100%)' }),
              style({ opacity: 1, transform: 'translateY(0%)' }),
            ])
          ),
          query('@listItem', stagger(50, animateChild()), { optional: true }),
        ]),
      ]),
    ]),
    trigger('slideLeftRight', [
      state('void', style({ transform: 'translateX(-100%)' })),
      transition('void <=> *', [animate('200ms')]),
    ]),
    trigger('slideLeftRightWithList', [
      transition('* => *', [
        sequence([
          animate(
            '200ms ease-in',
            keyframes([style({ transform: 'translateX(-100%)' }), style({ transform: 'translateX(0%)' })])
          ),
          query('@listItem', stagger(50, animateChild()), { optional: true }),
        ]),
      ]),
    ]),
    trigger('listItem', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(+10px)' }),
        animate('150ms', style({ opacity: 1, transform: 'translateY(0px)' })),
      ]),
    ]),
  ],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('textArea', { read: ElementRef }) textArea: ElementRef<HTMLTextAreaElement>;
  private readonly subs: Unsubscribable[] = [];
  private readonly typing$ = new Subject();
  private typing = false;
  overlays$ = this.store.select<any>('overlays');
  user = this.store.selectForLocal('user') || {
    id: localStorage.getItem('tmpSessionId'),
    name: localStorage.getItem('tmpSessionName'),
    email: localStorage.getItem('tmpSessionEmail'),
  };
  message: any = {};
  chatStore$ = this.store.select<any>('chatStore');
  openChats = [];
  closedChats = [];
  showAllConversations = false;
  disableFirstSlideAnimation = true;
  guest = {
    id: '',
    name: '',
    email: '',
  };
  newGuest = false;
  editMode = false;
  guestForm: FormGroup;
  guestFormSubmitted = false;
  showUploader = false;
  uploadConfig: any;
  resizeWidth: 500;
  typingSignalSent = false;
  editingMessageOriginalContent: any;
  messages = [];

  constructor(
    public cs: ChatService,
    private store: Store,
    private overlayService: OverlayService,
    private formBuilder: FormBuilder,
    private gaService: GoogleAnalyticsService,
    private fbqService: FacebookPixelService,
    private cdr: ChangeDetectorRef,
    @Inject(ENVIRONMENT_CONFIG) public env: EnvironmentType
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.cs.receiveDeleteSignal().subscribe(),
      this.cs.receiveEditSignal().subscribe(),
      this.cs.receiveTypingSignal().subscribe(),
      this.typing$
        .pipe(
          tap(() => {
            if (!this.typing) {
              this.sendTypingSignal(true);
            }
            this.typing = true;
          }),
          debounceTime(750)
        )
        .subscribe(() => {
          this.sendTypingSignal(false);
          this.typing = false;
        }),
      this.chatStore$.subscribe((chatStore) => {
        this.messages = chatStore.messages.map((m) => this.normalizeSenderName(m));
        this.openChats = chatStore.groups.filter((g) => g.isClosed === 0 && g.messagesCnt > 0);
        this.closedChats = chatStore.groups.filter((g) => g.isClosed === 1 && g.messagesCnt > 0);
      }),
      this.cs.scrollToBottom$.subscribe(() => {
        const typeBox = document.getElementById('chat-content-holder');
        if (typeBox) {
          this.cdr.detectChanges();
          setTimeout$(() => typeBox.scrollTo(0, typeBox.scrollHeight), 100);
        }
      })
    );
    this.cs.getGroups().pipe(take(1)).subscribe();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  get field() {
    return this.guestForm.controls;
  }

  toggleAllConversations() {
    this.showAllConversations = !this.showAllConversations;
  }

  checkForUserInfo() {
    if (this.user.username || this.guest.name || this.user.name) {
      this.startSupportChat();
    } else {
      if (!this.guestForm) {
        this.guestForm = this.formBuilder.group({
          name: ['', [Validators.required, Validators.minLength(3)]],
          email: ['', [Validators.required, Validators.email, Validators.minLength(5)]],
        });
      }
      this.newGuest = true;
    }
  }

  public editMessage(message: any): void {
    this.editingMessageOriginalContent = message.content;
    message.isEditMode = true;
    message.isActionModeEnable = false;
    this.message = { ...message };
  }

  public cancelEditedMessage(message: any): void {
    message.content = this.editingMessageOriginalContent;
    this.message = { content: '' };
    message.isEditMode = false;
  }

  public deleteMessage(message: any): void {
    this.cs.sendDeleteSignal(message);
  }

  onGuestFormSubmit() {
    if (!this.guestFormSubmitted) {
      this.guestFormSubmitted = true;
      if (this.guestForm.invalid) {
        this.guestForm.markAllAsTouched();
        this.guestFormSubmitted = false;
        return;
      }
      this.guest = {
        id: localStorage.getItem('tmpSessionId'),
        name: this.guestForm.controls.name.value,
        email: this.guestForm.controls.email.value,
      };
      localStorage.setItem('tmpSessionName', this.guest.name);
      localStorage.setItem('tmpSessionEmail', this.guest.email);
      this.gaService.event('submit_guest_chat_form', 'Chat', 'Submit guest chat form');
      this.fbqService.event('submit_guest_chat_form');
      this.checkForUserInfo();
    }
  }

  startSupportChat() {
    let sender = this.restoreGuestFromLocal();
    if (this.user.username) {
      sender = this.store.selectForLocal('user');
    }

    if (!sender.id?.length) {
      sender.id = this.user.id;
    }

    this.cs.startChatGroupFromWithinChat({ id: ChatService.CUSTOMER_SERVICE_ID }, '', sender).subscribe((chat) => {
      this.cs.getGroups().subscribe(() => {
        this.assignSubjects(chat.data.subjects);
        const group = this.getGroupById(chat.data.groupId);
        this.gaService.event('start_new_chat', 'Chat', 'Start new conversation');
        this.fbqService.event('start_new_chat');

        // Todo if group is newly created use selectGroup without welcome Message
        this.selectGroupWithWelcomeMessage(group);
      });
    });
  }

  assignSubjects(subjects) {
    const chatStore = this.store.selectForLocal('chatStore');
    chatStore.subjects = subjects;
    this.store.set('chatStore', chatStore);
  }

  getGroupById(groupId) {
    const chatStore = this.store.selectForLocal('chatStore');
    return chatStore.groups.filter((group) => group.id === groupId)[0];
  }

  selectGroup(group) {
    const chatStore = this.store.selectForLocal('chatStore');
    this.cs.getByGroupId(group.id, true).subscribe(() => {
      chatStore.group = group;
      chatStore.group.unread = 0;
      chatStore.unread = 0;
      chatStore.groups.forEach((g) => {
        chatStore.unread = +chatStore.unread + +g.unread;
      });
      this.store.set('chatStore', chatStore);
      this.markAsRead(group);
      this.scrollBottom();
    });
  }

  selectGroupWithWelcomeMessage(group) {
    this.cs.getByGroupId(group.id, true).subscribe(() => {
      const chatStore = this.store.selectForLocal('chatStore');
      chatStore.group = group;
      chatStore.group.unread = 0;
      chatStore.unread = 0;
      chatStore.groups.forEach((g) => {
        chatStore.unread = +chatStore.unread + +g.unread;
      });
      this.store.set('chatStore', chatStore);
      this.newGuest = false;
      this.markAsRead(group);
      this.createGroupWelcomeMessage(group);
    });
  }

  markAsRead(group) {
    this.cs.markAsRead(group.id).subscribe();
  }

  addChat(nonChatContent?: any) {
    // message edit case
    if (this.message.isEditMode) {
      this.cs.editMessage(this.message);
      const message = this.messages.find((m) => m.id === this.message.id);
      message.isEditMode = false;
      message.edited = true;
      message.content = this.message.content;
      this.message = { content: '' };
      return;
    }
    const chatStore = this.store.selectForLocal('chatStore');
    this.message.createdAt = new Date();
    this.message.groupId = chatStore.group.id;
    this.message.userId = this.user.id;
    this.message.id = new Date().getTime();
    this.message.isActionModeEnable = false;
    this.message.isEditMode = false;
    this.message.nonChatContent = nonChatContent;
    this.message.recipients = [
      {
        userId: 'd6e37e44-ae9b-4c45-89f5-a3508970b876',
        name: 'Customer Services',
        recipientType: '',
      },
    ];

    // case for communication regarding order
    if (chatStore.group.chatType === 'Order') {
      this.message.recipients.push({
        userId: chatStore.group.sender?.userId,
        name: chatStore.group.sender?.contactName,
        recipientType: chatStore.group.sender?.accountType,
      });
    }

    let name, firstname, surname;
    [name, firstname, surname] = this.getUserName();

    this.message.user = {
      id: this.user.id,
      name,
      companyId: this.user.company ? this.user.company.id : '',
      firstname,
      surname,
      accountType: this.user.accountType ? this.user.accountType : 'guest',
    };

    if (nonChatContent) {
      this.message.type = nonChatContent.type;
    } else {
      this.message.type = 'chat';
    }

    this.cs.sendChat(this.message);

    if (!nonChatContent || nonChatContent.type !== 'subject') {
      // TODO: for this moment we set 'sending = true' but never switch  it back
      // temporary commented out
      // this.message.sending = true;
    }
    chatStore.messages.push(this.message);
    this.store.set('chatStore', chatStore);

    this.message = { content: '' };

    const textArea = this.textArea.nativeElement;
    textArea.style.height = 'auto';

    if (nonChatContent) {
      if (nonChatContent.type === 'subject') {
        this.createSubjectResponse(chatStore.group.id);
        this.scrollBottom();
      }
      if (nonChatContent.type === 'file') {
        this.scrollBottom();
      }
    } else {
      this.scrollBottom();
    }
  }

  createGroupWelcomeMessage(group) {
    const chatStore = this.store.selectForLocal('chatStore');
    let userName = this.getUserName()[0];
    const message = {
      groupId: group.id,
      createdAt: new Date(),
      type: 'welcome',
      user: {
        id: ChatService.CUSTOMER_SERVICE_ID,
        name: 'Customer Services',
        companyId: '',
      },
      recipients: [],
      isActionModeEnable: false,
      isEditMode: false,
      tmpSessionId: localStorage.getItem('tmpSessionId'),
      content: 'Hello ' + userName + ', thank you contacting us. Please let us know what you would like help with.',
    };
    chatStore.messages.push(message);
    this.store.set('chatStore', chatStore);
    this.cs.sendChat(message);
    this.cs.getGroups().pipe(delay(1000), take(1)).subscribe();
  }

  scrollBottom() {
    this.cs.scrollBottom();
  }

  viewGroups() {
    const chatStore = this.store.selectForLocal('chatStore');
    chatStore.group = undefined;
    this.store.set('chatStore', chatStore);
  }

  back() {
    const chatStore = this.store.selectForLocal('chatStore');
    this.disableFirstSlideAnimation = false;
    this.cs.getGroups().pipe(take(1)).subscribe();
    if (chatStore.group && this.showAllConversations) {
      this.viewGroups();
    } else if (!chatStore.group && this.showAllConversations) {
      this.toggleAllConversations();
    } else if (chatStore.group && !this.showAllConversations) {
      this.viewGroups();
    } else if (this.newGuest) {
      this.newGuest = !this.newGuest;
    }
    this.closeUploader();
  }

  close() {
    this.viewGroups();
    this.overlayService.closeAll();
  }

  openChat() {
    const chatStore = this.store.selectForLocal('chatStore');
    chatStore.group.isClosed = 0;
    for (let i = 0; i < chatStore.groups.length; i++) {
      if (chatStore.groups[i].id === chatStore.group.id) {
        chatStore.groups[i].isClosed = 0;
      }
    }
    this.store.set('chatStore', chatStore);

    this.cs.openChat(chatStore.group.id).subscribe();
  }

  sendTranscript() {
    const chatStore = this.store.selectForLocal('chatStore');

    this.cs.sendTranscript(chatStore.group).subscribe((data) => {
      chatStore.group.transcriptSent = true;
      chatStore.group.transcriptSentDate = new Date();
    });
  }

  setSubject(subject) {
    const chatStore = this.store.selectForLocal('chatStore');
    chatStore.group.subjectName = subject.name;
    this.store.set('chatStore', chatStore);
    this.message.content = 'Subject: ' + subject.name;
    this.addChat({ type: 'subject', subject });
  }

  createSubjectResponse(groupId) {
    const chatStore = this.store.selectForLocal('chatStore');
    const message = {
      groupId,
      createdAt: new Date(),
      type: 'chat',
      user: {
        id: 'd6e37e44-ae9b-4c45-89f5-a3508970b876',
        name: 'Customer Services',
        companyId: '',
      },
      recipients: [],
      isEditMode: false,
      isActionModeEnable: false,
      content: 'An expert will reply as soon as possible in order to support you.',
    };
    chatStore.messages.push(message);
    this.store.set('chatStore', chatStore);
    this.cs.sendChat(message);
    this.cs.getGroups().pipe(delay(1000), take(1)).subscribe();
  }

  triggerUpload() {
    this.initUpload();
    this.showUploader = true;
  }
  closeUploader() {
    this.showUploader = false;
  }

  initUpload() {
    this.uploadConfig = {
      url: this.env.imageUploadPath + 'chat/upload',
      acceptedFiles: 'image/jpeg,image/png,image/gif',
      parallelUploads: 1,
      resizeWidth: this.resizeWidth,
      maxFiles: 5,
      maxFilesize: 2048,
    };
  }

  onUploadError(e) {}

  onUploadSuccess(response) {
    if (response.data) {
      this.closeUploader();
      this.initUpload();
      setTimeout(() => {
        this.pushUploadToChat(response.data);
      }, 1000);
    }
  }

  queueComplete(e) {
    this.closeUploader();
  }

  pushUploadToChat(fileData) {
    const chatStore = this.store.selectForLocal('chatStore');
    this.store.set('chatStore', chatStore);
    this.message.content = fileData;
    this.message.filename = fileData;
    this.message.type = 'file';
    this.addChat({ type: 'file', fileData });
  }

  public textareaKeyDownHandler($event: KeyboardEvent): void {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      this.addChat();
      return;
    }
    // @ts-ignore
    this.typing$.next();
    this.resizeTextarea(this.textArea.nativeElement);
  }

  // in future we need to use one way to pass sender name
  normalizeSenderName(message): string {
    let senderName = '';
    if (message.user?.firstname && message.user?.surname) {
      senderName = message.user?.firstname + ' ' + message.user?.surname;
    }

    if (message.user.name?.length) {
      senderName = message.user.name;
    }

    if (message.firstname && message.sername) {
      senderName = message.firstname + ' ' + message.surname;
    }

    if (message.user) {
      message.user.name = senderName;
    } else {
      message.user = { name: senderName };
    }

    return message;
  }

  private sendTypingSignal(typing: boolean): void {
    const chatStore = this.store.selectForLocal('chatStore');
    const group = chatStore.group;
    const dataToSend: any = {
      groupId: group.id,
      user: this.cs.getChatUser(),
      typing,
    };
    this.cs.sendTypingSignal(dataToSend);
  }

  public resizeTextarea(textarea: HTMLTextAreaElement): void {
    setTimeout(() => {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }, 0);
  }

  private getUserName(): string[] {
    let name;
    let firstname;
    let surname;
    if (this.user.contact) {
      name = this.user.contact.firstname + ' ' + this.user.contact.surname;
      firstname = this.user.contact.firstname;
      surname = this.user.contact.surname;
    } else if (this.user.name) {
      name = this.user.name;
      firstname = name.split(' ')[0];
      surname = name.split(' ')[1];
    } else if (localStorage.getItem('tmpSessionName')) {
      const userNameFromLocalStorage = localStorage.getItem('tmpSessionName');
      name = userNameFromLocalStorage;
      firstname = userNameFromLocalStorage.split(' ')[0];
      surname = userNameFromLocalStorage.split(' ')[1];
    } else {
      name = 'Guest';
      firstname = 'Guest';
      surname = '';
    }

    return [name, firstname, surname];
  }

  private restoreGuestFromLocal() {
    return (this.guest = {
      id: localStorage.getItem('tmpSessionId'),
      name: localStorage.getItem('tmpSessionName'),
      email: localStorage.getItem('tmpSessionEmail'),
    });
  }
}
