
<div class="modal max-qty-modal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header flex-column">
                <h3 class="modal-title fw-bold text-center">Maximum Amount Limit</h3>
            </div>
            <div class="modal-body p-3 p-lg-5">
                Maximum online order amount is 500 units, please contact customer services if you would like to purchase more
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-center w-100 px-3 px-lg-5">
                    <button (click)="this.close()" type="button" class="btn btn-outline-primary">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
