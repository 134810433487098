import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { OverlayService, UserService } from '@upero/services';
import { Store } from '@upero/store';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'upero-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent implements OnInit {
  @Input() showNavigation = false;
  @Input() user;
  @Output() addressAdded = new EventEmitter();

  address = null;
  type = this.store.selectForLocal('addressType');
  isBrowser = false;

  constructor(
    private overlayService: OverlayService,
    private store: Store,
    private userService: UserService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser && !this.user) {
      this.user = this.store.selectForLocal('user');
    }
  }

  ngOnInit(): void {}

  async saveAddress() {
    if (this.user && this.user.guest) {
      if (this.type === 'billing') {
        localStorage.setItem('tmpSessionBillingAddress', JSON.stringify(this.address));
      } else {
        localStorage.setItem('tmpSessionDeliveryAddress', JSON.stringify(this.address));
      }

      this.addressAdded.emit({ ...this.address, userId: this.user.id });
    } else {
      const addressResult = await this.userService.createAddress({ ...this.address, userId: this.user.id }, this.user);
      this.addressAdded.emit(addressResult.data);
    }
  }

  addressCreated(type, address): void {
    this.address = address;
  }

  close() {
    this.store.set('addressType', '');
    this.overlayService.toggle('addressCreate');
  }
}
