import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter, Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  animate,
  animateChild,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ApiAuthHttpClient, ENVIRONMENT_CONFIG, EnvironmentType, setTimeout$ } from '@upero/misc';
import { AlertService } from '@upero/services';


@Component({
  selector: 'upero-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', [animate('200ms')]),
    ]),
    trigger('list', [
      transition('* => *', [
        query('@listItem', stagger(300, animateChild()), {
          optional: true,
        }),
      ]),
    ]),
    trigger('listItem', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-50px)' }),
        animate(
          '300ms',
          style({ opacity: 1, transform: 'translateX(0px)' })
        ),
      ]),
    ]),
    trigger('bounceIn', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', [
        animate(
          '0.3s 0.3s cubic-bezier(0.215, 0.610, 0.355, 1.000)',
          keyframes([
            style({
              opacity: 0,
              transform: 'scale3d(.3, .3, .3)',
              offset: 0,
            }),
            style({
              transform: 'scale3d(1.1, 1.1, 1.1)',
              offset: 0.2,
            }),
            style({
              transform: 'scale3d(.9, .9, .9)',
              offset: 0.4,
            }),
            style({
              opacity: 1,
              transform: 'scale3d(1.03, 1.03, 1.03)',
              offset: 0.6,
            }),
            style({
              transform: 'scale3d(.97, .97, .97)',
              offset: 0.8,
            }),
            style({
              opacity: 1,
              transform: 'scale3d(1, 1, 1)',
              offset: 1,
            }),
          ])
        ),
      ]),
    ]),
  ],
})
export class FileUploaderComponent implements OnInit
{
  @Input() uploadConfig = {
    url: '',
    acceptedFiles: '',
    maxFiles: 5,
    maxFileSize: 10024,
  };
  @Input() ownerType = '';
  @Input() ownerId = '';
  @Input() batchId = '';
  @Input() confirmBeforeUpload?: boolean = false;
  @Output() success = new EventEmitter();
  @Output() uploading = new EventEmitter();
  @Output() confirmUpload = new EventEmitter();
  @Output() showCompetitionConfirm = new EventEmitter();

  @ViewChild('fileDropRef', { static: false })
  fileDropper: ElementRef;

  public fileUploaderForm = new FormGroup({
    fileUploader: new FormControl(''),
  });

  private _error: Error;

  files: any[] = [];
  errors: [];
  fileUploadError: boolean = false;

  confirmationData = {
    open: false,
    title: '',
    hideFooter: false
  };

  constructor(
    private http: ApiAuthHttpClient,
    private toastrService: ToastrService,
    private alertService:  AlertService,
    @Inject(ENVIRONMENT_CONFIG) private env: EnvironmentType
  )
  {
  }

  ngOnInit()
  {
  }

  defaultFile()
  {
    // used to trigger input change detection due to uploading of same file after deletion not doing so

    this.fileUploaderForm.controls.fileUploader.patchValue('');
  }

  onFileDropped($event)
  {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(target: any)
  {
    //const files = <HTMLInputElement>target.files;
    this.prepareFilesList(Array.from(target.files));
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number)
  {
    this.files.splice(index, 1);
  }

  uploadFiles()
  {
    if (this.uploadConfig.url) {
      this.files.forEach((file, index) =>
      {
        const formData: any = new FormData();
        formData.append('file', file);
        formData.append('fileId', this.getUniqueId(1));
        formData.append('type', this.ownerType);
        formData.append('userId', this.ownerId);
        formData.append('batchId', this.batchId);

        this.http
          .post(
            this.uploadConfig.url,
            formData,
            {
              reportProgress: true,
              observe: 'events' as 'body'
            }
          )
          .pipe(
            tap((event: any) =>
            {
              const cast = event as HttpEvent<any>;

              switch (cast.type) {
                case HttpEventType.Sent:
                  break;
                case HttpEventType.ResponseHeader:
                  break;
                case HttpEventType.UploadProgress:
                  this.uploading.emit();

                  this.files[index].progress = Math.round(
                    // @ts-ignore
                    (cast.loaded / cast.total) * 100
                  );

                  break;
                case HttpEventType.Response:
                  this.success.emit(cast.body);
                  setTimeout$(() =>
                  {
                    this.files[index].loaded = true;
                  }, 2000);
              }
            }),
            catchError((error) =>
            {
              this._error = error;
              this.files[index].error = true;
              this.toastrService.error(
                'Sorry, something went wrong - please try again in a couple of minutes.'
              );

              return error;
            }),
            finalize(() =>
            {
              if (!this._error) {
                setTimeout$(() =>
                {
                  this.showCompetitionConfirm.emit(true);
                }, this.files.length * 325);
              }
            })
          )
          .subscribe();
      });
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>)
  {
    const filesArray = [...files];
    filesArray.forEach((file, index) =>
    {
      file.errors = [];

      if (index + 1 > this.uploadConfig.maxFiles) {
        file.errors.push('Only one file for upload allowed.');
      }

      if (this.uploadConfig.acceptedFiles) {
        let isAccepted = false;
        const acceptedFilesArray =
          this.uploadConfig.acceptedFiles.split(',');
        acceptedFilesArray.forEach((acceptedFile) =>
        {
          if (acceptedFile === file.type) {
            isAccepted = true;
          }
        });

        if (!isAccepted) {
          file.errors.push('File format is not allowed.');
        }
      }

      if (file.size > this.uploadConfig.maxFileSize) {
        file.errors.push('File size is too large.');
      }

      if (file.errors.length) {
        this.fileUploadError = false;
        this.alertService.notification([file.errors[0]]);
      } else {
        this.fileUploadError = true;
        file.progress = 0;
        this.files.push(file);
      }
    });

    // added this temporarily to ensure parent components with no function for confirmUpload emit can handle uploading files
    if (this.fileUploadError) {
      this.confirmBeforeUpload
        ? this.confirmUpload.emit(true)
        : this.uploadFiles();
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals)
  {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    );
  }

  /**
   * generate groups of 4 random characters
   * @example getUniqueId(1) : 607f
   * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
   */
  getUniqueId(parts: number): string
  {
    const stringArr: string[] = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }
}
