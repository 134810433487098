import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AccountConfirmation, BaseBlockComponent } from '@upero/misc';


@Component({
  selector: 'upero-proxy-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.scss']
})
export class AccountConfirmationComponent extends BaseBlockComponent<AccountConfirmation> implements AfterViewInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('uperoProxyContainer', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver)
  {
    super();
  }

  async ngAfterViewInit()
  {
    if (this.viewContainerRef) {
      const { AccountConfirmationComponent } = await import('@upero/user');
      const comp = await this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(AccountConfirmationComponent));
    }
  }
}
