import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@upero/store';
import { BreadcrumbsService, CmsService, FavouritesService, ProductService, SeoService } from '@upero/services';
import { BaseBlockComponent, ProductRangeDetailFields } from '@upero/misc';

@Component({
  selector: 'upero-product-range-detail',
  templateUrl: './product-range-detail.component.html',
  styleUrls: ['./product-range-detail.component.scss']
})
export class ProductRangeDetailComponent extends BaseBlockComponent<ProductRangeDetailFields> implements OnInit, OnDestroy
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  productRange;
  products = [];
  favourites$ = this.store.select<any[]>('favourites');
  isLoaded = false;
  public destroyed = new Subject<any>();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductService,
    private favouritesService: FavouritesService,
    private breadcrumbsService: BreadcrumbsService,
    private seoService: SeoService,
    private cmsService: CmsService
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.getProductRanges();
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroyed)
      )
      .subscribe((event) => this.getProductRanges());
  }

  getProductRanges()
  {
    this.productsService
      .getProductRange(this.block.fields.rangeSlug)
      .subscribe(data =>
        {
          this.productRange = data.data;
          this.isLoaded = true;
          this.breadcrumbsService.changeBreadcrumb([
            { text: 'Spaces', route: ['/spaces'] },
            { text: this.productRange.name }
          ]);
          this.productsByRange(this.productRange);
          this.setMetaTags(data.data);
          this.cmsService.broadCastBlockData.next({
            target: 'page-intro',
            data: {
              backgroundImgSrc: this.productRange.bannerUrl,
              title: this.productRange.name,
              text: this.productRange.bannerText
            }
          })
        }
      );
  }

  productsByRange(productRange)
  {
    this.productsService.productsByRange(productRange).subscribe(data =>
    {
      this.products = data.data;
    });
  }

  toggleFavourite(productId)
  {
    const favourites = this.store.selectForLocal('favourites');
    if (favourites.indexOf(productId) < 0) {
      this.favouritesService.addToFavourites(productId);
    } else {
      this.favouritesService.deleteFavourite(productId);
    }
  }

  setMetaTags(productRange)
  {
    this.seoService.updateTitle(productRange.seoTitle);
    this.seoService.addMetaTag('description', productRange.seoDescription);
    this.seoService.createLinkForCanonicalURL();
  }

  ngOnDestroy(): void
  {
    this.destroyed.next(true);
    this.destroyed.complete();
  }
}
