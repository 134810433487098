import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    HostBinding, OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { BaseBlockComponent, ProductDetailsFields } from '@upero/misc';
import { Store } from '@upero/store';
import { AddedToBasketComponent } from '@upero/basket';
import {
    PriceBandModalComponent
} from '@upero/product';
import {
    BespokeFormModalComponent
} from '@upero/product';
import {
    ColourVariationsModalComponent
} from '@upero/product';
import {
    CompetitionEntryComponent
} from '@upero/product';

@Component({
    selector: 'upero-proxy-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent extends BaseBlockComponent<ProductDetailsFields> implements AfterViewInit, OnInit
{
    @HostBinding('attr.class') get classes()
    {
        return this.cssClass;
    };

    @ViewChild('uperoProxyContainer', { read: ViewContainerRef, static: true }) viewContainerRef: ViewContainerRef;

    @ViewChild('uperoAddedToBasket', { read: ViewContainerRef, static: true }) uperoAddedToBasket!: ViewContainerRef;
    private addedToBasketFactory;

    @ViewChild('uperoPriceBandModal', { read: ViewContainerRef, static: true }) uperoPriceBandModal!: ViewContainerRef;
    private uperoPriceBandModalFactory;

    @ViewChild('uperoBespokeFormModal', { read: ViewContainerRef, static: true }) uperoBespokeFormModal!: ViewContainerRef;
    private uperoBespokeFormModalFactory;

    @ViewChild('uperoColourVariationsModal', { read: ViewContainerRef, static: true }) uperoColourVariationsModal!: ViewContainerRef;
    private uperoColourVariationsModalFactory;

    @ViewChild('uperoCompetitionEntry', { read: ViewContainerRef, static: true }) uperoCompetitionEntry!: ViewContainerRef;
    private uperoCompetitionEntryFactory;

    overlays$ = this.store.select<any>('overlays');

    constructor(private store: Store, private componentFactoryResolver: ComponentFactoryResolver)
    {
        super();
    }

    async ngAfterViewInit()
    {
        if (this.viewContainerRef) {
            const { ProductDetailsComponent } = await import('@upero/product');
            this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(ProductDetailsComponent));
        }
    }

    async ngOnInit()
    {
        const { AddedToBasketComponent } = await import('@upero/basket');
        this.addedToBasketFactory = this.componentFactoryResolver.resolveComponentFactory(AddedToBasketComponent);

        const { PriceBandModalComponent, BespokeFormModalComponent, ColourVariationsModalComponent, CompetitionEntryComponent } = await import('@upero/product');
        this.uperoPriceBandModalFactory = this.componentFactoryResolver.resolveComponentFactory(PriceBandModalComponent);
        this.uperoBespokeFormModalFactory = this.componentFactoryResolver.resolveComponentFactory(BespokeFormModalComponent);
        this.uperoColourVariationsModalFactory = this.componentFactoryResolver.resolveComponentFactory(ColourVariationsModalComponent);
        this.uperoCompetitionEntryFactory = this.componentFactoryResolver.resolveComponentFactory(CompetitionEntryComponent);

        this.overlays$.subscribe(data => {
            if (data.addedToBasket) {
                this.uperoAddedToBasket.createComponent(this.addedToBasketFactory);
            } else this.uperoAddedToBasket.clear();

            if (data.priceBandModal) {
                this.uperoPriceBandModal.createComponent(this.uperoPriceBandModalFactory);
            } else this.uperoPriceBandModal.clear();

            if (data.bespokeForm) {
                this.uperoBespokeFormModal.createComponent(this.uperoBespokeFormModalFactory);
            } else this.uperoBespokeFormModal.clear();

            if (data.colourVariationsModal) {
                this.uperoColourVariationsModal.createComponent(this.uperoColourVariationsModalFactory);
            } else this.uperoColourVariationsModal.clear();

            if (data.enterCompetition) {
                this.uperoCompetitionEntry.createComponent(this.uperoCompetitionEntryFactory);
            } else this.uperoCompetitionEntry.clear();
        });

    }
}
