<article *ngIf="entry" class="py-5">
    <div class="container container--article">
        <h1 class="title display-6 fw-400 text-center mx-auto mb-2">{{entry.contents.title}}</h1>
        <div class="fw-400 text-center mb-4 mb-lg-5">Advice</div>
        <div class="lead text-center mb-4 mb-lg-5">
          {{entry.contents.description}}
        </div>
    </div>
    <aside *ngIf="entry.contents.images">
        <div class="mb-4 mb-lg-5">
            <upero-image-slider-fw [images]="entry.contents.images" [previewParagraph]="entry.contents.description"></upero-image-slider-fw>
        </div>
    </aside>
    <section class="container container--article mb-4" [innerHTML]="entry.contents.content"></section>
    <div class="d-flex justify-content-center">
      <a class="btn"
              [ngClass]="currentBrand ? 'btn-' + currentBrand.settings.colors.primary : 'btn-primary'"
              [uperoBrandRouterLink]="['/product-groups']">Browse products</a>
    </div>

</article>

<!--<div *ngIf="block.fields.productLink" class="d-flex justify-content-center mb-5">
    <a class="btn btn-primary btn-wide fw-bold"
       [uperoBrandRouterLink]="['/product-groups']">
        View Products
    </a>
</div>-->

<!--
<aside *ngIf="linkedCaseStudies.length > 0">
    <div class="bg-slate-10">
        <div class="container g-0 p-5">
            <h2 class="fs-5 fw-bold text-center text-xl-start ps-xl-4 mb-5">Related Case Studies</h2>
        </div>
    </div>
</aside>
-->
