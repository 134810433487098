import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@upero/store';
import { AppService, MenuService, OverlayService, ProductService, UserService } from '@upero/services';
import { CollectionsService } from '@upero/collections';
import { RequestCreateComponent } from '../../../../user/src/lib/requests/request-create/request-create.component';
import { UserEditComponent } from '@upero/user';

@Component({
    selector: 'upero-account-layout',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit
{
    overlays$ = this.store.select<any>('overlays');
    alerts$ = this.store.select<any>('alerts');
    asideOpen$ = this.store.select<boolean>('asideOpen');
    deviceType$ = this.store.select<string>('deviceType');
    mobileMenu$ = this.store.select<boolean>('mobileMenu');
    mobileMenuAccount$ = this.store.select<boolean>('mobileMenuAccount');
    mobileMenuUser$ = this.store.select<boolean>('mobileMenuUser');
    step = 'dashboard';
    user;
    items = [];
    productGroups = [];

    @ViewChild('uperoRequestCreate', {
        read: ViewContainerRef,
        static: false
    }) uperoRequestCreate: ViewContainerRef;
    private uperoRequestCreateFactory;

    @ViewChild('uperoUserEdit', {
        read: ViewContainerRef,
        static: false
    }) uperoUserEdit: ViewContainerRef;
    private uperoUserEditFactory;

    constructor(
        private store: Store,
        private userService: UserService,
        private menuService: MenuService,
        private appService: AppService,
        public overlayService: OverlayService,
        public collectionsService: CollectionsService,
        public productService: ProductService,
        private componentFactoryResolver: ComponentFactoryResolver
    )
    {
    }

    async ngOnInit()
    {
        this.menuService.menuGroups();
        this.appService.initApp();
        this.userService.getByToken().subscribe(userData => this.user = userData.data);

        this.collectionsService
            .getCollectionEntriesByType('Upero Paving Navbar', 1)
            .subscribe(response => this.items = response)

        this.productService
            .getProductGroups()
            .subscribe(data => this.productGroups = data.data);


        const { RequestCreateComponent, UserEditComponent } = await import('@upero/user');
        this.uperoRequestCreateFactory = this.componentFactoryResolver.resolveComponentFactory(RequestCreateComponent);
        this.uperoUserEditFactory = this.componentFactoryResolver.resolveComponentFactory(UserEditComponent);


        this.overlays$.subscribe(data =>
        {
            if (data.requestCreate) {
                this.uperoRequestCreate.createComponent(this.uperoRequestCreateFactory);
            } else this.uperoRequestCreate.clear();
            if (data.userEdit) {
                this.uperoUserEdit.createComponent(this.uperoUserEditFactory);
            } else this.uperoUserEdit.clear();
        });

    }

    navigationChange(e)
    {
        this.step = e;
    }
}
