import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BaseBlockComponent, BasketReloadFields } from '@upero/misc';

@Component({
  selector: 'upero-proxy-basket-reload',
  templateUrl: './basket-reload.component.html',
  styleUrls: ['./basket-reload.component.scss']
})
export class BasketReloadComponent extends BaseBlockComponent<BasketReloadFields> implements AfterViewInit
{
  @HostBinding('attr.class') get classes()
  {
    return this.cssClass;
  };

  @ViewChild('uperoProxyContainer', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver)
  {
    super();
  }

  async ngAfterViewInit()
  {
    if (this.viewContainerRef) {
      const { BasketReloadComponent } = await import('@upero/basket');
      this.viewContainerRef.createComponent(this.componentFactoryResolver.resolveComponentFactory(BasketReloadComponent));
    }
  }
}
