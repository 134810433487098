import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@upero/store';

@Component({
  selector: 'upero-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit, AfterViewInit {
  @ViewChild('headerMobile') headerMobile: ElementRef;
  user$ = this.store.select<any>('user');
  basket$ = this.store.select<any>('basket');
  notifications$ = this.store.select<any>('notifications');
  chatStore$ = this.store.select<any>('chatStore');
  settings$ = this.store.select<any>('settings');
  siteSettings = this.store.selectForLocal('siteSettings');
  deviceType$ = this.store.select<string>('deviceType');
  favourites$ = this.store.select<string>('favourites');
  currentBrand = this.store.selectForLocal('currentBrand');
  headerOverflow: boolean;
  showPromoBar = false;

  confirmationData = {
    open: false,
    title: '',
    detail: '',
    data: undefined
  };

  prevScrollpos;
  brand: any;
  private isBrowser: boolean;

  constructor(
    private store: Store,
    public router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    const currentUrl = this.router.url;
    this.showPromoBar = currentUrl === '/';

    this.route
      .data
      .subscribe(data => {
        this.brand = data.brand;
      });
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.prevScrollpos = window.pageYOffset;
    }
  }

  toggleMenu() {
    this.store.set('mobileMenu', true);
  }

  toggleMenuUser() {
    this.store.set('mobileMenuUser', true);
  }
}
