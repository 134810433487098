<div class="position-relative">
  <div class="image-as-bg__wrapper">
    <img class="image-as-bg"
         [src]="'https://ys-assets.b-cdn.net/cms/images/ec57c9f1710a5daa10511ab568adf3c84a.JPG'"
         [appScrset]="'https://ys-assets.b-cdn.net/cms/images/ec57c9f1710a5daa10511ab568adf3c84a.JPG'"
         [width]="1920"/>
  </div>
  <div class="intro-overlay bg-dark"></div>
  <div class="intro-text__wrapper pt-5">
    <div class="d-flex flex-column justify-content-center align-items-center text-center px-3 px-lg-0 py-4">
      <h1 class="display-4 text-white text-shadow mb-5">
        Discounts, rewards & more?<br class="d-none d-lg-block">
        Join Upero Rewards
      </h1>
      <a [uperoBrandRouterLink]="['/register']"
         class="btn btn-primary btn-wide fw-bold mb-5">
        Sign up for free
      </a>
    </div>
    <div class="d-flex flex-column align-items-center position-relative w-100">
      <img [src]="'https://api.ysdev.co.uk/cms/images/02f26ab10358c35cfa466abf75a9778c4.png'"
           [appScrset]="'https://api.ysdev.co.uk/cms/images/02f26ab10358c35cfa466abf75a9778c4.png'"
           [width]="900"
           class="img-screen px-3 px-lg-0">
      <div class="img-screen-bg bg-light"></div>
    </div>
    <div class="bg-light">
      <div class="container g-4 g-lg-0">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-xxl-6 py-5">
            <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start w-100">
              <svg-icon
                src="assets/images/icon-present.svg"
                [svgClass]="'icon--present'"
                [class]="'d-none d-md-block ms-md-5 ms-lg-0 me-5'">
              </svg-icon>
              <div class="d-flex flex-column text-sm-center text-md-start">
                <div class="fs-4 fw-bold mb-4">
                  If you’ve not heard about Upero Rewards yet then you’re in for a treat. There are loads of benefits to
                  being a member, from special gifts to 10% discount – and it couldn’t be easier to start earning.
                </div>
                <div class="lh-lg">
                  Everything you do, from sharing your Upero experience online through a trusted review website such as
                  Trust Pilot to Google, to repeat purchases will all add up. And it’s all easily managed through your
                  My Account. You can shop across any of our brands and access the same benefits!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
