<ng-container *ngIf="block">
  <div class="container-fluid container--1920 position-relative" [ngClass]="showDots ? 'pt-5 pb-0' : 'py-5'">
    <h2 *ngIf="block.fields.title" class="fs-3 fw-600 text-center mb-3">{{block.fields.title}}</h2>
    <div
      class="keen-slider"
      style="height: 100px;"
      #sliderRef>
      <div
        class="keen-slider__slide preview-tile d-flex align-items-center px-1 px-md-2 px-lg-4"
        [ngClass]="{'active' : gi === currentSlide}"
        *ngFor="let item of block.fields.items; let gi = index"
      >
        <div class="slider__container d-flex flex-column justify-content-center align-items-center">
          <img class="slider__img" [src]="item.image" [appScrset]="item.image" [width]="250" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showDots" class="d-flex justify-content-center py-5">
    <button
      (click)="slider.moveToSlideRelative(i)"
      *ngFor="let slide of dotHelper; let i = index"
      [class]="'dot ' + (i === currentSlide ? 'active' : '')"
    ></button>
  </div>
</ng-container>
