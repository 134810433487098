import { Component, Input } from "@angular/core";
import { Router } from '@angular/router';
import { AlertService, NotificationService, OverlayService } from '@upero/services';
import { Store } from '@upero/store';
import { Notification } from './notification.model';
import { AlertConfirmData, defaultAlertConfirmData } from '@upero/misc';

@Component({
  selector: 'upero-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent
{
  @Input() showRead = false;

  deviceType$ = this.store.select<string>('deviceType');
  notifications$ = this.store.select<{ unread: 0, data: Notification[] }>('notifications');

  constructor(
    private overlayService: OverlayService,
    private store: Store,
    private notificationService: NotificationService,
    private alertService: AlertService,
    private router: Router,
  )
  {
    this.notificationService.init().subscribe();
  }

  public delete(notification: Notification, $event: MouseEvent)
  {
    $event.stopPropagation();
    const notifications = this.store.selectForLocal('notifications');
    notifications.data = notifications.data.filter(item => item.id !== notification.id);
    notifications.unread--;
    this.store.set('notifications', notifications);
    this.notificationService.delete(notification.id).subscribe();
  }

  markAllAsRead()
  {
    this.alertService
      .confirm({
          ...defaultAlertConfirmData,
          title: 'Are you Sure',
          confirmText: "Yes",
          confirmClass: "btn-danger",
          confirmCb: this.clearNotifications.bind(this)
        } as AlertConfirmData
      );
  }

  private clearNotifications()
  {
    const user = this.store.selectForLocal('user');
    this.notificationService.markAllAsRead(user.id).subscribe((res) =>
    {
      if (res.data) {
        this.store.set('notifications', {
          ...this.store.selectForLocal('notifications'),
          unread: 0
        });

        this.overlayService.closeAll();
      }
    });
  }

  public notificationClickHandler(notification: Notification): void
  {
    if (notification.orderId) {
      this.router.navigate(['/account', 'orders', notification.orderId]);
    }
  }
}
